var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Reports = Pmp.Manager.Reports || {};
Pmp.Manager.Reports.Tally = Pmp.Manager.Reports.Tally || {};

Pmp.Manager.Reports.Tally.List = {
	initialize : function(manager_base_url, currency_symbol, tally_list, tally_by_date_list) {
		this._base = Pmp.Manager.Reports.Base;
		this._base.initialize();

		this._manager_base_url = manager_base_url;
		this._currency_symbol = currency_symbol;

		this._tally_list = tally_list;
		this._tally_by_date_list = tally_by_date_list;

		this._bindEventHandlers();
		this._initCharts();
	},

	log : function(msg) {
		console.log("Pmp.Manager.Reports.Tally.List: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var clickGoBtnFn = function(event) {self._clickGoBtn(event);};

		$('#summary-report-submit-btn').click(clickGoBtnFn);
	},

	_initCharts : function() {
		var self = this;
		/* Callback that creates and populates a data table,
		 * instantiates the pie chart, passes in the data and
		 * draws it.
		 **/
		Pmp.Utils.CreateChart(function(){self._drawCharts();});
	},

	_drawCharts : function() {
		this._drawFemalesByName();
		this._drawMalesByName();
		this._drawTalliesByDate();
	},

	_drawFemalesByName : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Name');
		data.addColumn('number', 'Females');

		var tallyRows = [];
		for (var i = 0; i < this._tally_list.length; i++) {
			var tally = this._tally_list[i];
			var tallyRow = [
			    tally['name'],
			    tally['num_females']
			];
			tallyRows.push(tallyRow);
		}
		data.addRows(tallyRows);

		var formatter = new google.visualization.NumberFormat({groupingSymbol: ',', fractionDigits:0});
	    formatter.format(data, 1);

		var options = {
		        width:445,
		        height:189
		};

		var chart = new google.visualization.PieChart(document.getElementById('females-by-name'));
		chart.draw(data, options);
	},

	_drawMalesByName : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Name');
		data.addColumn('number', 'Males');

		var tallyRows = [];
		for (var i = 0; i < this._tally_list.length; i++) {
			var tally = this._tally_list[i];
			var tallyRow = [
			    tally['name'],
			    tally['num_males']
			];
			tallyRows.push(tallyRow);
		}
		data.addRows(tallyRows);

		var formatter = new google.visualization.NumberFormat({groupingSymbol: ',', fractionDigits:0});
	    formatter.format(data, 1);

		var options = {
		        width:445,
		        height:189
		};

		var chart = new google.visualization.PieChart(document.getElementById('males-by-name'));
		chart.draw(data, options);
	},

	_drawTalliesByDate : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Date');
		data.addColumn('number', 'Males');
		data.addColumn('number', 'Females');

		var tallyRows = [];
		for (var i = 0; i < this._tally_by_date_list.length; i++) {
			var tally = this._tally_by_date_list[i];
			var tallyRow = [
			    tally['date'],
			    tally['num_males'],
			    tally['num_females']
			];
			tallyRows.push(tallyRow);
		}
		data.addRows(tallyRows);

		var formatter = new google.visualization.NumberFormat({groupingSymbol: ',', fractionDigits:0});
	    formatter.format(data, 1);
	    formatter.format(data, 2);

	    var single_date = (this._tally_by_date_list.length == 1);

		var options = {
		        width:900,
		        height:240,
		        chartArea: {top:25},
		        hAxis: {
		        	title: 'Date',
		        	slantedText:!single_date,
		        	showTextEvery:true,
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        vAxis: {
		        	title: '',
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        fontSize: 11
		};
		var chart = null;
		if (single_date) {
			// unfortunately area charts break down with single dates
			chart = new google.visualization.ColumnChart(document.getElementById('males-and-females'));
		} else {
			chart = new google.visualization.AreaChart(document.getElementById('males-and-females'));
		}
		chart.draw(data, options);
	},

	_clickGoBtn : function(event) {
		if (this._base.validateCalendars()) {
      this._base.showLoadingBar();
			var from_date_url = this._base.getFromDateUrl();
			var to_date_url = this._base.getToDateUrl();
			var relativeUrl = this._manager_base_url + "/reports/tally/"+ from_date_url +"/"+ to_date_url;
      sessionStorage.setItem('tally_url', relativeUrl);
			Pmp.Client.Static.Page.Navigate('#nightloop', relativeUrl, undefined, true /*break ajax*/);
		}
	}
};
