var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Access = Pmp.Manager.Access || {};
Pmp.Manager.Access.User = Pmp.Manager.Access.User || {};

Pmp.Manager.Access.User.Edit = {
    SUPERUSER: 'SUPERUSER',
    MANAGER: 'MANAGER',
    SUBMANAGER: 'SUBMANAGER',
    USERMANAGER: 'USERMANAGER',
    ACTUALS: 'ACTUALS',
    BASIC: 'BASIC',
    BASIC_IPAD: 'BASIC_IPAD',

    initialize: function (is_new_user) {
        this.initialValues = Object.create(null)
        $('.addl-options input').each(function (i, e) {
            this.initialValues[e.name] = e.checked
        }.bind(this))
        this._is_new_user = is_new_user;
        this._is_in_validation_mode = false;

        this._bindEventHandlers();
        this.updatePrivilegeLevelOptions(is_new_user);
    },

    log: function (msg) {
        console.log("Pmp.Manager.Access.User.Edit: " + msg);
    },

    debug: function (msg) {
        if (Pmp.Settings.DEBUG) {
            this.log(msg);
        }
    },

    _bindEventHandlers: function () {
        this.debug("_bindEventHandlers");

        var self = this;
        var clickRemoveAccessBtnFn = function (event) {
            self._clickRemoveAccessBtn(event, $(this));
        }
        var clickCancelRemoveAccessBtnFn = function (event) {
            self._clickCancelRemoveAccessBtn(event);
        };
        var clickRemoveAccessConfirmBtnFn = function (event) {
            self._clickRemoveAccessConfirmBtn(event);
        };
        var clickAddAnotherBtnFn = function (event) {
            self._clickAddAnotherBtn(event);
        };
        var changeSelectPrivilegeLevelFn = function (event) {
            self._changeSelectPrivilegeLevel(event, $(this));
        };
        var submitHandlerFn = function () {
            self._submitHandler($(this).closest('form'));
        };
        var forcedLogoutResetHandlerFn = function () {
            self._forcedLogoutResetHandler($(this).closest('form'));
        };
        var keyupValidateFieldFn = function (event) {
            self._validateForm();
        };
        var updateRemoveVenueText = function (event) {
            self._updateRemoveVenueText(event);
        };

        /* action handlers */
        $('#remove-access').click(clickRemoveAccessBtnFn);
        $('.remove-user-access-cancel').click(clickCancelRemoveAccessBtnFn);
        $('#remove-user-access-confirm-btn').click(clickRemoveAccessConfirmBtnFn);
        $('#select-privilege-level').change(changeSelectPrivilegeLevelFn);
        $('.first-name').keyup(keyupValidateFieldFn);
        $('.last-name').keyup(keyupValidateFieldFn);
        $('.email').keyup(keyupValidateFieldFn);

        $('#save-user-access-edit-btn').click(submitHandlerFn)
        $('#force-logout-reset').click(forcedLogoutResetHandlerFn)
        $('#create-and-add-user-access-btn').click(clickAddAnotherBtnFn)

        // sub option
        $('.book-guestlist').on('change', function () {
            if ($(this).prop('checked')) {
                $('.guestlist-options-label').removeClass('no-display');
            } else {
                $('.book-guestlist').prop('checked', false);
                $('.guestlist-options-label').addClass('no-display');
            }
        });

        $('.tag-client-edit').on('change', function () {
            if ($(this).prop('checked')) {
                $('.tag-client-edit-restricted-label').removeClass('no-display');
            } else {
                $('.tag-client-edit-restricted').prop('checked', false);
                $('.tag-client-edit-restricted-label').addClass('no-display');
            }
        });

        $('.access-marketing-tools').on('change', function () {
            const currentPrivLevel = $('#select-privilege-level').val();
            if ($(this).prop('checked') && [self.SUPERUSER, self.MANAGER, self.SUBMANAGER].includes(currentPrivLevel)) {
                $('.email-campaigns-with-global-tags-label').removeClass('no-display');
            } else {
                $('input[name=f_emails_with_global_tags]').prop('checked', false);
                $('.f_emails_with_global_tags').toggleClass('checked', false);
                $('.email-campaigns-with-global-tags-label').addClass('no-display');
            }
        });

        $('.access-client-tab').on('change', function () {
            const currentPrivLevel = $('#select-privilege-level').val();
            if ($(this).prop('checked') && [self.MANAGER, self.SUBMANAGER, self.BASIC, self.ACTUALS, self.BASIC_IPAD].includes(currentPrivLevel)) {
                $('.view-clients-across-venue-group-label').removeClass('no-display');
                $('input[name=f_view_clients_across_venue_group]').prop('checked', true);
                $('.f_view_clients_across_venue_group').toggleClass('checked', true);
            } else {
                $('input[name=f_view_clients_across_venue_group]').prop('checked', false);
                $('.f_view_clients_across_venue_group').toggleClass('checked', false);
                $('.view-clients-across-venue-group-label').addClass('no-display');
            }
        });

        $('.access-operations-tab').on('change', function () {
            const currentPrivLevel = $('#select-privilege-level').val();
            if ($(this).prop('checked') && [self.SUBMANAGER, self.BASIC, self.ACTUALS, self.BASIC_IPAD].includes(currentPrivLevel)) {
                $('.access-requests-tab-label').removeClass('no-display');
                $('input[name=f_access_requests_tab]').prop('checked', true);
                $('.f_access_requests_tab').toggleClass('checked', true);
            } else {
                $('input[name=f_access_requests_tab]').prop('checked', false);
                $('.f_access_requests_tab').toggleClass('checked', false);
                $('.access-requests-tab-label').addClass('no-display');
            }
        });


        $('.access-vip-chat').on('change', function () {
            const currentPrivLevel = $('#select-privilege-level').val();
            if ($(this).prop('checked') && [self.MANAGER, self.SUBMANAGER, self.BASIC].includes(currentPrivLevel)) {
                $('.send-vip-chat-label').removeClass('no-display');
            } else {
                $('input[name=f_send_vip_chat').prop('checked', false);
                $('.f_send_vip_chat').toggleClass('checked', false);
                $('.send-vip-chat-label').addClass('no-display');
            }
        });

        $('.receive-concierge-email').on('change', function () {
            var isChecked = $(this).prop('checked');
            $('.receive-confirm-confirmation').prop('checked', isChecked);
            $('.receive-confirm-confirmation-label').toggleClass('no-display', !isChecked);
        });

        $('.receive-internal-res-alert-email').on('change', function () {
            var isChecked = $(this).prop('checked');
            $('.make-request-follower').prop('checked', isChecked);
            if(isChecked){
                $('.f_make_request_follower').addClass('checked');
            }else{
                $('.f_make_request_follower').removeClass('checked');
            }
        });

        $('.create-same-access').on('change', function () {
            var isChecked = $(this).prop('checked');
            $('.create-same-access-label').toggleClass('no-display', !isChecked);
            updateRemoveVenueText();
        });

        $('.check-all-venues').on('change', function () {
            var isChecked = $(this).prop('checked');
            var $venueListItem = $('.venue-list-item');
            $venueListItem.closest('div').toggleClass('checked', isChecked);
            $venueListItem.prop('checked', isChecked);
            updateRemoveVenueText();
        });

        $('.venue-list-item').on('change', function () {
            var allVenuesChecked = $('.venue-list-item:checked').length == $('.venue-list-item').length;
            $('.check-all-venues').closest('div').toggleClass('checked', allVenuesChecked);
            $('.check-all-venues').prop('checked', allVenuesChecked);
            updateRemoveVenueText();
        });

        $('.make-reservation-follower').on('change', function () {
            if ($(this).prop('checked')) {
                $('input[name=f_make_reservation_booker_follower').prop('checked', true);
                $('.f_make_reservation_booker_follower').toggleClass('checked', true);
                $('.make-reservation-booker-follower').addClass('disabled');
            } else {
                $('input[name=f_make_reservation_booker_follower').prop('checked', false);
                $('.f_make_reservation_booker_follower').toggleClass('checked', false);
                $('.make-reservation-booker-follower').removeClass('disabled');
            }
        });

        this._updateSubOptions();
    },

    _updateSubOptions: function () {
        $('.book-guestlist').trigger('change');
        $('.tag-client-edit').trigger('change');
        $('.access-marketing-tools').trigger('change');
        $('.access-vip-chat').trigger('change');
        const accessClientTabChecked = $('input[name=f_access_client_tab]').prop('checked')
        if (accessClientTabChecked) {
            $('.view-clients-across-venue-group-label').removeClass('no-display');
        }
        const accessOperationsTabChecked = $('input[name=f_access_operations_tab]').prop('checked')
        if (accessOperationsTabChecked) {
            $('.access-requests-tab-label').removeClass('no-display');
        }
        const reservationFollowerChecked = $('input[name=f_make_reservation_follower]').prop('checked')
        if (reservationFollowerChecked) {
            $('.make-reservation-follower').trigger('change');
        }
    },

    _clickAddAnotherBtn: function () {
        $('#add-another-user').val('true')
        $('#save-user-access-edit-btn').click();
    },

    _clickRemoveAccessBtn: function (event, element) {
        this.debug('_clickRemoveAccessBtn');
        $('#remove-user-access-confirm').removeClass('no-display');
    },

    _clickCancelRemoveAccessBtn: function (event) {
        this.debug('_clickCancelRemoveAccessBtn');
        $('#remove-user-access-confirm').addClass('no-display');
    },

    _clickRemoveAccessConfirmBtn: function (event) {
        $('#input-do-delete-venue-user').val('true');
        $('#save-user-access-edit-btn').click();
    },

    _setDefaultEmailSubscriptionCheckbox: function (newPrivLevel) {
        if (this._is_new_user === true) {
            var defaultEmailSubscriptionOn = newPrivLevel === this.SUPERUSER || newPrivLevel === this.MANAGER;

            $('input[name=receive_daily_summary_email]').prop('checked', defaultEmailSubscriptionOn);
            $('.receive_daily_summary_email').toggleClass('checked', defaultEmailSubscriptionOn);

            $('input[name=receive_ipad_summary_email]').prop('checked', defaultEmailSubscriptionOn);
            $('.receive_ipad_summary_email').toggleClass('checked', defaultEmailSubscriptionOn);

            $('input[name=receive_api_request_email]').prop('checked', defaultEmailSubscriptionOn);
            $('.receive_api_request_email').toggleClass('checked', defaultEmailSubscriptionOn);

            $('input[name=receive_feedback_summary_email]').prop('checked', defaultEmailSubscriptionOn);
            $('.receive_feedback_summary_email').toggleClass('checked', defaultEmailSubscriptionOn);

            $('input[name=receive_special_occasions_email]').prop('checked', defaultEmailSubscriptionOn);
            $('.receive_special_occasions_email').toggleClass('checked', defaultEmailSubscriptionOn);

            $('input[name=receive_resolution_report_email]').prop('checked', defaultEmailSubscriptionOn);
            $('.receive_resolution_report_email').toggleClass('checked', defaultEmailSubscriptionOn);

            $('input[name=receive_insights_summary_email]').prop('checked', defaultEmailSubscriptionOn);
            $('.receive_insights_summary_email').toggleClass('checked', defaultEmailSubscriptionOn);
        }
    },

    updatePrivilegeLevelOptions: function (doResetOptions) {
        var newPrivLevel = $('#select-privilege-level').val();
        $('.addl-options').addClass('no-display');
        if (doResetOptions === true) {
            $('.addl-options input').prop('checked', false)
            $('.options .form-element').removeClass('checked')
        }
        $('.addl-options-' + newPrivLevel)
            .removeClass('no-display')
            .each(function (i, el) {
                var $input = $('input', $(el));
                if (this.initialValues[$input.attr('name')]) {
                    $input.prop('checked', true)
                    $(el).parent('.form-element').addClass('checked');
                }
            }.bind(this));
        $('.privilege-box').addClass('no-display');
        $('.privilege-box-' + newPrivLevel).removeClass('no-display');
        this._updateEmailOptions(newPrivLevel);
        if (doResetOptions === true) {
            this._updatePermissionCheckbox(newPrivLevel)
        }
        this._updateSubOptions();
    },

    _updatePermissionCheckbox: function (newPrivLevel) {
        const defaultPermissionsPerPrivilegeLevel = {
            [this.SUPERUSER]: {
                'f_make_reservation_booker_follower': true,
                'f_data_client_export': true,
                'f_data_actuals_export': true,
                'f_access_vip_chat': true,
                'f_send_vip_chat': true,
                'f_can_book_outside_of_the_shift_internal_booking_limit': true,
                'f_access_marketing_tools': true,
                'f_emails_with_global_tags': true,
                'f_only_notes': true,
                'f_manage_room_invites': true,
            },
            [this.MANAGER]: {
                'f_general_settings_admin': true,
                'f_reservation_tag_list_admin': true,
                'f_manage_global_auto_tags': true,
                'f_manage_room_invites': true,
                'f_client_tag_list_admin': true,
                'f_access_client_tab': true,
                'f_access_marketing_tools': true,
                'f_emails_with_global_tags': true,
                'f_access_operations_tab': true,
                'f_access_requests_tab': true,
                'f_view_reporting_full': true,
                'f_widget_settings_admin': true,
                'f_shifts_access_rules_admin': true,
                'f_blackout_dates_daily_program_admin': true,
                'f_can_charge': true,
                'f_can_refund': true,
                'f_can_edit_maximum_total_covers_for_shift': false,
                'f_overbook_enforced_shift_party_size': true,
                'f_make_reservation_booker_follower': true,
                'f_guestlist_access': true,
                'f_guestlist_own_access': false,
                'f_guestlist_own_access_not_others': false,
                'f_guestlist_access_all': true,
                'f_access_insights_page': true,
                'f_access_private_events_tab': true,
                'f_hide_actuals_report': false,
                'f_only_notes': false,
            },
            [this.SUBMANAGER]: {
                'f_general_settings_admin': false,
                'f_reservation_tag_list_admin': false,
                'f_manage_global_auto_tags': false,
                'f_manage_room_invites': true,
                'f_client_tag_list_admin': false,
                'f_access_client_tab': true,
                'f_access_operations_tab': true,
                'f_access_requests_tab': true,
                'f_widget_settings_admin': false,
                'f_shifts_access_rules_admin': false,
                'f_blackout_dates_daily_program_admin': false,
                'f_can_charge': true,
                'f_can_refund': true,
                'f_can_edit_maximum_total_covers_for_shift': false,
                'f_overbook_enforced_shift_party_size': false,
                'f_make_reservation_booker_follower': true,
                'f_guestlist_access': true,
                'f_guestlist_own_access': false,
                'f_guestlist_own_access_not_others': false,
                'f_guestlist_access_all': true,
                'f_access_insights_page': false,
                'f_access_private_events_tab': false,
                'f_hide_actuals_report': false,
                'f_only_notes': false,
            },
            [this.BASIC]: {
                'f_access_client_tab': true,
                'f_access_operations_tab': true,
                'f_access_requests_tab': true,
                'f_make_reservation_booker_follower': true,
                'f_guestlist_access': false,
                'f_guestlist_own_access_not_others': true,
                'f_guestlist_own_access': false,
                'f_guestlist_access_all': false,
                'f_hide_actuals_report': false,
                'f_only_notes': false,
            },
            [this.BASIC_IPAD]: {},
        }
        if (newPrivLevel in defaultPermissionsPerPrivilegeLevel) {
            for (const [permission, checked] of Object.entries(defaultPermissionsPerPrivilegeLevel[newPrivLevel])) {
                $('input[name=' + permission + ']').prop('checked', checked);
                $('.' + permission).toggleClass('checked', checked);
            }
        }
    },

    _updateEmailOptions: function (newPrivLevel) {
        var $emailSubTitle = $('#email-subscriptions-title');
        var $emailSubBody = $('#email-subscriptions-body');
        if (newPrivLevel === this.USERMANAGER) {
            $emailSubTitle.hide();
            $emailSubBody.hide();
        } else {
            $emailSubTitle.show();
            $emailSubBody.show();
        }

        this._setDefaultEmailSubscriptionCheckbox(newPrivLevel);
    },

    _updateRemoveVenueText: function () {
        var allVenuesCheckedLen = $('.venue-list-item:checked').length
        var selectedVenueNames = 'Remove user access to ' + Pmp.Manager.Global._venue_name;
        if ($('.create-same-access').prop('checked')) {
            $('.venue-list-item:checked').each(function (index, object) {
                if (index == allVenuesCheckedLen - 1) {
                    selectedVenueNames = selectedVenueNames + ' and ' + $(this)[0].id
                } else {
                    selectedVenueNames = selectedVenueNames + ', ' + $(this)[0].id
                }
            });
        }
        $('#remove-venue-text').text(selectedVenueNames);
    },

    _changeSelectPrivilegeLevel: function (event, element) {
        this.updatePrivilegeLevelOptions(true);
        // Update now-visible fake radio buttons to be serialized in form submission if checked by default
        //$('.form-element.radio-style.checked').click();
    },

    _validateForm: function () {
        if (this._is_new_user === false) {
            return true;
        }
        if (this._is_in_validation_mode === false) {
            return;
        }
        var firstName = $('.first-name').val();
        var validFirstName = !Pmp.Common.Reservation.Validator.isEmpty(firstName);
        if (validFirstName === false) {
            $('#invalid-first-name').removeClass('no-display');
        } else {
            $('#invalid-first-name').addClass('no-display');
        }

        var lastName = $('.last-name').val();
        var validLastName = !Pmp.Common.Reservation.Validator.isEmpty(lastName);
        if (validLastName === false) {
            $('#invalid-last-name').removeClass('no-display');
        } else {
            $('#invalid-last-name').addClass('no-display');
        }

        var email = $('.email').val();
        var validEmail = Pmp.Common.Reservation.Validator.isValidEmail(email);
        if (validEmail === false) {
            $('#invalid-email').removeClass('no-display');
        } else {
            $('#invalid-email').addClass('no-display');
        }

        var isValid = validFirstName && validLastName && validEmail;

        if (isValid === false) {
            $('#invalid-all').removeClass('no-display');
        } else {
            $('#invalid-all').addClass('no-display');
        }

        return isValid;
    },

    _submitHandler: function (formHandle) {
        this._is_in_validation_mode = true;
        var isValid = this._validateForm(formHandle);
        if (isValid === true) {
            $(formHandle).submit();
        } else {
            $('#save-user-access-edit-btn').removeClass('pending disabled');
            $('#create-and-add-user-access-btn').removeClass('pending disabled');
        }
    },

    _forcedLogoutResetHandler: function (formHandle) {
        $(formHandle).append('<input type="hidden" name="force_logout_reset" value="true" />');
        $(formHandle).submit();
    }
};
