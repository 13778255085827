AddCreditCardSlideOut = {
  init : function(manager_base_url, vgc_id, locale) {
    
    this._manager_base_url = manager_base_url;
    this._vgc_id = vgc_id;
    this._locale = locale;
    this._bindEvents();
    this.validator = new sr.Validator('#cc-interface', undefined) // TODO: add currency symbol
    this._onAddCreditCardHandler = null;
  },
  
  _bindEvents : function () {
    var that = this;
    $('#save-card').click(function() {
      that.submit();
    });
  },
  
  onAddCreditCard : function (handler) {
    this._onAddCreditCardHandler = handler;
  },
  
  showAddCreditCard : function() {
    this.clear();
    var that = this;
    Interface.openslide('#cc-interface');
  },
  
  submit : function () {
    if (!this.validator.validate()) {
      return false;
    }
    
    var that = this;
    var $form = $('#add-creditcard-form');
    
    // Construct the parameters to the addCard call.
    var card_data = {
      client_id: that._vgc_id,
      name: $form.find('input[name="cardholder_name"]').val(), 
      number: $form.find('input[name="card_number"]').val(), 
      exp_month: $form.find('select[name="cc_expiration_month"]').val(),
      exp_year: $form.find('select[name="cc_exp_year"]').val(), 
      cvc: $form.find('input[name="card_cvv"]').val(),
      noConnect: true
    };

    var stripe_obj = {
      stripe: this._stripe,
      card: this.stripeMount,
    }
    
    $('#save-card').addClass('disabled');
    
    // Add the card. It returns a deferred which we can attach to.
    var deferred = BillingService.addCard(card_data, stripe_obj);
    deferred.done(function(card) {
      if (that._onAddCreditCardHandler) {
        $('#save-card').removeClass('disabled');
        that._onAddCreditCardHandler(card);
      }
    }).fail(function(error) {
      // addCard failed
      Interface._alert(error);
      $('#save-card').removeClass('disabled');
    });
    return false;
  },
  
  clear : function () {
    $('#cc-interface input').val('');
    $('#cc-interface select').val('');
    $('#save-card').removeClass('disabled');
    this.validator.clear();
    Interface.clear('#cc-interface');
  },
  
  close : function () {
    Interface.closeslide('#cc-interface');
    this.clear();
    return false;
  }
};

