// This file was automatically generated from manager.manage.diningevents.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Settings == 'undefined') { Nightloop.Templates.Manager.Settings = {}; }


Nightloop.Templates.Manager.Settings.DiningEvents = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Settings.DiningEventsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Settings.DiningEventsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'dining_events'}), output);
  output.append('<div id="page-dining-events" class="page-view-mode has-sidebar"><div id="content-data"></div><div><form id="flyout-form" class="styled" method="post" sr-validate="always"></form></div><!-- TODO: Eventually move to one file --><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/manager.diningeventsweekview.js']), '"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/manager.weekview.js']), '"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/manager.formelement.js']), '"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/manager.diningeventslideout.js']), '"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/manager.slideout.js']), '"><\/script><script type="text/javascript">$(document).ready( function() {modules.diningEventsWeekViewManager.init(\'content-data\', \'', soy.$$escapeHtml(opt_data.venue.url_key_or_id), '\', \'', soy.$$escapeHtml(opt_data.MEDIA_URL), '\', \'', soy.$$escapeHtml(opt_data.start_of_day_hour), '\'); modules.diningeventslideout.init(\'', soy.$$escapeHtml(opt_data.venue.locale), '\');})<\/script></div>');
  return opt_sb ? '' : output.toString();
};
