var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Manage = Pmp.Manager.Manage || {};

Pmp.Manager.Manage.VenuePromotersView = {
	initialize : function() {
		this._bindEventHandlers();
	},
	
	log : function(msg) {
		console.log("Pmp.Manager.Manage.VenuePromotersView: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("bindEventHandlers");
	    Pmp.Utils.AddDropdown('#contextual-link-bookedby', '#contextual-content-bookedby');
	}
};