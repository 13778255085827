
var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Manage = Pmp.Manager.Manage || {};
Pmp.Manager.Manage.Perks = Pmp.Manager.Manage.Perks || {};

Pmp.Manager.Manage.Perks.Edit = {
	initialize : function(locale) {
		this._is_in_validation_mode = false;
		this._locale = locale;
		
		this._bindEventHandlers();
		this._initCalendars();
	},
	
	log : function(msg) {
		console.log("Pmp.Manager.Manage.Perks.Edit: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		
		var self = this;
		var clickDeletePerkBtnFn = function(event) {self._clickDeletePerkBtn(event, $(this));}
		var clickCancelDeletePerkBtnFn = function(event) {self._clickCancelDeletePerkBtn(event);};
		var clickDeletePerkConfirmBtnFn = function(event) {self._clickDeletePerkConfirmBtn(event);};
		var submitHandlerFn = function() {self._submitHandler($('#perk-form')); };
		var changeValidateFieldFn = function(event) {self._validateForm(); };
		var clickConciergeAccessCbox = function(event) {self._clickConciergeAccessCbox(event);};
		
		/* action handlers */
		$('#delete-perk-btn').click(clickDeletePerkBtnFn);
		$('.delete-perk-cancel').click(clickCancelDeletePerkBtnFn);
		$('#delete-perk-confirm-btn').click(clickDeletePerkConfirmBtnFn);
		$('input[name=title]').keyup(changeValidateFieldFn);
		$('#start-date').keyup(changeValidateFieldFn);
		$('#start-date').change(changeValidateFieldFn);
		$('#end-date').keyup(changeValidateFieldFn);
		$('#end-date').change(changeValidateFieldFn);
		$('.venue-instructions-textarea').keyup(changeValidateFieldFn);
		$('.dow-offered-cbox').click(changeValidateFieldFn);
		$('.concierge-access-cbox').click(clickConciergeAccessCbox);
		
		$('#save-manage-perks-edit-btn').click(submitHandlerFn);
		
	},
	
	_initCalendars : function() {
		this.debug("_initCalendars");
		Pmp.Utils.LocalizeDatePicker(this._locale, '#start-date', '#start-date-submit');
		Pmp.Utils.LocalizeDatePicker(this._locale, '#end-date', '#end-date-submit');
		$('#ui-datepicker-div').addClass('customize'); /* Theming */
	},
	
	_clickDeletePerkBtn : function(event, element) {
		this.debug('_clickDeletePerkBtn');
		$('#delete-perk-confirm').removeClass('no-display');
	},
	
	_clickCancelDeletePerkBtn : function(event) {
		this.debug('_clickCancelDeletePerkBtn');
		$('#delete-perk-confirm').addClass('no-display');
	},
	
	_clickDeletePerkConfirmBtn : function(event) {
		$('#input-do-delete').val('true');
		$('#perk-form').submit();
	},
	
	_clickConciergeAccessCbox : function(event) {
		if ($('.concierge-access-cbox:checked').length >= 500) {
			$('.concierge-access-cbox:not(:checked)').attr('disabled', 'disabled');
		} else {
			$('.concierge-access-cbox:disabled').attr('disabled', '');
		}
	},
	
	_validateForm : function() {
		if (this._is_in_validation_mode === false) {
			return;
		}
		var isValid = true;
		
		var title = $('input[name=title]').val();
		var validTitle = !Pmp.Common.Reservation.Validator.isEmpty(title);
		if (validTitle === false) {
			$('#invalid-title').removeClass('no-display');
		} else {
			$('#invalid-title').addClass('no-display');
		}
		isValid = isValid && validTitle;
		
		var venueInstructions = $('.venue-instructions-textarea').val();
		var validVenueInstructions = !Pmp.Common.Reservation.Validator.isEmpty(venueInstructions);
		if (validVenueInstructions === false) {
			$('#invalid-venue-instructions').removeClass('no-display');
		} else {
			$('#invalid-venue-instructions').addClass('no-display');
		}
		isValid = isValid && validVenueInstructions;
		
		var url = $('input[name=url]').val();
		var validUrl = Pmp.Common.Reservation.Validator.isValidUrlOrEmpty(url);
		if (validUrl === false) {
			$('#invalid-url').removeClass('no-display');
		} else {
			$('#invalid-url').addClass('no-display');
		}
		isValid = isValid && validUrl;
		
		var startDateInput = $('#start-date');
		var validStartDate = Pmp.Common.Reservation.Validator.isValidDateOrEmpty(startDateInput);
		if (validStartDate === false) {
			$('#invalid-start-date').removeClass('no-display');
		} else {
			$('#invalid-start-date').addClass('no-display');
		}
		isValid = isValid && validStartDate;
		
		var endDateInput = $('#end-date');
		var validEndDate = Pmp.Common.Reservation.Validator.isValidDateOrEmpty(endDateInput);
		if (validEndDate === false) {
			$('#invalid-end-date').removeClass('no-display');
		} else {
			$('#invalid-end-date').addClass('no-display');
		}
		isValid = isValid && validEndDate;
		
		var validDowOffered = $('.dow-offered-cbox:checked').length > 0;
		if (validDowOffered === false) {
			$('#invalid-dow-offered').removeClass('no-display');
		} else {
			$('#invalid-dow-offered').addClass('no-display');
		}
		isValid = isValid && validDowOffered;

		if (isValid === false) {
			$('#invalid-all').removeClass('no-display');
		} else {
			$('#invalid-all').addClass('no-display');
		}
		
		return isValid;
	},
	
	_submitHandler : function(formHandle) {
		this._is_in_validation_mode = true;
		var isValid = this._validateForm(formHandle);
		if (isValid === true) {
			$(formHandle).submit();
		}
	}
};