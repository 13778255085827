// This file was automatically generated from manager.marketingsidebar.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Marketing == 'undefined') { Nightloop.Templates.Manager.Marketing = {}; }


Nightloop.Templates.Manager.Marketing.Sidebar = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="sidebar"></div>\n    <style>\n        #manager #sidebar {\n            width: 198px;\n            left: 80px;\n        }\n        #manager #content-container .has-sidebar {\n            margin-left: 198px;\n        }\n        #manager #content-container.so-above #status h3 {\n            margin-left: 208px;\n        }\n    </style>\n    <script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/manager/settings.js']), '" crossorigin="anonymous"><\/script><script type="text/javascript">$(document).ready(function() {SvrManager.MarketingPage.initLeftNavBarMarketing();})<\/script>');
  return opt_sb ? '' : output.toString();
};
