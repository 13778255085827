var Requests = {
  baseurl: '',
  mediaurl: '',
  isdiningclass: true,
  cursor: null,
  query: null,
  showing: 'all',

  ts: null,
  requests: [],
  active_group: 'active',
  request_groups: {},
  num_requests: 0,
  assign_expanded: false,
  status_expanded: false,
  shift_expanded: false,

  status_settings: 'all',
  search_params: {
    query: null,
    cursor: null,
    venue: null,
    from_date: null,
    to_date: null,
    date: null,
    dates: null,
    source: null,
    expired: null,
    expiring_within: null,
    expired_within: null,
    shift: null,
    status: 'active',
    booked_by: null,
    assigned_to: null,
    priority: null,
    is_held: null,
    has_reservation: null,
    sort_by: $('#sortby-select').val(),
  },

  assigned_people: {},
  assigned_defaults: {
    'Assigned to me': { id: 'me', name: 'Assigned to me' },
    Unassigned: { id: 'unassigned', name: 'Unassigned' },
  },
  filtered_assigned_people: {},
  shifts: [],
  assigned_idx: 0,
  // List for when we are hiding the rest of the list
  assigned_short_list: {},

  init: function () {
    this._global_base_url = $('#base_url').val()
    this.baseurl = this._global_base_url + '/requests'
    this.venueId = $('#venue_id').val()
    this.mediaurl = $('#media_url').val()
    this.isdiningclass = $('#is_dining_class').val() === 'True'
    this.date = $('#current_date').val()
    this.single_day = $('#single_day').val()
    this.basic_user = $('#basic_user').val()
    this.is_bank_setup = $('#is_bank_setup').val() === 'True'
    this.send_email_confirmation_by_default =
      globalInit.venueSettings.send_email_confirmations_default && globalInit.venueSettings.uses_confirmation_email_default
    this.send_sms_confirmation_by_default =
      globalInit.venueSettings.send_email_confirmations_default && globalInit.venueSettings.uses_confirmation_sms_default
    this.internal_ar_booking_enabled = globalInit.venueSettings.internal_ar_booking_enabled
    this.requests_availability_enabled = $('#requests_availability_enabled').val() === 'True'
    this.is_priority_alert_tab = false

    this.search_params.venue = this._global_base_url.split('/')[2]

    if (this.single_day) {
      this.search_params.date = this.date
    } else {
      this.search_params.date = null
    }

    this.bind()
    this.init_temp()

    var self = this

    $('#send-export').on('click', function () {
      var $button = $(this)
      $button.addClass('disabled')

      var data = {
        from: $('#from-calendar-submit').val(),
        to: $('#to-calendar-submit').val(),
      }

      $.ajax({
        url: self.baseurl + '_export',
        data: data,
        method: 'get',
        success: function () {
          $button.removeClass('disabled')
          $('#export-requests').show()
          $('.export-interface').hide()
          $('#confirm-sending').show()
        },
        error: function () {
          $button.removeClass('disabled')
          $('#export-requests').show()
          $('.export-interface').hide()
          $('#export-failure').show()
        },
      })
    })

    this.init_datepickers()
    $('#show-active').find('a').addClass('selected')

    var that = this
    var results_promise = this.searchRequests()
    $.ajax({
      url: that._global_base_url + '/data/bookedbynames',
      success: function (response) {
        that.booked_by_content = response.payload.content
        var people = that.booked_by_content.booked_by_users
        for (var i = 0; i < people.length; i++) {
          that.assigned_people[people[i].id] = people[i]
        }
        RequestSlideout.followers.refreshFollowersSelect(that.booked_by_content.booked_by_users)
        Assigned.refreshSelect(that.booked_by_content.booked_by_users)
      },
    }).done(function () {
      if (that.basic_user) {
        that.search_params.booked_by = 'me'
        // that.setAssignedTo('Assigned to me', false);
        $('.checkbox-section.assigned').hide()
        that.paintRequests(false, true, results_promise)
      } else {
        that.paintRequests(true, true, results_promise)
      }
      that.updateTallies()
    })
    RequestSlideout.init(
      this.venueId,
      this._global_base_url,
      this.isdiningclass,
      this.basic_user,
      this.is_bank_setup,
      this.send_email_confirmation_by_default,
      this.send_sms_confirmation_by_default
    )

    var preload_id = $.getParam('rid')

    if (preload_id) {
      this.preload(preload_id)
    }
  },

  init_temp: function () {
    var that = this
    var form_settings = {
      date_var: 'mm-dd-yy',
    }

    $('#req-date-selector').datepicker({
      altField: '#actual-date-submit,#tab-offer .request_date',
      altFormat: form_settings.date_var,
      minDate: new Date(),
      beforeShow: function () {
        $('#ui-datepicker-div').addClass('customize')
      },
      beforeShowDay: function (date) {
        return Pmp.Manager.Global._beforeShowDay(date)
      },
      onSelect: function (dateText, inst) {
        $('#req-date-display').sext(dateText)
        Pmp.Utils.formatDatePicker('#req-date-selector')
      },
    })

    //remove selected highlight
    $('#close-interface').on('click', function () {
      $('.standard-row').removeClass('selected')
      RequestSlideout.clear()
    })
    $('#interface-client')
      .find('h3 span.toggle')
      .on('click', function () {
        $('#main-interface').toggleClass('min-client')
      })
  },

  init_datepickers: function () {
    var self = this

    var sel = '#request-date'
    var locale = Pmp.Manager.Global._locale
    var dateFmt = Pmp.Utils.dateFormat(locale)
    var dt = Pmp.Utils.parseDateUrlParam(self.date)
    var formattedDate = $.datepicker.formatDate(dateFmt, dt)
    Pmp.Utils.LocalizeDatePicker(locale, sel, sel + '-submit')
    $(sel).datepicker('option', 'defaultDate', formattedDate)
    $(sel).datepicker('option', 'showOtherMonths', true)
    $(sel).datepicker('option', 'selectOtherMonths', true)
    $(sel).datepicker('option', 'onSelect', function (dateText, inst) {
      var d = [inst.currentMonth + 1, inst.currentDay, inst.currentYear].join('/')
      self.setDate(d)
      Pmp.Utils.formatDatePicker('#request-date')
    })
    $(sel).datepicker('option', 'beforeShow', function () {
      $('#ui-datepicker-div').addClass('calendar customize')
    })
    $('#grid-date-selector').datepicker('setDate', formattedDate)
    Pmp.Utils.formatDatePicker(sel)

    var $from_calendar = $('#from-calendar')
    var $to_calendar = $('#to-calendar')
    $from_calendar.datepicker({
      altFormat: 'yy-mm-dd',
      altField: '#from-calendar-submit',
      onSelect: function () {
        Pmp.Utils.formatDatePicker('#from-calendar')
      },
      beforeShow: function () {
        $('#ui-datepicker-div').addClass('calendar customize')
      },
    })

    $to_calendar.datepicker({
      altFormat: 'yy-mm-dd',
      altField: '#to-calendar-submit',
      onSelect: function () {
        Pmp.Utils.formatDatePicker('#to-calendar')
      },
      beforeShow: function () {
        $('#ui-datepicker-div').addClass('calendar customize')
      },
    })

    var current = new Date()
    $to_calendar.datepicker('setDate', current)
    current.setDate(current.getDate() - 7)
    $from_calendar.datepicker('setDate', current)

    Pmp.Utils.formatDatePicker('#to-calendar')
    Pmp.Utils.formatDatePicker('#from-calendar')
  },

  findPerson: function (id, name) {
    id = id || null
    name = name || null
    if (id === null && name === null) {
      return null
    } else {
      var prop = id ? 'id' : 'name'
      var val = id ? id : name
    }
    for (var key in this.assigned_people) {
      if (this.assigned_people[key][prop] === val) {
        return this.assigned_people[key]
      }
    }
    return null
  },

  /**
   * Get a slice of the requests based on the group name. e.g. only active
   * requests, or expired requests, etc.
   */
  getRequestGroup: function (group) {
    if (group === 'all') {
      return this.requests
    } else {
      return this.request_groups[group]
    }
  },

  addAssignedPeople: function (people) {
    var self = this
    var html = ''
    var list = $('.assigned-selector')
    list.html('')
    var key, markup
    var found_selected = false
    for (key in people) {
      if (!people.hasOwnProperty(key)) {
        continue
      }
      var s = people[key]
      if (s.name.trim() === '') {
        continue
      }
      var id = 'show-' + s.id
      var cls = ''
      if (s.id === self.search_params.assigned_to) {
        cls = 'selected'
        found_selected = true
      }
      markup = ['<li id="', id, '"><a href="#" class="', cls, '">', _.escape(s.name), '</a></li>'].join('')
      html += markup
    }
    if (!found_selected) {
      self.search_params.assigned_id = null
    }
    list.append(html)
    for (key in people) {
      if (!people.hasOwnProperty(key)) {
        continue
      }
      s = people[key]
      var idx = 'show-' + s.id
      $('#' + idx).on('click', self.setAssignedTo.bind(self, s.id))
    }
  },

  searchRequests: function (use_cursor) {
    var self = this
    return $.ajax({
      url: '/api-yoa/requests',
      data: self.getSearchParams(use_cursor),
      type: 'get',
    })
  },

  /**
   * Separate requestfor global tally counts. Note that these are only accurate
   * up to a 1000 count.
   */
  searchTallies: function () {
    var self = this
    var data = {
      venue: self.search_params.venue,
    }
    if (self.basic_user && self.search_params.assigned_to !== null) {
      data.assigned_to = 'me'
    }
    if (self.basic_user) {
      data.booked_by = 'me'
    }
    return $.ajax({
      url: '/api-yoa/requests/tallies',
      type: 'get',
      data: data,
    })
  },

  updateTallies: function () {
    var self = this
    self
      .searchTallies()
      .done(function (response) {
        self.tallies = response.data
        self.paintTallies()
      })
      .fail(function () {})
  },

  paintTallies: function () {
    var self = this
    if (self.tallies) {
      for (var status in self.tallies) {
        var id = '#show-' + status.split('_').join('-')
        $(id + ' .num').sext(' (' + self.tallies[status] + ')')
      }
    }
  },

  /**
   * Update Internal state after fetching the list of requests. This will
   * filter for the assigned users and group requests by state.
   */
  updateState: function (update_names, update_counts, results_promise) {
    var self = this
    var d = $.Deferred()
    var success = function (response) {
      self.ts = response.data.ts
      self.setState(response.data.requests, update_counts, update_names, response.data.cursor)
      d.resolve()
    }
    var failure = function () {
      self.requests = []
      console.log('Something went wrong when searching requests!')
      d.fail(function () {
        console.log('Failed to save requests in js')
      })
    }

    results_promise.done(success).fail(failure)
    self.updateTallies()
    return d.promise()
  },

  /**
   * Unpack a response and set all of our data - request, grouping, etc.
   */
  setState: function (requests, update_counts, update_names, cursor) {
    var self = this
    if (cursor === undefined) cursor = null
    update_counts = update_counts || false
    self.requests = requests
    self.cursor = cursor

    // We only want to show people that have been assigned to a request
    // in the checkboxes. This will filter out the rest.
    if (update_names) {
      var peeps = {}
      var count = 2
      peeps['Assigned to me'] = self.assigned_defaults['Assigned to me']
      peeps['Unassigned'] = self.assigned_defaults['Unassigned']
      if (self.search_params.assigned_to) {
        peeps[self.search_params.assigned_to] = self.assigned_people[self.search_params.assigned_to]
      }
      for (var i = 0; i < self.requests.length; i++) {
        var id = self.requests[i].assigned_to_user
        if (peeps[id] === undefined && self.assigned_people[id] !== undefined) {
          peeps[self.requests[i].assigned_to_user] = self.assigned_people[id]
          count++
        }
      }

      self.filtered_assigned = peeps
      // We need to attach events to these checkboxes
      self.addAssignedPeople(self.filtered_assigned)
    }
    if (self.cursor) {
      $('.show-more').show()
    } else {
      $('.show-more').hide()
    }
  },

  /**
   * Clear the dom from any requests
   */
  clearQueue: function () {
    $('#regular-queue').empty()
  },

  dispatchMessageRequestsPromise: function (request_ids) {
    var url = '/api-yoa/requests/messages'
    var data = {
      venue: this.search_params.venue,
      request_id: request_ids.join(','),
    }
    return $.ajax({
      url: url,
      data: data,
      traditional: true,
      type: 'POST',
    }).promise()
  },

  insertMessagesAndUpdatedTime: function (messages) {
    var $row_selector, msg, html, visibility, last_message_internal
    for (var i = 0; i < messages.length; i++) {
      msg = messages[i]
      $row_selector = $('#request-row-' + msg.id)
      visibility = msg.last_communication_all_internal ? msg.last_communication_all_internal.visibility : []
      last_message_internal = _.indexOf(visibility, 'INTERNAL') > -1
      html = Nightloop.Templates.Manager.Requests.LastMessageFragment({
        MEDIA_URL: this.mediaurl,
        last_message_internal: !!last_message_internal,
        last_message_with_internal: msg.last_communication_all_internal,
      })

      $row_selector.find('.auto.-consolidated_notes').append(html)
      $row_selector.find('.last-communication').sext(msg.last_communication_all_internal_formatted)
    }
  },

  dispatchAndInsertMessages: function (startRequestIdx) {
    var self = this
    var handle_response = function (resp) {
      self.insertMessagesAndUpdatedTime(resp.data || [])
    }
    var batch_buffer = []
    // Chunk the message requests into batches of 10
    for (var i = startRequestIdx; i < self.requests.length; i++) {
      batch_buffer.push(self.requests[i].id)
      if ((i + 1) % 10 === 0) {
        self.dispatchMessageRequestsPromise(batch_buffer).done(handle_response)
        batch_buffer = []
      }
    }
    if (batch_buffer.length > 0) {
      // Flush any lingering requests
      self.dispatchMessageRequestsPromise(batch_buffer).done(handle_response)
    }
  },

  /**
   * Insert requests into DOM
   */
  paintRequests: function (update_counts, update_names, results_promise) {
    var self = this
    var d = $.Deferred()
    $('p.no-content').hide()
    $('#loading-row').show()
    $('.priority-section').hide()
    $('#show-more-requests').hide()
    $('#priority-queue').empty()

    self.clearQueue()
    self
      .updateState(update_counts, update_names, results_promise)
      .done(function () {
        self.insertListIntoDom(0)
        self.dispatchAndInsertMessages(0)
        self.checkpriorities()
        $('#show-more-requests').show()
        if (self.search_params.query !== null && self.search_params.query !== '') {
          var results_count = self.requests.length
          var text = ['Showing', '(' + results_count + ')', 'requests', 'matching', '"' + self.search_params.query + '"'].join(' ')
          $('#wd-results-info-header').sext(text)
          $('.results-area').show()
        } else {
          $('.results-area').hide()
        }
        d.resolve()
      })
      .fail(function () {
        Interface._alert()
        $('#loading-row').hide()
        d.fail()
      })
    return d.promise()
  },

  insertRequestIntoDom: function (request, even) {
    even = even || false
    var html = Nightloop.Templates.Manager.Requests.RequestRow({
      MEDIA_URL: this.mediaurl,
      is_nightlife: !this.isdiningclass,
      isEven: even,
      showall: !this.single_day,
      requests_availability_enabled: this.requests_availability_enabled && !this.is_priority_alert_tab,
      request: request,
      internal_ar_booking_enabled: this.internal_ar_booking_enabled,
    })

    if (this.single_day && request.priority) {
      $('#priority-queue').append(html)
    } else {
      $('#regular-queue').append(html)
    }
  },

  insertListIntoDom: function (startIdx) {
    var self = this
    if (!startIdx) {
      this.clearQueue()
    }
    $('#loading-row').hide()
    for (var i = startIdx; i < self.requests.length; i++) {
      this.insertRequestIntoDom(self.requests[i], i % 2 === 0)
    }
  },

  /**
   * Parameter centric functions
   */
  getSearchParams: function (use_cursor) {
    var filtered_params = {}
    for (var p in this.search_params) {
      if (this.search_params[p] !== null) {
        filtered_params[p] = this.search_params[p]
      }
    }
    if (use_cursor === true) {
      filtered_params.cursor = this.cursor
      filtered_params.ts = this.ts
    }
    filtered_params['include_availability'] = this.isdiningclass && !this.is_priority_alert_tab
    return filtered_params
  },

  resetSearchParams: function () {
    for (var p in this.search_params) {
      if (
        p !== 'venue' &&
        p !== 'query' &&
        p !== 'from_date' &&
        p !== 'to_date' &&
        p !== 'date' &&
        p !== 'source' &&
        p !== 'assigned_to' &&
        p !== 'sort_by' &&
        p !== 'shift'
      ) {
        this.search_params[p] = null
      }
    }
  },

  toggleAvailabilityColumns: function (shouldShow) {
    var $availabilityColumns = $('.col-availability')
    if ($availabilityColumns.length === 0) {
      return
    }
    $availabilityColumns.each(function (index, element) {
      var $currentColumn = $(element)
      if (shouldShow) {
        $currentColumn.show()
      } else {
        $currentColumn.hide()
      }
    })
  },

  /**
   * Search for high level things like trashed recently. These selections
   * affect multiple params.
   */
  setStatus: function (type, do_fetch) {
    do_fetch = do_fetch || true
    this.resetSearchParams()
    if (type === 'priority alert') {
      this.is_priority_alert_tab = true
      this.toggleAvailabilityColumns(false)
    } else {
      this.is_priority_alert_tab = false
      this.toggleAvailabilityColumns(true)
    }
    if (type === this.status_setting) {
      this.unsetCheckbox(type, 'status-selector')
    } else {
      this.setCheckbox(type, 'status-selector')
      switch (type) {
        case 'all':
          break
        case 'active':
          this.search_params.status = 'active'
          break
        case 'priority':
          this.search_params.status = 'active'
          this.search_params.priority = true
          break
        case 'priority alert':
          this.search_params.status = 'active'
          this.search_params.priority_alert = true
          break
        case 'expiring soon':
          this.search_params.status = 'active'
          this.search_params.expiring_within = '1day'
          break
        case 'needs action':
          this.search_params.status = 'needs_action'
          break
        case 'offer pending':
          this.search_params.status = 'offer_pending'
          break
        case 'booked':
          this.search_params.has_reservation = true
          break
        case 'declined':
          this.search_params.status = 'declined'
          break
        case 'trashed':
          this.search_params.status = 'removed'
          break
        case 'expired':
          this.search_params.status = 'expired'
          break
        default:
          break
      }
      metric.track('Requests.typeSelected', {
        page: 'request-queue',
        selection: type,
      })
    }

    if (do_fetch) {
      var r_promise = this.searchRequests()
      this.paintRequests(false, true, r_promise)
    }
  },

  /**
   * Sets acheckbox or whatever as selected. Expects each set of checkboxes
   * to have mutually exclusive options.
   */
  setCheckbox: function (word, parent_selector, do_not_split) {
    $(`.${parent_selector} li a`).removeClass('selected')
    const word_id = do_not_split ? word : word.split(' ').join('-')
    $(`.${parent_selector} li[id="show-${word_id}"] a`).addClass('selected')
  },

  unsetCheckbox: function (word, parent_selector, do_not_split) {
    const word_id = do_not_split ? word : word.split(' ').join('-')
    $(`.${parent_selector} li[id="show-${word_id}"] a`).removeClass('selected')
  },

  setSource: function (source, do_fetch) {
    do_fetch = do_fetch || true
    if (source === this.search_params.source) {
      this.unsetCheckbox(source, 'source-selector')
      this.search_params.source = null
    } else {
      this.setCheckbox(source, 'source-selector')
      this.search_params.source = source
    }
    metric.track('Requests.sourceSelected', {
      page: 'request-queue',
      selection: source,
    })
    if (do_fetch) {
      var results_promise = this.searchRequests()
      this.paintRequests(false, false, results_promise)
    }
  },

  setAssignedTo: function (id, do_fetch) {
    metric.track('Requests.assignedSelected', {
      page: 'request-queue',
      selection: id,
    })
    do_fetch = do_fetch || true

    var unselect = false
    if (id === this.search_params.assigned_to) {
      this.unsetCheckbox(id, 'assigned-selector')
      this.search_params.assigned_to = null
      unselect = true
    } else {
      this.setCheckbox(id, 'assigned-selector')
      this.search_params.assigned_to = id
    }
    if (do_fetch) {
      var results_promise = this.searchRequests()
      if (unselect === true) {
        this.paintRequests(true, true, results_promise)
      } else {
        this.paintRequests(false, false, results_promise)
      }
    }
  },

  setShift: function (shift, do_fetch) {
    metric.track('Requests.shiftSelected', {
      page: 'request-queue',
      selection: shift,
    })
    do_fetch = do_fetch || true
    if (shift === this.search_params.shift) {
      this.unsetCheckbox(shift, 'shift-selector', true)
      this.search_params.shift = null
    } else {
      this.setCheckbox(shift, 'shift-selector', true)
      this.search_params.shift = shift
    }
    if (do_fetch) {
      var results_promise = this.searchRequests()
      this.paintRequests(false, false, results_promise)
    }
  },

  setSortOrder: function (sort_by, do_fetch) {
    metric.track('Requests.sortSelected', {
      page: 'request-queue',
      selection: sort_by,
    })
    do_fetch = do_fetch || true
    this.search_params.sort_by = sort_by
    if (do_fetch) {
      var results_promise = this.searchRequests()
      this.paintRequests(false, false, results_promise)
    }
  },

  setDate: function (date_str, do_fetch) {
    metric.track('Requests.dateSelected', {
      page: 'request-queue',
      selection: date_str,
    })
    do_fetch = do_fetch || true
    this.search_params.date = date_str
    this.search_params.dates = null
    this.search_params.from_date = null
    this.search_params.to_date = null
    if (do_fetch) {
      var results_promise = this.searchRequests()
      this.paintRequests(false, false, results_promise)
      $('.date-closer').show()
    }
  },

  clearDate: function () {
    this.search_params.from_date = null
    this.search_params.to_date = null
    this.search_params.dates = null
    this.search_params.date = null
    if (!this.basic_user) {
      this.search_params.assigned_to = null
    }
    $('#request-date').val('')
    $('.date-closer').hide()
    var results_promise = this.searchRequests()
    this.paintRequests(false, false, results_promise)
  },

  minimizeAssigned: function () {
    $('.assigned-selector li:gt(1)').hide(100)
    $('.checkbox-section .toggler.maximizer').show()
    $('.checkbox-section .toggler.minimizer').hide()
    this.assign_expanded = false
  },

  maximizeAssigned: function () {
    $('.assigned-selector li:gt(1)').show(100)
    $('.checkbox-section .toggler.minimizer').show()
    $('.checkbox-section .toggler.maximizer').hide()
    this.assign_expanded = true
  },
  minimizeStatus: function () {
    $('.status-selector li').slice(5, 8).hide(100)
    $('.status-selector .toggler.maximizer').show()
    $('.status-selector .toggler.minimizer').hide()
    this.assign_expanded = false
  },

  maximizeStatus: function () {
    $('.status-selector li').slice(5, 8).show(100)
    $('.status-selector .toggler.minimizer').show()
    $('.status-selector .toggler.maximizer').hide()
    this.assign_expanded = true
  },

  /**
   * Event centric functions
   */

  loadMoreRequests: function () {
    var self = this
    $('p.no-content').hide()
    $('#show-more-requests').hide()
    $('#loading-row').show()
    this.searchRequests(true) // means search with cursor
      .done(function (response) {
        var more_requests = response.data.requests
        var new_cursor = response.data.cursor
        self.ts = response.data.ts
        var lastRequestIndex = self.requests.length
        self.setState(
          self.requests.concat(more_requests),
          false,
          true, // update names
          new_cursor
        )
        self.insertListIntoDom(lastRequestIndex)
        self.dispatchAndInsertMessages(lastRequestIndex)
        $('#show-more-requests').show()
        $('#loading-row').hide()
      })
      .fail(function () {
        $('#show-more-requests').show()
        $('#loading-row').hide()
      })
  },

  checkpriorities: function () {
    var hid = 0
    $('p.no-content').hide()
    $('div.priority-section').show()
    if (this.single_day) {
      $('div.priority-section').each(function () {
        if (!$(this).find('.request-row').length) {
          hid++
          $(this).hide()
        }
      })
      if (hid === 2) {
        $('div.priority-section').eq(1).show()
        $('p.no-content').show()
      }
    } else {
      var rows = $('#regular-queue').find('.request-row')

      if (rows.length === 0) {
        $('p.no-content').show()
      }
    }
  },

  bind: function () {
    var self = this
    // selectors for status types
    $('#show-all').on('click', self.setStatus.bind(self, 'all'))
    $('#show-priority').on('click', self.setStatus.bind(self, 'priority'))
    $('#show-priority-alert').on('click', self.setStatus.bind(self, 'priority alert'))
    $('#show-active').on('click', self.setStatus.bind(self, 'active'))
    $('#show-needs-action').on('click', self.setStatus.bind(self, 'needs action'))
    $('#show-offer-pending').on('click', self.setStatus.bind(self, 'offer pending'))
    $('#show-expiring-soon').on('click', self.setStatus.bind(self, 'expiring soon'))
    $('#show-booked').on('click', self.setStatus.bind(self, 'booked'))
    $('#show-declined').on('click', self.setStatus.bind(self, 'declined'))
    $('#show-trashed').on('click', self.setStatus.bind(self, 'trashed'))
    $('.date-closer').on('click', self.clearDate.bind(self))
    $('#cancel-export, #export-requests').on('click', function () {
      $('#export-requests,.export-interface').toggle()
      $('.export-message').hide()
    })

    //selectors for Assignments
    // $('#show-assigned-to-me').on('click', self.setAssignedTo.bind(self, 'assigned to me'));
    // $('#show-unassigned').on('click', self.setAssignedTo.bind(self, 'unassigned'));

    // selectors for source
    $('#show-concierge').on('click', self.setSource.bind(self, 'concierge'))
    $('#show-website').on('click', self.setSource.bind(self, 'website'))
    $('#show-internal').on('click', self.setSource.bind(self, 'internal'))

    // sort order selector
    $('#sortby-select').change(function (e) {
      var sort_order = $(this).val()
      self.setSortOrder(sort_order)
    })

    // attach handlers for shifts
    var shifts = $('.shift-selector li')
    for (var i = 0; i < shifts.length; i++) {
      var shift_name = $($('.shift-selector li a')[i]).sext()
      $(shifts[i]).on('click', self.setShift.bind(self, shift_name))
    }

    // Control flow handlers. Open up the request and stuff like that
    $('#show-more-requests').on('click', $.proxy(this.loadMoreRequests, this))
    $('#priority-queue').on('click', '.request-row span.priority', $.proxy(this.priority, this))
    $('#priority-queue').on('click', '.request-row', _.debounce($.proxy(this.loadrequest, this), 500))
    $('#regular-queue').on('click', '.request-row span.priority', $.proxy(this.priority, this))
    $('#regular-queue').on('click', '.request-row', _.debounce($.proxy(this.loadrequest, this), 500))
    $('.checkbox-section .toggler.maximizer').on('click', self.maximizeAssigned.bind(self))
    $('.checkbox-section .toggler.minimizer').on('click', self.minimizeAssigned.bind(self))

    // expand and contract status section
    $('.status-selector .toggler.maximizer').on('click', self.maximizeStatus.bind(self))
    $('.status-selector .toggler.minimizer').on('click', self.minimizeStatus.bind(self))

    $('.reset-query-link').on('click', self.reset_search.bind(self))

    this.attachSearch('#search-requests')
  },

  reset_search: function () {
    var self = this
    $('#search-requests').val('')
    $('.results-area').hide()
    self.search_params.query = null
    var results_promise = self.searchRequests()
    self.paintRequests(true, true)
  },

  attachSearch: function (searcher) {
    var that = this
    $(searcher).on('keyup', function (e) {
      if (e.keyCode === 13) {
        var searchparam = $('#search-requests').val()
        metric.track('Requests.search', {
          page: 'requests',
          value: searchparam,
        })
        that.search_params.query = searchparam
        var results_promise = that.searchRequests()
        that.paintRequests(undefined, undefined, results_promise)
      }
    })
  },

  preload: function (request_id) {
    var $row = $('.list-block .standard-row[request_id=' + request_id + ']')
    RequestSlideout.loadrequest(request_id, function () {
      $row.addClass('read')
    })
  },

  loadrequest: function (e) {
    metric.track('Requests.requestSelected', { page: 'request-queue' })
    if ($(e.target).hasClass('star')) return

    var $row = $(e.currentTarget)
    var request_id = $row.attr('request_id')
    var that = this

    $('.list-block .standard-row').removeClass('selected')
    $row.addClass('selected')

    const loadedRequestData = this.requests.find(({ id }) => id === request_id)
    RequestSlideout.loadrequest(
      request_id,
      function () {
        $row.addClass('read')
      },
      {
        firstAvailableShift: (loadedRequestData && loadedRequestData.first_shift_availability) || undefined,
        firstAvailableArShift: (loadedRequestData && loadedRequestData.first_ar_shift_availability) || undefined,
        offerName: (loadedRequestData && loadedRequestData.offer_name) || undefined,
        isRequestAvailable: loadedRequestData && loadedRequestData.can_book,
      }
    )
  },

  priority: function (e) {
    // not sure where you get the id, but you'll need to get it from $star or $row I guess?
    var $star = $(e.target),
      $row = $star.closest('.request-row'),
      priority = $row.hasClass('priority'),
      request_id = $row.attr('request_id'),
      target = priority ? 'unprioritize' : 'prioritize',
      that = this,
      url = this.baseurl + '/' + request_id + '/' + target

    $.ajax({
      url: url,
      method: 'post',
      data: {
        request_id: request_id,
      },

      success: function (response) {
        $row.toggleClass('priority')
        //We need to remove this star from the list of all requests and
        // regenerate the groupings so that every group knows that this request
        // has been toggled.
        if (target === 'prioritize') {
          var priority_val = true
        } else {
          var priority_val = false
        }
        that.updateTallies()
        var found = false
        for (var i = 0; i < that.requests.length; i++) {
          if (that.requests[i].id === request_id) {
            that.requests[i].priority = priority_val
            found = true
            break
          }
        }
        if (found) {
        }
        that.checkpriorities()
      },

      error: function (response) {
        Interface._alert()
      },
    })
  },
}

$(function () {
  if (!$('#page-requests').length) return
  $('.show-more').hide()
  Requests.init()
})
