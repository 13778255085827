// depends on Interface
var BookingBlockSlideOut = {
    base_url: null,
    page_date_url: null,
    full_eta_options: null,
    seating_area_name_to_id_map: null,
    id_to_seating_area_name_map: null,
    seating_area_options: null,
    table_code_to_id_map: null,
    id_to_table_code_map: null,
    table_options: null,
    privilege_to_id_map: null,
    id_to_privilege_map: null,
    privilege_options: null,
    all_tables_party_size_max: 0,
    add_success_handler: null,
    validator: null,
    page: undefined,
    isInitialized: false,
    ALL_TABLES_PLACEHOLDER: {'label':'All Tables','type':'ALL_TABLES'},

    init: function(base_url, page_date_url, seating_area_name_to_id_map,
        id_to_seating_area_name_map, table_code_to_id_map, id_to_table_code_map,
        all_tables_party_size_max, add_success_handler, page, remove_success_handler) {

      this.base_url = base_url;
      this.page_date_url = page_date_url;
      this.full_eta_options = this._generateTimeOptions();
      // 'map' maps the name/display of the item to it's id
      // 'options' is the list of all the name/display of the items to show in autocomplete
      this.seating_area_name_to_id_map = seating_area_name_to_id_map;
      this.id_to_seating_area_name_map = id_to_seating_area_name_map;
      this.table_code_to_id_map = table_code_to_id_map;
      this.id_to_table_code_map = id_to_table_code_map;
      // from venueuser.VenuePrivilegeLevel
      this.privilege_and_user_to_id_map = {
        'Superuser': 'SUPERUSER',
        'Manager': 'MANAGER',
        'Sub Manager': 'SUBMANAGER',
        'Basic': 'BASIC'
      };
      this.privilege_and_user_options = [];
      this.id_to_privilege_and_user_map = {};
      this.page = page;

      var that = this;

      var _keysToMapFn = function(lst, val) {
        var map = {};
        for (var i=0; i < lst.length; i++) {
          var key = lst[i];
          map[key] = val;
        }
        return map;
      };

      BookedByService.getByVenue(base_url, function(response) {
        //success
        for (var i =0; i < response.content.booked_by_users.length; i++) {
          var bbuser = response.content.booked_by_users[i];
          if (bbuser.is_user) that.privilege_and_user_to_id_map[bbuser.name] = bbuser.id;
        }

        for (var priv in that.privilege_and_user_to_id_map) {
          that.privilege_and_user_options.push(priv);
          // just creating the reverse look up map
          that.id_to_privilege_and_user_map[that.privilege_and_user_to_id_map[priv]] = priv;
        }
        that._autoCompleteSetup('#privileges-input', that.privilege_and_user_options,
                                _keysToMapFn(that.privilege_and_user_options,'privilege'),
                                _keysToMapFn(that.privilege_and_user_options,'privileges'),
                                that.privilege_and_user_to_id_map, '#privileges-chosen');
      }, undefined, // error case is to only allow privileges
      true); // use cache


      this.add_success_handler = add_success_handler || function(response) { };
      this.remove_success_handler = remove_success_handler || function(response) {};

      // seating area names
      this.seating_area_options = [];
      for (var area in seating_area_name_to_id_map) {
        this.seating_area_options.push(area);
      }

      // table code names
      this.table_options = [];
      for (var table in table_code_to_id_map) {
        this.table_options.push(table);
      }

      this.all_tables_party_size_max = all_tables_party_size_max;
      this.party_size_options = [];
      for (var i = 1; i <= all_tables_party_size_max; i++) {
        var tuple = [(i+' top table'), i];
        this.party_size_options.push(tuple);
      }

      this._populate_select_options('#start-time-select', this.full_eta_options);
      this._populate_select_options('#end-time-select', this.full_eta_options);
      this._populate_select_options('#start-time-select-start-time-only', this.full_eta_options);
      this._populate_select_options('#end-time-select-start-time-only', this.full_eta_options);
      this._populate_from_tuples_select_options('#block-type-party-size-select', this.party_size_options);

      $('#start-time-select').change(function(e) {
        var start_selected = $('#start-time-select').find('option:selected').sext();
        if (!start_selected) {
          return;
        }

        var $end_time_select = $("#end-time-select");
        var end_selected = $end_time_select.find("option:selected").sext();
        BookingBlockSlideOut._populate_select_options('#end-time-select', that.full_eta_options);
        var end_options = $end_time_select.find('option');
        for (var i = 0; i < end_options.length; ++i) {
          $(end_options[i]).remove();
          if ($(end_options[i]).sext() === start_selected) {
            break;
          }
        }
        $end_time_select.val(end_selected);
      });

      $('#block-date-selector').datepicker({
        //dateFormat: 'D M, d',
        altField: '#block-date',
        altFormat: 'mm-dd-yy',
        minDate: new Date(),
        beforeShow: function() {
          $('#ui-datepicker-div').addClass('customize');
        },
        onSelect: function() {
          that._formatDate($('#block-date-selector'));
        }
      });
      this._formatDate($('#block-date-selector'));

      $('#block-from-date-selector').datepicker({
        //dateFormat: 'D M, d',
        altField: '#block-from-date',
        altFormat: 'mm-dd-yy',
        beforeShow: function() {
          $('#ui-datepicker-div').addClass('customize');
        },
        onSelect: function() {
          that._formatDate($('#block-from-date-selector'));
        }
      });
      this._formatDate($('#block-from-date-selector'));
      $('#block-to-date-selector').datepicker({
        //dateFormat: 'D M, d',
        altField: '#block-to-date',
        altFormat: 'mm-dd-yy',
        beforeShow: function() {
          $('#ui-datepicker-div').addClass('customize');
        },
        onSelect: function() {
          that._formatDate($('#block-to-date-selector'));
        }
      });
      this._formatDate($('#block-to-date-selector'));

      var openAddBlockFn = function () {
        that.openAddBlock();
      }

      $('#add-block-slideout-button').click(openAddBlockFn);
      $('#add-block-button').click($.proxy(this.addBlock, this));

      $('#save-block-button').click($.proxy(this.saveBlock, this));
      $('#remove-block-button').click($.proxy(this.removeBlock, this));
      $('#close-block-slideout-button').click($.proxy(this.close, this));
      var all_tables_object = {};
          all_tables_object[this.ALL_TABLES_PLACEHOLDER.label] = this.ALL_TABLES_PLACEHOLDER.type;
      var option_list = this.seating_area_options.concat(this.table_options);
      option_list.unshift(this.ALL_TABLES_PLACEHOLDER.label);
      var type_map = $.extend({}, _keysToMapFn(this.seating_area_options, 'seating-area'),
                                _keysToMapFn(this.table_options, 'table'), all_tables_object);
      var input_map = $.extend({}, _keysToMapFn(this.seating_area_options, 'seating_areas'),
                                  _keysToMapFn(this.table_options, 'tables'), all_tables_object);
      var value_map = $.extend({}, this.seating_area_name_to_id_map,
                              this.table_code_to_id_map, all_tables_object);
      this._autoCompleteSetup('#areas-input', option_list, type_map, input_map,
                              value_map, '#areas-chosen');

      $('#id_is_indefinite').on('change', function() {
        if ($(this).prop('checked')) {
          $('#block-to-date-selector').addClass('disabled');
          $('#block-to-date-selector').val("");
          $('#block-to-date').addClass('disabled');
          $('#block-to-date').val("");
        } else {
          $('#block-to-date-selector').removeClass('disabled');
          $('#block-to-date').removeClass('disabled');
          $('#block-to-date-selector').val($("#block-from-date-selector").val());
          $('#block-to-date').val($("#block-from-date").val())
        }
      });

      this.validator = new sr.Validator($('#add-new-block'));
      this.isInitialized = true
    },

    isInit: function () {
      return this.isInitialized
    },

    _renderTimePicker: function(start_time, specific_times_display){

      var time_pick_div = window.document.getElementById('start-time-only-react-shim');
      React.unmountComponentAtNode(time_pick_div);
      var time_picker = React.createElement(Common.SpecificTime, {
        'start_time': start_time,
        'specific_times_display': specific_times_display,
        'disabled': false
      });
      React.render(time_picker, time_pick_div);
    },

    _generateTimeOptions: function() {
      var options = [];
      for (var i = 0; i < 96; ++i) {
        var time = timeutils.sort_order_to_time(i, Pmp.Manager.Global._is_military_time);
        options.push(time);
      }
      return options;
    },

    _formatDate: function(dateSel) {
      Pmp.Utils.formatDatePicker(dateSel);
    },

    openAddBlock: function(date, tableId, startTime, endTime) {
      $('#block-slideout > div.default').show();
      $('#block-slideout > div.access-rule').hide();
      $('#add-new-block').show();

      $('#block-slideout').find('.slide-status').find('p.status').each(function() {
        if ($(this).hasClass('add-block')) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });

      $('#block-actions').hide();
      $('#add-block-div').show();
      $('#block-name').val('');

      if (!date) {
        date = $.datepicker.parseDate('mm-dd-yy', this.page_date_url);
      }

      $('#block-date-selector').datepicker('setDate', date).change();
      this._formatDate($('#block-date-selector'));
      $('#start-time-select').val('');
      $('#end-time-select').val('');

      this._populate_select_options('#start-time-select', this.full_eta_options);
      this._populate_select_options('#end-time-select', this.full_eta_options);
      $('#areas-chosen').find('.tag').remove();
      //$('#tables-chosen').find('.tag').remove();
      $('#privileges-chosen').find('.tag').remove();
      // SUPERUSER chosen by default, cannot be removed
      BookingBlockSlideOut._addChosen('privilege', 'Superuser', 'privileges',
          'SUPERUSER', '#privileges-chosen', 'unremovable');
      BookingBlockSlideOut._addChosen('privilege', 'Manager', 'privileges',
          'MANAGER', '#privileges-chosen', 'unremovable');
      $('#block-id-input').val('');
      $('#expiration-select').val('');

      $('#id_is_block_type_location').trigger('click');
      $('#id_is_block_time_all').trigger('click');
      $('#id_is_block_date_single').trigger('click');

      $('#id_is_indefinite').prop('checked',undefined)
                            .parents('.form-element')
                            .removeClass('checked');

      $('#block-from-date-selector').datepicker('setDate', date).change();
      $('#block-to-date-selector').datepicker('setDate', date).change();
      this._formatDate($('#block-from-date-selector'));
      this._formatDate($('#block-to-date-selector'));
      $('#block-to-date-selector').removeClass('disabled');
      $('#add-block-button').removeClass('disabled');
      this._renderTimePicker(timeutils.sort_order_to_time(0), []);

      // populate table list
      if (tableId) {
        var name = BookingBlockSlideOut.id_to_table_code_map[tableId];
        BookingBlockSlideOut._addChosen('table', name, 'tables', tableId, '#areas-chosen');
      }

      // populate default time
      if (startTime && endTime) {
        $('#id_is_block_time_custom').trigger('click');
        $('#start-time-select').val(startTime).change();
        $('#end-time-select').val(endTime).change();
      }

      Interface.openslide('#block-slideout');
      metric.track(this.page + '.addBlock', {'page': this.page.toLowerCase()});
    },

    openEditBlock: function(block_id, rule_name, successHandler) {
      if (rule_name) {
        var $block_slideout = $('#block-slideout');
        $('#block-slideout > div.default').hide();
        $('#block-slideout > div.access-rule').show();
        $block_slideout.find('span.rule-name').sext(rule_name);
        $block_slideout.find('.slide-status').find('p.status').each(function() {
          if ($(this).hasClass('view-access')) {
            $(this).show();
          } else {
            $(this).hide();
          }
        });

        Interface.openslide('#block-slideout');
        if (successHandler) {
          successHandler();
        }
        return;
      }
      this._renderTimePicker(timeutils.sort_order_to_time(0), []);
      $('#block-slideout > div.default').show();
      $('#block-slideout > div.access-rule').hide();

      var that = this;
      $.ajax({
        url: this.base_url + '/blocks/' + block_id,
        method: 'get',
        success: function(response) {

          $('#add-new-block').show();

          var block = response;
          var start = new Date(block.start);
          var date = $.datepicker.parseDate('yy-mm-dd', block.date);
          var $date_selector = $('#block-date-selector');
          $date_selector.datepicker('setDate', date).change();
          that._formatDate($date_selector);
          that._populate_select_options('#start-time-select', that.full_eta_options);
          that._populate_select_options('#end-time-select', that.full_eta_options);
          $('#start-time-select').val(block.start_time_display).change();
          $('#end-time-select').val(block.end_time_display);
          $('#areas-chosen').find('.tag').remove();
          $('#privileges-chosen').find('.tag').remove();
          if(block.block_time_type === 'START_TIME_ONLY') {
            var selected_times = _.map(block.block_start_times, function(raw_time){
              if (Pmp.Manager.Global._is_military_time) {
                var extract_military_time_no_seconds = /(\d+:\d+)/g;
                var matches = extract_military_time_no_seconds.exec(raw_time);
                if (matches) {
                  raw_time = matches[0];
                }
              }
              return Pmp.Utils.militaryTimeToLocale(raw_time);
            });
            that._renderTimePicker(timeutils.sort_order_to_time(0), selected_times);
          }

          // TODO display some kind of message if no tables or seating areas set? (ie block all tables)


          if(block.table_ids.length === 0 && block.table_combo_ids.length === 0
              && block.seating_area_ids.length === 0) {
            var all_tables_type = that.ALL_TABLES_PLACEHOLDER.type;
            var label = that.ALL_TABLES_PLACEHOLDER.label;
            BookingBlockSlideOut._addChosen(all_tables_type, label,
                all_tables_type, all_tables_type, '#areas-chosen');
          }

          for (var i = 0; i < block.seating_area_ids.length; ++i) {
            var area_id = block.seating_area_ids[i];
            var name = BookingBlockSlideOut.id_to_seating_area_name_map[area_id];
            BookingBlockSlideOut._addChosen('seating-area', name, 'seating_areas', area_id, '#areas-chosen');
          }

          for (var i = 0; i < block.table_ids.length; ++i) {
            var table_id = block.table_ids[i];
            var name = BookingBlockSlideOut.id_to_table_code_map[table_id];
            BookingBlockSlideOut._addChosen('table', name, 'tables', table_id, '#areas-chosen');
          }
          for (i = 0; i < block.table_combo_ids.length; ++i) {
            BookingBlockSlideOut._addChosen('table',
                BookingBlockSlideOut.id_to_table_code_map[block.table_combo_ids[i]],
                'tables',
                block.table_combo_ids[i],
                '#areas-chosen');
          }
          for (var i = 0; i < block.privilege_levels.length; ++i) {
            var privilege_id = block.privilege_levels[i];
            var name = BookingBlockSlideOut.id_to_privilege_and_user_map[privilege_id];
            var unremoveable = privilege_id === 'SUPERUSER' || privilege_id === 'MANAGER';
            BookingBlockSlideOut._addChosen('privilege', name, 'privileges',
                privilege_id, '#privileges-chosen', unremoveable);
          }

          for (var i = 0; i < block.privilege_users.length; ++i) {
            var privilege_id = block.privilege_users[i];
            var name = BookingBlockSlideOut.id_to_privilege_and_user_map[privilege_id];
            BookingBlockSlideOut._addChosen('privilege', name, 'privileges',
                privilege_id, '#privileges-chosen');
          }

          $('#block-id-input').val(block.id);
          $('#expiration-select').val(block.expiration);

          $('#block-name').val(block.name);

          if (block.block_type === 'LOCATION') {
            $('#id_is_block_type_location').trigger('click');
          } else if (block.block_type === 'PARTY_SIZE') {
            $('#id_is_block_type_party_size').trigger('click');
            $('#block-type-party-size-select').val(block.party_sizes[0]);
          }

          if (block.block_time_type === 'ALL') {
            $('#id_is_block_time_all').trigger('click');
          } else if (block.block_time_type === 'TIME_RANGE') {
            $('#id_is_block_time_custom').trigger('click');
          } else if (block.block_time_type === 'START_TIME_ONLY') {
            $('#id_is_start_time_only').trigger('click');
          } else if (block.block_time_type === 'SHIFT') {
            $('#id_is_block_time_shift').trigger('click');
            $('#blocks-shift-select').val(block.shift_persistent_id);
          }

          if (block.block_date_type === 'DATE') {
            $('#id_is_block_date_single').trigger('click');
          } else if (block.block_date_type === 'DATE_RANGE') {
            $('#id_is_block_date_custom').trigger('click');
            for (var i=0; i < 7; i++) {
              if (block.days_of_week[i]) {
                $('#id_block_dow_' + i).prop('checked','checked')
                                       .parents('.form-element')
                                       .addClass('checked');
              } else {
                $('#id_block_dow_' + i).prop('checked',undefined)
                                       .parents('.form-element')
                                       .removeClass('checked');
              }
            }
          }

          var $block_from_date_selector = $('#block-from-date-selector');
          var $block_to_date_selector = $('#block-to-date-selector');
          var $block_to_date = $('#block-to-date');
          if (block.start_date) {
            var start_date = $.datepicker.parseDate('yy-mm-dd', block.start_date);
            $block_from_date_selector.datepicker('setDate', start_date).change();
          } else {
            $block_from_date_selector.datepicker('setDate', date).change();
          }

          if (block.is_indefinite) {
            $('#id_is_indefinite').prop('checked', 'checked')
                                  .parents('.form-element')
                                  .addClass('checked');
            $block_to_date_selector.addClass('disabled');
            $block_to_date_selector.val("");
            $block_to_date.addClass('disabled');
            $block_to_date.val("");
          } else {
            $('#id_is_indefinite').prop('checked',undefined)
                                  .parents('.form-element')
                                  .removeClass('checked');
            $block_to_date_selector.removeClass('disabled');
            $block_to_date.removeClass('disabled');
            $block_to_date_selector.val($("#block-from-date-selector").val());
            if (block.end_date) {
              var end_date = $.datepicker.parseDate('yy-mm-dd', block.end_date);
              $block_to_date_selector.datepicker('setDate', end_date).change();
            } else {
              $block_to_date_selector.datepicker('setDate', date).change();
            }
          }
          that._formatDate($block_from_date_selector);
          that._formatDate($block_to_date_selector);

          Interface.openslide('#block-slideout');
          if (successHandler) {
            successHandler();
          }
        }
      });

      $('#block-slideout').find('.slide-status').find('p.status').each(function() {
        if ($(this).hasClass('edit-block')) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });

      $('#block-actions').show();
      $('#add-block-div').hide();
    },

    close: function() {
      $('#add-new-block').hide();
      Interface.closeslide('#block-slideout');
    },

    _validate: function() {
      // sr.Validator doesn't handle checkboxes yet
      // make sure at least one dow is checked if it's recurring
      // both methods have UI side effects, so we want them both to run, always
      var dow_valid = this._validateDOW();
      var valid = this.validator.validate();
      return dow_valid && valid;
    },

    _validateDOW: function() {
      $('#invalid-dow').hide();
      if (!($('#id_is_block_date_custom').prop('checked'))) {
        return true;
      }

      for (var i = 0; i < 7; i++) {
        if ($('#id_block_dow_' + i).prop('checked')) {
          // make sure at least one day is checked
          return true;
        }
      }

      $('#invalid-dow').show();
      return false;
    },

    addBlock: function(e) {
      var add_button = $('#add-block-button');
      if (!this._validate() || add_button.hasClass('disabled')) {
        return false;
      }

      add_button.addClass('disabled');

      var form = $(e.target).closest('form');
      var data = form.serialize();
      var that = this;
      $.ajax({
        url: that.base_url + '/blocks',
        method: 'post',
        data: data,
        success: function(response) {
          add_button.removeClass('disabled');
          that.close();
          that.add_success_handler(response);
        },
        error: function(response) {
          add_button.removeClass('disabled');
          if (response.status === 401) {
            UserNotificationInterop.error('You do not have permissions to do that.');
            return;
          }

          var errors = $.parseJSON(response.responseText).errors;
          var msg = errors.join(' ');
          if (msg) {
            UserNotificationInterop.error(msg);
          } else {
            UserNotificationInterop.error();
          }
        }
      });
    },

    saveBlock: function(e) {
      if (!this._validate()) {
        return false;
      }

      var form = $(e.target).closest('form');
      var data = form.serialize();
      var block_id = $('#block-id-input').val();
      var that = this;
      $.ajax({
        url: that.base_url + '/blocks/' + block_id,
        method: 'put',
        data: data,
        success: function(response) {
          that.close();
          $('[block_id=' + block_id + ']').remove();
          that.add_success_handler(response);
        },
        error: function(response) {
          if (response.status === 401) {
            UserNotificationInterop.error('You do not have permissions to do that.');
            return;
          }
          var errors = $.parseJSON(response.responseText).errors;
          var msg = errors.join(' ');
          UserNotificationInterop.error(msg);
        }
      });
    },

    removeBlock: function() {
      var block_id = $('#block-id-input').val();
      var that = this;
      $.ajax({
        url: that.base_url + '/blocks/' + block_id,
        method: 'delete',
        success: function(response) {
          that.close();
          $('[block_id=' + block_id + ']').remove();
          that.remove_success_handler(block_id);
        }
      });
    },

    isBlockValidForDateAndShift: function(block, shiftPersistentId) {
      return block.block_time_type !== 'SHIFT' || block.shift_persistent_id !== shiftPersistentId;
    },

    _autoCompleteSetup: function(input_id, source, type_map, input_map, value_map, chosen_div_id) {
      $(input_id).autocomplete({
        minLength: 0,
        delay: 0,
        source: source,
        select: function(event, ui) {
          BookingBlockSlideOut._addChosen(type_map[ui.item.value], ui.item.value,
            input_map[ui.item.value], value_map[ui.item.value], chosen_div_id);
          $(event.target).val('');
          return false;
        }
      }).on('click', function(event) {
        $(input_id).autocomplete('search', '');
      });
    },

    _addChosen: function(type, key, input, value, chosen_div_id, unremovable) {
      // avoid adding duplicates
      var uniqueid = type + '-' + key;
      if ($("[bbunique='" + uniqueid + "']").length) {
        return;
      }

      var html = Nightloop.Templates.Manager.BookingBlocks.Tag({
        'type': type,
        'key': key,
        'input': input,
        'value': value,
        'unremovable': unremovable
      });
      var chosen = $(html);
      chosen.find('.close-button').each(function() {
        $(this).click(function() {
          $(this).closest('.tag').remove();
        });
      });
      $(chosen_div_id).append(chosen);
    },

    // where options is an array, and the options have the same value and text
    _populate_select_options: function(selector, options) {
      $(selector).find('option').remove();
      for (var i = 0; i < options.length; ++i) {
        var option = $('<option/>').val(options[i]).sext(options[i]);
        $(selector).append(option);
      }
    },

    // where options is an array, and the options are a name, value tuple
    _populate_from_tuples_select_options: function(selector, options) {
      $(selector).find('option').remove();
      for (var i = 0; i < options.length; ++i) {
        var option = $('<option/>').val(options[i][1]).sext(options[i][0]);
        $(selector).append(option);
      }
    }
};
