// This file was automatically generated from manager.account.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }


Nightloop.Templates.Manager.Account = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.AccountBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.AccountBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<!-- ', soy.$$escapeHtml(opt_data.venue_settings), ' --><!-- ', opt_data.content, ' --><!-- ', soy.$$escapeHtml(opt_data.venue), ' --><!-- ', soy.$$escapeHtml(opt_data.MEDIA_URL), ' -->', (opt_data.settings.IS_MOBILE) ? '<div id="sidebar"><div id="calendar-wrap"><div id="monthly-calendar" class="calendar"></div><input id="monthly-calendar-submit" type="hidden" /></div><div class="fake-close-button is-mobile"><a id="close-main-cal" href="">Cancel</a></div></div>' : '', '<div id="page-account" class=""><div id="main-area"><div id="content-header"><h2>Account</h2></div><div id="common-account-form">');
  Nightloop.Base.AccountForm(soy.$$augmentData(opt_data, {formaction: '/account-edit', base_url: opt_data.venue.manager_base_url}), output);
  output.append('</div></div></div><script type="text/javascript">$(document).ready( function() {$(\'#monthly-calendar\').datepicker(\'option\', \'onSelect\',function(dateText, calendar) {var dateObj = $(\'#monthly-calendar\').datepicker(\'getDate\'); var dateText = $.datepicker.formatDate(\'mm-dd-yy\', dateObj); var relativeUrl = "', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/reservations/day/" + dateText; window.location = relativeUrl;});});<\/script>');
  return opt_sb ? '' : output.toString();
};
