// This file was automatically generated from manager.reports.waittimes.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Reports == 'undefined') { Nightloop.Templates.Manager.Reports = {}; }


Nightloop.Templates.Manager.Reports.WaitTimes = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Reports.WaitTimesBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.WaitTimesBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Reports.ReportSidebar(soy.$$augmentData(opt_data, {selected: 'wait-times'}), output);
  output.append('<div id="page-waittimesreport" class="page-report has-sidebar"><div id="main-area">');
  Nightloop.Templates.Manager.Reports.PrintHeader(soy.$$augmentData(opt_data, {name: 'Wait Times Summary'}), output);
  output.append('<div id="content-header" class="do-not-print">');
  Nightloop.Templates.Manager.Reports.ReportNav(soy.$$augmentData(opt_data, {enable_shifts: 'true'}), output);
  output.append('</div>', (opt_data.content.error_msg) ? '<p class="block-error">' + soy.$$escapeHtml(opt_data.content.error_msg) + '</p>' : '', '<div class="float-end"></div><div class="report-content"><div class="row"><div class="col last"><p class="title">Quote Accuracy</p><div id="wait-times-quote-accuracy" class="chart"></div></div><div class="float-end"></div></div><div class="row"><div class="col last"><p class="title">Wait Time</p><div id="wait-times-average" class="chart"></div></div><div class="float-end"></div></div><div class="row"><div class="col last"><p class="title">Abandon Rate</p><div id="abandon-rates" class="chart"></div></div><div class="float-end"></div></div><div class="row"><div class="col last"><p class="title">Return Time</p><div id="return-times" class="chart"></div></div><div class="float-end"></div></div><div class="row last"></div></div></div></div><div id="data-source" class="no-display">', soy.$$escapeHtml(opt_data.content.data), '</div><script type="text/javascript">$(document).ready(function() {Pmp.Manager.Reports.WaitTimes.initialize( "', soy.$$escapeHtml(opt_data.venue.manager_base_url), '", "', soy.$$escapeHtml(opt_data.venue.currency_symbol), '" );});<\/script>');
  return opt_sb ? '' : output.toString();
};
