var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Reports = Pmp.Manager.Reports || {};
Pmp.Manager.Reports.BookedBy = Pmp.Manager.Reports.BookedBy || {};

Pmp.Manager.Reports.BookedBy.Individual = {
	initialize : function(manager_base_url, is_guestlist, booked_by_key, completed_reservations_list,
			total_completed_num, total_completed_amt, currency_symbol, is_source, is_source_by_tag) {
		this._base = Pmp.Manager.Reports.Base;
		this._base.initialize();

		this._manager_base_url = manager_base_url;
		this._is_guestlist = is_guestlist;
		this._is_source = is_source;
		this._is_source_by_tag = is_source_by_tag;
		this._booked_by_key = booked_by_key;
		this._completed_reservations_list = completed_reservations_list;
		this._total_completed_num = total_completed_num;
		this._total_completed_amt = total_completed_amt;
		this._currency_symbol = currency_symbol;

		this._bindEventHandlers();
		this._initCharts();

		// Turn all values with this class combo into integers
		$('.number.int').each(function(idx, td) {
			var int_value = parseInt(this.innerHTML);
			$(this).sext(int_value);
		});
	},

	log : function(msg) {
		console.log("Pmp.Manager.Reports.BookedBy.Individual: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var clickGoBtnFn = function(event) {self._clickGoBtn(event);};

		$('#summary-report-submit-btn').click(clickGoBtnFn);
	},

	_clickGoBtn : function(event) {
		if (this._base.validateCalendars()) {
      this._base.showLoadingBar();
			var from_date_url = this._base.getFromDateUrl();
			var to_date_url = this._base.getToDateUrl();
			var relativeUrl = ''
			if (this._is_source) {
				var source_by_tag = this._is_source_by_tag ? '1' : '0';
				relativeUrl = this._manager_base_url + "/reports/sourceby/"+ encodeURI(this._booked_by_key) +"/"+ from_date_url +"/"+ to_date_url + '?is_source=1&is_source_by_tag=' + source_by_tag
			}
			else {
				var guestlist_url = '';
				if (this._is_guestlist){
					guestlist_url = 'guestlist/';
				}
				relativeUrl = this._manager_base_url + "/reports/"+guestlist_url+"bookedby/"+ this._booked_by_key +"/"+ from_date_url +"/"+ to_date_url + '?is_source=0&is_source_by_tag=0&shift_code=' + this._base.getShiftCode();
			}
			window.location = relativeUrl
		}
	},

	_initCharts : function() {
		var self = this;
		/* Callback that creates and populates a data table,
		 * instantiates the pie chart, passes in the data and
		 * draws it.
		 **/
		Pmp.Utils.CreateChart(function(){self._drawCharts();});
	},

	_drawCharts : function() {
		if (!this._is_guestlist) {
			this._drawSpendOverTime();
			this._drawCompletedOverTime();
		} else {
			this._drawCheckinsOverTime();
		}
	},

	_drawCompletedOverTime : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Date');
		data.addColumn('number', 'No. Completed');

		var resRows = [];
		for (var i = 0; i < this._completed_reservations_list.length; i++) {
			var completed_reservation = this._completed_reservations_list[i];
			var resRow = [
			    completed_reservation['date'],
			    completed_reservation['num']
			];
			resRows.push(resRow);
		}
		data.addRows(resRows);

		var options = {
		        width:445,
		        height:189,
		        hAxis: {
		        	title: 'Date',
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        vAxis: {
		        	title: 'No. Completed',
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        legend : 'none',
		        colors : this._base.CHART_COLORS_BLUE
		};

		if (this._total_completed_num < 12) {
			options.vAxis.viewWindow = {
        		min: 0,
        		max: 12
        	};
		}

		var chart = new google.visualization.ColumnChart(document.getElementById('num-completed-over-time'));
		chart.draw(data, options);
	},

	_drawSpendOverTime : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Date');
		data.addColumn('number', 'Total Spend');

		var resRows = [];
		for (var i = 0; i < this._completed_reservations_list.length; i++) {
			var completed_reservation = this._completed_reservations_list[i];
			var resRow = [
			    completed_reservation['date'],
			    completed_reservation['amt']
			];
			resRows.push(resRow);
		}
		data.addRows(resRows);

	    var formatter = new google.visualization.NumberFormat({prefix: this._currency_symbol});
	    formatter.format(data, 1); // Apply formatter to second column

		var options = {
		        width:445,
		        height:189,
		        hAxis: {
		        	title: 'Date',
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        vAxis: {
		        	title: 'Total Spend',
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        legend : 'none',
		        colors : this._base.CHART_COLORS_BLUE
		};

		if (this._total_completed_amt < 1000) {
			options.vAxis.viewWindow = {
        		min: 0,
        		max: 1000
        	};
		}

		var chart = new google.visualization.ColumnChart(document.getElementById('total-spend-over-time'));
		chart.draw(data, options);
	},

	_drawCheckinsOverTime : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Date');
		data.addColumn('number', 'Check-Ins');

		var resRows = [];
		for (var i = 0; i < this._completed_reservations_list.length; i++) {
			var completed_reservation = this._completed_reservations_list[i];
			var resRow = [
			    completed_reservation['date'],
			    completed_reservation['checkedin']
			];
			resRows.push(resRow);
		}
		data.addRows(resRows);

		var options = {
		        width:445,
		        height:189,
		        hAxis: {
		        	title: 'Date',
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        vAxis: {
		        	title: 'Check-Ins',
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        legend : 'none',
		        colors : this._base.CHART_COLORS_BLUE
		};

		/*
		if (this._total_completed_num < 12) {
			options.vAxis.viewWindow = {
        		min: 0,
        		max: 12
        	};
		}
		*/

		var single_date = (this._completed_reservations_list.length == 1);
		var chart = null;
		if (single_date) {
			// unfortunately area charts break down with single dates
			chart = new google.visualization.ColumnChart(document.getElementById('checkins-over-time'));
		} else {
			chart = new google.visualization.LineChart(document.getElementById('checkins-over-time'));
		}


		chart.draw(data, options);
	}
};
