// This file was automatically generated from manager.manage.venueprofile.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Marketing == 'undefined') { Nightloop.Templates.Manager.Marketing = {}; }


Nightloop.Templates.Manager.Marketing.VenueProfile = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Marketing.VenueProfileBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Marketing.VenueProfileBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Marketing.Sidebar(soy.$$augmentData(opt_data, {subcategory: 'venue_profile'}), output);
  output.append('<!-- ', soy.$$escapeHtml(opt_data.venue), ' --><div id="page-venue-profile" class="page-view-mode has-sidebar"><div id="venue-profile"></div><script type="text/javascript">var venueProfileInit = {venueId: "', soy.$$escapeHtml(opt_data.venue.id), '", MEDIA_URL: "', soy.$$escapeHtml(opt_data.MEDIA_URL), '"}<\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/manager/venueprofile.js']), '"><\/script></div>');
  return opt_sb ? '' : output.toString();
};
