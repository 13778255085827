var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Manage = Pmp.Manager.Manage || {};

Pmp.Manager.Manage.VenuePromotersEdit = {
	initialize : function() {
		this._bindEventHandlers();
		$('.message').show().delay(4000).fadeOut(1000);
		$('.error-message').show().delay(4000).fadeOut(1000);
	},
	
	log : function(msg) {
		console.log("Pmp.Manager.Manage.VenuePromotersEdit: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers: function () {
		this.debug("bindEventHandlers");

		var self = this;
		var clickAddNewPromoterNameFn = function(event) {self._clickAddNewPromoterName(event);};
		var clickDeletePromoterNameFn = function(event) {self._clickDeletePromoterName(event);};
		var clickSubmitFn = function(event) {self._clickSubmit(event);return false;};
		var keyupPromoterNameFn = function(event) {self._keyupPromoterName(event);};

		$('#add-new-promoter-name').click(clickAddNewPromoterNameFn);
		$('#venuepromoters-submit-btn').click(clickSubmitFn);

        var page = Pmp.Client.Static.Page;
		$('.promoter-name-input').off('focusout').on('focusout', _.bind(this._onFocusOut, this));
		page.AddLiveHandler('.venue-promoter-delete-link', 'click', clickDeletePromoterNameFn);
		page.AddLiveHandler('.promoter-name-input', 'keyup', keyupPromoterNameFn);
	},

	_onFocusOut: function (event) {
		var currentTarget = event.currentTarget,
			inputText = $(currentTarget).val(),
			promoters = inputText.split(","),
			first_promoter = _.first(promoters),
			remaining_promoters = _.rest(promoters)

		for (var i in remaining_promoters) {
			var promoter = remaining_promoters[i];
			this._addNewPromoterName(promoter);
		}
		$(currentTarget).val(first_promoter)
	},

	_clickAddNewPromoterName: function (event) {
		this._addNewPromoterName('');
	},

	_addNewPromoterName: function (name) {
		var new_record = $('.venue-promoter-name-clone .venue-promoter-name').clone();
		$('.promoters-list').append(new_record);
		new_record.find('input').val(name).off('focusout').on('focusout', _.bind(this._onFocusOut, this));
		return new_record;
	},

	_clickDeletePromoterName : function(event) {
		var link = $(event.target);
		var row = link.parents('.venue-promoter-name');
		row.remove();
	},
	
	_clickSubmit : function(event) {
	    if (this._validate()) {
    	    $('#manager-ajax').show();
			    $('#venuepromoters-submit-btn').sext('Saving...');
	        $('#venuepromoters-edit-form').submit();
        }
	},
	
	_keyupPromoterName : function(event) {
		var input = $(event.target);
		input.removeClass('invalid');
	},
	
	_validate : function() {
		var isValid = true;
		$('.promoter-name-input').each(function() {
			$(this).removeClass('invalid');
			if ($(this).parents('.new-inp').length !== 0) {
				return;
			}
			
			$(this).val($.trim($(this).val()));
			if ($(this).val() == '') {
				isValid = false;
				$(this).addClass('invalid');
			}
		});
		
		if (!isValid) {
			$('#validation-error-message').show().delay(4000).fadeOut(1000);
		}
		
		return isValid;
	}
};
