var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Manage = Pmp.Manager.Manage || {};

Pmp.Manager.Manage.BankEdit = {
  initialize : function(STRIPE_KEY, countryCode) {

    this.countryCode = countryCode; // US or CA or UK
    this._bind();
    BillingService.init(STRIPE_KEY);
    this.legal_entity_validator = new sr.Validator('#payout-container div.stepone', undefined); // TODO: add currency symbol
    this.add_bank_validator = new sr.Validator('#payout-container div.steptwo', undefined); // TODO: add currency symbol
    this.add_tax_validator = new sr.Validator('#payout-container div.tax-box', undefined);
    const params = window.location.search.slice(1).split("&").reduce((acc, text) => { // Check if 'update_user_venue_users_cache'
      const param_items = text.split('=');                                            //  in query params
      acc[param_items[0]] = param_items[1];
      return acc;
    }, {})
    if (params.update_user_venue_users_cache) {
      try {
        window.localStorage.user_domain_cache_version = Date.now() // Change venue cache version till the end of the session
      } catch(err) {
        this.debug('Local storage not available')
      }
    }
  },

  log : function(msg) {
    console.log("Pmp.Manager.Manage.BankEdit: "+ msg);
  },

  debug : function(msg) {
    if (Pmp.Settings.DEBUG) {
      this.log(msg);
    }
  },

  _bind : function() {
    this.debug("bind");
    var self = this;
    this.legal_submit_btn = $('#legal-submit-btn');
    this.legal_submit_btn.on('click', function() { self._submitLegalEntity(); return false; })
    this.add_bank_btn = $('#add-bank-btn');
    this.add_bank_btn.on('click', function() { self._submitAddBankAccount(); return false; })
    this.country_select = $('#select-country');
    this.country_select.on('change', function () { self._changeBankCountry($(this)); });
    this.country_select.change();

    $("#save-tax-btn").on('click', function () {
      self._saveTaxRates($(this));
    });

    $("#add-tax-column").on('click', function () {
      self._addTaxColumn();
    });

    $('.form-element.personal_id_number').on('click', function() {
      if ($('#personal_id_number').attr('disabled')) {
        $('#personal_id_number').val('');
        $('#personal_id_number').removeClass('disabled');
        $('#personal_id_number').attr('disabled', false);
      }
    });

  },

  _saveTaxRates : function (btn) {
    if ($(btn).hasClass('disabled')) {
      return;
    }
    $(btn).addClass('disabled');
    var taxRows = {'headers': [], 'venues': {}, 'delete_tax_group_ids': []};
    var nameRow = true;
    var emptyTaxNames = [];
    $('#tax-table').find('tr').each(function() {
      var venueId = this.id;
      if (!nameRow) {
        taxRows['venues'][venueId] = []
      }
      var index = 0;
      $(this).find('input').each(function() {
        if (nameRow) {
          var taxName = $(this).val();
          if (!taxName) {
            $(this).css({'border-color': 'red'});
            emptyTaxNames.push([this.id, index]);
          } else {
            $(this).css({'border-color': '#d3d3d3'});
          }
          var taxId = this.id ? this.id : null;
          taxRows['headers'].push([taxId, taxName]);
          index += 1;
        } else {
          taxRows['venues'][venueId].push($(this).val());
        }
      });
      nameRow = false;
    });

    var taxNameError = false;
    var emptyNewColumn = [];
    if (emptyTaxNames) {
      for (var i = 0; i < emptyTaxNames.length; i++) {
        var tax_group_id = emptyTaxNames[i][0];
        var tax_group_index = emptyTaxNames[i][1];
        var deleted_tax_group = true;
        for (var venue_id in taxRows['venues']) {
          var venue_tax_rates = taxRows['venues'][venue_id];
          if (venue_tax_rates[tax_group_index]) {
            deleted_tax_group = false;
            break;
          }
        }
        if (deleted_tax_group) {
          if (tax_group_id) {
            taxRows['delete_tax_group_ids'].push(tax_group_id);
            $('#' + tax_group_id).css({'border-color': '#d3d3d3'});
          } else {
            emptyNewColumn.push(tax_group_index);
          }
        } else {
          taxNameError = true;
        }
      }
    }
    // remove empty new tax column from tax data
    for (var i = emptyNewColumn.length - 1; i >= 0; i--) {
      taxRows['headers'].splice(emptyNewColumn[i], 1);
      for (var venue_id in taxRows['venues']) {
        taxRows['venues'][venue_id].splice(emptyNewColumn[i], 1);
      }
    }

    if (taxNameError) {
      $('#tax-table-errors').show();
      $(btn).removeClass('disabled');
      return;
    } else {
      $('#tax-table-errors').hide();
      $('input').css({'border-color': '#d3d3d3'});
      var deferred = BillingService.saveTaxRates({
        tax_rows: JSON.stringify(taxRows)
      });
      deferred.done(function(refresh) {
        metric.track('Settings.Payouts.SubmitTaxRate', {});
        Interface.alertSuccess('Tax rates saved');
        if (refresh || emptyNewColumn.length > 0) {
          window.location.reload();
        } else {
          $(btn).removeClass('disabled');
        }
        // Change venue cache version till the end of the session
        window.localStorage.user_domain_cache_version = Date.now();
      }).fail(function(error) {
        Interface._alert(error.message);
        $(btn).removeClass('disabled');
      })
    }
  },

  _addTaxColumn : function () {
    var taxtable = $('#tax-table')
    taxtable.find('tr').each(function() {
        var trow = $(this);
        if(trow.index() === 0) {
            trow.append('<td class="tax-rate"><input class="input-box" type="text" value="" placeholder="Add Tax Type *" /></td>');
        } else{
            trow.append('<td class="tax-rate"><input class="input-box" type="number" value="" /><span class="tax-rate-percent">%</span></td>');
        }
    });
  },

  _changeBankCountry : function (el) {
    var countrySelect = $('#select-country');
    var addressCountry = $('#address-country');
    if (el.val() == 'US') {
      addressCountry.val('US');
    } else if (el.val() == 'CA') {
      addressCountry.val('CA');
    } else if (el.val() == 'GB') {
      addressCountry.val('GB');
    } else if (el.val() == 'IT') {
      addressCountry.val('IT');
    }
  },
  _submitLegalEntity : function () {
    metric.track('Settings.Payouts.SubmitBankInfo', {});
    $('#legal-entity-form #dob-error').hide();
    if (this.legal_entity_validator.validate()
        && this._valid_dob()) {
      if (this.legal_submit_btn.hasClass('disabled')) {
        return;
      }
      this.legal_submit_btn.addClass('disabled');
      $('#legal-entity-form').submit();
    } else {
      this.legal_submit_btn.removeClass('disabled');
    }
  },

  _submitAddBankAccount : function () {
    var self = this;
    if (this.add_bank_validator.validate()) {

      if (this.add_bank_btn.hasClass('disabled')) {
        return;
      }

      this.add_bank_btn.addClass('disabled');

      var deferred;
      if (this.countryCode == 'US') {
        deferred = this._addBankUSA();
      } else if (this.countryCode == 'CA') {
        deferred = this._addBankCanada();
      } else if (this.countryCode == 'GB') {
        deferred = this._addBankGB();
      } else if (this.countryCode == 'IT') {
        deferred = this._addBankIT();
      }
      deferred.done(function(acct) {
        $('#routing_number').addClass('disabled');
        $('#account_number').addClass('disabled');
      }).fail(function(error) {
        self.add_bank_btn.removeClass('disabled');
        Interface._alert(error.message);
      });

    } else {
      this.add_bank_btn.removeClass('disabled');
    }
  },

  _addBankUSA : function () {
    var deferred = BillingService.addBankAccountUSA({
      routing_number: $('#bank-account-form #routing_number').val(),
      account_number: $('#bank-account-form #account_number').val()
    });
    return deferred;
  },

  _addBankCanada : function () {
    var deferred = BillingService.addBankAccountCanada({
      routing_number: $('#bank-account-form #routing_number').val(),
      account_number: $('#bank-account-form #account_number').val()
    });
    return deferred;
  },

  _addBankGB : function () {
    var deferred = BillingService.addBankAccountGB({
      account_number: $('#bank-account-form #account_number').val()
    });
    return deferred;
  },

  _addBankIT : function () {
    var deferred = BillingService.addBankAccountIT({
      account_number: $('#bank-account-form #account_number').val()
    });
    return deferred;
  },

  _valid_dob: function() {
    var dob = /^\d{4}-\d{2}-\d{2}$/;
    if (dob.test($('#legal-entity-form #dob').val())) {
      return true;
    }
    $('#legal-entity-form #dob-error').show();
    return false;
  }

};
