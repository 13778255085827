// This file was automatically generated from manager.manage.taxrates.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.TaxRatesEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.TaxRatesEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.TaxRatesEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'taxrates'}), output);
  output.append('<div id="page-bankaccount" class="has-sidebar"><div id="main-area"><div id="content-header"><h2>Tax Rates</h2></div></div><div id="tax-table-wrapper"><table id="tax-table">');
  Nightloop.Templates.Manager.Manage.TaxRateRow(opt_data, output);
  output.append('</table></div><div id="tax-table-errors">* Tax name field cannot be empty</div><div id="save-tax-btn" class="button"><a href="javascript:void(0);">Save tax rates</a></div><div id="add-tax-column" class="button"><a class="add-tax-column-btn" href="javascript:void(0);">Add tax column</a></div></div><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Manage.BankEdit.initialize("', soy.$$escapeHtml(opt_data.settings.STRIPE_PUBLIC_KEY), '", "', (opt_data.content.payout_profile) ? soy.$$escapeHtml(opt_data.content.payout_profile.country) : 'US', '");})<\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.TaxRateRow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  var iLimit10017 = opt_data.content.all_venues_tax_fields.length;
  for (var i10017 = 0; i10017 < iLimit10017; i10017++) {
    output.append('<tr id=', soy.$$escapeHtml(opt_data.content.all_venues_tax_fields[i10017][0]), ' class="tax-row ', (i10017 == 0) ? 'header' : '', '">');
    var jLimit10025 = opt_data.content.all_venues_tax_fields[i10017].length;
    for (var j10025 = 1; j10025 < jLimit10025; j10025++) {
      output.append((j10025 == 1) ? '<th class="venue-name">' + soy.$$escapeHtml(opt_data.content.all_venues_tax_fields[i10017][j10025]) + '</th>' : '<td class="tax-rate ' + ((j10025 == 2) ? 'first' : '') + '"><input class="input-box" type="' + ((i10017 == 0) ? 'text' : 'number') + '" ' + ((i10017 == 0) ? 'id="' + soy.$$escapeHtml(opt_data.content.all_venues_tax_fields[i10017][j10025]) + '"' : '') + 'value="' + ((i10017 == 0) ? soy.$$escapeHtml(opt_data.content.tax_groups_dict[opt_data.content.all_venues_tax_fields[i10017][j10025]]) : soy.$$escapeHtml(opt_data.content.all_venues_tax_fields[i10017][j10025])) + '"' + ((i10017 == 0) ? 'placeholder="Add Tax Type"' : '') + ' />' + ((i10017 != 0) ? '<span class="tax-rate-percent">%</span>' : '') + '</td>');
    }
    output.append((opt_data.content.all_venues_tax_fields[i10017].length == 2) ? '<td class="tax-rate first"><input class="input-box" type="' + ((i10017 == 0) ? 'text' : 'number') + '"' + ((i10017 == 0) ? 'placeholder="Add Tax Type"' : '') + ' />' + ((i10017 != 0) ? '<span class="tax-rate-percent">%</span>' : '') + '</td>' : '', '</tr>');
  }
  return opt_sb ? '' : output.toString();
};
