// This file was automatically generated from manager.manage.inventory.seatingareas.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }
if (typeof Nightloop.Templates.Manager.Manage.Inventory == 'undefined') { Nightloop.Templates.Manager.Manage.Inventory = {}; }


Nightloop.Templates.Manager.Manage.Inventory.SeatingAreasEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.Inventory.SeatingAreasEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.Inventory.SeatingAreasEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'seating_areas'}), output);
  output.append('<div id="page-inventory" class="has-sidebar page-edit-mode"><div id="main-area"><div id="content-header"><h2>EDIT SEATING AREAS</h2></div>', (opt_data.content.message) ? '<div class="global-message success fade">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', (opt_data.content.error) ? '<div class="global-message error fade">' + soy.$$escapeHtml(opt_data.content.error) + '</div>' : '', '<form id="seatingareas-submit-form" class="styled" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/capacity/areas/edit" method="post"><input type="hidden" name="no_op_placeholder" value="" /><div><div id="seatingareas-box"><div class="box-content"><div><div id="table-service-header" class="table-header ', (opt_data.content.no_tables) ? ' no-display ' : '', '"><div class="float-left id-column">ABBREVIATION</div><div class="float-left seatarea-name-column">NAME</div><div class="float-left seatarea-order-column">LIST ORDER  <a id="contextual-link-seatarea-order" class="contextual-link" href="javascript:void(0);">(?)</a><div id="contextual-content-seatarea-order" class="contextual-content">This will determine the order that seating areas are listed across the system and notably on the staff summary print-out.</div></div><div class="float-left delete-column">&nbsp;</div><div class="float-end"></div></div>');
  var seating_areaList18057 = opt_data.content.seating_areas;
  var seating_areaListLen18057 = seating_areaList18057.length;
  for (var seating_areaIndex18057 = 0; seating_areaIndex18057 < seating_areaListLen18057; seating_areaIndex18057++) {
    var seating_areaData18057 = seating_areaList18057[seating_areaIndex18057];
    Nightloop.Templates.Manager.Manage.Inventory.SeatingAreasRow({seating_area: seating_areaData18057, is_even: seating_areaIndex18057 % 2}, output);
  }
  output.append('<div id="add-new-tables-here" class="no-display ', (opt_data.content.seating_areas.length % 2) ? ' odd ' : ' even ', '"></div></div><div id="add-new"><p class="indent"><a id="add-new-button" href="javascript:void(0)">Add seating area</a></p></div></div></div><div id="save-button" ><div id="validation-error-message" class="no-display"></div><p class="button"><a id="seatingareas-submit-btn" href="">Save changes</a></p></div></div></form><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Manage.Inventory.SeatingAreas.Edit.initialize();})<\/script></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.Inventory.SeatingAreasRow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="table-row ', (opt_data.is_even) ? ' even ' : ' odd ', '"><div class="float-left id-column">');
  Nightloop.Base.FormElement({name: 'code', type: 'text', value: opt_data.seating_area.code, classes: 'code', label: ''}, output);
  output.append('<div class="float-end"></div></div><div class="float-left seatarea-name-column">');
  Nightloop.Base.FormElement({name: 'name', type: 'text', value: opt_data.seating_area.name, classes: 'name', label: ''}, output);
  output.append('<div class="float-end"></div></div><div class="float-left seatarea-order-column">');
  Nightloop.Base.FormElement({name: 'sort_order', type: 'text', value: opt_data.seating_area.sort_order, classes: 'sort_order', label: ''}, output);
  output.append('<div class="float-end"></div></div><input type="hidden" name="id" value="', (opt_data.seating_area.id) ? soy.$$escapeHtml(opt_data.seating_area.id) : '', '" /><div class="float-left delete-column"><a class="closer" href="javascript:void(0);" onclick=\'javascript:Pmp.Manager.Manage.Inventory.SeatingAreas.Edit.deleteTable(this);\'>&times</a></div><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};
