// This file was automatically generated from manager.manage.dailynotes.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Home == 'undefined') { Nightloop.Templates.Manager.Home = {}; }


Nightloop.Templates.Manager.Home.DailyNotesEditPopup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Main.Popup.PopupLayout(soy.$$augmentData(opt_data, {div_id: 'manager-edit-dailynotes-popup', header_html: 'Today\'s Notes', show_close_link: (opt_data.content.success) ? 'true' : 'false', DynamicTemplate: 'Nightloop.Templates.Manager.Home.DailyNotesEditPopupBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Home.DailyNotesEditPopupBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<form id="edit-dailynotes-form"  action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/home/dailynotes/', soy.$$escapeHtml(opt_data.content.currentday_urlparam), '/edit" method="post"><textarea id="note-textarea" name="note" maxlength=300>', (opt_data.content.note) ? soy.$$escapeHtml(opt_data.content.note) : '', '</textarea><div><div id="save-button-container" class="float-right"><div class="link-next-to-button-div float-left"><a id="dailynotes-edit-cancel-btn" class="close-main-colorbox-link" href="javascript:void(0);">cancel</a></div> ');
  Nightloop.Templates.Widget.GoldButton({text: 'SAVE', size: 'medium', id: 'dailynotes-submit-button'}, output);
  output.append('</div><div class="float-end"></div></div></form><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Home.DailyNotes.Edit.initialize();});<\/script>');
  return opt_sb ? '' : output.toString();
};
