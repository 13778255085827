var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Reports = Pmp.Manager.Reports || {};

Pmp.Manager.Reports.Noshow = {
	initialize : function(manager_base_url, currency_symbol) {

    this._totals = JSON.parse($('#totals-data').sext());
    this._no_shows = JSON.parse($('#noshow-data').sext());
    this._short_shows = JSON.parse($('#shortshow-data').sext());
    this._orderings = JSON.parse($('#ordering-data').sext());

		this._base = Pmp.Manager.Reports.Base;
		this._base.initialize();

		this._manager_base_url = manager_base_url;
		this._currency_symbol = currency_symbol;

		this._bindEventHandlers();
		this._initCharts();

		this._shift_code = this._base.getShiftCode();
    this._type = $('#type-select').val();
	},

	log: function(msg) {
		console.log("Pmp.Manager.Reports.Turndown: "+ msg);
	},

	debug: function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var clickGoBtnFn = function(event) {self._clickGoBtn(event);};
		$('#summary-report-submit-btn').click(clickGoBtnFn);
	},

	_initCharts : function() {
		var self = this;
		/* Callback that creates and populates a data table,
		 * instantiates the pie chart, passes in the data and
		 * draws it.
		 **/
		Pmp.Utils.CreateChart(function(){self._drawCharts();});
    this._showTotals();
	},

  _showTotals: function() {
    var _RESERVATIONS = 0;
    var _COVERS = 1;

    // no show
    $('#no-show-covers').sext(this._no_shows.number.total[_COVERS]);
    $('#no-show-covers-percentage').sext(this._no_shows.percent.total[_COVERS]);
    $('#no-show-reservations').sext(this._no_shows.number.total[_RESERVATIONS]);
    $('#no-show-reservations-percentage').sext(this._no_shows.percent.total[_RESERVATIONS]);

    // short show
    $('#short-show-covers').sext(this._short_shows.number.total[_COVERS]);
    $('#short-show-covers-percentage').sext(this._short_shows.percent.total[_COVERS]);
    $('#short-show-reservations').sext(this._short_shows.number.total[_RESERVATIONS]);
    $('#short-show-reservations-percentage').sext(this._short_shows.percent.total[_RESERVATIONS]);

    // combined
    $('#combined-covers').sext(this._totals.no_show_plus_short_show_covers);
    $('#combined-covers-percentage').sext(this._totals.no_show_plus_short_show_covers_percent);

  },

	_drawCharts: function() {
    var chart_type;
    for (var item in this._orderings) {
      // process date charge separately
      if (item == 'date') {
        continue;
      }
      chart_type = 'BarChart';
      if (item == 'shift') {
        if (this._shift_code) { continue; }
        chart_type = 'ColumnChart';
      }
      var heightOverride = false;
      if (item == 'time' ) {
        heightOverride = 1000;
      }
		  this._drawByItem(item, 0, chart_type, heightOverride);
		  this._drawByItem(item, 1, chart_type, heightOverride);
    }
    this._drawOverTimeChart(0);
    this._drawOverTimeChart(1);
	},

  _drawOverTimeChart : function(type) {

    var type_name = (type ? 'covers' : 'reservations');
		var data = new google.visualization.DataTable();
	  data.addColumn('date', 'Date');
    if (!this._type || this._type == 'no_show') {
      data.addColumn('number', 'No show ' + type_name);
    }
    if (!this._type || this._type == 'short_show') {
      data.addColumn('number', 'Short show ' + type_name);
    }

		var rows = [];
		for (var iter=0; iter<this._orderings["date"].length; iter++) {
      var date = this._orderings["date"][iter];
      var noShowValue = shortShowValue = 0;
      if (date in this._no_shows.number["date"]) {
        noShowValue = this._no_shows.number["date"][date][type];
      }
			if (date in this._short_shows.number["date"]) {
        shortShowValue = this._short_shows.number["date"][date][type];
      }
			var row = [new Date(date)];
      if (!this._type || this._type == 'no_show') {
        row.push(noShowValue);
      }
      if (!this._type || this._type == 'short_show') {
        row.push(shortShowValue);
      }
			rows.push(row);
		}
		data.addRows(rows);

		var options = {
      width:'100%',
      height:268,
      legend: 'top',
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        format: 'MMM d',
        gridlines: {
          color: 'transparent'
        },
      	title: 'Date',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
      	title: '',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      series: {
        0: { lineWidth: 3 },
        1: { lineWidth: 3 }
      },
      isStacked : true,
      colors: this._base.CHART_COLORS_BLUE
		};
    options.chartArea = {
      width: '85%',
      height: '60%'
    };
	  var chart = new google.visualization.LineChart(document.getElementById('date-' + type_name));
    $(window).on('resize', function() {
      chart.draw(data, options);
    });
		chart.draw(data, options);
	},


	_drawByItem: function(item, type, chart_type, heightOverride) {

    if (!chart_type) {
      chart_type = 'BarChart';
    }

    var type_name = (type ? 'covers' : 'reservations');
		var data = new google.visualization.DataTable();

    data.addColumn('string', item);

    if (!this._type || this._type == 'no_show') {
      data.addColumn('number', 'No shows');
    }

    if (!this._type || this._type == 'short_show') {
      data.addColumn('number', 'Short shows');
    }

    var rows = [];
    var row;
    var value;
    var name;
    for (var iter=0; iter<this._orderings[item].length; iter++) {
      value = this._orderings[item][iter];
      if (item === 'shift') {
          name = value[0];
          value = value[1];
      } else {
          name = value;
      }
      row = [name];

      // This is to avoid hardcoded shift ordering
      // creating errors
      try {
        if (!this._type || this._type == 'no_show') {
          row.push(this._no_shows.number[item][value][type]);
        }

        if (!this._type || this._type == 'short_show') {
          row.push(this._short_shows.number[item][value][type]);
        }
      } catch(e) {
        continue;
      }

      if (item === 'shift' && row[1] === 0 && row[2] === 0) {
          // Don't show empty shift categories
          continue;
      }
      rows.push(row);
    }

		data.addRows(rows);

    var height = rows.length * 42;
    var reduction = 50;
    if (chart_type == 'ColumnChart') {
      reduction = 100;
      height = 300;
    }

    var legend = 'bottom';
    if (this._type) {
      legend = 'none';
    }

		var options = {
		  width:375,
      legend:legend,
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      fontSize: 12,
      chartArea: {'height': height-reduction},
		  height: heightOverride || height,
			colors: this._base.CHART_COLORS_BLUE
		};
    if (chart_type == 'ColumnChart') {
      options.chartArea.width = '85%';
    }
		var chart = new google.visualization[chart_type](document.getElementById(item + '-' + type_name));
		chart.draw(data, options);
	},

	_clickGoBtn : function(event) {
		if (this._base.validateCalendars()) {
      this._base.showLoadingBar();
			var from_date_url = this._base.getFromDateUrl();
			var to_date_url = this._base.getToDateUrl();
			var shiftCode = this._base.getShiftCode();
			var type = $('#type-select').val();
			var relativeUrl = this._manager_base_url + "/reports/noshow/"+ from_date_url +"/"+ to_date_url;
			relativeUrl += '?shift_code=' + shiftCode;
			relativeUrl += '&show_type=' + type;
      window.location = relativeUrl;
		}
	}
};
