var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Reports = Pmp.Manager.Reports || {};

Pmp.Manager.Reports.Summary = {
	initialize : function(
			venue_class,
			manager_base_url,
			date_completed_list,
			date_created_list,
			party_size_booking_list,
			dow_booking_list,
			has_unknown,
			total_completed_num,
			total_completed_amt,
			currency_symbol,
			covers,
			covers_for_spend
		) {
		this._base = Pmp.Manager.Reports.Base;
		this._base.initialize();

		this._manager_base_url = manager_base_url;
		this._date_completed_list = date_completed_list;
		this._date_created_list = date_created_list;
		this._party_size_booking_list = party_size_booking_list;
		this._dow_booking_list = dow_booking_list;
		this._has_unknown = has_unknown;
		this._total_completed_num = total_completed_num;
		this._total_completed_amt = total_completed_amt;
		this._currency_symbol = currency_symbol;
		this.total_covers = covers;
		this.total_covers_with_spend = covers_for_spend;

		// switch out between nightlife and dining charts
		this.venue_class = venue_class;

		this._bindEventHandlers();
		this._initCharts();
	},

	log : function(msg) {
		console.log("Pmp.Manager.Reports.Summary: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var clickGoBtnFn = function(event) {self._clickGoBtn(event);};

		$('#summary-report-submit-btn').click(clickGoBtnFn);
	},

	_initCharts : function() {
		var self = this;

		/* Callback that creates and populates a data table,
		 * instantiates the pie chart, passes in the data and
		 * draws it.
		 **/
		if (self.venue_class == 'NIGHTLIFE') {
			Pmp.Utils.CreateChart(function(){self._drawCharts();});
		} else {
			Pmp.Utils.CreateChart(function(){self._drawDiningCharts();});
		}
	},

	_drawCharts : function() {
		this._drawSpendOverTimeChart();
		this._drawByPartySizeChart('reservation');
		this._drawReservationsOverTimeChart();
	},

	_drawDiningCharts: function() {
		this._drawSpendOverTimeChart();
		this._drawByPartySizeChart('reservation');
		this._drawByPartySizeChart('cover');
		this._drawByDowChart('reservation');
		this._drawByDowChart('cover');
		this._drawReservationsOverTimeChart();

		this._drawSpendPer('cover');
		this._drawSpendPer('reservation');
		// this._drawCoversResBar();
		// this._drawCoversResSpendBar();
    // this._drawCreatedOverTimeChart();
	},

	_drawCoversResBar: function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Type');
		data.addColumn('number', 'Counts');

		var rows = [];
		rows.push(['Covers', this.total_covers]);
		rows.push(['Reservations', this._total_completed_num]);
		data.addRows(rows);

		var chart = new google.visualization.ColumnChart(document.getElementById('covers-res-chart'));
		var options = {
      width:445,
      height:268,
      legend: 'none',
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
      	title: '',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        label: 'none',
        textStyle: { color: this._base.CHART_TEXT_COLOR },
      	title: '',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      colors: this._base.CHART_COLORS_BLUE
		};

		chart.draw(data, options);
	},

	_drawCoversResSpendBar: function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Type');
		data.addColumn('number', 'spend');

		var rows = [];
		rows.push(['Covers', this._total_completed_amt/this.total_covers_with_spend]);
		rows.push(['Reservations', this._total_completed_amt/this._total_completed_num]);
		data.addRows(rows);

		var chart = new google.visualization.ColumnChart(document.getElementById('covers-res-spend-chart'));
		var formatter = new google.visualization.NumberFormat({prefix: this._currency_symbol});
		var options = {
      width:445,
      height:268,
      legend: 'none',
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
      	title: '',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
      	title: '',
        label: 'none',
      	format: this._currency_symbol+'#,###',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      colors: this._base.CHART_COLORS_BLUE
		};

		chart.draw(data, options);
	},

	_drawSpendPer: function(type) {

		var data = new google.visualization.DataTable();
    data.addColumn('date', 'Date');
    data.addColumn('number', 'Average Spend');

		var spendRows = [];

		for (var i = 0; i < this._date_completed_list.length; i++) {
			var date_completed = this._date_completed_list[i];
      var spend_total = date_completed['spend_table'] + date_completed['spend_bar'] + date_completed['spend_unknown'];
      if (type == 'cover') {
        var divisor = date_completed['num_covers'];
      } else {
				var divisor = date_completed['num_table'] + date_completed['num_bar'] + date_completed['num_unknown'];
      }
			var spendRow = [
			  new Date(date_completed['date']),
        spend_total / divisor
      ];
			spendRows.push(spendRow);
    }
		data.addRows(spendRows);

		var options = {
      width:445,
      height:268,
      legend: 'none',
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        format: 'MMM d',
        gridlines: {
          color: 'transparent'
        },
      	title: 'Date',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
      	title: '',
      	format: this._currency_symbol+'#,###',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      isStacked : true,
      colors: this._base.CHART_COLORS_BLUE
		};

    var formatter = new google.visualization.NumberFormat({prefix: this._currency_symbol});
    formatter.format(data, 1);

		var chart = new google.visualization.ColumnChart(document.getElementById('spend-' + type + '-chart'));

		chart.draw(data, options);
  },

	_drawSpendOverTimeChart : function() {
		var data = new google.visualization.DataTable();
		if (this.venue_class == 'NIGHTLIFE') {
		  data.addColumn('string', 'Date');
			data.addColumn('number', 'Table Service');
			data.addColumn('number', 'Bar Service');
			if (this._has_unknown) {
				data.addColumn('number', 'Other');
			}
		} else {
		  data.addColumn('date', 'Date');
			data.addColumn('number', 'Spend');
		}
		var spendRows = [];
		for (var i = 0; i < this._date_completed_list.length; i++) {
			var date_completed = this._date_completed_list[i];
			if (this.venue_class == 'NIGHTLIFE') {
				var spendRow = [
				    date_completed['date'],
				    date_completed['spend_table'],
				    date_completed['spend_bar']
				];
				if (this._has_unknown) {
					spendRow.push(date_completed['spend_unknown']);
				}
			} else {
				var spend_total = date_completed['spend_table'] + date_completed['spend_bar'] + date_completed['spend_unknown'];
				var spendRow = [
				    new Date(date_completed['date']),
				    spend_total,
				];
			}
			spendRows.push(spendRow);
		}
		data.addRows(spendRows);

    var formatter = new google.visualization.NumberFormat({prefix: this._currency_symbol});

		if (this.venue_class == 'NIGHTLIFE') {
	    formatter.format(data, 1);
	    formatter.format(data, 2);
			if (this._has_unknown) {
			    formatter.format(data, 3);
			}
		} else {
			formatter.format(data, 1);
		}

		var options = {
      width:445,
      height:268,
      legend: 'none',
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        format: 'MMM d',
        gridlines: {
          color: 'transparent'
        },
      	title: 'Date',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
      	title: '',
      	format: this._currency_symbol+'#,###',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      series: {
        0: { lineWidth: 3 }
      },
      isStacked : true,
      colors: this._base.CHART_COLORS_BLUE
		};

		if (this._total_completed_amt < 1000) {
			options.vAxis.viewWindow = {
        min: 0,
        max: 1000
      };
		}

		if (this.venue_class == 'NIGHTLIFE') {
		  var chart = new google.visualization.ColumnChart(document.getElementById('spend-over-time-chart'));
    } else {
      options.width = '100%';
      options.chartArea = {
        width: '85%',
        height: '60%'
      };
		  var chart = new google.visualization.LineChart(document.getElementById('spend-over-time-chart'));
      $(window).on('resize', function() {
        chart.draw(data, options);
      });
    }

		chart.draw(data, options);
	},

	_drawByDowChart : function(type) {
		var data = new google.visualization.DataTable();

    // NOTE not locale aware
    var ordering = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

		data.addColumn('string', 'Day of Week');
		data.addColumn('number', 'Booked');

    var dRows = [];
		for (var i = 0; i < ordering.length; i++) {
			var dow_booking = this._dow_booking_list[ordering[i]];

			var dRow = [
			  dow_booking['dow'],
        dow_booking[type + 's']
			];

      dRows.push(dRow);
    }

    data.addRows(dRows);

		var options = {
		  width:445,
		  height:268,
      legend: 'none',
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        format: '0',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      colors: this._base.CHART_COLORS_BLUE
		};

		var chart = new google.visualization.ColumnChart(document.getElementById(type + 's-by-dow-chart'));
		chart.draw(data, options);
  },

	_drawByPartySizeChart : function(type) {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Party Size');
		data.addColumn('number', 'Booked');

		var psRows = [];
		for (var i = 0; i < this._party_size_booking_list.length; i++) {
			var party_size_booking = this._party_size_booking_list[i];

      if (type == 'reservation') {
			  var variable = party_size_booking['num']
      } else {
			  var variable = party_size_booking['covers']
      }
			var psRow = [
			  party_size_booking['party_size'],
        variable
			];
			psRows.push(psRow);
		}
		data.addRows(psRows);

		var options = {
		  width:445,
		  height:268,
      legend: 'none',
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      colors: this._base.CHART_COLORS_BLUE
		};

		var chart = new google.visualization.ColumnChart(document.getElementById(type + 's-by-party-size-chart'));
		chart.draw(data, options);
	},

  // For dining, this draws a more complex chart
  // with covers and ressies
	_drawReservationsOverTimeChart : function() {
		var data = new google.visualization.DataTable();
		if (this.venue_class == 'NIGHTLIFE') {
		  data.addColumn('string', 'Date');
			data.addColumn('number', 'Table Service');
			data.addColumn('number', 'Bar Service');
			if (this._has_unknown) {
				data.addColumn('number', 'Other');
			}
		} else {
		  data.addColumn('date', 'Date');
			data.addColumn('number', 'Reservations');
			data.addColumn('number', 'Covers');
		}
		var resRows = [];
		for (var i = 0; i < this._date_completed_list.length; i++) {
			var date_completed = this._date_completed_list[i];
			if (this.venue_class == 'NIGHTLIFE') {
				var resRow = [
				    date_completed['date'],
				    date_completed['num_table'],
				    date_completed['num_bar']
				];
				if (this._has_unknown) {
					resRow.push(date_completed['num_unknown']);
				}
			} else {
				var num_res = date_completed['num_table'] + date_completed['num_bar'] + date_completed['num_unknown'];
				var resRow = [
					new Date(date_completed['date']),
					num_res,
					date_completed['num_covers'],
				];
			}
			resRows.push(resRow);
		}
		data.addRows(resRows);

		var options = {
      width:445,
      height:268,
      legend: 'top',
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        format: 'MMM d',
        gridlines: {
          color: 'transparent'
        },
      	title: 'Date',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
      	title: '',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      series: {
        0: { lineWidth: 3 },
        1: { lineWidth: 3 }
      },
      isStacked : true,
      colors: this._base.CHART_COLORS_BLUE
		};

    /*
		if (this._total_completed_num < 12) {
			options.vAxis.viewWindow = {
        min: 0,
        max: 12
      };
		}
    */

		if (this.venue_class == 'NIGHTLIFE') {
		  var chart = new google.visualization.ColumnChart(document.getElementById('reservations-over-time-chart'));
    } else {
      options.width = '100%';
      options.chartArea = {
        width: '85%',
        height: '60%'
      };
		  var chart = new google.visualization.LineChart(document.getElementById('reservations-over-time-chart'));
      $(window).on('resize', function() {
        chart.draw(data, options);
      });
    }
		chart.draw(data, options);
	},

  _drawCreatedOverTimeChart: function() {
    var data = new google.visualization.DataTable();
		data.addColumn('string', 'Date');
    data.addColumn('number', 'Reservations Created');
		var resRows = [];
		for (var i = 0; i < this._date_created_list.length; i++) {
			var date_created = this._date_created_list[i];
      var resRow = [date_created['date'], date_created['num']];
			resRows.push(resRow);
		}
		data.addRows(resRows);

    var options = {
      width:445,
      height:268,
      legend: 'none',
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        slantedText: true,
        slantedTextAngle: 45,
      	title: 'Date',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
      	title: '',
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      isStacked : true,
      colors: this._base.CHART_COLORS_BLUE,
      min: 0
		};
    var chart = new google.visualization.ColumnChart(document.getElementById('created-over-time-chart'));
    chart.draw(data, options);
  },

	_clickGoBtn : function(event) {
		if (this._base.validateCalendars()) {
      this._base.showLoadingBar();
			var from_date_url = this._base.getFromDateUrl();
			var to_date_url = this._base.getToDateUrl();
			var relativeUrl = this._manager_base_url + "/reports/summary/"+ from_date_url +"/"+ to_date_url + '?shift_code=' + this._base.getShiftCode();

      window.location = relativeUrl;

			// Pmp.Client.Static.Page.Navigate('#nightloop', relativeUrl);
		}
	}
};
