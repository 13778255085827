var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Clients = Pmp.Manager.Clients || {};

Pmp.Manager.Clients.Merge = {
	initialize : function(manager_base_url, member_profiles_exist) {
		this._manager_base_url = manager_base_url;
		this._member_profiles_exist = member_profiles_exist;
		this._is_notes_in_error_mode = false;

		this._bindEventHandlers();
		this.validateNotesTextArea();
	},
	
	log : function(msg) {
		console.log("Pmp.Manager.Clients.Merge: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var onClickPropertyCellFn = function(event) {self._onClickPropertyCell(event, $(this));};
		var onClickMergeClientProfilesCancelBtnFn = function(event) {self._onClickMergeClientProfilesCancelBtn(event);};
		var validateNotesTextAreaFn = function(event) {self.validateNotesTextArea(event);};
		
		$('.property-cell').click(onClickPropertyCellFn);
		//Pmp.Utils.GoldButtonClickSubmit('#merge-client-profiles-save-btn');
		
		$('#merge-client-profiles-save-btn').on('click', function() {
		  self._submit();
		})
		
		$('#merge-client-profiles-cancel-btn').click(onClickMergeClientProfilesCancelBtnFn);
	  $('#combined-notes').keyup(validateNotesTextAreaFn);
	  $('#combined-notes').change(validateNotesTextAreaFn);
	},
	
	_submit : function(btn) {
	  if (this._validate()) {
	    $(btn).addClass('disabled pending');
	    $('#clients-merge-form').submit();
	  }
	},
	
	_validate : function () {
	  $('.merge-cell').removeClass('error-outline');
	  if (this._member_profiles_exist) {
  	  var mergedEmailCell = $('.merge-cell.email');
  	  if (mergedEmailCell.html().trim().indexOf('@') === -1) {
  	    mergedEmailCell.addClass('error-outline');
  	    Interface._alert('Please select an email address.')
  	    return false;
  	  }
  	  
  	  var mergedMemberGroupCell = $('.merge-cell.member_groups');
      if (mergedMemberGroupCell.html().trim().indexOf('0') !== -1) {
        mergedMemberGroupCell.addClass('error-outline');
        Interface._alert('Please select a client\'s member groups.')
        return false;
      }
      
      var mergedBillingProfileCell = $('.merge-cell.billing_profile_key');
      if (mergedBillingProfileCell.html().trim().indexOf('billing') === -1) {
        mergedBillingProfileCell.addClass('error-outline');
        Interface._alert('Please select a client\'s billing profile.')
        return false;
      }
  	  
	  }
	  
	  return true;
	},
	
	
	_onClickPropertyCell : function(event, $clicked_cell) {
		if ($clicked_cell.hasClass('selectable')) {
			var property_name = $clicked_cell.attr('property_name'),
				profile_index = $clicked_cell.attr('profile_index'),
				selected_value = $clicked_cell.html(),
				$prev_selected_cell = $('.property-cell.selected.'+property_name),
				$merge_cell = $('.property-cell.merge-cell.'+property_name),
				$merge_input = $merge_cell.find('input'),
				$merge_select = $merge_cell.find('select'),
				$merge_span = $merge_cell.find('input'),
				$merge_index_input = $('input#'+property_name+'_index');

			$prev_selected_cell.addClass('selectable').removeClass('selected');
			$clicked_cell.addClass('selected').removeClass('selectable');
			$merge_select.val(selected_value);
			$merge_input.val(selected_value);
			$merge_span.html(selected_value);
			$merge_index_input.val(profile_index);
			if (property_name == "photo") {
				$merge_cell.find('img').remove();
				$merge_cell.append(selected_value);
			}
		}
	},

	_onClickMergeClientProfilesCancelBtn : function(event) {
		this.debug('_onClickMergeClientProfilesCancelBtn');
		history.go(-1);
	},

	validateNotesTextArea : function(event) {
		var textarea = $('#combined-notes');
	  var maxlength = parseInt(textarea.prop('maxlength'), 10);
		var remaining = maxlength - textarea.val().length;
		var remaining_span = $('#combined-notes-remaining');
		if (remaining < 0) {
			remaining_span.sext((0 - remaining) + ' over limit');
			if (this._is_notes_in_error_mode === false) {
				remaining_span.addClass('error');
				textarea.addClass('error-outline');
				$('#merge-client-profiles-save-btn').addClass('disabled');
				this._is_notes_in_error_mode = true;
			}
			return false;
		} else {
			remaining_span.sext(remaining + '');
			if (this._is_notes_in_error_mode === true) {
				remaining_span.removeClass('error');
				textarea.removeClass('error-outline');
				$('#merge-client-profiles-save-btn.disabled').removeClass('disabled');
				this._is_notes_in_error_mode = false;
			}
			return true;
		}
	}
	
};
