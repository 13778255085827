var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Reports = Pmp.Manager.Reports || {};
Pmp.Manager.Reports.Tally = Pmp.Manager.Reports.Tally || {};

Pmp.Manager.Reports.Tally.Individual = {
	initialize : function(manager_base_url, currency_symbol, tally_by_date_list, venue_crowd_promoter_key, promoter_name) {
		this._base = Pmp.Manager.Reports.Base;
		this._base.initialize();

		this._manager_base_url = manager_base_url;
		this._currency_symbol = currency_symbol;

		this._tally_by_date_list = tally_by_date_list;
		this._venue_crowd_promoter_key = venue_crowd_promoter_key;
		this._promoter_name = promoter_name;

		this._bindEventHandlers();
		this._initCharts();

    var back_link = sessionStorage.getItem('tally_url');
    if( back_link ) {
      $('#back-link').prop('href', back_link);
    }
	},

	log : function(msg) {
		console.log("Pmp.Manager.Reports.Tally.Individual: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var clickGoBtnFn = function(event) {self._clickGoBtn(event);};

		$('#summary-report-submit-btn').click(clickGoBtnFn);
	},

	_initCharts : function() {
		var self = this;
		/* Callback that creates and populates a data table,
		 * instantiates the pie chart, passes in the data and
		 * draws it.
		 **/
		Pmp.Utils.CreateChart(function(){self._drawCharts();});
	},

	_drawCharts : function() {
		this._drawMalesByDate();
		this._drawFemalesByDate();
		this._drawRatingsByDate();
	},

	_drawMalesByDate : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Date');
		data.addColumn('number', this._promoter_name);
		data.addColumn('number', 'All Promoters');

		var tallyRows = [];
		for (var i = this._tally_by_date_list.length - 1; i >= 0 ; i--) {
			var tally = this._tally_by_date_list[i];
			var tallyRow = [
			    tally['date'],
			    tally['num_males'],
			    tally['avg_num_males']
			];
			tallyRows.push(tallyRow);
		}
		data.addRows(tallyRows);

		var formatter = new google.visualization.NumberFormat({groupingSymbol: ',', fractionDigits:0});
	    formatter.format(data, 1);
	    formatter.format(data, 2);

	    var single_date = (this._tally_by_date_list.length == 1);

		var options = {
				width:445,
		        height:189,
		        chartArea: {top:25},
		        hAxis: {
              textStyle: { color: this._base.CHART_TEXT_COLOR },
		        	title: 'Date',
		        	slantedText:!single_date,
		        	showTextEvery:true,
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        vAxis: {
              textStyle: { color: this._base.CHART_TEXT_COLOR },
		        	title: '',
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        fontSize: 11,
		        colors: ['#012393', '#a5b7f8']
		};
		var chart = null;
		if (single_date) {
			// unfortunately area charts break down with single dates
			chart = new google.visualization.ColumnChart(document.getElementById('males-by-day'));
		} else {
			chart = new google.visualization.LineChart(document.getElementById('males-by-day'));
		}
		chart.draw(data, options);
	},

	_drawFemalesByDate : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Date');
		data.addColumn('number', this._promoter_name);
		data.addColumn('number', 'All Promoters');

		var tallyRows = [];
		for (var i = this._tally_by_date_list.length - 1; i >= 0 ; i--) {
			var tally = this._tally_by_date_list[i];
			var tallyRow = [
			    tally['date'],
			    tally['num_females'],
			    tally['avg_num_females']
			];
			tallyRows.push(tallyRow);
		}
		data.addRows(tallyRows);

		var formatter = new google.visualization.NumberFormat({groupingSymbol: ',', fractionDigits:0});
	    formatter.format(data, 1);
	    formatter.format(data, 2);

	    var single_date = (this._tally_by_date_list.length == 1);

		var options = {
				width:445,
		        height:189,
		        chartArea: {top:25},
		        hAxis: {
              textStyle: { color: this._base.CHART_TEXT_COLOR },
		        	title: 'Date',
		        	slantedText:!single_date,
		        	showTextEvery:true,
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        vAxis: {
              textStyle: { color: this._base.CHART_TEXT_COLOR },
		        	title: '',
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        fontSize: 11,
		        colors: ['#b50101', '#db9b9b']
		};
		var chart = null;
		if (single_date) {
			// unfortunately area charts break down with single dates
			chart = new google.visualization.ColumnChart(document.getElementById('females-by-day'));
		} else {
			chart = new google.visualization.LineChart(document.getElementById('females-by-day'));
		}
		chart.draw(data, options);
	},

	_drawRatingsByDate : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Date');
		data.addColumn('number', this._promoter_name);
		data.addColumn('number', 'All Promoters');

		var tallyRows = [];
		for (var i = this._tally_by_date_list.length - 1; i >= 0 ; i--) {
			var tally = this._tally_by_date_list[i];
			var tallyRow = [
			    tally['date'],
			    tally['rating'],
			    tally['avg_rating']
			];
			tallyRows.push(tallyRow);
		}
		data.addRows(tallyRows);

		var formatter = new google.visualization.NumberFormat({groupingSymbol: ',', fractionDigits:2});
	    formatter.format(data, 1);
	    formatter.format(data, 2);

	    var single_date = (this._tally_by_date_list.length == 1);

		var options = {
		        width:445,
		        height:240,
		        chartArea: {top:25},
		        hAxis: {
              textStyle: { color: this._base.CHART_TEXT_COLOR },
		        	title: 'Date',
		        	slantedText:!single_date,
		        	showTextEvery:true,
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        vAxis: {
              textStyle: { color: this._base.CHART_TEXT_COLOR },
		        	title: '',
		          titleTextStyle: this._base.CHART_TITLE_STYLE
		        },
		        fontSize: 11,
		        colors: ['#a56f06', '#daaf5b']
		};
		var chart = null;
		if (single_date) {
			// unfortunately area charts break down with single dates
			chart = new google.visualization.ColumnChart(document.getElementById('rating-by-day'));
		} else {
			chart = new google.visualization.LineChart(document.getElementById('rating-by-day'));
		}
		chart.draw(data, options);
	},

	_clickGoBtn : function(event) {
		if (this._base.validateCalendars()) {
      this._base.showLoadingBar();
			var from_date_url = this._base.getFromDateUrl();
			var to_date_url = this._base.getToDateUrl();
			var relativeUrl = this._manager_base_url + "/reports/tallyindividual/" + this._venue_crowd_promoter_key + "/"+ from_date_url +"/"+ to_date_url;
			window.location = relativeUrl;
		}
	}
};
