// This file was automatically generated from manager.home.accessrulestable.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Home == 'undefined') { Nightloop.Templates.Home = {}; }
if (typeof Nightloop.Templates.Home.AccessRulesTable == 'undefined') { Nightloop.Templates.Home.AccessRulesTable = {}; }


Nightloop.Templates.Home.AccessRulesTable.AccessRules = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Home.AccessRulesTableBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Home.AccessRulesTable.AccessRulesTableBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'accessrules'}), output);
  output.append('<div id="page-access-rules" class="page-view-mode"><div id="content-data">TESTING</div></div>');
  return opt_sb ? '' : output.toString();
};
