// This file was automatically generated from manager.reports.payments.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Reports == 'undefined') { Nightloop.Templates.Manager.Reports = {}; }


Nightloop.Templates.Manager.Reports.Payments = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Reports.PaymentsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.PaymentsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Reports.ReportSidebar(soy.$$augmentData(opt_data, {selected: 'payments'}), output);
  output.append('<div id="page-payments" class="page-report has-sidebar"><div id="main-area">');
  Nightloop.Templates.Manager.Reports.PrintHeader(soy.$$augmentData(opt_data, {name: 'Search Payments', custom_display: opt_data.content.recent_display + ' - All transactions - All statuses '}), output);
  output.append('<div id="content-header"><h2>Payments</h2><p class="button"><a id="export-payments" href="#">Export</a></p></div>');
  Nightloop.Templates.Manager.Reports.Seek(opt_data, output);
  output.append('<div id="payments-box" class="standard-list"><div class="table-header"><div class="col col-name">Venue</div><div class="col col-date">Date</div><div class="col col-description">Description</div><div class="col col-res-date">Res. Date</div><div class="col col-notes">Notes</div><div class="col col-client">Client</div><div class="col col-status">Status</div><div class="col col-custom">Card</div><div class="col col-base">Base</div><div class="col col-base">Upgrade</div><div class="col col-service-charge">Service Charge</div><div class="col col-gratuity">Gratuity</div><div class="col col-custom">Custom Fee</div><div class="col col-service-charge">Custom Fee Tax</div><div class="col col-tax">Tax</div><div class="col col-promo">Promotion</div>', (opt_data.is_superuser) ? '<div class="col col-custom">Total</div><div class="col col-custom">Proc. Fee</div>' : '<div class="col col-amount">Total</div>', '</div><div id="payments-block" class="list-block"></div></div><img id="list-loader" class="new-spin bare" src=\'', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/new-spinner.gif\'/><div id="show-more" class="plain button"><a>Show More</a></div></div></div>');
  Nightloop.Templates.Manager.MainInterface(soy.$$augmentData(opt_data, {caller: 'reservations'}), output);
  output.append('<script type="text/javascript">$(function() {Payments.init("', soy.$$escapeHtml(opt_data.settings.STRIPE_PUBLIC_KEY), '", \'payments\'); var payment_client_key = ', (opt_data.content.payment_client_key) ? '"' + soy.$$escapeHtml(opt_data.content.payment_client_key) + '"' : 'undefined', ';');
  Nightloop.Base.ReservationSlideOutInit(opt_data, output);
  output.append('});<\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.PaymentRow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="standard-row payment-row ', (! opt_data.successful) ? 'failed' : '', '"><div class="col col-name">', soy.$$escapeHtml(opt_data.name), '</div><div class="col col-date">', soy.$$escapeHtml(opt_data.date), '</div><div class="col col-description">', (opt_data.reference_code) ? ((opt_data.transaction_type == 'transaction_type_web_order_charge' || opt_data.service_type == 'Order') ? 'Online order' : 'Reservation') + ' <span class="reference-code" service_type="' + soy.$$escapeHtml(opt_data.service_type) + '" venue_id="' + soy.$$escapeHtml(opt_data.venue_id) + '" transaction_type="' + soy.$$escapeHtml(opt_data.transaction_type) + '" actualid="' + soy.$$escapeHtml(opt_data.entity_id) + '">#' + soy.$$escapeHtml(opt_data.reference_code) + '</span>' : (opt_data.plan) ? soy.$$escapeHtml(opt_data.plan) : '', '</div><div class="col col-res-date">', soy.$$escapeHtml(opt_data.res_date), '</div><div class="col col-notes">', soy.$$escapeHtml(opt_data.res_notes), '</div><div class="col col-client"><a href="', soy.$$escapeHtml(opt_data.base_url), '/clients/profile/', soy.$$escapeHtml(opt_data.venue_group_client_id), '">', soy.$$escapeHtml(opt_data.client), '</a></div><div class="col col-status">', soy.$$escapeHtml(opt_data.transaction_type_str), '</div><div class="col col-custom">', soy.$$escapeHtml(opt_data.card_display), '</div><div class="col col-base">');
  Nightloop.Templates.Manager.Reports.AmountDisplay(soy.$$augmentData(opt_data, {display_amount: opt_data.base}), output);
  output.append('</div><div class="col col-base">');
  Nightloop.Templates.Manager.Reports.AmountDisplay(soy.$$augmentData(opt_data, {display_amount: opt_data.upsell}), output);
  output.append('</div><div class="col col-service-charge">');
  Nightloop.Templates.Manager.Reports.AmountDisplay(soy.$$augmentData(opt_data, {display_amount: opt_data.service_charge}), output);
  output.append('</div><div class="col col-gratuity">');
  Nightloop.Templates.Manager.Reports.AmountDisplay(soy.$$augmentData(opt_data, {display_amount: opt_data.gratuity}), output);
  output.append('</div><div class="col col-custom">');
  Nightloop.Templates.Manager.Reports.AmountDisplay(soy.$$augmentData(opt_data, {display_amount: opt_data.custom}), output);
  output.append('</div><div class="col col-service-charge">');
  Nightloop.Templates.Manager.Reports.AmountDisplay(soy.$$augmentData(opt_data, {display_amount: opt_data.custom_tax}), output);
  output.append('</div><div class="col col-tax">');
  Nightloop.Templates.Manager.Reports.AmountDisplay(soy.$$augmentData(opt_data, {display_amount: opt_data.tax}), output);
  output.append('</div><div class="col col-promo">', (opt_data.promo_discount) ? '<em>-' + soy.$$escapeHtml(opt_data.promo_discount) + '</em>' : '', '</div>');
  if (opt_data.is_superuser) {
    output.append('<div class="col ', (! opt_data.successful) ? 'failed' : '', ' col-custom">', (opt_data.is_refund) ? '(' + soy.$$escapeHtml(opt_data.amount) + ')' : soy.$$escapeHtml(opt_data.amount), '</div><div class="col col-custom">');
    Nightloop.Templates.Manager.Reports.AmountDisplay(soy.$$augmentData(opt_data, {display_amount: opt_data.processing}), output);
    output.append('</div>');
  } else {
    output.append('<div class="col ', (! opt_data.successful) ? 'failed' : '', ' col-amount">', (opt_data.is_refund) ? '(' + soy.$$escapeHtml(opt_data.amount) + ')' : soy.$$escapeHtml(opt_data.amount), '</div>');
  }
  output.append('</div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.AmountDisplay = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append((opt_data.report_refund_breakdown && opt_data.display_amount) ? '(' + soy.$$escapeHtml(opt_data.display_amount) + ')' : (opt_data.is_refund) ? '-' : (opt_data.display_amount) ? soy.$$escapeHtml(opt_data.display_amount) : '-');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.Seek = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="interface" class="do-not-print"><form id="search-and-filter-form" class="styled" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/payments" method="GET"><div class="search-interface"><p><img class="glass" src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/magnifier.gif" alt="" /><input type="text" placeholder="Search" id="search-query-input" name="q" value="', soy.$$escapeHtml(opt_data.content.query), '" /></p></div><div id="client-lookup-search-btn"></div><div class="form-element select"><label for="payment-date-filter"><p class="input"><select id="payment-date-filter" name="date"><option value="', soy.$$escapeHtml(opt_data.content.date_ranges.recent.value), '">', soy.$$escapeHtml(opt_data.content.date_ranges.recent.label), '</option><option value="', soy.$$escapeHtml(opt_data.content.date_ranges.last_month.value), '">', soy.$$escapeHtml(opt_data.content.date_ranges.last_month.label), '</option><option value="', soy.$$escapeHtml(opt_data.content.date_ranges.two_month.value), '">', soy.$$escapeHtml(opt_data.content.date_ranges.two_month.label), '</option><option value="', soy.$$escapeHtml(opt_data.content.date_ranges.three_month.value), '">', soy.$$escapeHtml(opt_data.content.date_ranges.three_month.label), '</option><option value="', soy.$$escapeHtml(opt_data.content.date_ranges.year_to_date.value), '">', soy.$$escapeHtml(opt_data.content.date_ranges.year_to_date.label), '</option><option value="CUSTOM">Custom date range</option></select><span class="downer"></span></p></label></div><div class="custom-range">');
  Nightloop.Base.FormElement({type: 'text', id: 'from-calendar', name: 'from-date-localized', placeholder: 'Start date', value: ''}, output);
  Nightloop.Base.FormElement({type: 'text', id: 'to-calendar', name: 'to-date-localized', placeholder: 'End date', value: ''}, output);
  output.append('<input id="from-calendar-submit" type="hidden" name="from-date" value="" /><input id="to-calendar-submit" type="hidden" name="to-date" value="" /></div><div class="form-element select"><label for="payment-type-filter"><p class="input"><select id="payment-transaction-filter" name="transaction_type"><option value="ALL">All transactions</option><option value="INTERNAL">Internal reservations</option><option value="WEB">Web reservations</option><option value="MEMBERSHIP">Membership</option><option value="REFUND">Refund</option></select><span class="downer"></span></p></label></div><div class="form-element select payment-type-filter"><label for="payment-type-filter"><p class="input"><select id="payment-type-filter" name="status"><option value="ALL">All statuses</option><option value="SUCCESSFUL">Successful</option><option value="FAILED">Failed</option></select><span class="downer"></span></p></label></div><div class="form-element select venue-type-filter-wrapper"><label for="venue-type-filter"><div class="venue-type-filter"><input class="checkbox all" type="checkbox"><div class="label">Search All Venues</div><div class="divider"></div></div></label></div><p class="button small"><a href="javascript:void(0);" id="search-form-submit">Go</a></p><div class="float-end"></div></form></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.VenueOption = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<input  class="checkbox" type="checkbox"', (opt_data.checked) ? 'checked="checked"' : '', 'name="venue_ids" value="', soy.$$escapeHtml(opt_data.id), '"/><div class="label">', soy.$$escapeHtml(opt_data.label), '</div><div class="divider"></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.ConfirmReportExportPopup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Main.Popup.PopupLayout(soy.$$augmentData(opt_data, {div_id: 'manager-payments-confirm-export-popup', header_html: 'Payments Export', show_close_link: 'true', DynamicTemplate: 'Nightloop.Templates.Manager.Reports.ConfirmReportExportPopupBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.ConfirmReportExportPopupBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="confirm-export-container"><div id="export-prompt-text">Exporting payments may take several minutes. Click export and we\'ll email a download link to ', soy.$$escapeHtml(opt_data.user_email), ' when it\'s ready.</div><div id="export-processing-text" class="no-display">Your request is being processed. You will receive an email notification at ', soy.$$escapeHtml(opt_data.user_email), ' when the export is complete.</div><div id="export-button-container"><p class="button"><a id="confirm-report-export-button" href="javascript:void(0);">Export</a></p><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};
