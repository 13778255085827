// This file was automatically generated from manager.actual.partialedit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Actual == 'undefined') { Nightloop.Templates.Manager.Actual = {}; }


Nightloop.Templates.Manager.Actual.PartialEditPopup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Main.Popup.PopupLayout(soy.$$augmentData(opt_data, {div_id: 'manager-edit-partial-res-popup', header_html: 'Edit Reservation', show_close_link: 'false', DynamicTemplate: 'Nightloop.Templates.Manager.Actual.PartialEditPopupBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Actual.PartialEditPopupBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<form id="edit-partial-res-form" method="POST" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/actual/', soy.$$escapeHtml(opt_data.content.actual.id), '/partial-edit"><div id="actual-details-display" class="body-block"><div class="block-title">', soy.$$escapeHtml(opt_data.content.actual.client_display_name), '</div><div class="editable-area"><div><div class="float-left w35 right indent-right">Reservation No.</div><div class="float-left w60 number">', soy.$$escapeHtml(opt_data.content.actual.reference_code), '</div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Date:</div><div class="float-left w60">', soy.$$escapeHtml(opt_data.content.actual.date_formatted_long_display), '</div><div class="float-end"></div></div>');
  if (opt_data.content.can_pick_seating) {
    if (opt_data.content.actual.system_class == 'BAR') {
      output.append('<div><div class="float-left w35 right indent-right">Table No.:</div><div class="float-left w60">Bar</div><div class="float-end"></div></div>');
    } else {
      output.append('<div><div class="float-left w35 right indent-right">Seating Area:</div><div class="float-left w60"><select id="select-seating-area" name="venue_seating_area_id">');
      if (opt_data.content.actual.is_reservation) {
        var seating_areaList1549 = opt_data.content.venue_seating_areas;
        var seating_areaListLen1549 = seating_areaList1549.length;
        for (var seating_areaIndex1549 = 0; seating_areaIndex1549 < seating_areaListLen1549; seating_areaIndex1549++) {
          var seating_areaData1549 = seating_areaList1549[seating_areaIndex1549];
          output.append((opt_data.content.actual.venue_seating_area_id == seating_areaData1549.id) ? '<option value="' + soy.$$escapeHtml(seating_areaData1549.id) + '" selected="selected" >' + soy.$$escapeHtml(seating_areaData1549.code) + '</option>' : '');
        }
      } else {
        output.append('<option value=""></option>');
        var seating_areaList1560 = opt_data.content.venue_seating_areas;
        var seating_areaListLen1560 = seating_areaList1560.length;
        for (var seating_areaIndex1560 = 0; seating_areaIndex1560 < seating_areaListLen1560; seating_areaIndex1560++) {
          var seating_areaData1560 = seating_areaList1560[seating_areaIndex1560];
          output.append('<option value="', soy.$$escapeHtml(seating_areaData1560.id), '"', (opt_data.content.actual.venue_seating_area_id == seating_areaData1560.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(seating_areaData1560.code), '</option>');
        }
      }
      output.append('</select></div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Table No.:</div><div class="float-left w60"><select id="select-seating-table" name="table_ids"><option value=""></option>');
      var tableList1572 = opt_data.content.all_table_inventory_items;
      var tableListLen1572 = tableList1572.length;
      for (var tableIndex1572 = 0; tableIndex1572 < tableListLen1572; tableIndex1572++) {
        var tableData1572 = tableList1572[tableIndex1572];
        output.append('<option value="', soy.$$escapeHtml(tableData1572.id), '"', (opt_data.content.actual.table_id == tableData1572.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(tableData1572.item_code), ' (', soy.$$escapeHtml(tableData1572.party_size_range_display), ')</option>');
      }
      output.append('</select><select id="sac_to_tables_all" class="no-display"><option value=""></option>');
      var tableList1586 = opt_data.content.all_table_inventory_items;
      var tableListLen1586 = tableList1586.length;
      for (var tableIndex1586 = 0; tableIndex1586 < tableListLen1586; tableIndex1586++) {
        var tableData1586 = tableList1586[tableIndex1586];
        output.append('<option value="', soy.$$escapeHtml(tableData1586.id), '">', soy.$$escapeHtml(tableData1586.item_code), ' (', soy.$$escapeHtml(tableData1586.party_size_range_display), ')</option>');
      }
      output.append('</select>');
      var sac_tList1596 = opt_data.content.seatingareacodes_to_tables;
      var sac_tListLen1596 = sac_tList1596.length;
      for (var sac_tIndex1596 = 0; sac_tIndex1596 < sac_tListLen1596; sac_tIndex1596++) {
        var sac_tData1596 = sac_tList1596[sac_tIndex1596];
        if (sac_tData1596.tables.length > 0) {
          output.append('<select id="sac_to_tables_', soy.$$escapeHtml(sac_tData1596.id), '" class="no-display"><option value=""></option>');
          var tableList1602 = sac_tData1596.tables;
          var tableListLen1602 = tableList1602.length;
          for (var tableIndex1602 = 0; tableIndex1602 < tableListLen1602; tableIndex1602++) {
            var tableData1602 = tableList1602[tableIndex1602];
            output.append('<option value="', soy.$$escapeHtml(tableData1602.id), '">', soy.$$escapeHtml(tableData1602.item_code), ' (', soy.$$escapeHtml(tableData1602.party_size_range_display), ')</option>');
          }
          output.append('</select>');
        }
      }
      output.append('</div><div class="float-end"></div></div>');
    }
  }
  output.append('<div><div class="float-right supersubtext"> characters remaining: <span id=\'venue-notes-remaining\' class=\'number\' ></span></div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Notes:</div><div class="float-left w60"><textarea id="venue-notes-textarea" name="venue_notes" maxlength="150">', (opt_data.content.actual.notes) ? soy.$$escapeHtml(opt_data.content.actual.notes) : '', '</textarea></div><div class="float-end"></div></div></div></div><div class="save-links"><div class="float-right">');
  Nightloop.Templates.Widget.GoldButton({text: 'SAVE', size: 'medium', id: 'edit-partial-res-submit-btn'}, output);
  output.append('</div><div class="float-right"><a href="javascript:void(0);" id="edit-partial-res-cancel-btn">cancel</a></div></div></form><script type="text/javascript">$(document).ready( function() {var tableids_to_seatingareaids = {};');
  if (opt_data.content.actual.system_class == 'TABLE') {
    var sac_to_tList1626 = opt_data.content.seatingareacodes_to_tables;
    var sac_to_tListLen1626 = sac_to_tList1626.length;
    for (var sac_to_tIndex1626 = 0; sac_to_tIndex1626 < sac_to_tListLen1626; sac_to_tIndex1626++) {
      var sac_to_tData1626 = sac_to_tList1626[sac_to_tIndex1626];
      var tableList1627 = sac_to_tData1626.tables;
      var tableListLen1627 = tableList1627.length;
      for (var tableIndex1627 = 0; tableIndex1627 < tableListLen1627; tableIndex1627++) {
        var tableData1627 = tableList1627[tableIndex1627];
        output.append('tableids_to_seatingareaids[\'', soy.$$escapeHtml(tableData1627.id), '\'] = \'', soy.$$escapeHtml(sac_to_tData1626.id), '\';');
      }
    }
  }
  output.append('Pmp.Manager.Actual.PartialEdit.initialize( "', soy.$$escapeHtml(opt_data.venue.manager_base_url), '", "', soy.$$escapeHtml(opt_data.content.actual.id), '", tableids_to_seatingareaids);});<\/script>');
  return opt_sb ? '' : output.toString();
};
