// This file was automatically generated from manager.manage.widgetsettings.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Marketing == 'undefined') { Nightloop.Templates.Manager.Marketing = {}; }


Nightloop.Templates.Manager.Marketing.WidgetSettings = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Marketing.WidgetSettingsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Marketing.WidgetSettingsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(opt_data, output);
  output.append('<!-- ', soy.$$escapeHtml(opt_data.venue), ' --><div id="page-widget-settings" class="page-view-mode has-sidebar"><div id="widget-settings"></div><script type="text/javascript">var widgetSettingsInit = {venueId: "', soy.$$escapeHtml(opt_data.venue.id), '", MEDIA_URL: "', soy.$$escapeHtml(opt_data.MEDIA_URL), '"}; var MEDIA_URL = widgetSettingsInit.MEDIA_URL; var SEVENROOMS_VENUE_ID = "', soy.$$escapeHtml(opt_data.venue.url_key), '"; var HIDE_CALENDAR = false;<\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/manager/widgetsettings.js']), '" crossorigin="anonymous"><\/script><link rel="stylesheet" type="text/css" href="/css/logo.css"></div>');
  return opt_sb ? '' : output.toString();
};
