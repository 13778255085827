var Pmp = Pmp || {}
Pmp.Manager = Pmp.Manager || {}
Pmp.Manager.Reports = Pmp.Manager.Reports || {}

Pmp.Manager.Reports.Base = {
  initialize: function () {
    this._initCalendars()
    this.CHART_COLORS_BLUE = ['#347baf', '#ACC1F4']
    this.CHART_TEXT_COLOR = '#999'
    this.CHART_TITLE_STYLE = { fontSize: 12, italic: false, color: '#999' }
  },

  log: function (msg) {
    console.log('Pmp.Manager.Reports.Base: ' + msg)
  },

  debug: function (msg) {
    if (Pmp.Settings.DEBUG) {
      this.log(msg)
    }
  },

  initFakeExport: function () {
    $('#export-btn').on('click', function () {
      $('#export-prompt-text').addClass('no-display')
      $('#export-button-container').addClass('no-display')
      $('#export-processing-text').removeClass('no-display')
      $.colorbox.resize()
    })
  },

  _initCalendars: function () {
    this.debug('_initCalendars')
    var locale = Pmp.Manager.Global._locale
    var dateFmt = Pmp.Utils.dateFormatWithYear(locale)
    Pmp.Utils.LocalizeDatePicker(locale, '#from-calendar', '#from-calendar-submit')
    $('#from-calendar').datepicker('option', 'dateFormat', dateFmt)
    Pmp.Utils.LocalizeDatePicker(locale, '#to-calendar', '#to-calendar-submit')
    $('#to-calendar').datepicker('option', 'dateFormat', dateFmt)
    $('#ui-datepicker-div').addClass('calendar customize') /* Theming */
  },

  validateCalendars: function () {
    $('#from-calendar.error-outline').removeClass('error-outline')
    $('#to-calendar.error-outline').removeClass('error-outline')

    var isValid = true
    if ($('#from-calendar').datepicker('getDate') === null) {
      isValid = false
      $('#from-calendar').addClass('error-outline')
    }
    if ($('#to-calendar').datepicker('getDate') === null) {
      isValid = false
      $('#to-calendar').addClass('error-outline')
    }
    return isValid
  },

  showLoadingBar: function () {
    $('#manager-ajax').show()
  },

  getFromDateUrl: function () {
    var from_date = $('#from-calendar').datepicker('getDate')
    return $.datepicker.formatDate('mm-dd-yy', from_date)
  },

  getToDateUrl: function () {
    var from_date = $('#to-calendar').datepicker('getDate')
    return $.datepicker.formatDate('mm-dd-yy', from_date)
  },

  getShiftCode: function () {
    var dom = $('#shift-code-select')
    if (!dom.length) {
      return ''
    }
    return $('#shift-code-select').val()
  },
}
