var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Home = Pmp.Manager.Home || {};
Pmp.Manager.Home.GuestList = Pmp.Manager.Home.GuestList || {};

Pmp.Manager.Home.GuestList.Import = {
	initialize : function(manager_base_url, booked_by_key, can_edit_only_my_own) {
		this._base = Pmp.Main.Popup.Base;
		this._manager_base_url = manager_base_url;
		this._booked_by_key = booked_by_key;
		this._can_edit_only_my_own = can_edit_only_my_own;
		this.list_cache_key = 'import:guestlist';
		this._bindEventHandlers();
	},

	log : function(msg) {
		console.log("Pmp.Manager.Home.GuestList.Import: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_loadCachedList : function() {
		var cache = sessionStorage[this.list_cache_key];
		if (cache !== undefined && cache !== "" && cache !== "null") {
			var list = $('#guestlist-batch');
			list.val(cache);
		}
	},

	_loadPlaceholder : function() {
			// Inserts multiline placeholder in import textarea
			$('p.label.pre').hide();
			var placeholder = $.map([true], function(n){
				return 'First & Last Name, +(Add\'l Guests), Email, Notes';
			}).join('\n');
			$('#guestlist-batch').attr('placeholder', placeholder);
			$('#guestlist-batch', $(this._scope)).placeholderLabel();
	},

	_bindEventHandlers : function() {
		this.debug("bindEventHandlers");

		var self = this;
		var clickSubmitFn = function(event) {self._clickSubmit(event, $(this));};
		var clickConfirmFn = function(event) {self._clickConfirm(event, $(this));};
		var clickMakeChangesFn = function(event) {self._clickMakeChanges(event, $(this));};

		$('#guestlist-import-preview-btn').click(clickSubmitFn);
		$('#guestlist-import-confirm-btn').click(clickConfirmFn);
		$('#guestlist-import-makechanges-btn').click(clickMakeChangesFn);

		self._loadPlaceholder();
		self._loadCachedList();
		self._loadBookedBy('#booked-by-select');
	},

	_loadBookedBy : function(select_id) {
		var url = this._manager_base_url + '/data/bookedbynames';
		var self = this;
		Pmp.Client.AsyncGet(url, function(data) {

			var booked_by_options = [];
			var booked_by_users = data.payload.content.booked_by_users;
			if (self._can_edit_only_my_own) {
				for (var i = 0; i < booked_by_users.length; i++) {
					if (booked_by_users[i]['id'] == self._booked_by_key) {
						booked_by_options.push(booked_by_users[i]);
					}
				}
			} else {
				booked_by_options = booked_by_users;
			}

			var options_html = Nightloop.Templates.Manager.Reservation.BookedByUsers({"content" : {'booked_by_users':booked_by_options},"booked_by_key": self._booked_by_key});
			$(select_id).append(options_html);
			$('#booked-by-spinner').addClass('no-display');
			$(select_id).removeClass('no-display');
		});
	},

	_clickSubmit : function(event, element) {
		console.log(element);
		$('#guestlist-import-confirm-btn').sext('Saving...');
		$('#manager-ajax').show();
	  if (!$(element).hasClass('disabled')) {
      $(element).addClass('disabled pending');
      var formObj = $('#import-daily-guestlist-form');
      var list = $('#guestlist-batch');
      if(list.val() !== "") {
        sessionStorage[this.list_cache_key] = list.val();
      }
      formObj.submit();
      //TODO: why'd this break now?
      //Pmp.Utils.LoadForm(event, formObj, '#main-popup', this._base.waitForLoadAndResize, true /* break ajax */);
    }
	},

	_clickConfirm : function(event, element) {
		$('#guestlist-import-confirm-btn').sext('Saving...');
		$('#manager-ajax').show();
	  if (!$(element).hasClass('disabled')) {
      $(element).addClass('disabled pending');
      var formObj = $('#import-daily-guestlist-form');
      var list = $('#guestlist-batch');
			sessionStorage.removeItem(this.list_cache_key);
      Pmp.Utils.LoadForm(event, formObj, '#main-popup', this._base.waitForLoadAndResize, true /* break ajax */);
    }
	},

	_clickMakeChanges : function(event, element) {
		$('#guestlist-import-confirm-input').val('false');
		$('#guestlist-import-confirm-display').addClass('no-display');
		$('#guestlist-import-preview-display').removeClass('no-display');
		$('#guestlist-import-confirm-buttons').addClass('no-display');
		$('#guestlist-import-preview-buttons').removeClass('no-display');
		$('#guestlist-import-header-preview-label').addClass('no-display');
		$.colorbox.resize();
	}
};
