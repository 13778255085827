var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Reports = Pmp.Manager.Reports || {};

Pmp.Manager.Reports.Turndown = {
	initialize : function(manager_base_url, currency_symbol) {
		this._base = Pmp.Manager.Reports.Base;
		this._base.initialize();

		this._manager_base_url = manager_base_url;
		this._currency_symbol = currency_symbol;

		this._dataSource = $.parseJSON($('#data-source').html());

		this._bindEventHandlers();
		this._initCharts();
	},

	log : function(msg) {
		console.log("Pmp.Manager.Reports.Turndown: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var clickGoBtnFn = function(event) {self._clickGoBtn(event);};
		$('#summary-report-submit-btn').click(clickGoBtnFn);
	},

	_initCharts : function() {
		var self = this;
		/* Callback that creates and populates a data table,
		 * instantiates the pie chart, passes in the data and
		 * draws it.
		 **/
		Pmp.Utils.CreateChart(function(){self._drawCharts();});
	},

	_drawCharts : function() {
		var self = this;
		this._drawDonut('turndowns-by-type-occurrences', function(data) {
			data.addColumn('string', 'Source');
			data.addColumn('number', 'Occurrences');
			var rows = [];
			var sources = self._dataSource['sources'];
			for (var type in sources) {
				rows.push([type, sources[type]['occurrences']]);
			}
			data.addRows(rows);
		});
		this._drawDonut('turndowns-by-type-covers', function(data) {
			data.addColumn('string', 'Source');
			data.addColumn('number', 'Covers');
			var rows = [];
			var sources = self._dataSource['sources'];
			for (var type in sources) {
				rows.push([type, sources[type]['covers']]);
			}
			data.addRows(rows);
		});

		this._drawColumnChart('turndowns-by-shift-occurrences', 'Shifts', 'Occurrences', function(data) {
			data.addColumn('string', 'Shift');
			data.addColumn('number', 'Occurrences');
			var rows = [];
			var ordering = self._dataSource['ordering_shifts'];
			var sources = self._dataSource['shifts'];
			for (var i =0; i < ordering.length; i++) {
			    var name = ordering[i][0];
			    var type = ordering[i][1];
			    if (!(type in sources)) {
			        continue;
			    }
				rows.push([name, sources[type]['occurrences']]);
			}
			data.addRows(rows);
		});
		this._drawColumnChart('turndowns-by-shift-covers', 'Shifts', 'Covers', function(data) {
			data.addColumn('string', 'Shift');
			data.addColumn('number', 'Covers');
			var rows = [];
			var ordering = self._dataSource['ordering_shifts'];
			var sources = self._dataSource['shifts'];
			for (var i =0; i < ordering.length; i++) {
			    var name = ordering[i][0];
			    var type = ordering[i][1];
			    if (!(type in sources)) {
			        continue;
			    }
				rows.push([name, sources[type]['covers']]);
			}
			data.addRows(rows);
		});

		this._drawBarChart('turndowns-by-partysize-occurrences', 'Party Sizes', 'Occurrences', function(data) {
			data.addColumn('string', 'Party Sizes');
			data.addColumn('number', 'Occurrences');
			var rows = [];
			var ordering = self._dataSource['ordering_ps'];
			var sources = self._dataSource['party_sizes'];
			for (var i =0; i < ordering.length; i++) {
				var type = ordering[i];
				var val = (type in sources) ? sources[type]['occurrences'] : 0;
				rows.push([''+type, val]);
			}
			data.addRows(rows);
		});

		this._drawBarChart('turndowns-by-partysize-covers', 'Party Sizes', 'Covers', function(data) {
			data.addColumn('string', 'Party Sizes');
			data.addColumn('number', 'Covers');
			var rows = [];
			var ordering = self._dataSource['ordering_ps'];
			var sources = self._dataSource['party_sizes'];
			for (var i =0; i < ordering.length; i++) {
				var type = ordering[i];
				var val = (type in sources) ? sources[type]['covers'] : 0;
				rows.push([''+type, val]);
			}
			data.addRows(rows);
		});


		this._drawColumnChart('turndowns-by-dow-occurrences', 'Day of Week', 'Occurrences', function(data) {
			data.addColumn('string', 'Day of Week');
			data.addColumn('number', 'Occurrences');
			var rows = [];
			var ordering = self._dataSource['ordering_dow'];
			var sources = self._dataSource['dow'];
			for (var i =0; i < ordering.length; i++) {
				var type = ordering[i];
				var val = (type in sources) ? sources[type]['occurrences'] : 0;
				rows.push([type, val]);
			}
			data.addRows(rows);
		});
		this._drawColumnChart('turndowns-by-dow-covers', 'Day of Week', 'Covers', function(data) {
			data.addColumn('string', 'Day of Week');
			data.addColumn('number', 'Covers');
			var rows = [];
			var ordering = self._dataSource['ordering_dow'];
			var sources = self._dataSource['dow'];
			for (var i =0; i < ordering.length; i++) {
				var type = ordering[i];
				var val = (type in sources) ? sources[type]['covers'] : 0;
				rows.push([type, val]);
			}
			data.addRows(rows);
		});


		this._drawBarChart('turndowns-by-time-occurrences', 'Time Slot', 'Occurrences', function(data) {
			data.addColumn('string', 'Time Slot');
			data.addColumn('number', 'Occurrences');
			var rows = [];
			var ordering = self._dataSource['ordering_time'];
			var sources = self._dataSource['time'];
			for (var i =0; i < ordering.length; i++) {
				var type = ordering[i];
				var val = (type in sources) ? sources[type]['occurrences'] : 0;
				rows.push([type, val]);
			}
			data.addRows(rows);
		}, 700);

		this._drawBarChart('turndowns-by-time-covers', 'Time Slot', 'Covers', function(data) {
			data.addColumn('string', 'Time Slot');
			data.addColumn('number', 'Covers');
			var rows = [];
			var ordering = self._dataSource['ordering_time'];
			var sources = self._dataSource['time'];
			for (var i =0; i < ordering.length; i++) {
				var type = ordering[i];
				var val = (type in sources) ? sources[type]['covers'] : 0;
				rows.push([type, val]);
			}
			data.addRows(rows);
		}, 700);

	},

	_drawDonut : function(el_id, populateFn) {
		var data = new google.visualization.DataTable();
		populateFn(data);
		var options = {
			width:375,
		  height:268,
			pieHole: 0.4,
			colors: this._base.CHART_COLORS_BLUE,
			pieSliceText: 'value'
		};
		var chart = new google.visualization.PieChart(document.getElementById(el_id));
		chart.draw(data, options);
	},

	_drawColumnChart : function(el_id, hTitle, vTitle, populateFn) {
		var data = new google.visualization.DataTable();
		populateFn(data);
		var options = {
			width:445,
		  height:268,
			hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
				title: hTitle,
        titleTextStyle: this._base.CHART_TITLE_STYLE
			},
			vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
				title: '',
				format:'#,###',
        titleTextStyle: this._base.CHART_TITLE_STYLE
			},
			colors: this._base.CHART_COLORS_BLUE,
			legend: 'none'
		};
		var chart = new google.visualization.ColumnChart(document.getElementById(el_id));
		chart.draw(data, options);
	},

	_drawBarChart : function(el_id,vTitle, hTitle, populateFn, heightOverride) {
		var data = new google.visualization.DataTable();
		populateFn(data);
		var options = {
			width:445,
		  height:(heightOverride) ? heightOverride : 268,
			hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
				title: hTitle,
				format:'#,###',
        titleTextStyle: this._base.CHART_TITLE_STYLE
			},
			vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
				title: '',
        titleTextStyle: this._base.CHART_TITLE_STYLE
			},
			colors: [this._base.CHART_COLORS_BLUE[1]],
			legend: 'none'
		};
		var chart = new google.visualization.BarChart(document.getElementById(el_id));
		chart.draw(data, options);
	},

	_clickGoBtn : function(event) {
		if (this._base.validateCalendars()) {
      this._base.showLoadingBar();
			var from_date_url = this._base.getFromDateUrl();
			var to_date_url = this._base.getToDateUrl();
			var shiftCode = this._base.getShiftCode();
			var source = $('#source-select').val();
			var relativeUrl = this._manager_base_url + "/reports/turndown/"+ from_date_url +"/"+ to_date_url;
			relativeUrl += '?shift_code=' + shiftCode;
			relativeUrl += '&source=' + source;
      window.location = relativeUrl;
		}
	}
};
