var MembershipGroupList = {
  init: function(base_url)
  {
    this.base_url = base_url;
    this.bind();
  },

  bind: function()
  {
    var that = this;
    $('.col-action .closer').on('click', this.openConfirm);
    $('.col-action .cancel-confirm').on('click', this.closeConfirm);
    $('.col-action .delete-confirm').on('click', function(e) { that.confirmDelete(e, that.base_url); });
  },

  openConfirm: function(e)
  {
    $(e.target).closest('.col-action').find('.delete-group-confirm').show();
    return false;
  },

  closeConfirm: function(e)
  {
    $(e.target).closest('.delete-group-confirm').hide();
    return false;
  },

  confirmDelete: function(e, base_url)
  {
    $row = $(e.target).closest('.membership-group-row');
    MemberGroupService.remove(
      base_url,
      $row.attr('group_id'),
      function() {
        $row.remove();
        return false;
      }
    ); 
    return false;
  }

};


var MembershipGroupEdit = {

  init: function()
  {
	  console.log('init');
    this.bind();
  },

  bind: function()
  {
    Pmp.Utils.BindColorPicker('#colorpicker');
    Pmp.Utils.RefreshColors('#colorpicker');

    var that = this;

    $('#add-payment-plan').on('click', this.addPlan);
    $('#payment-plans').on('click', 'a.closer', function(e) { that.removePlan(e) });
  },

  addPlan: function()
  {
    var iter = $('#payment-plans .plan-row').length;    
    var plan = Nightloop.Templates.Manager.Manage.PaymentPlan({ });

    $('#append-plans').append($(plan));

    return false;
  },

  removePlan: function(e)
  {
    var $row = $(e.target).closest('.plan-row');
    $row.remove();
    this.reindex();
    return false;
  },

  reindex: function()
  {
    $('#append-plans .plan-row').each(function(index, row) {
      index++; // We skip the 0 row
      $row = $(row);
      $row.find('.plan-id').prop('name', 'plan_id_' + index);
      $row.find('label').each(function(_, item) {
        $item = $(item);
        var $input = $item.find('select,input');
        var basename = $input.prop('id');
        var parts = basename.split('_');
        parts.pop(0);
        parts.push(index);
        var name = parts.join('_');
        $input.prop('id', name);
        $input.prop('name', name);
        $item.prop('for', name);
      });
    });
  },
};
