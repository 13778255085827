GuestlistSlideOut = {
  init : function(send_confirmation_by_default) {
    this._send_confirmation_by_default = send_confirmation_by_default;
    this.guestaction = $('#baseurl').val() + '/home/guestlist/' + $('#actual-date-submit').val();
    // resend an email
    $('#resend-email').on('click', function() {
        var id = $('#id_modified').val();
        GuestlistSlideOut.sendEmailConfirmation(id);
      });
  },

  showGuestlist : function (id, row_modified, disable_edits) {
    this.clear();
    // This sucks, but just temporary to keep code in one place
    if (!id) {
      $('#main')
      $('#main-interface').addClass('new-reservation');
      $('#book-guest-form').prop('action', this.guestaction + '/create');
      $('#submit-guest-delete').hide();

      data = {
              'firstname': '',
              'lastname': '',
              'email' : '',
              'guests': '',
              'source': '',
              'note': '',
              'dodelete' : '',
              'modified' : ''
      }
      for(key in data) {
        $('#id_' + key).prop('name', key + '##').val('');
      }

      // tags
      $('.reservation-codes #find-tags-input span').html('');
      if (Pmp.Manager.ReservationTagsLookup) {
        Pmp.Manager.ReservationTagsLookup.renderTags();
      }

      if (this._send_confirmation_by_default) {
        if (!$('input[name=send_client_email##]').prop('checked')) {
          $('input[name=send_client_email##]').trigger('click');
        }
      }
      else {
        if ($('input[name=send_client_email##]').prop('checked')) {
          $('input[name=send_client_email##]').trigger('click');
        }
      }

      // manage contact info permissions
      var emailInput = $('#id_email');
      emailInput.prop('placeholder', '');
      emailInput.removeClass('disabled');

      $('#book-guest-form option').filter(function() {
        return $(this).hasClass('default');
      }).prop('selected', true);

      $('#guestlist-add').show();
      $('#guestlist-actions').hide();
      $('#conf_email').show();
    } else {
      $row = row_modified;

      $('#submit-guest-delete').show();
      var deleteText = $row.attr('billing_history_id') ? 'Refund and remove' : 'Remove';
      $('#submit-guest-delete').text(deleteText);
      $('#book-guest-form').prop('action', this.guestaction + '/' + id + '/edit');
      data = {
               'firstname': $row.attr('first_name'),
               'lastname': $row.attr('last_name'),
               'email' : $row.attr('email'),
               'guests': $row.find('.max-guests').sext(),
               'source': $row.attr('sort_via'),
               'note': $row.find('.col-note').eq(0).sext(),
               'dodelete': '1',
               'modified' : id
             }
      for(key in data) {
        $('#id_' + key).prop('name', key + '##' + id);
        if(key != 'source') {
          $('#id_' + key).val(data[key]);
        }
      }

      // tags
      var res_tags_actuals_dict = window.GuestlistSlideOutActualResTags || {}; // Set in soy template
      var res_tags_list = res_tags_actuals_dict[id];
      if (res_tags_list) {
        var reservation_tags = _.map(res_tags_list, function(x) {
          return _.extend({}, x, {'font_color': sr.formatter.getContrastYIQ(x['tag_color'])})
        });
        var html = Nightloop.Templates.Widget.GenericTagsByGroupDisplay({
          'tag_groups': reservation_tags,
          'can_view_private': true, // doesn't matter,
          'close_func': 'Pmp.Manager.Generic.Tags.onCloseTag(this)',
          'can_manage_restricted_tags': Pmp.Manager.Global._can_manage_restricted_tags,
        });
        $('.reservation-codes #find-tags-input span').html(html);
      } else {
        $('.reservation-codes #find-tags-input span').html('');
      }
      if (Pmp.Manager.ReservationTagsLookup) {
        Pmp.Manager.ReservationTagsLookup.renderTags();
      }

      // manage contact info permissions
      var should_hide = $row.attr('can_edit_contact_info') != '1';
      var emailInput = $('#id_email');

      if (data['email'].length) {
        if (should_hide) {
          emailInput.addClass('disabled');
          emailInput.prop('placeholder', data['email']);
          } else {
                   emailInput.prop('placeholder', '');
                   emailInput.removeClass('disabled');
                 }
          } else {
                   emailInput.prop('placeholder', '');
                   emailInput.removeClass('disabled');
                 }

      // more menu
      var showMoreMenuFn = function() {$('.more .container').addClass('show');};
      var hideMoreMenuFn = function() {$('.more .container').removeClass('show');};
      $('#guestlist-actions #more-button').on('click', showMoreMenuFn);
      $('#guestlist-actions .more').on('mouseleave', hideMoreMenuFn);
      // Blaming the fever for this one
      $('#book-guest-form option').filter(function() {
        return this.text == $row.attr('sort_via');
      }).prop('selected', true);
      var booked_by_key = $row.attr('booked_by_key');
      var $source_select = $('#id_source');
      if ($source_select.find('option[value="' + booked_by_key + '"]').length === 0) {
        // if the booked by name is not in the list, add it to the options (could have been deleted)
        var option = $('<option />').val(booked_by_key).sext($row.attr('sort_via'));
        $source_select.append(option);
      }
      $source_select.val(booked_by_key);

      $('#guestlist-add').hide();
      if (disable_edits) {
        $('#main-interface').addClass('view-reservation');
        $('#guestlist-actions').hide();
        $('#book-guest-form input, #book-guest-form textarea, #book-guest-form select').addClass('disabled');
      } else {
        $('#main-interface').addClass('edit-reservation');
        $('#guestlist-actions').show();

        if ($('input[name=send_client_email##]').prop('checked')) {
          $('input[name=send_client_email##]').trigger('click');
        }
      }
    }

    Interface.openslide('#main-interface');
  },


  submitNew : function () {

  },

  submitEdit : function () {

  },

  sendEmailConfirmation : function (actual_id) {
      $.ajax({
        url: actual_id + '/resend-email',
        method: 'post',
        data: {
          send_client_email: true
        }}).done(function(response) {
          Interface.alertSuccess('Succesfully resent confirmation email');
        }).error(function(response) {
          var errors = ['Something went wrong.'];
          try { // IE9 fix
            errors = $.parseJSON(response.responseText).errors;
          } catch(e) {};
          var msg = errors.join(' ');
          Interface._alert(msg);
        })
    },


  clear : function () {
    Interface.clear('#main-interface');
    $('#main-interface').removeClass('new-reservation edit-reservation view-reservation');
    $('#book-guest-form input, #book-guest-form textarea').removeClass('disabled');
    $('#find-tags-results').show();  // scrollTop doesn't work if element is hidden
    $('.tag-table-container').scrollTop(0);
    $('#find-tags-results').hide();
  },

  close : function () {

  }
};
