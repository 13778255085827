// This file was automatically generated from manager.manage.generictags.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.GenericTag = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.GenericTagBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.GenericTagBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: opt_data.identifier}), output);
  output.append('<div id="page-tags" class="has-sidebar"><div id="main-area"></div></div><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/manager.tagsmanager.js']), '"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/common.js']), '"><\/script><script type="text/javascript">$(document).ready( function() {modules.tagsmanager.init(\'', soy.$$escapeHtml(opt_data.identifier), '\', \'main-area\',', (opt_data.user_domain.is_superhero) ? 'true' : 'false', ', "', soy.$$escapeHtml(opt_data.MEDIA_URL), '", "', soy.$$escapeHtml(opt_data.venue.url_key_or_id), '", "', soy.$$escapeHtml(opt_data.venue.venue_group_id), '", "', soy.$$escapeHtml(opt_data.venue_settings.is_consumer_tags_enabled), '");});<\/script>');
  return opt_sb ? '' : output.toString();
};
