var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Manage = Pmp.Manager.Manage || {};
Pmp.Manager.Manage.Inventory = Pmp.Manager.Manage.Inventory || {};

Pmp.Manager.Manage.Inventory.TablesEdit = {
    initialize : function(seating_areas, ordering_sites, use_pos, is_on_premise_ordering_enabled, is_sevenrooms_ordering_enabled) {
        this._seating_areas = seating_areas;
        this._ordering_sites = ordering_sites;
        this._use_pos = use_pos;
        this._is_on_premise_ordering_enabled = is_on_premise_ordering_enabled;
        this._is_sevenrooms_ordering_enabled = is_sevenrooms_ordering_enabled;
        this.bindClickHandlers();
    },

    log : function(msg) {
        console.log("Pmp.Manager.Manage.Inventory.TablesEdit: "+ msg);
    },

    debug : function(msg) {
        if (Pmp.Settings.DEBUG) {
            this.log(msg);
        }
    },

    bindClickHandlers : function() {
        this.debug("Binding click handlers");
        var self = this;
        var addNewTableFn = function(event) {self.addNewTable(event);} ;
        var clickSubmitBtnFn = function(event) {self._clickSubmitBtn(event); return false;};
        $('#add-new-button').click(addNewTableFn);
        $('#tables-submit-btn').click(clickSubmitBtnFn);
    },

    fixEvenOddRows : function() {
        // Fix even/odd display
        $('.table-row').each(function(i, element) {
        	$tableRow = $(element);
            if (i % 2 == 0) {
                $tableRow.removeClass('even');
                $tableRow.addClass('odd');
            } else {
                $tableRow.removeClass('odd');
                $tableRow.addClass('even');
            }
        });
    },

    determineNextID : function(currentID) {
        var parsedID = currentID.match(/\d+/g);
        var lastIdx = parsedID.length - 1;
        var lastNumIdx = currentID.lastIndexOf(parsedID[lastIdx]);
        // We only want to increment last digits if table number was `3A23`
        var lastNumAsInt = parseInt(parsedID[lastIdx]);
        if (lastNumAsInt.toString().length < parsedID[lastIdx].length) {
            var numLeadingZeros = parsedID[lastIdx].length - lastNumAsInt.toString().length;
            var leadingZeros = "";
            while(leadingZeros.length < numLeadingZeros) leadingZeros += "0";
            parsedID[lastIdx] = leadingZeros + (lastNumAsInt + 1).toString();
        }
        else
            parsedID[lastIdx] = lastNumAsInt + 1;
        var finalID = "";
        for(i = 0; i < parsedID.length; i++) {
            var idx = (i == lastIdx) ? lastNumIdx : currentID.indexOf(parsedID[i]);
            finalID += currentID.substring(finalID.length, idx) + parsedID[i].toString();
        }
        return finalID + currentID.substring(finalID.length);
    },

    addNewTable : function (event) {
        // Hide msg
        $('#no-tables-msg').hide();

        // enable table heading
        $('#table-service-header').show()

        var is_even = $('#table-box').find('.table-row:last').hasClass('odd');

        // Update the #tables display
        var numRows = $('#table-box').find('.table-row').length + 1;
        $('.num-tables-display').sext(numRows + '');

        // calculate the next index
        var nextID = numRows;
        $('.item_code').each(function(index){
            var value = $(this).attr('value')
            if (!isNaN(value))
            {
                var num = parseInt(value, 10);
                if (nextID <= num)
                {
                    nextID = num + 1;
                }
            }
        });
        if (nextID < 10) {
            nextID = '00' + nextID;
        } else if (nextID < 100) {
            nextID = '0' + nextID;
        }

        var html = Nightloop.Templates.Manager.Manage.Inventory.TableEditRow({
            is_even: is_even,
            seating_areas: this._seating_areas,
            ordering_sites: this._ordering_sites,
            table_item: {
                party_size_min : '2',
                party_size_max : '10',
                item_code : nextID
            },
            use_pos: this._use_pos,
            is_on_premise_ordering_enabled: this._is_on_premise_ordering_enabled,
            is_sevenrooms_ordering_enabled: this._is_sevenrooms_ordering_enabled
        });
        $(html).insertBefore($('#add-new-tables-here'));
    },

    deleteTable : function (anchorObj) {
        // remove row
        $(anchorObj).parents('.table-row').remove();

        // Update the #tables display
        var numRows = $('#table-box').find('.table-row').length;
        $('.num-tables-display').sext(numRows + '');

        if (numRows == 0) {
            // Show message
            $('#no-tables-msg').show();
            // Hide header
            $('#table-service-header').hide();
        }
        this.fixEvenOddRows();
    },
    
    cloneTable : function (anchorObj) {
        // Update the #tables display
        var numRows = $('#table-box').find('.table-row').length + 1;
        $('.num-tables-display').sext(numRows + '');

        // calculate the next index
        var selectedRowElement = $(anchorObj).parents('.table-row');
        var selectedRow = $(selectedRowElement);
        var selectedRowID = selectedRow.find('#id_item_code').val();
        var rowPartyMin = selectedRow.find('#id_party_size_min').val();
        var rowPartyMax = selectedRow.find('#id_party_size_max').val();
        var seatingArea = selectedRow.find('select[name=seating_area_id]').val();
        var nextID = this.determineNextID(selectedRowID);
        var html = Nightloop.Templates.Manager.Manage.Inventory.TableEditRow({
            is_even: false, // Irrelevant b/c function below will refresh even/odd display
            seating_areas: this._seating_areas,
            ordering_sites: this._ordering_sites,
            table_item: {
                party_size_min : rowPartyMin,
                party_size_max : rowPartyMax,
                item_code : nextID,
                seating_area : {'id' : seatingArea }
            },
            use_pos: this._use_pos,
            is_on_premise_ordering_enabled: this._is_on_premise_ordering_enabled,
            is_sevenrooms_ordering_enabled: this._is_sevenrooms_ordering_enabled
        });
        $(html).insertAfter(selectedRowElement);
        this.fixEvenOddRows();
    },
    
    _clickSubmitBtn : function(event) {
        $('#manager-ajax').show();
        $('#tables-submit-btn').sext('Saving...');
        $('#tables-submit-form').submit();
    }
};
