// This file was automatically generated from manager.reports.utilization.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Reports == 'undefined') { Nightloop.Templates.Manager.Reports = {}; }


Nightloop.Templates.Manager.Reports.Utilization = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Reports.UtilizationBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.UtilizationBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Reports.ReportSidebar(soy.$$augmentData(opt_data, {selected: 'Utilization'}), output);
  output.append('<div id="page-turndownreport" class="page-report has-sidebar"><div id="main-area">');
  Nightloop.Templates.Manager.Reports.PrintHeader(soy.$$augmentData(opt_data, {name: 'Utilization'}), output);
  output.append('<div id="content-header" class="do-not-print">');
  Nightloop.Templates.Manager.Reports.ReportNav(soy.$$augmentData(opt_data, {enable_shifts: 'true'}), output);
  output.append('</div>', (opt_data.content.error_msg) ? '<p class="block-error">' + soy.$$escapeHtml(opt_data.content.error_msg) + '</p>' : '', '<div class="float-end"></div><div class="report-content"><div class="row"><div class="col"><p class="title">Utilization</p><p class="stat">', soy.$$escapeHtml(opt_data.content.data.totals.util), '%</p></div><div class="col last"><p class="title">Covers/Capacity</p><p class="stat">', soy.$$escapeHtml(opt_data.content.data.totals.covers), '/', soy.$$escapeHtml(opt_data.content.data.totals.capacity), '</p></div><div class="float-end"></div></div><div class="row"><div class="col"><p class="title">Utilization by Shift</p><div id="utilization-by-shift" class="chart"></div></div><div class="col last"><p class="title">Covers by Shift</p><div id="covers-by-shift" class="chart"></div></div><div class="float-end"></div></div><div class="row"><div class="col"><p class="title">Utilization by Day of Week</p><div id="utilization-by-dow" class="chart"></div></div><div class="col last"><p class="title">Covers by Day of Week</p><div id="covers-by-dow" class="chart"></div></div><div class="float-end"></div></div><div class="row last"></div></div></div></div><div id="data-source" class="no-display">', soy.$$escapeHtml(opt_data.content.dump_data), '</div></div><script type="text/javascript">$(document).ready(function() {Pmp.Manager.Reports.Utilization.initialize( "', soy.$$escapeHtml(opt_data.venue.manager_base_url), '", "', soy.$$escapeHtml(opt_data.venue.currency_symbol), '" );});<\/script>');
  return opt_sb ? '' : output.toString();
};
