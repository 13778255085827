var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Manage = Pmp.Manager.Manage || {};

Pmp.Manager.Manage.BlackoutDates = {
	initialize : function(locale, initCount) {
		this._locale = locale;
		this._newCount = initCount;
		this.bindClickHandlers();
	},

	log : function(msg) {
		console.log("Pmp.Manager.Manage.BlackoutDates: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	bindClickHandlers : function() {
		this.debug("bindClickHandlers");
		this._refreshDatePickers();

		var self = this;
		$('#submit-blackout-dates').click(function() {
	    if (self.validate()) {
              $('#manager-ajax').show();
              $('#submit-blackout-dates').sext('Saving...');
	        $('#blackout-dates-form').submit();
        }
	    return false;
    });

    var $message = $('.message');
    if ($message.is(':visible')) {
      $message.delay(5000).fadeOut(2000);
    }

    self.enterEditMode();

    $('#add-new-blackout-date').click(function() {
    	self.addNewBlackoutDate();
    });

	},

	enterEditMode : function() {
		$('#edit-blackout-dates-btn').hide();
		$('#add-date').show();
    $('#submit-area').show();
    $('.delete-blackout-link').show();
	},

	addNewBlackoutDate : function() {
    var html = Nightloop.Templates.Manager.Manage.BlackoutDatesEditRow({count:this._newCount});
    $('.bd-list').append(html)
    this._refreshDatePickers();
    $('#no-dates-listed').hide();
    this._newCount += 1;
  },

  onChangeDateInput : function(inputObj) {
    var jObj = $(inputObj);
    var dateObj = jObj.datepicker("getDate");
    var dateText = $.datepicker.formatDate('mm/dd/yy',dateObj)
    var existingObj = null;
    var exists = false;

    if (!dateText) {
      return;
    }

    $('.date-formatted').each(function() {
        if (this == inputObj) { return; }
        var value = $(this).val();
        value = Date.parse(value);
        var existingValue = Date.parse(dateText);
        if (existingValue.equals(value)) {
            existingObj = $(this);
            exists = true;
        }
    });

    if (exists) {
        jObj.val('');
        this._showError("You've already added " + dateText + ".", existingObj.parent().find('.date-header'));
        return false;
    }

    $(inputObj).next().val(dateText); // for internationalization

    var formattedDateText = (new Date(dateText)).toString('dddd');
    jObj.parent().next().children('span').sext(formattedDateText);
  },


  _showError : function(text, obj) {
    obj.addClass('invalid');
    $('#error').sext(text);
    $('#error').show().delay(1000).fadeOut(1000, function(){ obj.removeClass('invalid')});
  },

  removeBlackoutDate : function(anchorObj) {
    $(anchorObj).parents('.row').remove();
    var num_items = $('.row.data').length;
    if (num_items == 0) {
        $('#no-dates-listed').show();
    }
  },

  validate : function() {
    var valid = true;
    var self = this;
    $('.date-input').each(function() {
        var elem = $(this);
        if (!self._validateDate(elem.next().val())) {
            elem.addClass('invalid');
            valid = false;
            self._showError('Error: Please input date to save the blackout date', elem)
        } else {
            elem.removeClass('invalid');
        }
    });
    $('.desc-input').each(function() {
        var elem = $(this);
        if (!self._validateDescription(elem.val())) {
            elem.addClass('invalid');
            valid = false;
            self._showError('Error: Please input description to save the blackout date', elem)
        } else {
            elem.removeClass('invalid');
        }
    });
    return valid;
  },

  _refreshDatePickers : function () {
  	var self = this;
  	$('.datepicker').each(function() {
  		Pmp.Utils.LocalizeDatePicker(self._locale, '#'+$(this).attr('id'), '#'+$(this).next().attr('id'));
  		$('#'+$(this).attr('id')).datepicker('option', 'minDate', 0);
  	});
  	$("#ui-datepicker-div").addClass('customize');
  },

  _validateDate : function(text) {
    var parsedDate = Date.parse(text);
    if (isNaN(parsedDate) || parsedDate == null) {
      return false;
    }
    return true;
  },

	_validateDescription : function(text) {
    if (text.length == 0) {
      return false;
    }
    return true;
  }
};
