var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Reports = Pmp.Manager.Reports || {};

Pmp.Manager.Reports.Utilization = {
	initialize : function(manager_base_url, currency_symbol) {
		this._base = Pmp.Manager.Reports.Base;
		this._base.initialize();

		this._manager_base_url = manager_base_url;
		this._currency_symbol = currency_symbol;

    this._dataSource = $.parseJSON($('#data-source').html());

		this._bindEventHandlers();
		this._initCharts();
	},

	log : function(msg) {
		console.log("Pmp.Manager.Reports.Turndown: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var clickGoBtnFn = function(event) {self._clickGoBtn(event);};
		$('#summary-report-submit-btn').click(clickGoBtnFn);
	},

	_initCharts : function() {
		var self = this;
    console.log(self._dataSource);
		/* Callback that creates and populates a data table,
		 * instantiates the pie chart, passes in the data and
		 * draws it.
		 **/
		Pmp.Utils.CreateChart(function(){self._drawCharts();});
	},

	_drawCharts : function() {
    var self = this;

    this._drawColumnChart('covers-by-shift', 'Shifts', 'Covers', function(data) {
      data.addColumn('string', 'Shift');
      data.addColumn('number', 'Covers');
      var rows = [];
      var sources = self._dataSource['shifts'];
      for (var type in sources) {
        var covers = sources[type]['totals']['covers'];
        rows.push([type, covers]);
      }
      data.addRows(rows);
    });

    this._drawColumnChart('utilization-by-shift', 'Shifts', 'Utilization', function(data) {
      data.addColumn('string', 'Shift');
      data.addColumn('number', 'Utilization');
      var rows = [];
      var sources = self._dataSource['shifts'];
      for (var type in sources) {
        var util = sources[type]['totals']['util'];
        rows.push([type, util]);
      }
      data.addRows(rows);
    });


    this._drawColumnChart('utilization-by-dow', 'Day of Week', 'Utilization', function(data) {
      data.addColumn('string', 'Day of Week');
      data.addColumn('number', 'Utilization');
      var rows = [];

      var sources = self._dataSource['days']
      var dow_map = {
        0: 'Mon',
        1: 'Tue',
        2: 'Wed',
        3: 'Thu',
        4: 'Fri',
        5: 'Sat',
        6: 'Sun'
      };
      for (var i = 0; i < sources.length; i++) {
        var day = dow_map[i];
        var util = sources[i]['util'];
        rows.push([day, util]);
      }
      data.addRows(rows);
    });

    this._drawColumnChart('covers-by-dow', 'Shift', 'Covers', function(data) {
      data.addColumn('string', 'Day of Week');
      data.addColumn('number', 'Covers');
      var rows = [];
      var sources = self._dataSource['days']
      var dow_map = {
        0: 'Mon',
        1: 'Tue',
        2: 'Wed',
        3: 'Thu',
        4: 'Fri',
        5: 'Sat',
        6: 'Sun'
      };
      for (var i = 0; i < sources.length; i++) {
        var day = dow_map[i];
        var covers = sources[i]['covers'];
        var capacity = sources[i]['capacity'];
        rows.push([day, covers]);
      }
      console.log(rows);
      data.addRows(rows);
    });

    // for (var shift_type in self._dataSource['shifts']) {
    //   var el_id = 'utilization-by-shift-breakdown-' + shift_type;
    //   var covers_el_id = 'covers-by-shift-breakdown-' + shift_type;
    //   this._drawBarChart(el_id, 'Time Slot', 'Utilization', function(data){
    //     data.addColumn('string', 'Time Slot');
    //     data.addColumn('number', 'Covers');
    //     var rows = []
    //     var sources = self._dataSource['shifts'][shift_type];
    //     for (var i = 0; i < sources.length; i++) {
    //       var t = sources[i]['time_slot'];
    //       var util = sources[i]['util']
    //       rows.push([t, util])
    //     }
    //   });
    //
    //   this._drawBarChart(covers_el_id, 'Time Slot', 'Covers', function(data){
    //     data.addColumn('string', 'Time Slot');
    //     data.addColumn('number', 'Covers');
    //     data.addColumn('number', 'Capacity');
    //     var rows = []
    //     var sources = self._dataSource['shifts'][shift_type];
    //     for (var i = 0; i < sources.length; i++) {
    //       var t = sources[i]['time_slot'];
    //       var covers = sources[i]['covers'];
    //       var capacity = sources[i]['capacity'];
    //       rows.push([t, covers, capacity])
    //     }
    //   });
    // }

	},

  _drawColumnChart : function(el_id, hTitle, vTitle, populateFn) {
		var data = new google.visualization.DataTable();
		populateFn(data);
		var options = {
			width:445,
		  height:268,
			hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
				title: hTitle,
        titleTextStyle: this._base.CHART_TITLE_STYLE
			},
			vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
				title: '',
        titleTextStyle: this._base.CHART_TITLE_STYLE
			},
			colors: this._base.CHART_COLORS_BLUE,
			legend: 'none'
		};
		var chart = new google.visualization.ColumnChart(document.getElementById(el_id));
		chart.draw(data, options);
	},
  _drawDonut : function(el_id, populateFn) {
		var data = new google.visualization.DataTable();
		populateFn(data);
		var options = {
			width:375,
		  height:268,
			pieHole: 0.4,
			colors: this._base.CHART_COLORS_BLUE,
			pieSliceText: 'value'
		};
		var chart = new google.visualization.PieChart(document.getElementById(el_id));
		chart.draw(data, options);
	},
  _drawBarChart : function(el_id,vTitle, hTitle, populateFn) {
		var data = new google.visualization.DataTable();
		populateFn(data);
		var options = {
			width:445,
		  height:268,
			hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
				title: hTitle,
        titleTextStyle: this._base.CHART_TITLE_STYLE
			},
			vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
				title: '',
        titleTextStyle: this._base.CHART_TITLE_STYLE
			},
			colors: this._base.CHART_COLORS_BLUE,
			legend: 'none'
		};
		var chart = new google.visualization.BarChart(document.getElementById(el_id));
		chart.draw(data, options);
	},
	_clickGoBtn : function(event) {
		if (this._base.validateCalendars()) {
      this._base.showLoadingBar();
			var from_date_url = this._base.getFromDateUrl();
			var to_date_url = this._base.getToDateUrl();
			var shiftCode = this._base.getShiftCode();
			var source = $('#source-select').val();
			var relativeUrl = this._manager_base_url + "/reports/utilization/"+ from_date_url +"/"+ to_date_url;
			relativeUrl += '?shift_code=' + shiftCode;
			relativeUrl += '&source=' + source;
      window.location = relativeUrl;
		}
	}
};
