// This file was automatically generated from manager.manage.daysofoperation.override.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }
if (typeof Nightloop.Templates.Manager.Manage.DaysOfOperation == 'undefined') { Nightloop.Templates.Manager.Manage.DaysOfOperation = {}; }


Nightloop.Templates.Manager.Manage.DaysOfOperation.Override = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.DaysOfOperation.OverrideBit', full_width: true}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.DaysOfOperation.OverrideBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'days_of_operation'}), output);
  output.append('<div id="page-daysofoperation" class="has-sidebar override-page edit-page">', (opt_data.content.success) ? '<p class="global-message success fade">Changes saved. Listings will update in a few minutes.</p>' : '', (opt_data.content.error) ? '<p class="global-message error fade">' + soy.$$escapeHtml(opt_data.content.error) + '</p>' : '', '<div id="main-area"><div id="content-header"><h2>Edit Date of Operation for ', soy.$$escapeHtml(opt_data.content.currentday_display), '</h2></div><form class="styled" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/daysofoperation/', soy.$$escapeHtml(opt_data.content.currentday_urlparam), '" method="post"><input type="hidden" name="_" value="" /><div id="fixed-days-checkboxes-placeholder" class="no-display">&nbsp;</div><div id="days-checkboxes" class="sans edit"><div id="days-open-box" class="day-row"><p class="title">&nbsp;</p><div class="inline">');
  if (opt_data.content.venue_is_closed_on_day) {
    Nightloop.Base.FormElement({type: 'checkbox', name: 'is_custom_open_date', value: 'true', checked: opt_data.content.is_custom_open_date, label: 'Open This Date'}, output);
  } else {
    output.append('Open for operation on ', soy.$$escapeHtml(opt_data.content.currentday_display), '.  <a href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/daysofoperation">Edit week days of operation</a>  or  <a href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/blackoutdates">manage blackout dates</a>.');
  }
  output.append('</div><div class="float-end"></div></div></div>', (opt_data.content.venue_is_closed_on_day) ? '<div class="box"><p class="button"><a class="" id="save-daysofoperation" href="javascript:void(0);">Save changes</a></p><div class="float-end"></div></div>' : '', '</form></div><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Manage.DaysOfOperation.Override.initialize();})<\/script></div>');
  return opt_sb ? '' : output.toString();
};
