var manager_controls = {

  init: function() 
  {
    this.controls(); 
  },

  disable: function()
  {
    $(".small-action-button").css("opacity", 0.5);
    $(".small-action-button").die();
  },

  controls: function()
  {
    var that = this;
    
    // Paste controls
    $('body').on('click', '#preview-paste',function() {
    
      $('#empty-error').hide();
      if( !$('#imports').val().trim() ) {
        $('#empty-error').show();
        return;
      }
      
      $('#clients-import-confirm-input').remove(); 
      $('#pastebin').submit();
      that.disable();
    });
    $('body').on('click', '#confirm-paste', function() {
      
      $('#pastebin').submit();
      that.disable();
    });

    // Upload controls
    $('body').on('click', '#preview-upload', function() {
      $('#nofile-error').hide();
      $('#manager-ajax').show();
      $(this).sext('Uploading');
      if( !$('#importxlsx').val().trim() ) {
        $(this).sext('Preview');
        $('#manager-ajax').hide();
        $('#nofile-error').show();
        return;
      }

     
      $('#clients-upload-confirm-input').remove(); 
      $('#uploader').submit();
      that.disable();

      $('#uploader p').first().sext('Please wait...');
      var killzone = $("<div/>").css({
        'position': 'fixed',
        'width': '100%',
        'height': '100%',
        'z-index': 100,
        'background': 'transparent'
      });
      $('body').append(killzone);
    });
    $('body').on('click', '#confirm-upload', function() {
      $('#manager-ajax').show();
      $(this).sext('Saving...');
      $('#uploader').submit();
      that.disable();
    });
  }
};

$(function() {
  manager_controls.init();
});
