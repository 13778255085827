// This file was automatically generated from manager.manage.status.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.Status = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.StatusBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.StatusBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'service_status'}), output);
  output.append('<div id="page-service-status" class="has-sidebar"><div id="main-area"></div></div><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/common.js']), '"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/service_status.js']), '"><\/script><script type="text/javascript">$(document).ready( function() {modules.manager.statusmanager.init(\'main-area\', "', soy.$$escapeHtml(opt_data.MEDIA_URL), '", "', soy.$$escapeHtml(opt_data.venue.url_key_or_id), '", "', soy.$$escapeHtml(opt_data.venue.venue_group_id), '");});<\/script>');
  return opt_sb ? '' : output.toString();
};
