var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Home = Pmp.Manager.Home || {};
Pmp.Manager.Home.Doc = Pmp.Manager.Home.Doc || {};

Pmp.Manager.Home.Doc.StaffSummary = {
  initialize : function(email_summary_link) {
    this._bindEventHandlers();
    this._email_summary_link = email_summary_link;
  },

  log : function(msg) {
    console.log("Pmp.Manager.Home.Doc.StaffSummary: "+ msg);
  },

  debug : function(msg) {
    if (Pmp.Settings.DEBUG) {
      this.log(msg);
    }
  },

  _bindEventHandlers : function() {
    this.debug("bindEventHandlers");
    var self = this;
    var onClickSortLink = function(event) {self._onClickSortLink(event, $(this));};
    var page = Pmp.Client.Static.Page;
    page.AddLiveHandler('.sort-link', 'click', onClickSortLink);

    // Grouping magic moved to manager.reservations.js...

    Pmp.Utils.GoldButtonClickAnimation('.email-summary',function(button){

      var url = self._email_summary_link;
      var shift_id = $('#shift-select').val();
      if (shift_id) {
        url = url + '?shift_persistent_id=' + shift_id;
      }

      Pmp.Client.LoadAjaxData(url, null, false, function(){
        alert('Your reservation summary has been sent.')
        Pmp.Utils.GoldButtonReset(button);
      });
    });

    $('#show_client_phone').on('change', function() {
      if ($(this).is(':checked')) {
        $('#actuals-list').addClass('client_phone_show');
      } else {
        $('#actuals-list').removeClass('client_phone_show');
      }
    });

    $('#show_client_profile').on('change', function() {
      if ($(this).is(':checked')) {
        $('#actuals-list').addClass('client_info_show');
      } else {
        $('#actuals-list').removeClass('client_info_show');
      }
    });

    $('#show_confirmation_num').on('change', function() {
      if ($(this).is(':checked')) {
        $('#actuals-list').removeClass('confirmation_num_hide');
      } else {
        $('#actuals-list').addClass('confirmation_num_hide');
      }
    });
    $('#show_confirmation_num').prop('checked', true).parents('.form-element').addClass('checked');
  },

  _onClickSortLink : function(event, element) {
    if ($(element).closest('#resgroup-guestlist').length > 0) {
      var container_id = '#resgroup-guestlist';
      var row_class = '.guestlist-row';
    } else {
      var container_id = '#tablebar-groups';
      var row_class = '.staff-summary-row';
    }

    // TODO other than container_id and row_class above, this code is identical to manager.reservations.js
    // resie loader/sorters should be a component
    if( $(row_class, container_id).length == 0 ) return;

    var sort_key = $(element).attr('sort_key');
    var sort_attr = 'sort_' + sort_key;
    var sort_order = $(container_id).data('prev_sort_order') == 'desc' ? 'asc' : 'desc';

    if (this.prev_down_sort_key !== sort_key && container_id == this.prev_container) {
      sort_order = 'asc';
    }

    $(container_id).data('prev_sort_order', sort_order);

      this.prev_container = container_id;
      this.prev_down_sort_key = sort_key;

      $(container_id).find('.sort-link').removeClass('asc desc');
      $(container_id).find('.sort-link[sort_key=' + sort_key + ']').addClass(sort_order);

      var iAsc = sort_order=='asc'?1:-1;
      var sort_fn = function(a, b) {
      var sA = $.trim(a.s), sB = $.trim(b.s);
      // Always bubble empty rows to the bottom
      if (sA === '') {
        return 1;
      } else if (sB === '') {
        return -1;
      }
      // Sort numbers
      fA = parseFloat(sA, 10);
      fB = parseFloat(sB, 10);
      if (!isNaN(fA) && !isNaN(fB)) {
        return iAsc*(fA<fB?-1:(fA>fB?1:0));
      }
      return iAsc*(sA<sB?-1:(sA>sB?1:0));
    };

    this.debug('_onClickSortLink: '+ sort_key + ', ' + sort_order);
    $(row_class).tsort({attr:sort_attr, order:sort_order, sortFunction:sort_fn});
  }

};
