// This file was automatically generated from manager.manage.bank.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.BankEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.BankEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.BankEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'bank'}), output);
  output.append('<div id="page-bankaccount" class="has-sidebar"><div id="main-area"><div id="content-header"><h2>CREDIT CARDS</h2></div>');
  if (! opt_data.user_manageable) {
    output.append('<p class="instruction">Accounts with this payment provider are not manageable through our UI. Please contact SevenRooms with issues or concerns.</p>');
  } else {
    output.append('<p class="instruction-header">Accept Credit Cards and Payments</p><p class="instruction">Use this page to setup banking and payment information.</p>', (opt_data.content.error) ? '<div class="global-message error fade">' + soy.$$escapeHtml(opt_data.content.error) + '</div>' : '', (opt_data.content.message) ? '<div class="global-message success fade">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '');
    if (opt_data.content.payout_profile.is_managed_account) {
      Nightloop.Templates.Manager.Manage.ManagedAccount(opt_data, output);
    } else {
      Nightloop.Templates.Manager.Manage.StandaloneAccount(opt_data, output);
    }
  }
  output.append('</div><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Manage.BankEdit.initialize("', soy.$$escapeHtml(opt_data.settings.STRIPE_PUBLIC_KEY), '", "', (opt_data.content.payout_profile) ? soy.$$escapeHtml(opt_data.content.payout_profile.country) : 'US', '");})<\/script></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.StandaloneAccount = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="payout-container"><div class="stripe-box"><div class="float-left"><img class="stripelogo" src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/stripelogo2x.png" /></div><div class="float-left link-box">', (opt_data.content.payout_profile.id && opt_data.content.payout_profile.account_id) ? '<p class="directions"><img class="check" src="' + soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/check2x.png" /> Stripe account successfully linked<p>Account ID: ' + soy.$$escapeHtml(opt_data.content.payout_profile.account_id) + '</p></p><p class="button disabled"><a id="disconnect-btn" href="' + soy.$$escapeHtml(opt_data.venue.manager_base_url) + '/manage/bank/deauthorize">Disconnect Stripe</a></p>' : '<p class="directions">Create or link an existing Stripe account</p><p class="button"><a id="submit-btn" href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' + soy.$$escapeHtml(opt_data.content.client_id) + '&scope=read_write' + ((opt_data.content.stripe_redirect_uri) ? '&redirect_uri=' + soy.$$escapeHtml(opt_data.content.stripe_redirect_uri) : '') + ((opt_data.content.one_time_token) ? '&state=' + soy.$$escapeHtml(opt_data.content.one_time_token) : '') + '">Take me to Stripe</a></p>', '<a target="_blank" href="https://help.sevenrooms.com/hc/en-us/articles/360005011531-Stripe-FAQ">Learn more about how Stripe integration works</a><br/><a target="_blank" href="https://help.sevenrooms.com/hc/en-us/articles/360006844272-Charging-Saving-and-Refunding-Credit-Cards-on-Web-Dining-">Learn more about using Credit Cards on SevenRooms</a></div><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.ManagedAccount = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="payout-container"><div class="stepone workflowstep"><h3>1. Setup Account Information</h3>', (opt_data.content.payout_profile) ? '<div>Legal entity verification status: ' + soy.$$escapeHtml(opt_data.content.payout_profile.verification_status) + '</div>' : '', '<form sr-validate id=\'legal-entity-form\' class=\'styled\' method=\'post\' action=\'', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/bank\'><input type="hidden" name="profile_id" value="', soy.$$escapeHtml(opt_data.content.payout_profile.id), '" /><div class="form-element select inline-end" id="id_select-phone-locale"><label for="select-country"><p class="label pre">Country</p>', (opt_data.content.payout_profile.country == 'US') ? 'United States' : (opt_data.content.payout_profile.country == 'CA') ? 'Canada' : (opt_data.content.payout_profile.country == 'GB') ? 'United Kingdom' : (opt_data.content.payout_profile.country == 'IT') ? 'Italy' : '<p id="phone-locale" class="input"><select id="select-country" name="country"><option value="US">United States</option><option value="CA">Canada</option><option value="GB">United Kingdom</option><option value="IT">Italy</option></select><span class="downer"></span></p>', '</label></div>');
  Nightloop.Base.FormElement({id: 'first_name', label: 'Owner/Officer first name', name: 'first_name', value: opt_data.content.payout_profile.first_name, validator_type: 'not_empty', validator_message: 'Please enter a first name'}, output);
  Nightloop.Base.FormElement({id: 'last_name', label: 'Owner/Officer last name', name: 'last_name', value: opt_data.content.payout_profile.last_name, validator_type: 'not_empty', validator_message: 'Please enter a last name'}, output);
  Nightloop.Base.FormElement({id: 'dob', label: 'Owner/Officer date of birth', name: 'dob', value: opt_data.content.payout_profile.dob, placeholder: 'e.g. 1970-01-15', validator_type: 'not_empty', validator_message: 'Please enter date of birth'}, output);
  output.append('<p id="dob-error" class="validator" style="display: none;">Date of birth must be of the form 1970-01-15</p>');
  Nightloop.Base.FormElement({id: 'personal_id_number', label: 'Social security number (US)/ Social insurance number (Canada)', name: 'personal_id_number', disabled: opt_data.content.payout_profile.personal_id_number, value: (opt_data.content.payout_profile.personal_id_number) ? 'Click to edit' : '', placeholder: 'e.g. 123-12-1234'}, output);
  Nightloop.Base.FormElement({id: 'business_name', label: 'Business Name', name: 'business_name', value: opt_data.content.payout_profile.business_name, validator_type: 'not_empty', validator_message: 'Please enter a business name'}, output);
  Nightloop.Base.FormElement({id: 'business_url', label: 'Business URL', name: 'business_url', value: opt_data.content.payout_profile.business_url, validator_type: 'not_empty', validator_message: 'Please enter a business url'}, output);
  Nightloop.Base.FormElement({id: 'business_phone', label: 'Business Phone', name: 'business_phone', value: opt_data.content.payout_profile.business_phone, validator_type: 'not_empty', validator_message: 'Please enter a business phone'}, output);
  Nightloop.Base.FormElement({id: 'business_email', label: 'Business Email', name: 'business_email', value: opt_data.content.payout_profile.business_email, validator_type: 'not_empty', validator_message: 'Please enter a business email'}, output);
  Nightloop.Base.FormElement({id: 'business_tax_id', label: 'Employer Identification Number (EIN) or Tax / Company ID', name: 'business_tax_id', value: opt_data.content.payout_profile.business_tax_id, validator_type: 'not_empty', validator_message: 'Please enter a tax ID'}, output);
  Nightloop.Base.FormElement({id: 'business_vat_id', label: 'VAT ID (European accounts)', name: 'business_vat_id', value: opt_data.content.payout_profile.business_vat_id}, output);
  Nightloop.Base.FormElement({id: 'address_1', label: 'Address Line 1', name: 'address_1', value: opt_data.content.payout_profile.address_1, validator_type: 'not_empty', validator_message: 'Please enter an address'}, output);
  Nightloop.Base.FormElement({id: 'address_2', label: 'Address Line 2', name: 'address_2', value: opt_data.content.payout_profile.address_2}, output);
  Nightloop.Base.FormElement({id: 'city', label: 'City', name: 'city', value: opt_data.content.payout_profile.city, validator_type: 'not_empty', validator_message: 'Please enter a city'}, output);
  Nightloop.Base.FormElement({id: 'state', label: 'State / Province', name: 'state', value: opt_data.content.payout_profile.state, validator_type: 'not_empty', validator_message: 'Please enter a state'}, output);
  Nightloop.Base.FormElement({id: 'postal_code', label: 'Postal Code', name: 'postal_code', value: opt_data.content.payout_profile.postal_code, validator_type: 'not_empty', validator_message: 'Please enter a postal code'}, output);
  Nightloop.Base.FormElement({id: 'statement_descriptor', label: 'Charge Statement Descriptor', name: 'statement_descriptor', value: opt_data.content.payout_profile.statement_descriptor, maxlength: 22, placholder: 'e.g. WIDGET BILLING', validator_type: 'not_empty', validator_message: 'Please enter a charge statement descriptor'}, output);
  output.append('<input id="address-country" type="hidden" name="address_country" value="US"/><div class="float-end"></div><br/><br/><p class="button"><a id="legal-submit-btn" href="javascript:void(0);">', (opt_data.content.payout_profile.id) ? 'Update' : 'Submit for Verification', '</a></p></form></div><div class="steptwo workflowstep"><h3>2. Setup Bank Account</h3><form sr-validate id=\'bank-account-form\' class=\'styled\' method=\'post\' action=\'', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/bank\'>');
  if (opt_data.content.payout_profile.country == 'US' || opt_data.content.payout_profile.country == 'CA') {
    Nightloop.Base.FormElement({id: 'routing_number', label: (opt_data.content.payout_profile.country == 'US') ? 'Routing Number' : (opt_data.content.payout_profile.country == 'CA') ? 'Transit and Institution Number' : 'Routing Number', name: 'routing_number', value: (opt_data.content.payout_profile) ? (opt_data.content.payout_profile.bank_account_routing_number) ? soy.$$escapeHtml(opt_data.content.payout_profile.bank_account_routing_number) : '' : '', classes: (! opt_data.content.payout_profile) ? 'disabled' : (opt_data.content.payout_profile.bank_account_id) ? 'disabled' : '', placeholder: (opt_data.content.payout_profile.country == 'US') ? '100011111' : (opt_data.content.payout_profile.country == 'CA') ? '12345-123 (transit # - institution #)' : '100011111', validator_type: 'not_empty', validator_message: 'Please enter a routing number'}, output);
  }
  Nightloop.Base.FormElement({id: 'account_number', label: (opt_data.content.payout_profile.country == 'GB' || opt_data.content.payout_profile.country == 'IT') ? 'IBAN Number' : 'Account Number', name: 'account_number', value: (opt_data.content.payout_profile) ? (opt_data.content.payout_profile.bank_account_last_four) ? '****-' + soy.$$escapeHtml(opt_data.content.payout_profile.bank_account_last_four) : '' : '', classes: (! opt_data.content.payout_profile) ? 'disabled' : (opt_data.content.payout_profile.bank_account_id) ? 'disabled' : '', validator_type: 'not_empty', validator_message: 'Please enter an account number'}, output);
  output.append('<div class="float-end"></div><br/><br/><p class="button"><a id="add-bank-btn" class="', (! opt_data.content.payout_profile) ? 'disabled' : (opt_data.content.payout_profile.bank_account_id) ? 'disabled' : '', '" href="javascript:void(0);">Add Bank Account</a></p></form><br/><br/><h3>3. Set Tax Rate</h3><form sr-validate id=\'tax-rate-form\' class=\'styled\' method=\'post\' action=\'', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/add_tax_rate\'><p>Manage this on the Tax Rates settings page</p>');
  Nightloop.Base.FormElement({id: 'tax_rate', label: 'Tax Rate Percentage', prelabel: 'Rate charged on any payments collected', name: 'tax_rate', disabled: true, value: (opt_data.content.payout_profile && opt_data.content.payout_profile.tax_rate) ? soy.$$escapeHtml(opt_data.content.payout_profile.tax_rate) : ''}, output);
  output.append('<div class="float-end"></div></form></div><div class="float-end"></div><br/><br/></div>');
  return opt_sb ? '' : output.toString();
};
