// This file was automatically generated from manager.manage.upsells.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Marketing == 'undefined') { Nightloop.Templates.Manager.Marketing = {}; }


Nightloop.Templates.Manager.Marketing.Upsells = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Marketing.UpsellsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Marketing.UpsellsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Marketing.Sidebar(soy.$$augmentData(opt_data, {subcategory: 'upsells'}), output);
  output.append('<textarea style="display:none" id="_venue_id">', soy.$$escapeHtml(opt_data.venue.url_key_or_id), '</textarea><textarea style="display:none" id="_media_url">', soy.$$escapeHtml(opt_data.MEDIA_URL), '</textarea><textarea style="display:none" id="venueInfoJson">', opt_data.venue_info_json, '</textarea><!-- ', soy.$$escapeHtml(opt_data.venue), ' --><div id="page-upsells" class="page-view-mode has-sidebar"><div id="sr-app-upsells"></div><script type="text/javascript">var venueProfileInit = {venueId: "', soy.$$escapeHtml(opt_data.venue.id), '",}<\/script><textarea id="venueInfoJson" style="display:none">', opt_data.venue_info_json, '</textarea><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/manager/upsells.js']), '"><\/script></div>');
  return opt_sb ? '' : output.toString();
};
