var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};

Pmp.Manager.MobileRedirect = {
    initialize : function(mobileSiteLink, iosAppLink, itunesAppLink) {
        this._mobileSiteLink = mobileSiteLink;
        this._iosAppLink = iosAppLink;
        this._itunesAppLink = itunesAppLink;
        this._IS_IPAD = navigator.userAgent.match(/iPad/i) !== null;
        this._IS_IPHONE = !this._IS_IPAD && ((navigator.userAgent.match(/iPhone/i) !== null) || (navigator.userAgent.match(/iPod/i) !== null));
        this._IS_IOS = this._IS_IPAD || this._IS_IPHONE;

        this._bindEventHandlers();
    },

    _bindEventHandlers : function() {
        var self = this;
        $('#mobile-app').click(function() {
            if (self._IS_IOS) {
                window.location = self._iosAppLink;
                setTimeout(function() {
                    if (!document.webkitHidden) {
                        window.location = self._itunesAppLink;
                    }
                }, 3000);
            } else {
                window.location = self._mobileSiteLink;
            }
        });
    },
};
