// This file was automatically generated from manager.manage.perks.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }
if (typeof Nightloop.Templates.Manager.Manage.Perks == 'undefined') { Nightloop.Templates.Manager.Manage.Perks = {}; }


Nightloop.Templates.Manager.Manage.Perks.List = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.Perks.ListBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.Perks.ListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'perks'}), output);
  output.append('<div id="page-manage-perks-list" class="has-sidebar"><div id="main-area"><div id="content-header"><h2>PERKS</h2><a id="contextual-link-perks" class="contextual-link" href="javascript:void(0);">(?)</a><div id="contextual-content-perks" class="contextual-content">Perks can be offered to select third-parties with booking access.</div><p class="button"><a href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/perks/create" id="add-new">Add new</a></p></div>', (opt_data.content.msg) ? '<div class="global-message success fade">' + soy.$$escapeHtml(opt_data.content.msg) + '</div>' : '');
  if (opt_data.content.perks.length > 0) {
    output.append('<div class="perk-list">');
    var perkList9820 = opt_data.content.perks;
    var perkListLen9820 = perkList9820.length;
    for (var perkIndex9820 = 0; perkIndex9820 < perkListLen9820; perkIndex9820++) {
      var perkData9820 = perkList9820[perkIndex9820];
      output.append('<div class="perk-title-link"><a href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/perks/', soy.$$escapeHtml(perkData9820.id), '/edit">', (perkData9820.title) ? soy.$$escapeHtml(perkData9820.title) : '(no perk title)', '</a> </div><table class="perk-list-row ', (perkIndex9820 % 2 == 0) ? 'even' : 'odd', ' w100"><tr><td class="perk-label">Addl Info:</td><td colspan="3">', (perkData9820.description_html) ? perkData9820.description_html : 'None', '</td></tr><tr><td class="perk-label">Link to Collateral:</td><td colspan="3">', (perkData9820.url) ? soy.$$escapeHtml(perkData9820.url) : 'None', '</td></tr><tr><td class="perk-label">Staff Instructions:</td><td colspan="3">', (perkData9820.venue_instructions_html) ? perkData9820.venue_instructions_html : 'None', '</td></tr><tr><td class="perk-label">Start Date:</td><td colspan="3">', (perkData9820.start_date_display) ? soy.$$escapeHtml(perkData9820.start_date_display) : 'None', '</td></tr><tr><td class="perk-label">End Date:</td><td colspan="3">', (perkData9820.end_date_display) ? soy.$$escapeHtml(perkData9820.end_date_display) : 'None - lasts forever', '</td></tr><tr><td class="perk-label">Days of Week:</td><td colspan="3">', soy.$$escapeHtml(perkData9820.dow_offered_formatted), '</td></tr><tr><td class="perk-label">Access:</td><td colspan="3">');
      var concierge_nameList9872 = perkData9820.concierge_access_name_list;
      var concierge_nameListLen9872 = concierge_nameList9872.length;
      for (var concierge_nameIndex9872 = 0; concierge_nameIndex9872 < concierge_nameListLen9872; concierge_nameIndex9872++) {
        var concierge_nameData9872 = concierge_nameList9872[concierge_nameIndex9872];
        output.append(soy.$$escapeHtml(concierge_nameData9872), (! (concierge_nameIndex9872 == concierge_nameListLen9872 - 1)) ? ',' : '', ' ');
      }
      output.append('</td></tr></table>');
    }
    output.append('</div>');
  } else {
    output.append('<div id="no-perks-msg">No perks created.</div>');
  }
  output.append('<script type="text/javascript">$(document).ready(function() {Pmp.Manager.Manage.Perks.List.initialize();});<\/script></div></div>');
  return opt_sb ? '' : output.toString();
};
