var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Reports = Pmp.Manager.Reports || {};

Pmp.Manager.Reports.TurnAnalysis = {
	initialize : function(manager_base_url, currency_symbol) {
		this._base = Pmp.Manager.Reports.Base;
		this._base.initialize();

		this._manager_base_url = manager_base_url;
		this._currency_symbol = currency_symbol;

		this._dataSource = $.parseJSON($('#data-source').html());

		this._bindEventHandlers();
		this._initCharts();
	},

	log : function(msg) {
		console.log("Pmp.Manager.Reports.TurnAnalysis: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var clickGoBtnFn = function(event) {self._clickGoBtn(event);};
		$('#summary-report-submit-btn').click(clickGoBtnFn);
	},

	_initCharts : function() {
		var self = this;
		/* Callback that creates and populates a data table,
		 * instantiates the pie chart, passes in the data and
		 * draws it.
		 **/
		Pmp.Utils.CreateChart(function(){self._drawCharts();});
	},

	_drawCharts : function() {
		var self = this;
		this._drawComboChart('actual-vs-default-turn-times', 'Party Size', 'Turn Times', function(data) {
			data.addColumn('string', 'Party Size');
			data.addColumn('number', 'Turn Times (Default)');
			data.addColumn('number', 'Turn Times (Actual)');
			var rows = [];
			var sources = self._dataSource['all'];
			for (var ps in sources) {
				rows.push([ps, sources[ps]['default'], sources[ps]['actual']]);
			}
			data.addRows(rows);
		});
		this._drawComboChart('actual-vs-default-turn-times-monday', 'Party Size', 'Turn Times', function(data) {
			data.addColumn('string', 'Party Size');
			data.addColumn('number', 'Turn Times (Default)');
			data.addColumn('number', 'Turn Times (Actual)');
			var rows = [];
			var sources = self._dataSource['dow']['Monday'];
			for (var ps in sources) {
				rows.push([ps, sources[ps]['default'], sources[ps]['actual']]);
			}
			data.addRows(rows);
		});
		this._drawComboChart('actual-vs-default-turn-times-tuesday', 'Party Size', 'Turn Times', function(data) {
			data.addColumn('string', 'Party Size');
			data.addColumn('number', 'Turn Times (Default)');
			data.addColumn('number', 'Turn Times (Actual)');
			var rows = [];
			var sources = self._dataSource['dow']['Tuesday'];
			for (var ps in sources) {
				rows.push([ps, sources[ps]['default'], sources[ps]['actual']]);
			}
			data.addRows(rows);
		});
		this._drawComboChart('actual-vs-default-turn-times-wednesday', 'Party Size', 'Turn Times', function(data) {
			data.addColumn('string', 'Party Size');
			data.addColumn('number', 'Turn Times (Default)');
			data.addColumn('number', 'Turn Times (Actual)');
			var rows = [];
			var sources = self._dataSource['dow']['Wednesday'];
			for (var ps in sources) {
				rows.push([ps, sources[ps]['default'], sources[ps]['actual']]);
			}
			data.addRows(rows);
		});
		this._drawComboChart('actual-vs-default-turn-times-thursday', 'Party Size', 'Turn Times', function(data) {
			data.addColumn('string', 'Party Size');
			data.addColumn('number', 'Turn Times (Default)');
			data.addColumn('number', 'Turn Times (Actual)');
			var rows = [];
			var sources = self._dataSource['dow']['Thursday'];
			for (var ps in sources) {
				rows.push([ps, sources[ps]['default'], sources[ps]['actual']]);
			}
			data.addRows(rows);
		});
		this._drawComboChart('actual-vs-default-turn-times-friday', 'Party Size', 'Turn Times', function(data) {
			data.addColumn('string', 'Party Size');
			data.addColumn('number', 'Turn Times (Default)');
			data.addColumn('number', 'Turn Times (Actual)');
			var rows = [];
			var sources = self._dataSource['dow']['Friday'];
			for (var ps in sources) {
				rows.push([ps, sources[ps]['default'], sources[ps]['actual']]);
			}
			data.addRows(rows);
		});
		this._drawComboChart('actual-vs-default-turn-times-saturday', 'Party Size', 'Turn Times', function(data) {
			data.addColumn('string', 'Party Size');
			data.addColumn('number', 'Turn Times (Default)');
			data.addColumn('number', 'Turn Times (Actual)');
			var rows = [];
			var sources = self._dataSource['dow']['Saturday'];
			for (var ps in sources) {
				rows.push([ps, sources[ps]['default'], sources[ps]['actual']]);
			}
			data.addRows(rows);
		});
		this._drawComboChart('actual-vs-default-turn-times-sunday', 'Party Size', 'Turn Times', function(data) {
			data.addColumn('string', 'Party Size');
			data.addColumn('number', 'Turn Times (Default)');
			data.addColumn('number', 'Turn Times (Actual)');
			var rows = [];
			var sources = self._dataSource['dow']['Sunday'];
			for (var ps in sources) {
				rows.push([ps, sources[ps]['default'], sources[ps]['actual']]);
			}
			data.addRows(rows);
		});
	},

	_drawComboChart : function(el_id, hTitle, vTitle, populateFn) {
		var data = new google.visualization.DataTable();
		populateFn(data);
		var options = {
			width:445,
		  height:268,
			hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
				title: hTitle,
        titleTextStyle: this._base.CHART_TITLE_STYLE
			},
			vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
				title: '',
        titleTextStyle: this._base.CHART_TITLE_STYLE
			},
			seriesType: 'bars',
      series: {0: {type: 'line'}},
			colors: ['black', this._base.CHART_COLORS_BLUE[1]]
		};
		var chart = new google.visualization.ComboChart(document.getElementById(el_id));
		chart.draw(data, options);
	},

	_clickGoBtn : function(event) {
		if (this._base.validateCalendars()) {
      this._base.showLoadingBar();
			var from_date_url = this._base.getFromDateUrl();
			var to_date_url = this._base.getToDateUrl();
			var shiftCode = this._base.getShiftCode();
			var relativeUrl = this._manager_base_url + "/reports/turnanalysis/"+ from_date_url +"/"+ to_date_url;
			relativeUrl += '?shift_code=' + shiftCode;
      window.location = relativeUrl;
		}
	}
};
