
var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Manage = Pmp.Manager.Manage || {};
Pmp.Manager.Manage.DaysOfOperation = Pmp.Manager.Manage.DaysOfOperation || {};

Pmp.Manager.Manage.DaysOfOperation.Override = {
	initialize : function() {
		this.bindClickHandlers();
	},

	log : function(msg) {
		console.log("Pmp.Manager.Manage.DaysOfOperation.Override: "+ msg);
	},
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	bindClickHandlers : function() {
		this.debug("Binding click handlers");
		var self = this;
		var clickSubmitBtn = function(event) {self._clickSubmitBtn(event, $(this));};

		Pmp.Client.Static.Page.AddLiveHandler('#save-daysofoperation', 'click', clickSubmitBtn);
	},

	_clickSubmitBtn : function(event, btn) {
    console.log(btn);
    $('#manager-ajax').show();
    $('#save-daysofoperation').sext('Saving...');
		btn.closest('form').submit();
		return false;
	}
};
