var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Reports = Pmp.Manager.Reports || {};
Pmp.Manager.Reports.BookedBy = Pmp.Manager.Reports.BookedBy || {};

Pmp.Manager.Reports.BookedBy.List = {
	initialize : function(manager_base_url, is_guestlist, completed_reservations_list, currency_symbol, is_source) {
		this._base = Pmp.Manager.Reports.Base;
		this._base.initialize();

		this._manager_base_url = manager_base_url;
		this._is_guestlist = is_guestlist;
		this._is_source = is_source;
		this._completed_reservations_list = completed_reservations_list;
		this._currency_symbol = currency_symbol;

		this._bindEventHandlers();
		this._initCharts();

		// Turn all values with this class combo into integers
		$('.number.int').each(function(idx, td) {
			var int_value = parseInt(this.innerHTML);
			$(this).sext(int_value);
		});
	},

	log : function(msg) {
		console.log("Pmp.Manager.Reports.BookedBy.List: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var clickGoBtnFn = function(event) {self._clickGoBtn(event);};

		$('#summary-report-submit-btn').click(clickGoBtnFn);
	},

	_initCharts : function() {
		var self = this;
		/* Callback that creates and populates a data table,
		 * instantiates the pie chart, passes in the data and
		 * draws it.
		 **/
		Pmp.Utils.CreateChart(function(){self._drawCharts();});
	},

	_drawCharts : function() {
		if (!this._is_guestlist) {
			this._drawNumberCompletedReservationsByName();
			this._drawamtCompletedReservationsByName();
		} else {
			this._drawCheckinsByName();
		}
	},

	_drawNumberCompletedReservationsByName : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Name');
		data.addColumn('number', 'No. Completed');

		var resRows = [];
		for (var i = 0; i < this._completed_reservations_list.length; i++) {
			var completed_reservation = this._completed_reservations_list[i];
			var resRow = [
			    completed_reservation['name'],
			    completed_reservation['num']
			];
			resRows.push(resRow);
		}
		data.addRows(resRows);

		var options = {
		        width:445,
		        height:189,
		};

		var chart = new google.visualization.PieChart(document.getElementById('completed-by-source-chart'));
		chart.draw(data, options);
	},

	_drawamtCompletedReservationsByName : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Name');
		data.addColumn('number', 'Total Spend');

		var resRows = [];
		for (var i = 0; i < this._completed_reservations_list.length; i++) {
			var completed_reservation = this._completed_reservations_list[i];
			var resRow = [
			    completed_reservation['name'],
			    completed_reservation['amt']
			];
			resRows.push(resRow);
		}
		data.addRows(resRows);

	    var formatter = new google.visualization.NumberFormat({prefix: this._currency_symbol});
	    formatter.format(data, 1); // Apply formatter to second column

		var options = {
		        width:445,
		        height:189,
		};

		var chart = new google.visualization.PieChart(document.getElementById('spend-by-source-chart'));
		chart.draw(data, options);
	},

	_drawCheckinsByName : function() {
		var data = new google.visualization.DataTable();
		data.addColumn('string', 'Name');
		data.addColumn('number', 'Checked-In');

		var resRows = [];
		for (var i = 0; i < this._completed_reservations_list.length; i++) {
			var completed_reservation = this._completed_reservations_list[i];
			var resRow = [
			    completed_reservation['name'],
			    completed_reservation['checkedin']
			];
			resRows.push(resRow);
		}
		data.addRows(resRows);

		var options = {
		        width:445,
		        height:189
		};

		var chart = new google.visualization.PieChart(document.getElementById('checkins-by-source-chart'));
		chart.draw(data, options);
	},

	_clickGoBtn : function(event) {
		if (this._base.validateCalendars()) {
			this._base.showLoadingBar();
			var from_date_url = this._base.getFromDateUrl();
			var to_date_url = this._base.getToDateUrl();
			var report_type_url = '';
			if (this._is_guestlist){
				report_type_url = 'guestlist/bookedby/';
			}
			else if (this._is_source) {
				report_type_url = 'sourceby/';
			}
			else {
				report_type_url = 'bookedby/'
			}
		}
		var relativeUrl = this._manager_base_url + "/reports/"+report_type_url+ from_date_url +"/"+ to_date_url + '?shift_code=' + this._base.getShiftCode();
		Pmp.Client.Static.Page.Navigate('#nightloop', relativeUrl, undefined, true /* break_ajax*/);
	}
};
