var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Reports = Pmp.Manager.Reports || {};

Pmp.Manager.Reports.WaitTimes = {
  initialize : function(manager_base_url, currency_symbol) {
    this._base = Pmp.Manager.Reports.Base;
    this._base.initialize();

    this._manager_base_url = manager_base_url;
    this._currency_symbol = currency_symbol;

    this._dataSource = $.parseJSON($('#data-source').html());

    this._bindEventHandlers();
    this._initCharts();
  },

  log : function(msg) {
    console.log("Pmp.Manager.Reports.WaitTimes: "+ msg);
  },

  debug : function(msg) {
    if (Pmp.Settings.DEBUG) {
      this.log(msg);
    }
  },

  _bindEventHandlers : function() {
    this.debug("_bindEventHandlers");
    var self = this;
    var clickGoBtnFn = function(event) {self._clickGoBtn(event);};
    $('#summary-report-submit-btn').click(clickGoBtnFn);
  },

  _initCharts : function() {
    var self = this;
    /* Callback that creates and populates a data table,
     * instantiates the pie chart, passes in the data and
     * draws it.
     **/
    Pmp.Utils.CreateChart(function(){self._drawCharts();});
  },

  _drawCharts : function() {
    var self = this;

    this._drawStackedBarChart('wait-times-quote-accuracy', 'Occurrences', 'Wait Start Time', function(data) {
      data.addColumn('string', 'Wait Start Time');
      data.addColumn('number', 'Wait Shorter Than Quoted');
      data.addColumn('number', 'Accurate Quote');
      data.addColumn('number', 'Wait Longer Than Quoted');
      var rows = [];
      var ordering = self._dataSource['ordering_time'];
      var time_dicts = self._dataSource['time'];
      for (var i = 0; i < ordering.length; i++) {
        var time_slot = ordering[i];
        var slot_values = time_dicts[time_slot]
        if (slot_values) {
          rows.push([
            time_slot,
            slot_values['under'],
            slot_values['correct'],
            slot_values['over'],
          ]);
        } else {
          rows.push([time_slot, 0, 0, 0, 0]);
        }
      }
      data.addRows(rows);
    });

    this._drawComparisonBarChart('wait-times-average', 'Total Parties', '#,###', 'Average Wait Time', '#,### min', 'Wait Start Time', function(data) {
      data.addColumn('string', 'Wait Start Time');
      data.addColumn('number', 'Parties of 1-2');
      data.addColumn('number', 'Parties of 3-4');
      data.addColumn('number', 'Parties of 5-6');
      data.addColumn('number', 'Parties of 7+');
      data.addColumn('number', 'Average Wait Time');
      var rows = [];
      var ordering = self._dataSource['ordering_time'];
      var time_dicts = self._dataSource['time'];
      for (var i = 0; i < ordering.length; i++) {
        var time_slot = ordering[i];
        var slot_values = time_dicts[time_slot]
        if (slot_values && slot_values['total_parties']) {
          var average_wait_time = Math.floor(slot_values['total_wait_time'] / slot_values['total_parties']);
          rows.push([
            time_slot,
            slot_values['count_1_2'],
            slot_values['count_3_4'],
            slot_values['count_5_6'],
            slot_values['count_7_plus'],
            Math.max(0, average_wait_time)
          ]);
        } else {
          rows.push([time_slot, 0, 0, 0, 0, 0]);
        }
      }
      data.addRows(rows);
    });

    this._drawComparisonBarChart('abandon-rates', 'Total Abandons', '#,###', 'Abandon Percentage', '#,###%', 'Wait Start Time', function(data) {
      data.addColumn('string', 'Wait Start Time');
      data.addColumn('number', 'Parties of 1-2');
      data.addColumn('number', 'Parties of 3-4');
      data.addColumn('number', 'Parties of 5-6');
      data.addColumn('number', 'Parties of 7+');
      data.addColumn('number', 'Percentage');
      var rows = [];
      var ordering = self._dataSource['ordering_time'];
      var time_dicts = self._dataSource['time'];
      for (var i = 0; i < ordering.length; i++) {
        var time_slot = ordering[i];
        var slot_values = time_dicts[time_slot]
        if (slot_values && slot_values['total_parties']) {
          var total_abandons = slot_values['abandon_count_1_2'] + slot_values['abandon_count_3_4'] + slot_values['abandon_count_5_6'] + slot_values['abandon_count_7_plus'];
          var abandon_percent = total_abandons / slot_values['total_parties'];
          rows.push([
            time_slot,
            slot_values['abandon_count_1_2'],
            slot_values['abandon_count_3_4'],
            slot_values['abandon_count_5_6'],
            slot_values['abandon_count_7_plus'],
            Math.max(0, Math.min(1, abandon_percent))
          ]);
        } else {
          rows.push([time_slot, 0, 0, 0, 0, 0]);
        }
      }
      data.addRows(rows);
    });
    
    this._drawBarChart('return-times', 'Return Time', 'Party Size', function(data) {
      data.addColumn('string', 'Party Size');
      data.addColumn('number', 'Return Time');
      data.addColumn({ type: 'string', role: 'style' });
      var party_size_ordering = [
        ['Parties of 1-2', 'party_sizes_1_2', 'color: #990099'],
        ['Parties of 3-4', 'party_sizes_3_4', 'color: #0099C6'],
        ['Parties of 5-6', 'party_sizes_5_6', 'color: #66AA00'],
        ['Parties of 7+', 'party_sizes_7_plus', 'color: #CFE4F4'],
      ];
      var return_time_by_party_size = self._dataSource['return_time_by_party_size'];
      var return_times = [];
      for (var i = 0; i < party_size_ordering.length; i++) {
        var column_config = party_size_ordering[i]
        var column_name = [0];
        var return_time_dict = return_time_by_party_size[party_size_ordering[i][1]];
        var return_time_total = return_time_dict['return_time_total'];
        var return_time_count = return_time_dict['return_time_count'];
        return_times.push([
          column_config[0],
          Math.floor(return_time_total / (return_time_count || 1)),
          column_config[2]
        ]);
      }
      data.addRows(return_times);
    });
  },

  _drawStackedBarChart : function(elementId, vTitle, hTitle, populateFn, seriesColors) {
    var data = new google.visualization.DataTable();
    populateFn(data);
    var options = {
      width:900,
      height:268,
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR, fontSize: 10 },
        title: hTitle,
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        title: vTitle,
        minValue: 0,
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      colors: this._base.CHART_COLORS_BLUE,
      legend: {
        position: 'top'
      },
      series: {
        0: {
          color: '#990099'
        },
        1: {
          color: '#0099C6'
        },
        2: {
          color: '#66AA00'
        }
      },
      isStacked: 'percent',
      orientation: 'horizontal'
    };
    var chart = new google.visualization.BarChart(document.getElementById(elementId));
    chart.draw(data, options);
  },
  
  _drawComparisonBarChart : function(elementId, vTitle1, format1, vTitle2, format2, hTitle, populateFn) {
    var data = new google.visualization.DataTable();
    populateFn(data);
    var options = {
        width:900,
        height:268,
        hAxis: {
          textStyle: { color: this._base.CHART_TEXT_COLOR, fontSize: 10 },
          title: hTitle,
          titleTextStyle: this._base.CHART_TITLE_STYLE
        },
        vAxes: [{
          textStyle: { color: this._base.CHART_TEXT_COLOR },
          title: vTitle1,
          format: format1,
          minValue: 0,
          titleTextStyle: this._base.CHART_TITLE_STYLE
        }, {
          textStyle: { color: this._base.CHART_TEXT_COLOR },
          title: vTitle2,
          format: format2,
          minValue: 0,
          titleTextStyle: this._base.CHART_TITLE_STYLE
        }],
        seriesType: 'bars',
        series: {
          0: {
            targetAxisIndex: 0,
            color: '#990099'
          },
          1: {
            targetAxisIndex: 0,
            color: '#0099C6'
          },
          2: {
            targetAxisIndex: 0,
            color: '#66AA00'
          },
          3: {
            targetAxisIndex: 0,
            color: '#CFE4F4'
          },
          4: {
            type: 'line',
            pointSize: 1,
            targetAxisIndex: 1,
            color: '#DD4477'
          }
        },
        isStacked: true,
        colors: this._base.CHART_COLORS_BLUE
    };
    var formatter1 = new google.visualization.NumberFormat({ pattern: format1 });
    formatter1.format(data, 1);
    formatter1.format(data, 2);
    formatter1.format(data, 3);
    formatter1.format(data, 4);
    var formatter2 = new google.visualization.NumberFormat({ pattern: format2 });
    formatter2.format(data, 5);
    var chart = new google.visualization.ComboChart(document.getElementById(elementId));
    chart.draw(data, options);
  },

  _drawBarChart : function(elementId, vTitle, hTitle, populateFn) {
    var data = new google.visualization.DataTable();
    populateFn(data);
    var options = {
      width:900,
      height:268,
      hAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        title: hTitle,
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      vAxis: {
        textStyle: { color: this._base.CHART_TEXT_COLOR },
        title: vTitle,
        format:'#,### min',
        minValue: 0,
        titleTextStyle: this._base.CHART_TITLE_STYLE
      },
      colors: this._base.CHART_COLORS_BLUE,
      orientation: 'horizontal',
      legend: { position: 'none' }
    };
    var chart = new google.visualization.BarChart(document.getElementById(elementId));
    chart.draw(data, options);
  },

  _clickGoBtn : function(event) {
    if (this._base.validateCalendars()) {
      this._base.showLoadingBar();
      var from_date_url = this._base.getFromDateUrl();
      var to_date_url = this._base.getToDateUrl();
      var shiftCode = this._base.getShiftCode();
      var relativeUrl = this._manager_base_url + "/reports/wait-times/"+ from_date_url +"/"+ to_date_url;
      relativeUrl += '?shift_code=' + shiftCode;
      window.location = relativeUrl;
    }
  }
};
