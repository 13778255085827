
var ReservationStats = {

  init : function (actualsList, seatingAreaIdToCode, isDiningClass) {
    this.actuals = actualsList;
    this.seatingAreaIdToCode = seatingAreaIdToCode;
    this.isDiningClass = isDiningClass;
    this.stats = {};
    this.DEFAULTS = {
      'reservations' : 0,
      'netReservations' : 0,
      'covers' : 0,
      'booked' : 0,
      'coversBooked' : 0,
      'confirmed' : 0,
      'coversConfirmed' : 0,
      'hold' : 0,
      'coversHold' : 0,
      'canceled' : 0,
      'coversCanceled' : 0,
      'coversDead' : 0,
      'netCovers' : 0,
      'min' : 0.0
    };
  },

  statsForAllShifts : function () {
    var allStats = {},
        netCoverTemp = 0,
        coversTemp = 0,
        coversDeadTemp = 0;

    for (var grouping_type in this.stats) {
      if (!(grouping_type in allStats)) {
        allStats[grouping_type] = {};
      }
      for (var shift_id in this.stats[grouping_type]) {
        for (var group_variant in this.stats[grouping_type][shift_id]) {
          if (!(group_variant in allStats[grouping_type])) {
            allStats[grouping_type][group_variant] = $.extend(true, {}, this.DEFAULTS);
          }

          coversTemp = this.stats[grouping_type][shift_id][group_variant]['covers'];
          coversDeadTemp = this.stats[grouping_type][shift_id][group_variant]['coversDead'];
          netCoverTemp = coversTemp - coversDeadTemp;

          allStats[grouping_type][group_variant]['reservations'] += this.stats[grouping_type][shift_id][group_variant]['reservations'];
          allStats[grouping_type][group_variant]['netReservations'] += this.stats[grouping_type][shift_id][group_variant]['netReservations'];
          allStats[grouping_type][group_variant]['covers'] += coversTemp;
          allStats[grouping_type][group_variant]['booked'] += this.stats[grouping_type][shift_id][group_variant]['booked'];
          allStats[grouping_type][group_variant]['coversBooked'] += this.stats[grouping_type][shift_id][group_variant]['coversBooked'];
          allStats[grouping_type][group_variant]['confirmed'] += this.stats[grouping_type][shift_id][group_variant]['confirmed'];
          allStats[grouping_type][group_variant]['coversConfirmed'] += this.stats[grouping_type][shift_id][group_variant]['coversConfirmed'];
          allStats[grouping_type][group_variant]['hold'] += this.stats[grouping_type][shift_id][group_variant]['hold'];
          allStats[grouping_type][group_variant]['canceled'] += this.stats[grouping_type][shift_id][group_variant]['coversCanceled'];
          allStats[grouping_type][group_variant]['coversDead'] += coversDeadTemp;
          allStats[grouping_type][group_variant]['netCovers'] += netCoverTemp;
          allStats[grouping_type][group_variant]['min'] += this.stats[grouping_type][shift_id][group_variant]['min'];

        }
      }
    }

    return allStats;
  },

  refresh : function () {
    this.stats = {
      'DEFAULT' : {},
      'SEATING_AREA' : {},
      'TABLE_BAR' : {},
      'PAYING_COMP' : {},
      'RES_TYPE' : {},
      'VIP_ALL' : {},
      'VIP_NONVIP' : {},
        'RES_STATUS' :{}, 'RES_TIME': {}
    }; // by groupByType by shift by groupId

    this.reservations = 0;
    this.netReservations = 0;
    this.canceled = 0;
    this.booked = 0;
    this.hold = 0;
    this.confirmed = 0;
    this.covers = 0;
    this.coversCanceled = 0;
    this.coversBooked = 0;
    this.coversHold = 0;
    this.coversConfirmed = 0;
    this.coversDead = 0;
    this.netCovers = 0;
    this.min = 0.0;
    for (var i = 0; i < this.actuals.length; i ++) {
      var actual = this.actuals[i];
      var spid = (this.isDiningClass && actual.shift_persistent_id) ? actual.shift_persistent_id : 'DEFAULT';
      var covers = (actual.max_guests ? actual.max_guests : 0);
      var min = 0.0;
      if (actual.min_price_type === 'dollar') {
			  min = (actual.min_price ? actual.min_price : 0.0);
      }

      this.reservations += 1;
      this.netReservations += (!actual.is_dead ? 1 : 0);
      this.canceled += (actual.status === 'CANCELED' ? 1 : 0);
      this.booked += (actual.status === 'NOT_RECONCILED' ? 1 : 0);
      this.hold += (actual.status === 'HOLD' ? 1 : 0);
      this.confirmed += (actual.status === 'CONFIRMED' ? 1 : 0);
      this.covers += covers;
      this.coversCanceled += (actual.status === 'CANCELED' ? covers : 0);
      this.coversBooked += (actual.status === 'NOT_RECONCILED' ? covers : 0);
      this.coversHold += (actual.status === 'HOLD' ? covers : 0);
      this.coversConfirmed += (actual.status === 'CONFIRMED' ? covers : 0);
      this.coversDead += (actual.is_dead ? covers: 0);
      this.min +=  (!actual.is_dead ? min : 0);

      for (var groupByType in this.stats) {

        var groupId = this._groupIdFromActual(actual, groupByType, spid);
        var statsDict = this.stats[groupByType][spid][groupId];

        statsDict['reservations'] += 1;
        statsDict['netReservations'] += (!actual.is_dead ? 1 : 0);
        statsDict['canceled'] += (actual.status === 'CANCELED' ? 1 : 0);
        statsDict['booked'] += (actual.status === 'NOT_RECONCILED' ? 1 : 0);
        statsDict['hold'] += (actual.status === 'HOLD' ? 1 : 0);
        statsDict['confirmed'] += (actual.status === 'CONFIRMED' ? 1 : 0);
        statsDict['covers'] += covers;
        statsDict['coversCanceled'] += (actual.status === 'CANCELED' ? covers : 0);
        statsDict['coversBooked'] += (actual.status === 'NOT_RECONCILED' ? covers : 0);
        statsDict['coversHold'] += (actual.status === 'HOLD' ? covers : 0);
        statsDict['coversConfirmed'] += (actual.status === 'CONFIRMED' ? covers : 0);
        statsDict['coversDead'] += (actual.is_dead ? covers : 0);
        statsDict['min'] += (!actual.is_dead ? min : 0);
        statsDict['netCovers'] = statsDict['covers'] - statsDict['coversDead'];

      }


    }
    this.netCovers = this.covers - this.coversDead;
  },

  _groupIdFromActual : function(actual, groupByType, spid) {
    var groupId = '_'; // groupByType == 'DEFAULT'
    if (groupByType == 'SEATING_AREA') {
      groupId = actual.venue_seating_area_id;
      if (!(groupId && this.seatingAreaIdToCode[groupId] !== undefined)) {
        groupId = '';
      }
    } else if (groupByType == 'TABLE_BAR') {
      groupId = actual.is_bar_reservation ? 'BAR' : 'TABLE';
    } else if (groupByType == 'PAYING_COMP') {
      groupId = actual.is_comp ? 'COMP' : 'PAYING';
    } else if (groupByType == 'RES_TYPE') {
      var groupLabel = 'Reservations'; // No tag
      if (_.size(actual.reservation_tags) ) {
        var first_tag = actual.reservation_tags[0].tags[0];
        groupLabel = first_tag.tag_name_display || first_tag.tag_name;
      }
      groupId = groupLabel.replace(/[^\w\d-]/g, '-');
    } else if (groupByType == 'VIP_ALL') {
      groupId = 'all';
    } else if (groupByType == 'VIP_NONVIP') {
      groupId = actual.is_client_vip ? 'vip' : 'all';
    } else if (groupByType === 'RES_STATUS') {
      groupId = actual.status_formatted;
    } else if(groupByType === 'RES_TIME') {
      groupId = actual.arrival_time_sort_order;
    }
    // insert dictionary keys and defaults
    if (!(spid in this.stats[groupByType])) {
      this.stats[groupByType][spid] = {};
      this.stats[groupByType][spid][groupId] = $.extend(true, {}, this.DEFAULTS);
    } else if (!(groupId in this.stats[groupByType][spid])) {
      this.stats[groupByType][spid][groupId] = $.extend(true, {}, this.DEFAULTS);
    }
    return groupId;
  }

};
