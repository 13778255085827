
var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Access = Pmp.Manager.Access || {};
Pmp.Manager.Access.User = Pmp.Manager.Access.User || {};

Pmp.Manager.Access.User.List = {
	initialize : function(manager_base_url, user_email) {
	  this._manager_base_url = manager_base_url;
	  this._user_email = user_email;
	  this._export_type = null;

		this._bindEventHandlers();
	},
	
	log : function(msg) {
		console.log("Pmp.Manager.Access.User.List: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		
		var self = this;
		var clickViewPermissionsLinkFn = function(event) {self._clickViewPermissionsLink(event, $(this));}
		var clickReturnToTopLinkFn = function(event) {self._clickReturnToTopLink(event, $(this));}
		
		/* action handlers */
		$('.view-permissions-link').click(clickViewPermissionsLinkFn);
		$('.return-to-top-link').click(clickReturnToTopLinkFn);
		
		/* export dropdown */
    $('.export-button .button').click(function (e) {
      e.stopPropagation();
      $('.export-drop-down-wrapper').css('visibility', 'visible');
      $('.export-button .collapsed').css('visibility', 'hidden');
    });

    $(document).click(function (e) {
      $('.export-drop-down-wrapper').css('visibility', 'hidden');
      $('.export-button .collapsed').css('visibility', 'visible');
    });
    $('#export-venue-users').on('click', self._clickExportVenueUsersButton.bind(this));
    $('#export-venue-group-users').on('click', self._clickExportVenueGroupUsersButton.bind(this));
	},
	
	_clickViewPermissionsLink : function(event, element) {
		window.scroll(0,$('.view-permissions-anchor').offset().top);
	},
	
	_clickReturnToTopLink : function(event, element) {
		window.scroll(0,0);
	},
	
	_clickExportVenueUsersButton : function(event) {
	  this._export_type = 'Venue';
	  this._showExportPopup();
	},
	
	_clickExportVenueGroupUsersButton : function(event) {
	  this._export_type = 'VenueGroup';
	  this._showExportPopup();
	},

	_showExportPopup : function() {
    Pmp.Main.Popup.Base.loadHtml(Nightloop.Templates.Manager.Access.User.ConfirmExportPopup({
      export_type: this._export_type,
      user_email: this._user_email
    }));
    Pmp.Main.Popup.Base.showPopup();
	},

  initExport: function () {
    var self = this;
    Pmp.Utils.GoldButtonClickSubmit('#export-btn', '#search-and-filter-form', function (form) {
      var export_url = self._manager_base_url + '/access/user/export/' + self._export_type.toLowerCase();
      var on_complete = function (data) {
        Pmp.Utils.GoldButtonReset('#export-btn');
        $('#export-prompt-text').hide();
        $('#export-processing-text').show();
        $('#export-button-container').hide();
        $.colorbox.resize();
      };
      if (self._export_type === 'VenueGroup') {
        Pmp.Client.AsyncGet(export_url, on_complete);
      } else {
        window.open(export_url + '?_=' + new Date().getTime(), '_blank');
        on_complete();
      }
      CustomerSuccessTracker.trackReporting('Export '+ self._export_type +' Users');
    });
  }
};