
var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};

Pmp.Manager.Actuals = {
  initialize : function(
    media_url,
    manager_base_url,
    currentday_urlparam,
    post_url,
    currency_symbol,
    currency_code,
    locale,
    min_date,
    privilege_level,
    default_phone_locale,
    is_nightlife_class,
    pos_enabled,
    status_types,
    is_comps_enabled,
    time_options_by_shift
    ) {

    // register a destroyer
    var self = this;
    var destroyerFn = function() { self.destroy(); };
    Pmp.Client.Static.Factory.Register(Nightloop.Templates.Manager.Actuals,
                                      null, destroyerFn);

    this._MEDIA_URL = media_url;
    this._manager_base_url = manager_base_url;
    this._currentday_urlparam = currentday_urlparam;
    this._post_url = post_url;
    this._currency_symbol = currency_symbol;
    this._currency_code = currency_code;
    this._locale = locale;
    this._min_date = min_date;
    this._privilege_level = privilege_level;
    this._default_phone_locale = default_phone_locale;
    this._is_nightlife_class = is_nightlife_class;
    this._status_types = status_types;
    this._is_comps_enabled = is_comps_enabled;

    this._pos_enabled = pos_enabled;

    this._actuals = [];
    this._actuals_id_to_idx = {};
    this._table_choices = [];
    this._served_by_users = [];
    this._booked_by_users = [];

    this._time_options_by_shift = time_options_by_shift;
    
    LazyActualsService.initialize(manager_base_url, currentday_urlparam);
    ShiftSelector.init(manager_base_url);
    this.bindEventHandlersBeforeActuals();
    this._lazyLoadActuals();
    this.refreshTimeOptions();
  },

  log : function(msg) {
    console.log("Pmp.Manager.Actuals: "+ msg);
  },

  debug : function(msg) {
    if (Pmp.Settings.DEBUG) {
      this.log(msg);
    }
  },

  destroy : function() {
    this.debug("destroying");
    $(window).unbind('scroll');
  },

  bindEventHandlersBeforeActuals : function() {
    this.debug("bindEventHandlersBeforeActuals");
    var self = this;
    Pmp.Utils.LocalizeDatePicker(this._locale, '#date-calendar', '#date-calendar-submit');
    if (this._min_date !== null || this._min_date != undefined || !(this._min_date)) {
      $('#date-calendar').datepicker('option', 'minDate', this._min_date);
    }

    var self = this;
    $('#date-calendar').datepicker('option', 'maxDate', null);
    $('#date-calendar').datepicker('option', 'onSelect', function() { self._clickGoDate(); });
    $('#ui-datepicker-div').addClass('calendar customize'); /* Themeing */

    $('#date-icon').on('click', function () {
			$('#date-calendar').datepicker('show');
		});

    $('#shift-select').on('change', function() {
      $('.actuals-group').remove();
      $('#loading-row').removeClass('no-display');
      self._lazyLoadActuals();
    });

    var page = Pmp.Client.Static.Page;
    page.AddLiveHandler('.add-reservation-link', 'click', function(event) { self._clickAddReservation(event, this); });
    page.AddLiveHandler('.submit-actual-btn', 'click', function(event) { self._clickSubmitActual(event, this); });
    page.AddLiveHandler('.submit-multiple-actuals-btn', 'click', function(event) { self._clickSubmitMultipleActuals(event, this); });
    page.AddLiveHandler('.price-field', 'keyup', function(event) { self._onKeyUpPriceField(event); });
    page.AddLiveHandler('.reopen-link', 'click', function(event) { self._clickReopenLink(event, this); });
    page.AddLiveHandler('.price-type-select', 'change', function(event) { self._changePriceType(event, this); });
    page.AddLiveHandler('.is-comp-cbox', 'change', function(event) { self._clickCompTableCbox(event, this); });
    page.AddLiveHandler('.is-nomin-cbox', 'change', function(event) { self._clickNoMinTableCbox(event, this); });
    page.AddLiveHandler('.link-to-pos', 'click', function(event) { self._onClickLinkTickets(event, this); })
    page.AddLiveHandler('.unlink-from-pos', 'click', function(event) { self._onClickUnlinkTickets(event, this); })

    $(window).scroll(function(event) { self._onWindowScroll(event); });

    page.AddLiveHandler('.select-multiple-checkbox', 'change', function(event) {
      if ($(this).is(':checked')) {
        $('.submit-checkbox').filter(':visible').prop('checked', true); //.attr('checked', 'checked');
        $('.submit-checkbox').filter(':visible').closest('.form-element').addClass('checked');
      } else {
        $('.submit-checkbox').filter(':visible').prop('checked', false) //.removeAttr('checked');
        $('.submit-checkbox').filter(':visible').closest('.form-element').removeClass('checked');
      }
    });

    page.AddLiveHandler('.sort-link', 'click', function(ev) { self._onClickSortLink(ev, $(this), self); });

    page.AddLiveHandler('#ec-clients-link', 'click', function() { self._toggleExpandClients(); });

  },

  refreshTimeOptions : function () {
    // grab all time options
    this._current_time_options = [];
    for (var persistent_id in this._time_options_by_shift) {
      this._current_time_options.push.apply(this._current_time_options, this._time_options_by_shift[persistent_id]);
    }

  },

  bindEventHandlersAfterActuals : function() {
    this.debug("bindEventHandlersAfterActuals");

    var self = this;
    $('.fname').each(function(i,element) {
      var idx = $(element).attr('id').split('-')[1];
            Pmp.Utils.InputOverlayPrompt('#fname-' +idx+ '-container', '#fname-' +idx+ '-prompt');
            Pmp.Utils.InputOverlayPrompt('#lname-' +idx+ '-container', '#lname-' +idx+ '-prompt');
    })

    self._loadDropDowns();
  },

  _selectedShiftPersistentId: function() {
      var shift_persistent_id = $('#shift-select').val();
      if (shift_persistent_id) {
          return shift_persistent_id;
      } else {
          return undefined;
      }
  },

  _lazyLoadActuals : function() {
      var type_key = 'actuals';
      var shift_persistent_id = this._selectedShiftPersistentId();
      var self = this;
      var completion_callback = function (actuals) {
          self._actuals = actuals;
          self._rebuildActualsIdToIdxMap();
          self._renderActuals();
          self.bindEventHandlersAfterActuals();
          $('#loading-row').addClass('no-display');
          $('.actuals-group').removeClass('no-display');
          $('#shift-select').prop('disabled', false);
      }
      var didStart = LazyActualsService.lazyLoadActuals(
          shift_persistent_id,
          completion_callback,
          true // include canceled
      );
      if (didStart) {
          $('#shift-select').prop('disabled', true);
          this.refreshTimeOptions();
      }
  },

  _rebuildActualsIdToIdxMap : function() {
      for (var idx=0; idx < this._actuals.length; idx++) {
          this._actuals_id_to_idx[this._actuals[idx].id] = idx;
      }
  },

  _group_actuals : function() {
    // !!! Note that this function is replicated in manager_actuals.views for XLS download
    var actual_groups = {
      table_list : [],
      bar_list : [],
      not_completed_list : []
    }
    for (var i=0; i < this._actuals.length; i++) {
      var actual = this._actuals[i];
      if (actual.is_not_completed) {
        actual_groups.not_completed_list.push(actual);
      } else if (actual.system_class === 'BAR') {
        actual_groups.bar_list.push(actual);
      } else {
        actual_groups.table_list.push(actual);
      }
    }
    return actual_groups;
  },

  _renderActuals : function() {
    this.debug('_renderActuals');

    for (var idx = 0; idx < this._actuals.length; idx++) {
      this._actuals[idx].idx = idx;
    }
    this._next_idx = this._actuals.length;
    var actual_groups = this._group_actuals();

    var params = {
      'post_url' : this._post_url,
      'currency_code' : this._currency_code,
      'currency_symbol' : this._currency_symbol,
      'default_phone_locale' : this._default_phone_locale,
      'is_nightlife_class' : this._is_nightlife_class,
      'MEDIA_URL' : this._MEDIA_URL,
      'privilege_level' : this._privilege_level,
      'status_types' : this._status_types,
      'is_comps_enabled' : this._is_comps_enabled,
      'pos_enabled' : this._pos_enabled,
      'time_options' : this._current_time_options
    };

    if (this._is_nightlife_class) {
      params['heading'] = 'Table Service';
    } else {
      params['heading'] = false;
    }
    params['actuals'] = actual_groups.table_list;
    var tables_html = Nightloop.Templates.Manager.RenderActuals(params);

    params['heading'] = 'Bar Service';
    params['actuals'] = actual_groups.bar_list;
    var bar_html = Nightloop.Templates.Manager.RenderActuals(params);

    params['heading'] = 'Not Completed';
    params['actuals'] = actual_groups.not_completed_list;
    var not_completed_html = Nightloop.Templates.Manager.RenderActuals(params);

    $(not_completed_html).insertAfter('#insert-after-here');
    $(bar_html).insertAfter('#insert-after-here');
    $(tables_html).insertAfter('#insert-after-here');
  },

  _loadDropDowns : function() {
    var url = this._manager_base_url + '/actuals/dropdowns';

    if (this._currentday_urlparam) {
      url = url + '?date=' + this._currentday_urlparam;
    }

    var self = this;
    // disable add and save buttons
    $('.add-reservation-link').addClass('disabled');
    $('.submit-actual-btn').addClass('disabled');
    $('.submit-multiple-actuals-btn').addClass('disabled');

    Pmp.Client.AsyncGet(url, function(data) {
      var content = data.payload.content;
      self._table_choices = content.table_choices;
      self._served_by_users = content.served_by_users;
      self._booked_by_users = content.booked_by_users;
      $('.add-reservation-link').removeClass('disabled');

      $('.temp_actual_tableno_container').each(function(i, element){
        var tableNo = $(element).find('.temp_actual_tableno').html();
        tableNo = tableNo.split(',');
        var idx = $(element).find('.temp_actual_tableno_idx').html();

        var dropdown_html = Nightloop.Templates.Manager.ActualTableDropdowns({"table_choices":self._table_choices, "table_id_list":tableNo, "idx":idx, "MEDIA_URL":self._MEDIA_URL});

        $(dropdown_html).insertAfter(element);
        $(element).hide(); //.find('.dropdown-spinner').addClass('no-display');
      });

      $('.temp_actual_servedby_container').each(function(i, element){
        var servedBy = $(element).find('.temp_actual_servedby').html();
        var idx = $(element).find('.temp_actual_servedby_idx').html();
        var has_tickets = $(element).find('.temp_actual_servedby_has_tickets').html() == '1';
        var classes = (has_tickets) ? 'no-display' : '';
        var dropdown_html = Nightloop.Templates.Manager.ServedByDropDown({"choices":self._served_by_users, "selected":servedBy, "idx":idx, "classes":classes});
        $(dropdown_html).insertAfter(element);
        $(element).find('.dropdown-spinner').addClass('no-display');
      });

      $('.temp_actual_bookedby_container').each(function(i, element){
        var bookedBy = $(element).find('.temp_actual_bookedby').html();
        var idx = $(element).find('.temp_actual_bookedby_idx').html();
        var dropdown_html = Nightloop.Templates.Manager.BookedByDropDown({"MEDIA_URL":self._MEDIA_URL, "choices":self._booked_by_users, "selected":bookedBy, "idx":idx});
        $(dropdown_html).insertAfter(element);
        $(element).find('.dropdown-spinner').addClass('no-display');

        var actual_id = $('#actual-row-'+idx).find('.actuals_id_ipt').val();
        var alt_list = null;
        if (actual_id in self._actuals_id_to_idx) {
          var act_idx = self._actuals_id_to_idx[actual_id];
          alt_list = self._actuals[act_idx].booked_by_alt;
        }
        if (alt_list !== null) {
          self._insertBookedByAltList(idx, alt_list);
        }
      });
      $('.add-booked-by-link').removeClass('no-display');
      $('.submit-actual-btn').removeClass('disabled');
      $('.submit-multiple-actuals-btn').removeClass('disabled');
    });
  },

  _insertBookedByAltList : function(idx, booked_by_alt) {
    var alt_list = booked_by_alt;
    var booked_by_list = []
    for (var x=0; x < alt_list.length; x++) {
      if (alt_list[x].user) {
        booked_by_list.push(alt_list[x].user);
      } else if (alt_list[x].venue_promoter) {
        booked_by_list.push(alt_list[x].venue_promoter);
      }
    }

    for(var x=0;x<booked_by_list.length;x++) {
      var booked_by_key = booked_by_list[x];
      this._insertSingleBookedByAlt(booked_by_key, idx);
    }
  },

  _insertSingleBookedByAlt : function(booked_by_key, idx) {
    var options_html = Nightloop.Templates.Manager.BookedByOptions({"choices":this._booked_by_users, "selected":booked_by_key});
    var select_html = "<div><div class='form-element select'><label><p class='input'><select name='booked_by_alt"+idx+"'>" + options_html + "</select><span class='downer'></span></p></label></div><a href='javascript:void(0);' onclick='javascript:Pmp.Manager.Actuals.removeBookedBy(this);' class='closer'>&times;</a></div>";
    $('#insert-booked-by-'+idx).append(select_html);
  },

  clickAddBookedBy : function(el) {
    var idx = $(el).attr('booked_by_idx');
    this._insertSingleBookedByAlt(undefined, idx);
  },

  removeBookedBy : function(link_el) {
    $(link_el).parent().remove();
  },

  _insertSingleTable : function(table_id, idx) {

    var options_html = Nightloop.Templates.Manager.TableNumDropDown({"choices":this._table_choices, "selected":table_id, "idx": idx});
    var select_html = "<div>" + options_html + "&nbsp;<a href='javascript:void(0);' onclick='javascript:Pmp.Manager.Actuals.removeTable(this);' class='closer'>&times;</a></div>";

    $('#insert-table-'+idx).append(select_html);
  },

  clickAddTable : function(el) {
    var idx = $(el).attr('table_idx');
    this._insertSingleTable(undefined, idx);
  },

  removeTable : function(link_el) {
    $(link_el).parent().remove();
  },

  _onWindowScroll : function(event) {
    var leftOffset = ($('#page-actuals').width() - $('#actuals-outer-container').width()) / 2;
    var totalOffset = $(window).scrollLeft() * -1;
    if (leftOffset > 0) {
      totalOffset += leftOffset;
    }
    $('#fixed-res-header').css('left', totalOffset + 'px');

    if ($(window).scrollTop() > $('#relative-res-header').offset().top) {
      $('#fixed-res-header').removeClass('no-display');
    } else {
      $('#fixed-res-header').css('left', 'auto');
      $('#fixed-res-header').addClass('no-display');
    }
  },

  showConfirm : function(idx) {
    $('.actualdrop-confirm').hide();
    $('#actualdrop-confirm-'+idx).show();
  },

  hideConfirm : function(idx) {
    $('#actualdrop-confirm-'+idx).hide();
  },

  deleteActual : function(idx) {
    var actual_row = $('#actual-row-' + idx);
    var is_persisted_record = actual_row.find('input[name=actual_id]').val() !== '';
    if (!is_persisted_record) {
        actual_row.parents('.actual-row-container').remove();
    } else {
      actual_row.find('#is_delete_action').val('true');
      var formObj = actual_row.find('.actual-row-form');
      Pmp.Client.AsyncPost(formObj, function(data){
        actual_row.parents('.actual-row-container').remove();
      });
    }
  },

  _changePriceType : function(event, element) {
    var current_type = $(element).val();
    var min_field = $(element).siblings('.min-field');
    if (current_type == 'dollar') {
      if (!min_field.hasClass('price-field')) {
        min_field.addClass('price-field');
      }
    } else {
      if (min_field.hasClass('price-field')) {
        min_field.removeClass('price-field');
        min_field.val(Pmp.Utils.Currency.StripSymbol(min_field.val(), this._currency_symbol));
      }
    }
  },

  _clickCompTableCbox : function (event, element) {
    var idx = $(element).attr('id').split('-')[2];
    if ($(element).is(':checked')) {
      $('#is-nomin-'+idx).prop('checked', false);
      $('#is-nomin-'+idx).closest('.form-element').removeClass('checked');
      $('#comp-display-'+idx).css('display', 'inline-block');
      $('#nomin-display-'+idx).hide();
      $('#minimum-container-'+idx).hide();
      $('#minimum-'+idx).val(''); // Clear this field out to avoid validation issues
    } else {
      $('#comp-display-'+idx).hide();
      $('#nomin-display-'+idx).hide();
      $('#minimum-container-'+idx).show();
    }
    return false;
  },

  _clickNoMinTableCbox : function (event, element) {
    var idx = $(element).attr('id').split('-')[2];
    if ($(element).is(':checked')) {
      $('#is-comp-'+idx).prop('checked', false);
      $('#is-comp-'+idx).closest('.form-element').removeClass('checked');
      $('#nomin-display-'+idx).css('display', 'inline-block');
      $('#comp-display-'+idx).hide();
      $('#minimum-container-'+idx).hide();
      $('#minimum-'+idx).val(''); // Clear this field out to avoid validation issues
    } else {
      $('#nomin-display-'+idx).hide();
      $('#comp-display-'+idx).hide();
      $('#minimum-container-'+idx).show();
    }
    return false;
  },

  _clickAddReservation : function(event, element) {

    // wait for things to load before adding
    if ($(element).hasClass('disabled')) {
      return;
    }

    this.refreshTimeOptions();

    var self = this;
    $('.no-reservations').hide();
    $('.submit-actls-btn-area').show();
    var html = Nightloop.Templates.Manager.ActualsEditRow({
      'MEDIA_URL' : self._MEDIA_URL,
      'time_options' : self._current_time_options,
      'table_choices' : self._table_choices,
      'served_by_users' : self._served_by_users,
      'booked_by_users' : self._booked_by_users,
      'post_url' : self._post_url,
      'show' : true,
      'currency_code' : self._currency_code,
      'currency_symbol' : self._currency_symbol,
      'idx' : self._next_idx,
      'privilege_level' : self._privilege_level,
      'default_phone_locale' : self._default_phone_locale,
      'is_nightlife_class' : self._is_nightlife_class,
      'pos_enabled' : self._pos_enabled,
      'is_comps_enabled' : self._is_comps_enabled,
      'status_types' : self._status_types
    });

    html = "<div class='actual-row-container'>" + html + "</div>";
    $('#insert-in-here').append(html)

    Pmp.Utils.InputOverlayPrompt('#fname-' +this._next_idx+ '-container', '#fname-' +this._next_idx+ '-prompt');
    Pmp.Utils.InputOverlayPrompt('#lname-' +this._next_idx+ '-container', '#lname-' +this._next_idx+ '-prompt');
    this._next_idx += 1;

    // enforce view state
    self._enforceClientViewState();
  },

  _clickReopenLink : function(event, element) {
    var view_row = $(element).parents('.view-row');
    var edit_row = view_row.siblings('.edit-row');
    view_row.hide()
    edit_row.fadeIn('fast');
    var idx = edit_row.find('.actual_index').val();
    Pmp.Utils.InputOverlayPrompt('#fname-' +idx+ '-container', '#fname-' +idx+ '-prompt');
    Pmp.Utils.InputOverlayPrompt('#lname-' +idx+ '-container', '#lname-' +idx+ '-prompt');
  },

  _clickSubmitMultipleActuals : function(event, ele) {
    if ($(ele).hasClass('disabled')) {
      return;
    }
    var self = this;
    // loop through each checkmarked actual
    $('.submit-checkbox:checked').each(function(idx, el) {
      self._submitOneActual($(el), self);
    });

    $('.select-multiple-checkbox').removeAttr('checked');
  },

  _clickSubmitActual : function(event, ele) {

    if ($(ele).hasClass('disabled')) {
      return;
    }
    var self = this;

    self._submitOneActual($(ele), self);
  },

  _submitOneActual : function(element, self) {
    var row = $(element).closest('.edit-row');
    var row_container = $(element).parents('.actual-row-container');
    var idx = row.find('.actual_index').val();

    if (self._validateRow(row)) {
      var formObj = $(element).closest('form');
      self._showSavingText(formObj);
      Pmp.Client.AsyncPost(formObj, function(data) {
        self._refreshActualRow(idx, data, self, true);
        self._showSubmitText(formObj);
      });
    }
  },

  _showSavingText : function(form) {
    $(form).find('.submit-actual-btn').sext('...');
  },

  _showSubmitText : function(form) {
    $(form).find('.submit-actual-btn').sext('SUBMIT');
  },

  _validateRow : function(row) {
    var valid = true;
    var new_valid = true;
    //validate first name
    var element = row.find('.fname');
    var value = null;
    if (element.length > 0) {
      value = element.val();
      if (value.length <= 0) {
        $(element).addClass('error-outline');
        valid = false;
      } else {
        $(element).removeClass('error-outline');
      }
    }

    // validate partysize
    element = row.find('.people-field');
    if (element.length > 0) {
      value = element.val();
      new_valid = this._validate_partysize(value, element);
      valid = valid && new_valid;
    }

    // validate min / spend
    element = row.find('.min-field');
    if (element.length > 0) {
      value = element.val();
      new_valid = this._validate_price(value, element);
      valid = valid && new_valid;
    }

    element = row.find('.finalbill-field');
    if (element.length > 0) {
      value = element.val();
      new_valid = this._validate_price(value, element);
      valid = valid && new_valid;
    }

    // validate phone
    if (this._privilege_level !== 'ACTUALS') {

      if (!row.find('.private-phone').length) {
        var phone_element = row.find('.input-phone');
        var phone_locale = row.find('.select-phone-locale').val();
        new_valid = this._validate_phone_number(phone_element, phone_locale);
        valid = valid && new_valid;
      }
      // validate email
      if (!row.find('.private-email').length) {
        element = row.find('.email');
        value = element.val();
        new_valid = this._validate_email(value, element);
        valid = valid && new_valid;
      }
    }

    return valid;
  },

  _validate_price : function(price, inputElement) {

    price = Pmp.Utils.Currency.RawDecimal(price, this._currency_symbol);
    var valid = price.match(/^[0-9\.]*$/) !== null; // verify decimal or empty
    if (!valid) {
      inputElement.addClass('error-outline');
    } else {
      inputElement.removeClass('error-outline');
    }
    return valid;
  },

  _validate_email : function(email, inputElement) {
    // Validate email format - see dbproperties.py
    var valid = true;
    if (email.length > 0) {
        valid = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]([a-z0-9-]*[a-z0-9])?$/gi.test(email);
    }
    if (!valid) {
      inputElement.addClass('error-outline');
    } else {
      inputElement.removeClass('error-outline');
    }
    return valid;
  },

  _validate_partysize : function(partysize, inputElement) {
      var valid = true;
      if (partysize.length > 0) {
          if (isNaN(partysize)) {
              valid = false;
          } else {
              partysize = parseFloat(partysize);
              if (partysize <= 0) {
                  valid = false
              }
          }
      }
      if (!valid) {
      inputElement.addClass('error-outline');
  } else {
      inputElement.removeClass('error-outline');
    }
      return valid;
  },

  _validate_phone_number : function(phone_element, phone_locale) {
    var isValid = true
    var phone = $.trim(phone_element.val());
    if (phone !== '') {
      isValid = Pmp.Common.Reservation.Validator.isValidPhone(phone, phone_locale);
    }

    if (isValid) {
      phone_element.removeClass('error-outline');
    } else {
      phone_element.addClass('error-outline');
    }

    return isValid;
  },

  _onKeyUpPriceField : function(event) {
    var input = $(event.target);
    Pmp.Utils.Currency.AddSymbolToElementVal(input, this._currency_symbol);
  },

  _clickGoDate : function() {
    if (this._validateCalendar()) {
      var date_url = this.getDateForUrl();
      var relativeUrl = this._manager_base_url + "/actuals/"+ date_url;
      Pmp.Client.Static.Page.Navigate('#nightloop', relativeUrl, undefined, true);
    }
  },

  _validateCalendar : function() {
    $('#date-calendar.error-outline').removeClass('error-outline');
    var isValid = true;
    if ($('#date-calendar').datepicker('getDate') === null) {
      isValid = false;
      $('#date-calendar').addClass('error-outline');
    }
    return isValid;
  },

    getDateForUrl : function() {
    var from_date = $('#date-calendar').datepicker('getDate');
    return $.datepicker.formatDate('mm-dd-yy', from_date);
  },


  _onClickSortLink : function(event, element, self) {
    var row_class = '.actual-row-container';
    var self = this;

    self._refreshSortData();

    var sort_key = $(element).attr('sort_key');
    var sort_attr = 'sort_' + sort_key;
    var sort_order = 'asc';
    var prev_down_sort_key = $('.down-arrow:visible:first').parent().attr('sort_key');

    if (prev_down_sort_key === sort_key) {
      sort_order = 'desc';
    }

    $('.sort-link .up-arrow').addClass('no-display');
    $('.sort-link .down-arrow').addClass('no-display');
    if (sort_order === 'asc') {
      $('.sort-link[sort_key=' + sort_key + '] .down-arrow').removeClass('no-display');
    } else {
      $('.sort-link[sort_key=' + sort_key + '] .up-arrow').removeClass('no-display');
    }

    var iAsc = sort_order=='asc'?1:-1;
    var sort_fn = function(a, b) {
      var sA = $.trim(a.s), sB = $.trim(b.s);
      // Always bubble empty rows to the bottom
      if (sA === '') {
        return 1;
      } else if (sB === '') {
        return -1;
      }
      // Sort numbers
      fA = parseFloat(sA, 10);
      fB = parseFloat(sB, 10);
      if (!isNaN(fA) && !isNaN(fB)) {
        return iAsc*(fA<fB?-1:(fA>fB?1:0));
      }
      return iAsc*(sA<sB?-1:(sA>sB?1:0));
    };

    this.debug('_onClickSortLink: '+ sort_key + ', ' + sort_order);

    $('.actuals-group-sortable-area').each(function(idx, el) {
      $(row_class, $(el)).tsort({attr:sort_attr, order:sort_order, sortFunction:sort_fn});
    });
  },


  _refreshSortData : function() {
    $('.actual-row-container').each(function(idx,el) {
      //name
      var first_name = $(this).find('input.fname').val();
      var last_name = $(this).find('input.lname').val();
      $(this).attr('sort_name', first_name.toLowerCase() + last_name.toLowerCase());
      //booked by
      var bookedby_name = $(this).find('.booked-by-dropdown option:selected').sext();
      $(this).attr('sort_via', bookedby_name.toLowerCase());

      // table no
      var tableno = $(this).find('.tableno-dropdown option:selected').sext();
      $(this).attr('sort_table', tableno.toLowerCase());
    });
  },

  _enforceClientViewState : function () {
    if ($('#relative-res-header .col-phone').is(':visible')) {
      this._expandClients(true);
    }
  },

  _toggleExpandClients : function () {
    if ($('.col-phone').is(':visible')) {
      this._hideClients(false);
    } else {
      this._expandClients(false);
    }
  },

  _hideClients : function (do_not_toggle_width) {
    var outer = $('#actuals-outer-container');
    var title = $('#actuals-title');
    var ec = $('.ec-client');
    var pg = $('#page-actuals');
    var outer_width = outer.width();
    var title_width = title.width();
    var ec_width = ec.width();
    var pg_width = pg.width();
    var width = $('.col-email').width() + $('.col-phone').width();
    $('.col-phone').hide();
    $('.col-email').hide();
    if (!do_not_toggle_width) {
      outer.width(outer_width - width);
      title.width(title_width - width);
      ec.width(ec_width - width);
      pg.width(pg_width - width);
    }
    $('#ec-client-right-arrow').removeClass('no-display');
    $('#ec-client-left-arrow').addClass('no-display');
  },

  _expandClients : function (do_not_toggle_width) {
    var outer = $('#actuals-outer-container');
    var title = $('#actuals-title');
    var ec = $('.ec-client');
    var pg = $('#page-actuals');
    var outer_width = outer.width();
    var title_width = title.width();
    var ec_width = ec.width();
    var pg_width = pg.width();
    //var width = 350;
    $('.col-phone').show();
    $('.col-email').show();
    var width = $('.col-email').width()
              + $('.col-phone').width();
    if (!do_not_toggle_width) {
      outer.width(outer_width + width);
      title.width(title_width + width);
      ec.width(ec_width + width);
      pg.width(pg_width + width);
    }
    $('#ec-client-right-arrow').addClass('no-display');
    $('#ec-client-left-arrow').removeClass('no-display');
  },

  _refreshActualRow : function(idx, data, self, show_view) {
    self.refreshTimeOptions();
    var row_container = $('#actual-row-'+idx).parents('.actual-row-container');
    // use response data to turn edit row into view row
    var newViewRowHtml = Nightloop.Templates.Manager.ActualsViewRow({
      'MEDIA_URL' : data.payload.MEDIA_URL,
      'actual' : data.payload.content.actual,
      'show' : false,
      'privilege_level' : self._privilege_level,
      'is_nightlife_class' : self._is_nightlife_class,
      'is_comps_enabled' : self._is_comps_enabled
    });
    var newEditRowHtml = '';
    newEditRowHtml = Nightloop.Templates.Manager.ActualsEditRow({
      'MEDIA_URL' : data.payload.MEDIA_URL,
      'actual' : data.payload.content.actual,
      'time_options' : self._current_time_options,
      'table_choices' : self._table_choices,
      'served_by_users' : self._served_by_users,
      'booked_by_users' : self._booked_by_users,
      'post_url' : self._post_url,
      'show' : false,
      'currency_code' : self._currency_code,
      'currency_symbol' : self._currency_symbol,
      'privilege_level' : self._privilege_level,
      'default_phone_locale' : self._default_phone_locale,
      'is_nightlife_class' : self._is_nightlife_class,
      'pos_enabled' : self._pos_enabled,
      'status_types' : self._status_types,
      'is_comps_enabled' : self._is_comps_enabled,
      'idx' : idx
    });
    row_container.html(newViewRowHtml + newEditRowHtml);
    Pmp.Utils.InputOverlayPrompt('#fname-' +idx+ '-container', '#fname-' +idx+ '-prompt');
    Pmp.Utils.InputOverlayPrompt('#lname-' +idx+ '-container', '#lname-' +idx+ '-prompt');
    $('#add-booked-by-link-'+idx).removeClass('no-display');
    self._insertBookedByAltList(idx, data.payload.content.actual.booked_by_alt);
    if (show_view) {
      row_container.find('.view-row').fadeIn('fast');
    } else {
      row_container.find('.edit-row').fadeIn('fast');
    }

    self._enforceClientViewState();
  },

  initLinkTickets : function(actual_row_id) {
    var self = this;
    $('.link').on('click', function() {
      var actual_id = $('.actual_id').val();
      var date_str = $('.date_str').val();
      var check_row = $(this).closest('.check-row');
      var ticket_ids;
      if (check_row.length > 0) {
        ticket_ids = check_row.attr('check_id');
      } else {
        ticket_ids = $('.tickets-input').val();
      }
      self._reloadLinkTicketsPopup(date_str, actual_id, actual_row_id,
        ticket_ids, undefined, true);
    });
    $('.unlink').on('click', function() {
      var actual_id = $('.actual_id').val();
      var date_str = $('.date_str').val();
      var unlink_check_id = $(this).parents('.check-row').attr('check_id');
      self._reloadLinkTicketsPopup(date_str, actual_id, actual_row_id,
        undefined, unlink_check_id, true);
    });
  },

  _reloadLinkTicketsPopup : function(date_str, actual_id, actual_row_id, ticket_ids, unlink_check_id, refresh_row) {
    var self = this;
    var url = this._manager_base_url + '/actuals/' + date_str + '/linktickets';
    url = url + "?actual_id=" + actual_id;
    var is_post = (unlink_check_id != undefined) || (ticket_ids != undefined);
    var data = {
      'actual_id' : actual_id,
      'unlink_check_id' : unlink_check_id,
      'ticket_ids' : ticket_ids
    };
    $.ajax({
      url: url,
      method: (is_post) ? 'post' : 'get',
      data:data,
      success: function(response) {
        var actual = response.payload.content.actual;
        var suggested_tickets = response.payload.content.suggested_tickets;
        var html = Nightloop.Templates.Manager.LinkTicketsPopup({
                'actual_row_id': actual_row_id,
                'date_urlparam' : date_str,
                'actual' : actual,
                'suggested_checks': suggested_tickets});
        Pmp.Main.Popup.Base.loadHtml(html);
        Pmp.Main.Popup.Base.showPopup();
        if (refresh_row) {
            self._refreshActualRow(actual_row_id, response, self, false);
        }
      }
    });
  },

  _onClickLinkTickets : function(event, link_el) {
    var self = this;
    var actual_row = $(link_el).parents('.actual-row');
    var actual_row_id = actual_row.find('.actual_index').val();
    var actual_id = actual_row.find('.actuals_id_ipt').val();
    var date_str = this._currentday_urlparam;
    this._reloadLinkTicketsPopup(date_str, actual_id, actual_row_id);
  }
};
