// This file was automatically generated from manager.manage.trackinglinks.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Marketing == 'undefined') { Nightloop.Templates.Manager.Marketing = {}; }


Nightloop.Templates.Manager.Marketing.TrackingLinks = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Marketing.TrackingLinksBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Marketing.TrackingLinksBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Marketing.Sidebar(soy.$$augmentData(opt_data, {subcategory: 'tracking_links'}), output);
  output.append('<input type="hidden" id="_venue_id" value="', soy.$$escapeHtml(opt_data.venue.url_key_or_id), '" /><input type="hidden" id="_media_url" value="', soy.$$escapeHtml(opt_data.MEDIA_URL), '" />', (opt_data.venue_settings.event_widget_enabled) ? '<input type="hidden" id="_event_widget_enabled" />' : '', (opt_data.venue_settings.dining_widget_enabled) ? '<input type="hidden" id="_dining_widget_enabled" />' : '', (opt_data.venue_settings.new_res_widget_enabled) ? '<input type="hidden" id="_new_reservation_widget_enabled" />' : '', (opt_data.venue_settings.force_redirects_on_legacy_widget) ? '<input type="hidden" id="_force_redirects_on_legacy_widget" />' : '', '<!-- ', soy.$$escapeHtml(opt_data.venue), ' --><div id="page-tracking_links" class="page-view-mode has-sidebar"><div id="tracking-links"></div><script type="text/javascript">var venueProfileInit = {venueId: "', soy.$$escapeHtml(opt_data.venue.id), '"}<\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/manager/trackinglinks.js']), '"><\/script></div>');
  return opt_sb ? '' : output.toString();
};
