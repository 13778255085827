
var ClientGroupEdit = {

  init : function(baseUrl, clientGroupId) {
    this.baseUrl = baseUrl;
    this.followers = new Followers();
    this.followers.init(this.baseUrl, $('#client-edit'), false);
    this.loadBookedBy();
  },

  loadBookedBy : function () {
    var that = this;
    BookedByService.getByVenue(this.baseUrl, function(response) {
      that._bookedByContent = response.content;
      that.followers.refreshFollowersSelect(that._bookedByContent.booked_by_users);
    },undefined, // error
    true); // cache
  }

};
