// This file was automatically generated from manager.home.coverflow.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Home == 'undefined') { Nightloop.Templates.Manager.Home = {}; }


Nightloop.Templates.Manager.Home.Coverflow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Home.CoverflowBit', skip_nav: true}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Home.CoverflowBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="coverflow-content-wrapper"></div><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/manager.coverflow.js']), '"><\/script><script type="text/javascript">const shifts = ', opt_data.shifts_json, '; const data = ', opt_data.data_json, '; const MEDIA_URL = \'', soy.$$escapeHtml(opt_data.MEDIA_URL), '\';\n        $(document).ready(() => {\n            modules.coverflow.helpers.displayCoverFlow(\n                data.manager_base_url,\n                shifts,\n                data.currentday_urlparam,\n                MEDIA_URL,\n                \'Print\',\n                data.shift_persistent_id,\n                data.selected_area,\n                data.mode,\n            );\n        })\n        <\/script>');
  return opt_sb ? '' : output.toString();
};
