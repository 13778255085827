// This file was automatically generated from manager.manage.daysofoperation.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }
if (typeof Nightloop.Templates.Manager.Manage.DaysOfOperation == 'undefined') { Nightloop.Templates.Manager.Manage.DaysOfOperation = {}; }


Nightloop.Templates.Manager.Manage.DaysOfOperation.Edit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.DaysOfOperation.EditBit', full_width: true}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.DaysOfOperation.EditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'days_of_operation'}), output);
  output.append('<div id="page-daysofoperation" class="has-sidebar edit-page">', (opt_data.content.success) ? '<p class="global-message success fade">Changes saved.</p>' : '', (opt_data.content.error) ? '<p class="global-message error fade">' + soy.$$escapeHtml(opt_data.content.error) + '</p>' : '', '<div id="main-area"><div id="content-header"><h2>Edit Days of Operation</h2></div><form class="styled" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/daysofoperation" method="post"><div id="fixed-days-checkboxes-placeholder" class="no-display">&nbsp;</div><div id="days-checkboxes" class="sans edit"><div id="days-open-box" class="day-row"><p class="title">Days Venue Open</p><div class="days inline">');
  Nightloop.Templates.Manager.Manage.DaysOfOperation.EditDaysOpenDay(soy.$$augmentData(opt_data, {isOpen: opt_data.venue.days_of_operation[6], dowIndex: 6, dowName: 'Sun'}), output);
  Nightloop.Templates.Manager.Manage.DaysOfOperation.EditDaysOpenDay(soy.$$augmentData(opt_data, {isOpen: opt_data.venue.days_of_operation[0], dowIndex: 0, dowName: 'Mon'}), output);
  Nightloop.Templates.Manager.Manage.DaysOfOperation.EditDaysOpenDay(soy.$$augmentData(opt_data, {isOpen: opt_data.venue.days_of_operation[1], dowIndex: 1, dowName: 'Tue'}), output);
  Nightloop.Templates.Manager.Manage.DaysOfOperation.EditDaysOpenDay(soy.$$augmentData(opt_data, {isOpen: opt_data.venue.days_of_operation[2], dowIndex: 2, dowName: 'Wed'}), output);
  Nightloop.Templates.Manager.Manage.DaysOfOperation.EditDaysOpenDay(soy.$$augmentData(opt_data, {isOpen: opt_data.venue.days_of_operation[3], dowIndex: 3, dowName: 'Thu'}), output);
  Nightloop.Templates.Manager.Manage.DaysOfOperation.EditDaysOpenDay(soy.$$augmentData(opt_data, {isOpen: opt_data.venue.days_of_operation[4], dowIndex: 4, dowName: 'Fri'}), output);
  Nightloop.Templates.Manager.Manage.DaysOfOperation.EditDaysOpenDay(soy.$$augmentData(opt_data, {isOpen: opt_data.venue.days_of_operation[5], dowIndex: 5, dowName: 'Sat'}), output);
  output.append('</div></div></div><div class="box"><p class="button"><a class="" id="save-daysofoperation" href="javascript:void(0);">Save changes</a></p></div></form></div><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Manage.DaysOfOperation.Edit.initialize();})<\/script></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.DaysOfOperation.EditDaysOpenDay = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Base.FormElement({name: ((opt_data.prefix) ? soy.$$escapeHtml(opt_data.prefix) + '_' : '') + 'day_open_' + soy.$$escapeHtml(opt_data.dowIndex), label: opt_data.dowName, value: 'true', type: 'checkbox', checked: opt_data.isOpen, disabled: opt_data.isRestricted, classes: ((opt_data.prefix) ? soy.$$escapeHtml(opt_data.prefix) + '-' : '') + 'day-open-checkbox ' + ((opt_data.prefix) ? soy.$$escapeHtml(opt_data.prefix) + '-' : '') + 'day-open-checkbox-' + soy.$$escapeHtml(opt_data.dowIndex), attributes: 'dow_index=' + soy.$$escapeHtml(opt_data.dowIndex)}, output);
  return opt_sb ? '' : output.toString();
};
