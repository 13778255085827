// This file was automatically generated from manager.manage.tablecombos.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.TableCombos = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.TableCombosBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.TableCombosBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'table_combos'}), output);
  output.append('<div id="page-table-combos" class="has-sidebar"><div id="main-area"></div></div><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/common.js']), '"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/table_combos.js']), '"><\/script><script type="text/javascript">React.render(React.createElement(TableCombos.App,{\'url_key\': \'', soy.$$escapeHtml(opt_data.venue.url_key), '\', \'media_url\': \'', soy.$$escapeHtml(opt_data.MEDIA_URL), '\'}), document.getElementById(\'main-area\'));<\/script>');
  return opt_sb ? '' : output.toString();
};
