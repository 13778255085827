var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Manage = Pmp.Manager.Manage || {};

Pmp.Manager.Manage.VenueServersEdit = {
	initialize : function() {
		this._bindEventHandlers();
		$('.message').show().delay(4000).fadeOut(1000);
		$('.error-message').show().delay(4000).fadeOut(1000);
	},
	
	log : function(msg) {
		console.log("Pmp.Manager.Manage.VenueServersEdit: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("bindEventHandlers");

		var self = this;
		var clickAddNewServerNameFn = function(event) {self._clickAddNewServerName(event);};
		var clickDeleteServerNameFn = function(event) {self._clickDeleteServerName(event);};
		var clickSubmitFn = function(event) {self._clickSubmit(event);return false;};
		var keyupServerNameFn = function(event) {self._keyupServerName(event);};
		
		$('#add-new-server-name').click(clickAddNewServerNameFn);
		$('#venueservers-submit-btn').click(clickSubmitFn);

        var page = Pmp.Client.Static.Page;
		page.AddLiveHandler('.venue-server-delete-link', 'click', clickDeleteServerNameFn);
		page.AddLiveHandler('.server-name-input', 'keyup', keyupServerNameFn);
	},
	
	_clickAddNewServerName : function(event) {
		var new_html = $('.venue-server-name-clone').html();
	    var new_record = $(new_html);
	    new_record.removeClass('venue-server-name-clone');
	    $('.servernames-list').append(new_record);
	},
	
	_clickDeleteServerName : function(event) {
		var link = $(event.target);
		var row = link.parents('.venue-server-name');
		row.remove();
	},
	
	_clickSubmit : function(event) {
	    if (this._validate()) {
	  	    $('#manager-ajax').show();
			    $('#venueservers-submit-btn').sext('Saving...');

	        $('#venueservers-edit-form').submit();
        }
	},
	
	_keyupServerName : function(event) {
		var input = $(event.target);
		input.removeClass('invalid');
	},
	
	_validate : function() {
		var isValid = true;
		
		$('.server-name-input').each(function() {
			$(this).removeClass('invalid');
			if ($(this).parents('.new-inp').length !== 0) {
				return;
			}
			
			$(this).val($.trim($(this).val()));
			if ($(this).val() == '') {
				isValid = false;
				$(this).addClass('invalid');
			}
		});
		
		if (!isValid) {
			$('#validation-error-message').show().delay(4000).fadeOut(1000);
		}
		
		return isValid;
	}
};

