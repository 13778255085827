
var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Manage = Pmp.Manager.Manage || {};
Pmp.Manager.Manage.Inventory = Pmp.Manager.Manage.Inventory || {};
Pmp.Manager.Manage.Inventory.FloorplanRooms = Pmp.Manager.Manage.Inventory.FloorplanRooms || {};

Pmp.Manager.Manage.Inventory.FloorplanRooms.Edit = {
	initialize : function() {
		this.bindEventHandlers();
	},
	
	log : function(msg) {
		console.log("Pmp.Manager.Manage.Inventory.FloorplanRooms.Edit: "+ msg);
	},
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	bindEventHandlers : function() {
		this.debug("Binding click handlers");
		var self = this;
		var addNewTableFn = function(event) {self.addNewTable(event);};
		var clickSubmitBtnFn = function(event) {self.clickSubmitBtn(event); return false;};
		var keyupInputFn = function(event) {self.keyupInput(event, $(this));};

		$('#add-new-button').click(addNewTableFn);
		$('#floorplanrooms-submit-btn').click(clickSubmitBtnFn);
		Pmp.Client.Static.Page.AddLiveHandler('#floorplanrooms-box input', 'keyup', keyupInputFn);

	    Pmp.Utils.AddDropdown('#contextual-link-seatarea-order', '#contextual-content-seatarea-order');
	},
	
	addNewTable : function (event) {
	    var is_even = $('.table-row:last').hasClass('odd');
	    var next_sort_order = $('.table-row').length + 1;
	    $('.sort_order').each(function(i, e){
	    	var order = parseInt($(e).val(), 10);
	    	if (order >= next_sort_order) {
	    		next_sort_order = order + 1;
	    	}
	    });
	    var html = Nightloop.Templates.Manager.Manage.Inventory.FloorplanRoomsRow({
	    	floorplan_room : {
		    	id: '',
	    		code: '',
		    	name: '',
		    	sort_order: next_sort_order
	    	},
	    	is_even : is_even
	    });
	    $(html).insertBefore($('#add-new-tables-here'));
    },

    deleteTable : function (anchorObj) {
        // remove row
        $(anchorObj).closest('.table-row').remove();

        // Fix even/odd display
		$('.table-row').each(function(i, element) {
			if (i % 2 == 0) {
				$(element).removeClass('even');
				$(element).addClass('odd');
			} else {
				$(element).removeClass('odd');
				$(element).addClass('even');
			}
		});
	},
	
	keyupInput : function(event, element) {
		element.removeClass('invalid');
	},
	
	validate : function() {
		var error_msg = '';
		
		var hasCodeError = false;
		$('#floorplanrooms-box input.code').each(function (i, element) {
			if ($(element).val() === '') {
				if (!hasCodeError) {
					error_msg += 'Please provide an abbreviation for every floorplan room. ';
					hasCodeError = true;
				}
				$(element).addClass('invalid');
			}
		});
		
		var hasNameError = false;
		$('#floorplanrooms-box input.name').each(function (i, element) {
			if ($(element).val() === '') {
				if (!hasNameError) {
					error_msg += 'Please provide a name for every floorplan room. ';
					hasNameError = true;
				}
				$(element).addClass('invalid');
			}
		});
		
		var hasOrderError = false;
		$('#floorplanrooms-box input.sort_order').each(function (i, element) {
			var order = $(element).val();
			if (! /^[0-9]+$/.test(order)) {
				if (!hasOrderError) {
					error_msg += 'List Order must be a number. ';
					hasOrderError = true;
				}
				$(element).addClass('invalid');
			}
		});
		
		var hasDupeOrderError = false;
		var orderHash = {};
		$('#floorplanrooms-box input.sort_order').each(function (i, element) {
			var order = $(element).val();
			if (orderHash[order] !== undefined) {
				if (!hasDupeOrderError) {
					error_msg += 'List Order cannot contain duplicates. ';
					hasDupeOrderError = true;
				}
				$(element).addClass('invalid');
				$(orderHash[order]).addClass('invalid');
			}
			orderHash[order] = $(element);
		});
		
		if (hasCodeError || hasNameError || hasOrderError || hasDupeOrderError) {
			$('#validation-error-message').sext(error_msg);
			$('#validation-error-message').show().delay(4000).fadeOut(1000);
			return false;
		} else {
			return true;
		}
	},
	
	clickSubmitBtn : function(event) {
		if (this.validate()) {
			$('#floorplanrooms-submit-form').submit();
		}
	}
};
