
var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Manage = Pmp.Manager.Manage || {};
Pmp.Manager.Manage.Perks = Pmp.Manager.Manage.Perks || {};

Pmp.Manager.Manage.Perks.List = {
	initialize : function() {
		this._bindEventHandlers();
	},
	
	log : function(msg) {
		console.log("Pmp.Manager.Manage.Perks.List: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		
	    Pmp.Utils.AddDropdown('#contextual-link-perks', '#contextual-content-perks');
	}
};