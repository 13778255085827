$(function() {
  $('#book-guest').on('click', function(e) {
    metric.track('Guestlist.addGuest', {'page': 'guestlist'});
    GuestlistSlideOut.showGuestlist(undefined);
    return false;
  });

  var handle_error = function(data) {
    alert('Whoops! Something went wrong. Please try again in a minute.');
  }

  var handle_success = function(data) {

	// NOTE: KP - this seems broken. guestlist can paginate so inserting it on the current page
	// doesn't seem to make any sense. need to check for existence otherwise refresh.

    var _new = $('#main-interface').hasClass('new-reservation');

    var html = Nightloop.Templates.Manager.Guestlist.GuestListRow({
      'actual' : data.payload.actual,
      'just_added' : (_new ? true : false),
      'current_user_id' : data.payload.request.user.id
    });

    var el = $(html);

    if( _new ) {
      $('#guestlist-block').prepend(el);
    } else {
      $('.guestlist-row').filter(function() {
        return $(this).attr('actualid') === data.payload.actual.id;
      }).replaceWith(el);
    }
    $('p.no-content').hide();
    $('#close-interface').trigger('click');

    el.animate({backgroundColor: '#ffffff'}, 5000);
  };

  var handle_delete = function(data) {

    $('.guestlist-row').filter(function() {
      return $(this).attr('actualid') == data.payload.actual.id;
    }).remove();
    $('#close-interface').trigger('click');

  }

  $('#submit-guest-add').on('click', function(e) {
    if (!Pmp.Manager.Guestlist.Day.validator.validate()) {
      return;
    }
    Interface.sendform(e, handle_success, handle_error);
  });

  $('#save-guest-button').on('click', function(e) {
    if (!Pmp.Manager.Guestlist.Day.validator.validate()) {
      return;
    }
    $('#id_modified').prop('disabled',false);
    $('#id_dodelete').prop('disabled', true);
    Interface.sendform(e, handle_success, handle_error);
  });

  $('#submit-guest-delete').on('click', function(e) {
    $('#id_modified').prop('disabled',true);
    $('#id_dodelete').prop('disabled', false);
    Interface.sendform(e, handle_delete, handle_error);
  });

  // Guestlist form is wonky, so here we go
  $('body').on('click', '.guestlist-row', function(e){
      var $row = $(this)
        , id = $row.attr('actualid');
      $('.list-block .standard-row').removeClass('selected');
      $row.addClass('selected');

      var disable_edits = Pmp.Manager.Guestlist.Day._can_modify_only_my_own_guestlist && ($row.attr('booked_by_me') != '1');

      GuestlistSlideOut.showGuestlist(id, $row, disable_edits);
  });
  $('#close-interface').click(function(event) {
    $('.list-block .standard-row').removeClass('selected');
  });

  $('ul.submenu > li > a[booked_by_id]').on('click', function(event) {
	$(this).parents('.submenu').find('a').removeClass('selected');
	$(this).addClass('selected');
    var booked_by_id = $(this).attr('booked_by_id');
    var booker = $('#booked-by-filter').val(booked_by_id);
    $('#status-filter').val('');
    Pmp.Manager.Guestlist.Day._filterList();
  });

});

var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Guestlist = Pmp.Manager.Guestlist || {};

Pmp.Manager.Guestlist.Day = {
	initialize : function(
    managerBaseUrl,
    dateUrlParam,
    todayDate,
    muni_today_date,
	  media_url,
	  is_live_day,
    starting_index,
    num_res_pages,
    num_guestlist_pages,
    can_view_guestlist,
    can_modify_only_my_own_guestlist,
	  locale,
    is_nightlife_class,
    is_last_name_first)
    {

		var self = this;
		var destroyerFn = function() { self.destroy(); };
		Pmp.Client.Static.Factory.Register(Nightloop.Templates.Manager.Guestlist.Day,
                null, destroyerFn);

		this._managerBaseUrl = managerBaseUrl;
		this._dateUrlParam = dateUrlParam;
		this._doRefreshOnClose = false;
		this._media_url = media_url;
		this._is_live_day = is_live_day;
		this._ms_per_day = 24*60*60*1000;
		this._today_date = $.datepicker.parseDate('mm/dd/y', todayDate);
		this._muni_today_date = muni_today_date;
		this._num_res_pages = num_res_pages;
		this._num_guestlist_pages = num_guestlist_pages;
		this._can_view_guestlist = can_view_guestlist;
		this._can_modify_only_my_own_guestlist = can_modify_only_my_own_guestlist;
		this._locale = locale;
		this._is_nightlife_class = is_nightlife_class;
		this._actual_row_index = starting_index;

		this._bindHandlers();
		this._hideMessageOnDelay();

		this.validator = new sr.Validator($('#main-interface'), this._currency_symbol);

	},

	log : function(msg) {
		console.log("Pmp.Manager.Guestlist.Day: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	destroy : function() {
		this.debug('destroying');
		$(document).unbind('mouseup');
	},

	_hideMessageOnDelay : function() {
		if ($('.gold-message')) {
			$('.gold-message').delay(5000).slideUp(1000);
		}
	},

	_bindHandlers : function() {
		this.debug("bindClickHandlers");
		var self = this;

		var changeClientProfileSearchFn = function(event) {self._changeClientProfileSearch(event);};
		var clickSelectClientProfileFn = function(event) {self._clickSelectClientProfile(event, $(this)); };
		var clickDeselectClientProfileFn = function(event) {self._clickDeselectClientProfile(event); };

		var onClickExpandGuestlistBlockFn = function(event) {self._onClickExpandGuestlistBlock(event);};
		var onClickCollapseGuestlistBlockFn = function(event) {self._onClickCollapseGuestlistBlock(event);};
		var onClickSortLink = function(event) {self._onClickSortLink(event, $(this));};
		var onClickGuestlistRowFn = function(event) {self._onClickGuestlistRow(event);};

    // blackout date
    $('#blackout-toggle-link').on('click', function () {
      var toggle = $('#blackout-toggle-link span.blackout');
      var enabled = toggle.hasClass('enabled');
      if (enabled) {
        var deferred = BlackoutDateService.unblackoutDateGuestlist(
          self._managerBaseUrl,
          self._dateUrlParam
        );
        deferred.done(function (isEnabled) {
          $('.ui-datepicker-current-day').removeClass('blacked-date');
          toggle.removeClass('enabled');
          $('p.blackout').hide();
          metric.track('Guestlist.toggleBlackoutDate.disabled', {'page': 'guestlist'});
        });
      } else {
        var deferred = BlackoutDateService.blackoutDateGuestlist(
          self._managerBaseUrl,
          self._dateUrlParam
        );
        deferred.done(function (isEnabled) {
          $('.ui-datepicker-current-day').addClass('blacked-date');
          toggle.addClass('enabled');
          $('p.blackout').show();
          metric.track('Guestlist.toggleBlackoutDate.enabled', {'page': 'guestlist'});
        });
      }
    });


		var page = Pmp.Client.Static.Page;

    $('#filter-button').on('click', function(event) {
      $('.filter-dropdown-container').hide();
      self._filterList();
    });

    $('#search-button').on('click', function(event) {
      self._searchList();
    });
    $('#guest-search-input').on('keyup', function(event) {
      if( $(this).val().length >= 3 || $(this).val().length == 0) {
        self._searchList();
      }
    });
    $('#guest-search-input').on('focus', function(event) {
      $('.filter-dropdown-container').hide();
    });

    // Adapting mobile search
    $('#guestlist-search').on('blur', function() {
      self._searchList(true);
    });
    $('#search-action-form').on('submit', function() {
      $('#guestlist-search').trigger('blur');
    });

    $('#clear-button, #clear-guest-search').on('click', function(event) {
      $('.filter-dropdown-container').hide();
      self._clearParams(event);
    });

    $('.pagination a').on('click', function(event) {
      event.preventDefault();
      const className = event.currentTarget.className;
      if (!className.includes('disabled')) {
        self._switchPage(className);
      }
    });

    $('#show-filters-dropdown-btn,.filter-nevermind-link').on('click', function() {
      $('.filter-dropdown-container').toggle();
    });
    $('body').on('click', function(event) {
      const $click = $(event.target);
      if( !$click.closest('.filter-dropdown-container,#show-filters-dropdown-btn').length ) {
        $('.filter-dropdown').hide();
      }
    });

    $('.sort-link').on('click', function() {
      var $this = $(this)
        , sorter = $this.attr('sort_key')
        , order = ($this.hasClass('desc') ? 'asc' : 'desc');

      $('.sort-link').removeClass('desc asc');

      if( sorter != self.parameters.sort_field ) {
        order = 'asc';
      }

      $this.addClass(order);

      self.parameters.sort_field = sorter;
      self.parameters.sort_order = order;
      self.parameters.cursor = null;
      self.parameters.prev_cursors = [];
      self.parameters.page = 0;
      self._sendParameters();
    });

   	$('#expand-guestlist-block').click(onClickExpandGuestlistBlockFn);
    $('#collapse-guestlist-block').click(onClickCollapseGuestlistBlockFn);

		// lazy load more reservations/guestlist
		// this._lazyLoadGuestlist();
		this._sendParameters();

	},



	_getNumGuestsDisplay : function(actuals) {
		var count = 0;
		for (var i = 0; i < actuals.length; i++){
			var totalGuests = actuals[i].total_guests;
			if (totalGuests === '') {
				totalGuests = 0;
			}
			count += parseInt(totalGuests);
		}
		if (count == 1) {
			return count + ' guest';
		}
		return count + ' guests';
	},

	_lazyLoadGuestlist : function() {
		if (this._can_view_guestlist === false) {
			return;
		}

		// show spinning icon in place of link
		$('#loading-guestlist-row').fadeIn('fast');

		this._num_guestlist_pages_received = 0;
		var url = this._managerBaseUrl + '/home/day/guestlist/' + this._dateUrlParam;
		url += '?type_key=view&fae=y&page=0&sort_order=asc';
		var self = this;
		Pmp.Client.AsyncGet(
      url,
	    function(data) {
        self._populateMoreGuestlist(data.payload);
      });
	},

  // The gl state object
  parameters: {
    filters: false,
    query: false,
    current: 0,
    sort_order: 'asc',
    sort_field: 'client_name',
    total: 0
  },


  // Will eventually need to localize this is guest/rezzie share a page
  _filterDisplay: function() {

    const $display = $('.filter-display');

    $display.html('');
    var that = this;

    $('.filter-dropdown-container select').each(function() {
      const $this = $(this);

      if( !$this.val() ) return;
      let filter_name = $this.attr('name');
      const $close_button = $('<div/>').attr('class', 'close-button ' + filter_name)
                                 .sext('x')
                                 .on('click', function(event) {
                                   if (filter_name == 'booked_by_filter') {
                                     $('#booked-by-filter').val('');
                                   } else {
                                     $('#status-filter').val('');
                                   }
                                   that._filterList();
                                 });

      const $p = $('<div/>').sext($this.children('option:selected').sext()).append($close_button);
      $display.append($p);
    });
  },

  _filterList: function() {

    $('#guest-search-input').val('');

    var booker = $('#booked-by-filter').val();
    var status = $('#status-filter').val();


    let filters = [];
    if( booker ) {
      filters.push('booked_by_filter=' + booker);
    }
    if( status ) {
      filters.push('status_filter=' + status);
    }

    this.parameters.filters = filters;
    this.parameters.query = false;
    this.parameters.current = 0;
    this.parameters.cursor = null;
    this.parameters.prev_cursors = [];
    this.parameters.page = 0;

    this._filterDisplay();

    this._sendParameters();
  },

  _searchList: function(is_mobile) {

    $('#booked-by-filter').val('');
    $('#status-filter').val('');

    $('.filter-display').html('');

    var query = $('#guest-search-input').val();
    if( is_mobile ) {
      query = $('#guestlist-search').val();
    }

    this.parameters.query = query;
    this.parameters.filters = false;
    this.parameters.current = 0;
    this.parameters.cursor = null;
    this.parameters.prev_cursors = [];
    this.parameters.page = 0;

    this._sendParameters();
  },

  _clearParams: function(event) {
    event.preventDefault();
    $('#guest-search-input').val('');
    $('#booked-by-filter').val('');
    $('#status-filter').val('');
    $('#guestlist-search').val('');

    $('.filter-display').html('');

    $('.submenu a').removeClass('selected');

    this.parameters = {
      filters: false,
      query: false,
      current: 0,
      sort_order: 'asc',
      total: 0,
      cursor: null,
      prev_cursors: [],
      page: 0,
    };
    this._sendParameters();
    return false;
  },

  _sendParameters: function() {
    $('#guestlist-block').html('');
		$('#loading-guestlist-row').show();
    $('#no-guestlist-results').hide();
    $('.paging,.pagination').css('visibility', 'hidden');

    var base_url = this._managerBaseUrl + '/home/day/guestlist/' + this._dateUrlParam;;

    var params = {
      page: this.parameters.page ?? 0,
      sort_order: this.parameters.sort_order,
      sort_field: this.parameters.sort_field
    };

    if (this.parameters.cursor) {
      params.cursor = JSON.stringify(this.parameters.cursor);
    }
    if (this.parameters.prev_cursors) {
      params.prev_cursors = JSON.stringify(this.parameters.prev_cursors);
    }

    params = new URLSearchParams(params).toString();

    if( this.parameters.filters ) {
      params += '&' + this.parameters.filters.join('&');
    } else if( this.parameters.query ) {
      params += '&search_query=' + this.parameters.query;
    }

		var url = base_url + '?' + params + '&type_key=view';
    var export_url = base_url + '?' + params + '&type_key=excel';

    $('#export-link').prop('href', export_url);

    var self = this;
		Pmp.Client.AsyncGet(
      url,
	    function(data) {
        self._populateMoreGuestlist(data.payload);
      }
    );
  },

  _updatePageStatus: function(pageNum, perPage, numDisplayed, totalHits) {
    const seenSoFar = pageNum * perPage;
    const statusMsg = `Showing ${seenSoFar}-${seenSoFar + numDisplayed} of ${totalHits} results`;
    $('.pagination .status').each((_, elem) => elem.innerText = statusMsg);
  },

  _updatePaging: function(next, prevs, perPage) {
    if (next == null && (prevs == null || prevs.length <= 1)) {
      $('.paging,.pagination').css('visibility', 'hidden');
    } else {
      $('.paging,.pagination').css('visibility', 'visible');
    }
    $('.pagination > div > a').show();

    if( next == null ) {
      $('.pagination > .next-container > a').hide();
    }
    if( prevs.length < 2 ) {
      $('.pagination > .previous-container > a').hide();
    }

    $('.pagination a').each((_, elem) => elem.innerText = elem.innerText.replace('--', perPage));

    this.pagingData = {
      next,
      prevs,
    };
  },

  _switchPage: function(direction) {
    switch( direction ) {
      case 'next-link':
        this.parameters.cursor = this.pagingData.next;
        this.parameters.prev_cursors = this.pagingData.prevs;
        this.parameters.page++;
        break;
      case 'previous-link':
        this.pagingData.prevs.shift()
        this.parameters.cursor = this.pagingData.prevs.shift();
        this.parameters.prev_cursors = this.pagingData.prevs;
        this.parameters.page--;
        break;
      default:
        return;
    }

    this._sendParameters();
  },

  _populateMoreGuestlist : function(payload) {
    this._num_guestlist_pages_received++;
    var is_all_loaded = this._num_guestlist_pages_received == this._num_guestlist_pages;
    var content = payload.content;
    var page = content.page;
    var is_last_page = page === this._num_guestlist_pages - 1;
    var combined_html = '';

    this._updatePaging(content.next_cursor, content.prev_cursors, content.per_page);
    this._updatePageStatus(content.page, content.per_page, content.num_results, content.total_hits);
    this.parameters.page = content.page

    for (var i = 0; i < content.actuals.length; i++) {
      var client_display_name = content.actuals[i].first_name + ' ' + (content.actuals[i].last_name ?? '');

      if( content.is_last_name_first && content.actuals[i].last_name) {
        client_display_name = content.actuals[i].last_name + ', ' + content.actuals[i].first_name;
      }
      content.actuals[i]['client_display_name'] = client_display_name;

      var html = Nightloop.Templates.Manager.Guestlist.GuestListRow({
          'actual' : content.actuals[i],
          'page' : page,
          'current_user_id' : payload.request.user.id
      });

      combined_html += html;
    }

    $('#num_results').sext("(" + content.num_results + ")");
    $('#guestlist-block').html(combined_html);

    // Show spinners for page gaps above
    $('#insert-guestlist-page-spinner-'+page).remove();
    $('.insert-guestlist-page-spinner').each(function(i,el) {
      if (parseInt($(el).attr('page_num'),10) < page) {
        $(el).show();
      }
    });

    $('#loading-guestlist-row').hide();
    if (content.num_results == 0) {
      $('#no-guestlist-results').show();
    }
  },


	_clearDashboardActuals : function () {
		// remove headers
		$('.section-header').remove();
		$('.actual-group-header').remove();
		// remove actuals
		$('.booked-row').remove();
		$('.section-block').remove();
	},


	_onMouseEnterClientRequest : function(event) {
		var client_request_hover = $(event.target);
		var client_request = client_request_hover.siblings('.client-request');
		client_request.show();
	},

	_onMouseLeaveClientRequest : function(event) {
		var client_request_hover = $(event.target);
		var client_request = client_request_hover.siblings('.client-request');
		client_request.hide();
	},

	_onMouseEnterClientInfoReserved : function(event) {
		var client_request_hover = $(event.target);
		var client_request = client_request_hover.siblings('.client-info-reserved');
		client_request.show();
	},

	_onMouseLeaveClientInfoReserved : function(event) {
		var client_request_hover = $(event.target);
		var client_request = client_request_hover.siblings('.client-info-reserved');
		client_request.hide();
	},


	_onClickExpandGuestlistBlock : function(event) {
		$('#expand-guestlist-block').addClass('no-display');
		$('#collapse-guestlist-block').removeClass('no-display');
		$('#guestlist-block').slideDown();
	},

	_onClickCollapseGuestlistBlock : function(event) {
		$('#expand-guestlist-block').removeClass('no-display');
		$('#collapse-guestlist-block').addClass('no-display');
		$('#guestlist-block').slideUp();
	},


	// Sort defaults
	prev_sort_order: 'desc',
	prev_down_sort_key: 'name',

	_onClickSortLink : function(event, element) {


		var container_id = '#guestlist-box';
		var row_class = '.guestlist-row';

    if( $(row_class, container_id).length == 0 ) return;

		var sort_key = $(element).attr('sort_key');
		var sort_attr = 'sort_' + sort_key;
		var sort_order = $(container_id).data('prev_sort_order') == 'desc' ? 'asc' : 'desc';

		if (this.prev_down_sort_key !== sort_key && container_id == this.prev_container) {
			sort_order = 'asc';
		}

    $(container_id).data('prev_sort_order', sort_order);

    this.prev_container = container_id;
    this.prev_down_sort_key = sort_key;

    $(container_id).find('.sort-link').removeClass('asc desc');
    $(container_id).find('.sort-link[sort_key=' + sort_key + ']').addClass(sort_order);


		var iAsc = sort_order=='asc'?1:-1;
		var sort_fn = function(a, b) {
			var sA = $.trim(a.s), sB = $.trim(b.s);
			// Always bubble empty rows to the bottom
			if (sA === '') {
				return 1;
			} else if (sB === '') {
				return -1;
			}
			// Sort numbers
			const fA = parseFloat(sA, 10);
			const fB = parseFloat(sB, 10);
			if (!isNaN(fA) && !isNaN(fB)) {
				return iAsc*(fA<fB?-1:(fA>fB?1:0));
			}
			return iAsc*(sA<sB?-1:(sA>sB?1:0));
		};

		this.debug('_onClickSortLink: '+ sort_key + ', ' + sort_order);
		$(row_class).tsort({attr:sort_attr, order:sort_order, sortFunction:sort_fn});
	},

	_hideRegroupDropDown : function () {
		$('.regroup-dropdown-container').addClass('no-display');
	},

  // Client stuff copied from res.
	_is_client_profile_selected: false,

	_clickSelectClientProfile : function(event, element) {
		this.debug('_clickSelectClientProfile');
		var venue_group_client_id = $.trim($(element).attr('venue_group_client_id'));
		this.selectVenueGroupClientId(venue_group_client_id);
	},

	selectVenueGroupClientId : function(venue_group_client_id) {
		this.debug('selectVenueGroupClientId');
		this.clearClientProfileSearchTimeout();
		this._is_client_profile_selected = true;
		this.showLoadingClientProfileSpinner();

		var self = this;
		var onLoadFn = function(data) { self._handleLoadSelectedClientProfile(data); };
		var url = this._managerBaseUrl + '/clients/matched/' + venue_group_client_id;
    Pmp.Client.AsyncGet(url, onLoadFn);
	},

  _prev_firstname: false,
  _prev_lastname: false,
	_handleLoadSelectedClientProfile : function(data) {
		this.debug('_handleLoadSelectedClientProfile');

    var venue_group_client = data.payload.venue_group_client;

		this._prev_firstname = $('#id_firstname').val();
		this._prev_lastname = $('#id_lastname').val();

		$('#input-venue-group-client-id').val(venue_group_client.id);
		if (venue_group_client.first_name !== '') {
			$('#id_firstname').val(venue_group_client.first_name);
		}
		if (venue_group_client.last_name != null && venue_group_client.last_name !== '') {
			$('#id_lastname').val(venue_group_client.last_name);
		}

		Pmp.Client.Static.Page.RenderPart($('#client-profile-area'), data.template, data.payload);
		$('#client-profile-area').show();
		Pmp.Common.TagCache.renderTags();
		Pmp.Common.TagCache.sortTags('.tag-display');
  },

	_clickDeselectClientProfile: function(event) {
		this.debug('_clickDeselectClientProfile');
		this._is_client_profile_selected = false;

		$('#input-venue-group-client-id').val('');
		$('#id_firstname').val(this._prev_firstname);
		$('#id_lastname').val(this._prev_lastname);

		this._doClientProfileSearch();
	},

	clearClientProfileSearchTimeout : function() {
		if (this._do_client_search_timeout_obj !== undefined) {
			clearTimeout(this._do_client_search_timeout_obj);
			this._do_client_search_timeout_obj = undefined;
		}
	},

	showLoadingClientProfileSpinner : function() {
		$('#cpm-loading-div.no-display').removeClass('no-display');
	},

	_last_client_search_hash: '',
  _doClientProfileSearch : function() {

		var searchFirstName = $('#id_firstname').val();
    var searchLastName = $('#id_lastname').val();

		var client_search_hash = searchFirstName + searchLastName;
		this._last_client_search_hash = client_search_hash;

		this.clearClientProfileSearchTimeout();
		this.showLoadingClientProfileSpinner();

		var self = this;
		var onLoadFn = function(data) { self._handleLoadClientProfileMatches(data, client_search_hash); };

		var url = this._managerBaseUrl +
					'/clients/match?fn=' + encodeURIComponent(searchFirstName) +
					'&ln=' + encodeURIComponent(searchLastName);

		Pmp.Client.AsyncGet(url, onLoadFn);
	},

	isClientProfileSelected: function() {
		return this._is_client_profile_selected;
	},

	_changeClientProfileSearch: function(event) {

		var searchFirstName = $('#id_firstname').val();
    var searchLastName = $('#id_lastname').val();

		if (this.isClientProfileSelected()) {
			return;
		}
		if (this._last_client_search_hash === searchFirstName + searchLastName) {
			return;
		}
		this._setDoSearchTimeout();
	},

	_setDoSearchTimeout : function() {
		this.clearClientProfileSearchTimeout();
		this.showLoadingClientProfileSpinner();
		var self = this;
		var doClientProfileSearchFn = function() {self._doClientProfileSearch();};
		this._do_client_search_timeout_obj = setTimeout(doClientProfileSearchFn, 300);
	},

	_handleLoadClientProfileMatches : function(data, client_search_hash) {
		this.debug('_handleLoadClientProfileMatches');
		// Make sure we didn't do another search in the meantime
		if (this._last_client_search_hash !== client_search_hash) {
			return;
		}
	  if( !data.payload.venue_group_clients ) {
	    $('#client-profile-area').hide();
	  } else {
		  Pmp.Client.Static.Page.RenderPart($('#client-profile-area'), data.template, data.payload);
	    $('#client-profile-area').show();
		  Pmp.Common.TagCache.renderTags();
		  Pmp.Common.TagCache.sortTags('.tag-display');
	    $('#client-profile-area').show();
	  }
	}

};
