var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Search = Pmp.Manager.Search || {};

Pmp.Manager.Search.Page = {
  initialize: function (manager_base_url, search_query,
                        search_type, search_order_index, search_cursor, user_email,
                        has_filter, cursor_index, bb_name, can_view_other_client_profiles,
                        locale, venue_key, media_url,
                        is_7r_superuser, offset, venueId, venueGroupId, venuesSelected,
                        any_res_tags, any_client_tags, campaign_name, campaign_type, campaign_channel,) {

    this._locale = locale
    this._manager_base_url = manager_base_url
    this._search_query = search_query
    this._search_type = search_type
    this._search_order_index = search_order_index
    this._search_cursor = search_cursor
    this._search_cursor_index = cursor_index
    this._doRefreshOnClose = false
    this._user_email = user_email
    this._keep_filters_open = has_filter
    this._bb_name = bb_name
    this._can_view_other_client_profiles = can_view_other_client_profiles
    this._venue_key = venue_key
    this._media_url = media_url
    this._is_7r_superuser = is_7r_superuser
    this._venueId = venueId
    this._venueGroupId = venueGroupId
    this._venuesSelected = venuesSelected
    this._offset = parseInt(offset, 10)
    this._venueIdToUrlKey = {}
    this._any_res_tags = any_res_tags == 'True' ? true : false
    this._any_client_tags = any_client_tags == 'True' ? true : false
    this._campaign_name = campaign_name
    this._campaign_type = campaign_type
    this._campaign_channel = campaign_channel
    this._initDropDowns()
    this._bindEventHandlers()

    var self = this
    var destroyerFn = function () {
      self.destroy();
    };
    Pmp.Client.Static.Factory.Register(Nightloop.Templates.Manager.Search.Page,
        null, destroyerFn);


    $('#monthly-calendar').datepicker('option', 'onSelect', function (dateText, calendar) {
      var dateObj = $('#monthly-calendar').datepicker('getDate');
      var dateText = $.datepicker.formatDate('mm-dd-yy', dateObj);
      var relativeUrl = self._manager_base_url + "/reservations/day/" + dateText;
      window.location = relativeUrl;
    });

    // Make sure we have data before loading this guy
    Pmp.Manager.Global.subscribeOnUserDomainVenuesLoad(
        function (userDomainVenues) {
          self._initMultiVenueDropDown(userDomainVenues, self._venuesSelected);
        }
    );
  },

  log: function (msg) {
    console.log("Pmp.Manager.Search.Page: " + msg);
  },

  debug: function (msg) {
    if (Pmp.Settings.DEBUG) {
      this.log(msg);
    }
  },

  destroy: function () {
    var that = this;
    this.debug('destroying');
    $(document).unbind('mouseup');
  },

  _bindEventHandlers: function () {

    this.debug("_bindEventHandlers");
    var self = this;
    var clickSortHeaderFn = function (event) {
      self._clickSortHeader(event, $(this));
    };
    var clickPrevLinkFn = function (event) {
      self._clickPrevLink(event);
    };
    var clickNextLinkFn = function (event) {
      self._clickNextLink(event);
    };
    var clickResetQueryLinkFn = function (event) {
      self._clickResetQueryLink(event);
    };
    var onClickClientRowFn = function (event) {
      self._onClickClientRow(event);
    };
    var onClickShowFiltersFn = function (event) {
      self._onClickShowFilters(event);
    };
    var onClickExportLinkFn = function (event) {
      self._onClickExportLink(event);
    };
    var onClickShowTagsResultFn = function (event) {
      self._onClickShowTagsResult(this);
    };

    $('.sort-link').click(clickSortHeaderFn);
    $('.previous-link').click(clickPrevLinkFn);
    $('.next-link').click(clickNextLinkFn);
    $('.reset-query-link').click(clickResetQueryLinkFn);

    $('.client-row .col, .client-row.mobile-row').not('.col-merge').click(onClickClientRowFn);
    $('#export-link-container').click(onClickExportLinkFn);
    $('#show-tags-checkbox').change(onClickShowTagsResultFn);


    var page = Pmp.Client.Static.Page;

    var is_client_search = false;

    if (!is_client_search) {
      self.initReservationSearch();
    }

    $('#search-query-input').on('keyup', function (ev) {
      if (ev.which === 13) {
        if (is_client_search) {
          var relativeUrl = self._getSubmissionUrl();
          window.location = relativeUrl;
        } else {
          self._submitReservationSearch();
        }
        return false;
      }
    });

    Pmp.Utils.GoldButtonClickSubmit('#client-lookup-search-btn');
    Pmp.Utils.InputOverlayPrompt('#search-query-input-container', '#search-query-input-prompt');


    // Why is my brain broken with this?
    $('.show-tags').on('click', function (event) {
      event.stopPropagation();
      var el = $(event.currentTarget).next(),
          visi = el.is(':visible');

      $('.filter-dropdown-container').hide();
      if (!visi) {
        el.show();
      }
    });

    $('.filter-dropdown-container').on('click', function (event) {
      event.stopPropagation();
    });

    this._loadBookedByNames();

    // Define what to do on certain actions
    ReservationSlideOut.onBookNew();
    ReservationSlideOut.onEdit();
    ReservationSlideOut.onCancel();
    ReservationSlideOut.onUncancel();
  },

  initReservationSearch: function () {
    this.initCalendar();
    var that = this;
    $('#search-reservations').on('click', function () {
      that._submitReservationSearch();
    });
    //remove selected highlight
    $('#close-interface').on('click', function () {
      $('.client-row').removeClass('selected');
    });

    $('.show-filters-list').on('click', function () {
      $('.filter-dropdown-container').toggle();
    });

    $('body').on('click', function (event) {
      $click = $(event.target);
      if (!$click.closest('.filter-dropdown-container, .show-filters-list').length) {
        $('.filter-dropdown-container').hide();
      }
    });

    $('#filter-button').on('click', function (event) {
      $('.filter-dropdown-container').hide();
      that._submitReservationSearch();
    });

    var tags_url = that._manager_base_url + '/data/reservationtags';
    Pmp.modules.SearchTags.renderPicker(
        'Res Tags',
        tags_url,
        that._onClickReservationTag,
        'res-tags-filter',
        this._any_res_tags
    );
    tags_url = that._manager_base_url + '/data/clienttags';
    Pmp.modules.SearchTags.renderPicker(
        'Client Tags',
        tags_url,
        that._onClickClientTag,
        'client-tags-filter',
        this._any_client_tags
    );

    $('#date-type-select').change(function () {
      if ($(this).val() === 'CREATED_DATE') {
        $('.start-time-element').hide();
        $('.end-time-element').hide();
      } else {
        $('.start-time-element').show();
        $('.end-time-element').show();
      }
    });
    $('#date-type-select').change();
  },

  _onClickClientTag: function (tagHash, any_client_tags) {
    $('#last-client-tag').val(tagHash);
    AjaxLoader.show();
    $('#disabled-overlay').fadeIn('fast');
    if (any_client_tags) {
      Pmp.Manager.Search.Page._any_client_tags = true;
    }
    var relativeUrl = Pmp.Manager.Search.Page._getSubmissionUrl();
    window.location = relativeUrl;
  },

  _onClickReservationTag: function (tagHash, any_res_tags) {
    $('#last-res-tag').val(tagHash);
    AjaxLoader.show();
    $('#disabled-overlay').fadeIn('fast');
    var addOn = '';
    if (any_res_tags) {
      Pmp.Manager.Search.Page._any_res_tags = true;
    }
    var relativeUrl = Pmp.Manager.Search.Page._getSubmissionUrl();
    window.location = relativeUrl;
  },

  _initMultiVenueDropDown: function (userDomainVenues, venues) {
    var that = this;


    var venueIds = (venues) ? venues : [that.venue_id];
    var sameVenueGroupVenues = userDomainVenues.venues.filter(function (v) {
      return v.venue_group_id === that._venueGroupId;
    });
    var venueIdToUrlKey = {}, currVenue;
    for (var i = 0; i < sameVenueGroupVenues.length; i++) {
      currVenue = sameVenueGroupVenues[i];
      venueIdToUrlKey[currVenue.id] = currVenue.url_key;
    }

    var sameVenueGroupVenuesCamelCased = []
    sameVenueGroupVenues.filter(function (v) {
      var venueDict = {}
      for (var key in v) {
        // change snake case to camel case
        venueDict[key.replace(/(\_\w)/g, function(m){return m[1].toUpperCase()})] = v[key]
      }
      sameVenueGroupVenuesCamelCased.push(venueDict)
    })

    this._venueIdToUrlKey = venueIdToUrlKey;
    SvrManager.Components.mountVenueDropDown(
        'multi-venue-select',
        {
          venueIds: venueIds,
          onChange: function (newVenuesSelected) {
            that._applyVenueChanges(newVenuesSelected, userDomainVenues);
          },
          venues: sameVenueGroupVenuesCamelCased,
          venueSearchGroups: userDomainVenues.venue_search_groups,
          width: 200,
          height: 34,
          isLightTheme: true,
          isMultiSelect: true,
          noHeader: true,
          name: ''
        }
    );
  },

  _applyVenueChanges: function (venuesSelected, userDomainVenues) {
    var newVenueIds = this._getVenueIds(venuesSelected);
    this._initMultiVenueDropDown(userDomainVenues, newVenueIds);
    $('#selected-venues').val(newVenueIds.join(','));
  },

  _makeVenueIdsToUrlKey: function(newVenueIds){
    var newVenueUrlKeys = []
    for (var j = 0; j < newVenueIds.length; j++) {
      newVenueUrlKeys.push(this._venueIdToUrlKey[newVenueIds[j]]);
    }

    return newVenueUrlKeys;
  },

  _getVenueIds: function (venuesSelected) {
    var venueIds = [];
    for (var i = 0; i < venuesSelected.length; i++) {
      venueIds.push(venuesSelected[i].id);
    }
    return venueIds;
  },

  initCalendar: function () {
    this.debug("initCalendar");
    Pmp.Utils.LocalizeDatePicker(this._locale, '#from-calendar', '#from-calendar-submit');
    Pmp.Utils.LocalizeDatePicker(this._locale, '#to-calendar', '#to-calendar-submit');
    var self = this;

    $('#ui-datepicker-div').addClass('calendar customize');
    /* Themeing */

    $('#from-cal-icon').on('click', function () {
      $('#from-calendar').datepicker('show');
    });
    $('#to-cal-icon').on('click', function () {
      $('#to-calendar').datepicker('show');
    });

    $('#to-calendar').on('change', function () {
      if ($.trim($(this).val()) === '') {
        $('#to-calendar-submit').val('');
      }
    })
    $('#from-calendar').on('change', function () {
      if ($.trim($(this).val()) === '') {
        $('#from-calendar-submit').val('');
      }
    })
  },

  _submitReservationSearch: function () {
    var val = $('#search-reservations').val();
    metric.track('Reservations.search', {
      page: 'reservations',
      value: val
    });
    AjaxLoader.show();
    var relativeUrl = this._getSubmissionUrl();
    window.location = relativeUrl;
  },

  _displayRowGroups: function (group, attribute) {
    var tagDisplays = $('.tags-area-sortable');

    tagDisplays.each(function () {
      $display = $(this);
      var ids = JSON.parse($display.attr(attribute));

      if ($.inArray(group.id, ids) < 0) {
        return;
      }

      var group_tag = Nightloop.Templates.Widget.MembershipGroup({
        "group_id": group.id,
        "group_name": group.name,
        "group_color": group.color
      });

      $group_tag = $(group_tag);

      $display.prepend($group_tag);
    });
  },

  _loadBookedByNames: function () {
    if (!this._can_view_other_client_profiles) {
      return;
    }
    var url = this._manager_base_url + '/data/bookedbynames';
    var self = this;
    Pmp.Client.AsyncGet(url, function (data) {
      self._booked_by_content = data.payload.content.booked_by_users;
      var options_html = Nightloop.Templates.Manager.Search.FilterBookedByUsers({
        'selected': self._bb_name,
        'bb_names': self._booked_by_content
      });
      $('#bb-names-select').empty().append(options_html);
      if ((!self._bb_name) || (self._bb_name === 'Any')) {
        $('#bb-names-select').addClass('unused');
      } else {
        $('#bb-names-select').removeClass('unused');
      }
    });
  },

  _hideFilter: function (e) {
    var container = $('.filter-dropdown-container');
    if (container.has(e.target).length === 0) {
      container.hide();
      // clear tag search
      $('#find-tags-input').val('');
      $('.find-tags-container').find('.tag-item-container').remove()
    }
  },

  onCloseFilterText: function (el, input_el) {
    AjaxLoader.show();
    $(el).parents('.tag-item-container').remove();
    $(input_el).val('');
    $(input_el).change();
    $('#disabled-overlay').fadeIn('fast');
    var relativeUrl = Pmp.Manager.Search.Page._getSubmissionUrl();
    window.location = relativeUrl;
  },

  onCloseFilter: function (el, select_el) {
    AjaxLoader.show();
    $(el).parents('.tag-item-container').remove();
    $(select_el)[0].selectedIndex = 0;
    $(select_el).change();
    $('#disabled-overlay').fadeIn('fast');
    var relativeUrl = Pmp.Manager.Search.Page._getSubmissionUrl();
    window.location = relativeUrl;
  },

  onCloseTag: function (el) {
    $(el).parents('.tag-item-container').remove();
  },

  onCloseTagAndRefresh: function (el) {
    AjaxLoader.show();
    this.onCloseTag(el);
    $('#disabled-overlay').fadeIn('fast');
    var relativeUrl = this._getSubmissionUrl();
    window.location = relativeUrl;
  },

  _initDropDowns: function () {
    var self = this;


    $('#show-filters-dropdown-btn').click(function () {
      var tagdd = $('.tag-dd');
      var filterdd = $('.filter-dd');
      if (filterdd.hasClass('dd-showing')) {
        filterdd.removeClass('dd-showing');
        filterdd.hide();
      } else {
        filterdd.addClass('dd-showing');
        filterdd.show();
        tagdd.removeClass('dd-showing');
      }
    });

    $('.filter-nevermind-link').click(function () {
      $('.filter-dropdown-container').hide();
    });

    $('#client-filters-submit, #appl-tags-button').click(function () {
      $('.filter-dropdown-container').hide();

      var relativeUrl = self._getSubmissionUrl(); //_manager_base_url + '/clients' + '?' + $('#search-and-filter-form').serialize() + '&kf=' + self._keep_filters_open;

      window.location = relativeUrl;


      //var relativeUrl = self._getSubmissionUrl();
      //Pmp.Client.Static.Page.Navigate('#nightloop', relativeUrl);
    });

    $('.filter_select').change(function (e) {
      var is_client_search = self._search_type === 'clients';
      var event_name = 'Search.filter';
      var page = 'misc_search';
      if (is_client_search) {
        event_name = 'Clients.filter';
        page = 'clients';
      }

      the_val = $(this).val();

      var filter_name = $(this).attr('name');
      if (!filter_name || filter_name === '') {
        filter_name = 'NA';
      }

      var props = {
        'page': page,
        'name': filter_name,
        'value': the_val
      };
      // track all filters across all search pages here
      metric.track(event_name, props);

      if (the_val === 'Any') {
        $(this).addClass('unused');
      } else {
        $(this).removeClass('unused');
      }
    });

    $('.filter_select').change();
  },

  initExport: function () {
    var self = this;
    var is_client_search = this._search_type === 'clients';
    Pmp.Utils.GoldButtonClickSubmit('#export-btn', '#search-and-filter-form', function (form) {
      var search_params = window.location.search ? window.location.search : '?';
      var export_url = self._manager_base_url + '/clients/export' + search_params;
      if (!is_client_search) {
        search_params += $('#export-with-pos-data').is(':checked') ? '&include_pos_data=y' : '';
        export_url = self._manager_base_url + '/reservations/export' + search_params;
      }
      Pmp.Client.AsyncGet(export_url, function (data) {
        Pmp.Utils.GoldButtonReset('#export-btn');
        $('#export-prompt-text').hide();
        $('#export-processing-text').show();
        $('#export-button-container').hide();
        $.colorbox.resize();
      });
      CustomerSuccessTracker.trackReporting('Export Reservations');
    });
  },

  _onClickExportLink: function (event) {
    var is_client_search = this._search_type === 'clients';
    if (is_client_search) {
      Pmp.Main.Popup.Base.loadHtml(Nightloop.Templates.Manager.Clients.ConfirmExportPopup({
        user_email: this._user_email
      }));
      metric.track('Clients.export', {page: 'clients'});
    } else {
      Pmp.Main.Popup.Base.loadHtml(Nightloop.Templates.Manager.Search.ConfirmExportPopup({
        user_email: this._user_email,
        is_7r_superuser: this._is_7r_superuser
      }));
    }
    Pmp.Main.Popup.Base.showPopup();
  },


  reloadForOrderIndex: function (orderIndex, which) {
    this.debug('reloadForOrderIndex: ' + orderIndex);

    var relativeUrl = this._getSubmissionUrl();

    // Replace the order index query or add it if not present
    if (/.*[?&]oi=/.test(relativeUrl)) {
      relativeUrl = relativeUrl.replace(/oi=([0-9]|10|11|12|13|14|15|16)\&/, 'oi=' + orderIndex + '&')
    } else {
      // Add order index. _getSubmissionUrl guarantees this will not
      // be the only GET variable, hence we need not worry about '?'
      relativeUrl += '&oi=' + orderIndex;
    }
    if (this._search_type === 'clients') {
      sessionStorage.setItem('search_url', relativeUrl);
    }
    window.location = relativeUrl;

    //Pmp.Client.Static.Page.Navigate('#nightloop', relativeUrl);
  },

  _getSubmissionUrl: function (addon) {
    var base = this._manager_base_url + '/search/' + this._search_type;
    var venueIds = $('#selected-venues').val().split(',');
    var venueUrlKeys =  this._makeVenueIdsToUrlKey(venueIds);
    $('#selected-venues').val(venueUrlKeys.join(','));
    var relativeUrl = base + '?' + $('#search-and-filter-form').serialize() + '&kf=' + this._keep_filters_open;
    relativeUrl += '&' + $('#tags-form').serialize();

    if (Pmp.Manager.Search.Page._any_client_tags) {
      relativeUrl += '&any_client_tags=true';
    }

    if (Pmp.Manager.Search.Page._any_res_tags) {
      relativeUrl += '&any_res_tags=true';
    }

    if ($('#show-tags-checkbox').prop('checked')) {
      relativeUrl += '&show-tags-results=on';
    }

    if(this._search_type === 'reservations') {
      if(this._campaign_type) {
        relativeUrl += '&campaign_type=' + this._campaign_type
      }
      if(this._campaign_name) {
        relativeUrl += '&campaign_name=' + this._campaign_name
      }
      if(this._campaign_channel) {
        relativeUrl += '&campaign_channel=' + this._campaign_channel
      }
    }

    if (addon) {
      relativeUrl = relativeUrl + addon;
    }

    return relativeUrl;
  },

  changeOrderIndex: function (orderIndexList, which) {
    if (this._search_order_index === orderIndexList[1]) {
      this.reloadForOrderIndex(orderIndexList[0], which);
    } else {
      this.reloadForOrderIndex(orderIndexList[1], which);
    }
  },

  _clickSortHeader: function (event, element) {
    this.debug('_clickSortHeader');
    var id = $(element).prop('id');
    if (id === 'sort-link-name') {
      this.changeOrderIndex(['13', '12']);
    } else if (id === 'sort-link-date') {
      this.changeOrderIndex(['11', '10']);
    } else if (id === 'sort-link-spend') {
      this.changeOrderIndex(['15', '14']);
    } else if (id === 'sort-link-cpsname') {
      this.changeOrderIndex(['1', '0'], 'client');
    } else if (id === 'sort-link-cpstotspend') {
      this.changeOrderIndex(['3', '2'], 'client');
    } else if (id === 'sort-link-cpstotvisits') {
      this.changeOrderIndex(['5', '4'], 'client');
    } else if (id === 'sort-link-cpsavgrating') {
      this.changeOrderIndex(['7', '6'], 'client');
    }
  },

  _clickPrevLink: function (event) {
    /* Not possible to maintain a history of all previous cursor positions
     * so either use offset for prev, or browser back button.
     */
    history.go(-1);
  },

  _clickNextLink: function (event) {
    var relativeUrl = '';

    if (this._search_type === 'clients') {
      var addon = '&c=' + this._search_cursor + '&ci=' + this._search_cursor_index;
      relativeUrl = this._getSubmissionUrl(addon);
      window.location = relativeUrl;
      return
    } else {
      relativeUrl = this._getSubmissionUrl() + '&offset=' + (this._offset + 30);
    }

    Pmp.Client.Static.Page.Navigate('#nightloop', relativeUrl, undefined, true /*break ajax*/);
  },

  _clickResetQueryLink: function (event) {
    if (this._search_type === 'clients') {
      var relativeUrl = this._manager_base_url + '/clients' + '?oi=' + this._search_order_index;
      sessionStorage.setItem('search_url', relativeUrl);
    } else {
      var relativeUrl = this._manager_base_url + '/search/' + this._search_type +
          '?oi=' + this._search_order_index;
    }
    window.location = relativeUrl;
  },


  _onClickClientRow: function (event) {
    this.debug('_onClickClientRow');
    var target = $(event.target);
    if (target.hasClass('input') || target.is('input')) {
      return;
    }
    var client_row = Pmp.Utils.GetParentElemForEventHandle(event, '.client-row');
    if (this._search_type === 'clients') {
      var vgcid = $(client_row).attr('vgcid');
      var relative_url = this._manager_base_url + '/clients/profile/' + vgcid;
      window.location = relative_url;
      // Pmp.Client.Static.Page.Navigate('#nightloop', relative_url);
    } else if (this._search_type === 'reservations') {
      // clear all
      $('.client-row.selected').removeClass('selected');
      var actualid = $(client_row).attr('actualid');
      var venueid = $(client_row).attr('venueid');
      $(client_row).addClass('selected');
      if (globalInit.venueSettings.use_supafly) {
        SvrManager.ActualSlideout.viewActual(actualid, venueid);
      } else {
        ReservationSlideOut.showReservation(actualid);
      }
    }
  },

  setDoRefreshOnClose: function () {
    this._doRefreshOnClose = true;
  },

  _onCloseOverlay: function () {
    if (this._doRefreshOnClose) {
      // then need to refresh the page
      var url = Pmp.Client.getCurrentPage();
      Pmp.Client.Static.Page.Load('#nightloop', url);
    }
  }
};
