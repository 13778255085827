// This file was automatically generated from manager.mobileredirect.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Homepage == 'undefined') { Nightloop.Templates.Homepage = {}; }


Nightloop.Templates.Homepage.MobileRedirect = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Homepage.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Homepage.MobileRedirectBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Homepage.MobileRedirectBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="main login"><div class="content-wrapper"><div class="align"><div class="welcome-back">CONTINUE TO</div><div class="input-wrapper sso-padding"><a href="', soy.$$escapeHtml(opt_data.content.mobile_site_redirect), '"><div class="simple-btn blue">MOBILE SITE</div></a></div><div class="input-wrapper sso-padding"><div class="simple-btn blue" id="mobile-app">MOBILE APP</div></div></div></div></div><iframe class="main ad-container" src="', soy.$$escapeHtml(opt_data.wordpress_ad_url), '"></iframe><script type="text/javascript">Pmp.Manager.MobileRedirect.initialize("', soy.$$escapeHtml(opt_data.content.mobile_site_redirect), '", "', soy.$$escapeHtml(opt_data.content.mobile_app_redirect), '", "', soy.$$escapeHtml(opt_data.content.itunes_app_link), '");<\/script>');
  return opt_sb ? '' : output.toString();
};
