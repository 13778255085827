MembershipSlideOut = {
  init : function(manager_base_url, vgc_id, locale) {
    
    this._manager_base_url = manager_base_url;
    this._vgc_id = vgc_id;
    this._locale = locale;
    this._initCalendar();
    this._bindEvents();
    this._plan_cache = {}
    this.validator = new sr.Validator('#membership-interface', undefined) // TODO: add currency symbol
    this.setupValidator();
    this._onAddMembershipHandler = null;
    var that = this;
  },
  
  _bindEvents : function () {
    var that = this;
    $('#save-membership').click(function() {
      that.submit();
    });
    $('#membership-group-selector').change(function(e) {
    	var group_id = null;
    	$('#membership-group-selector option:selected').each(function() {
    		group_id = $(this).val();
    	});
    	if (group_id) {
    		that.updatePlanSelect(group_id);
    	} else {
    		that.renderPlanSelect([]);
    		that.updatePlanInfo();
    	}
    });
    $('#payment-plan').change(function(e) {
    	var plan_id = null;
    	$('#payment-plan option:selected').each(function() {
    		plan_id = $(this).val();
    	});
    	that.updatePlanInfo(plan_id);
    });
    $('#use_card_on_file_checkbox').on('click', function() {
      if ($(this).is(':checked')) {
        $('#membership-interface .credit-card-form').hide();
      } else {
        $('#membership-interface .credit-card-form').show();
      }
    })
  },
  
  
  _initCalendar : function() {
    var self = this;
    Pmp.Utils.LocalizeDatePicker(self._locale, '#membership-date-selector', '#membership-date-submit');
    var sel ='#membership-date-selector'; 
    //$(sel).datepicker('option', 'minDate', 0); // no min date to set exact end dates for current members
    $(sel).datepicker('option', 'dayNamesMin',['S','M','T','W','T','F','S'] );
    $(sel).datepicker('option', 'monthNames', ['JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER']);
    $(sel).datepicker('option', 'showOtherMonths', true);
    $(sel).datepicker('option', 'selectOtherMonths', true);

    $(sel).datepicker('option', 'altField', '#real-date-submit');
    $(sel).datepicker('option', 'altFormat', 'mm/dd/yy');
    
    $('#ui-datepicker-div').addClass('calendar customize'); /* Theming */
  },
  
  setupValidator : function () {
    var that = this;
    this.validator.onShouldValidateGroup = function(groupName) { return that.onShouldValidateGroup(groupName); };
  },
  
  onShouldValidateGroup : function (groupName) {
    var shouldValidate = true;
    if (groupName == 'payment-details') {
      shouldValidate = this.shouldAddCard();
    }
    return shouldValidate;
  },
  
  shouldAddCard : function () {
    var hasName = $.trim($('#cardholder_name').val()) != '';
    var hasCardNumber = $.trim($('#card_number').val()) != '';
    return (hasName || hasCardNumber);
  },
  
  updatePlanSelect: function(group_id) {
	  var that = this;
	  var url = that._manager_base_url + '/membershipgroups/' + group_id + '/plans';
	  $.ajax({
		  url: url,
		  success: function(response) {
			  that.renderPlanSelect(response);
		  }
	  });
  },

  updatePlanInfo: function(plan_id) {
	  var plan = this._plan_cache[plan_id];
	  if (plan) {
		  $('#payment').val(plan.payment_formatted);
	  } else {
		  $('#payment').val('');
	  }
  },
  
  renderPlanSelect: function(plans, plan_id) {
	  this._plan_cache = {};
	  var that = this;
	  var paymentSelect = $('#payment-plan');
	  paymentSelect.html('');
	  $.each(plans, function(i, plan) {
		  if (i == 0 && !plan_id) {
			  plan_id = plan.id;
		  }
		  that._plan_cache[plan.id] = plan;
		  var optionHtml = '<option value="' + plan.id + '" ';
		  if (plan_id == plan.id) {
		      optionHtml += 'selected';
		  }
		  optionHtml += '/>';
		  paymentSelect.append($(optionHtml).sext(that.renderPlanLabel(plan)));
	  });
	  this.updatePlanInfo(plan_id);
  },
  
  renderPlanLabel: function(plan) {
	  return plan.description;
  },
  
  onAddMembership : function (handler) {
    this._onAddMembershipHandler = handler;
  },
  
  showAddMembership : function() {
    this.clear();
    var that = this;
    var deferred = BillingService.listCards(this._vgc_id);
    deferred.done(function(cards) {
      that._cards = cards;
      if (cards.length == 0) {
        $('#membership-interface .use_card_on_file').hide();
        $('#membership-interface .credit-card-form').show();
        $('#use_card_on_file_checkbox').prop('checked', false);
      } else {
        $('#membership-interface .use_card_on_file').show();
        $('#membership-interface .credit-card-form').hide();
        $('#use_card_on_file_checkbox').prop('checked', true);
      }
    });
    $('#membership-interface').addClass('membership');
    Interface.openslide('#membership-interface');
    
  },
  
  submit : function () {    
    var that = this;
    if (!this.validator.validate()) {
		  return false;
	  }
    
    // if using an existing card, then just add without card data
    var use_existing_card = $('#use_card_on_file_checkbox').prop('checked');
    if (use_existing_card) {
      that._addToClient();
      return;
    }
    
    if (!that.shouldAddCard()) {
      that._addToClient();
      return;
    }
    
    // Otherwise proceed with adding a card first
    var $form = $('#membership-form');
    var card_data = {
  	  client_id: that._vgc_id,
  	  name: $form.find('input[name="cardholder_name"]').val(), 
  	  number: $form.find('input[name="card_number"]').val(), 
  	  exp_month: $form.find('select[name="cc_expiration_month"]').val(),
  	  exp_year: $form.find('select[name="cc_exp_year"]').val(), 
  	  cvc: $form.find('input[name="card_cvv"]').val()
    };
    // Add the card prior to adding membership group
    var deferred = BillingService.addCard(card_data);
      deferred.done(function(result) {
      // addCard succeeded
      that._addToClient();
    }).fail(function(error) {
  	  // addCard failed
  	  Interface._alert(error);
    });
    return false;
  },
  
  _addToClient : function () {
    var that = this;
    var group_id = $('#membership-group-selector').val();
    var plan_id = $('#payment-plan').val();
    var start_date = $('#real-date-submit').val();
    MemberGroupService.addToClient(
        that._manager_base_url, 
        group_id, 
        that._vgc_id, 
        plan_id, 
        start_date,
        function(membership_group_detail) {
          that.close();
          if (that._onAddMembershipHandler) {
            that._onAddMembershipHandler(membership_group_detail);
          }
        }, function(errors) {
          Interface._alert(errors[0]);
        });
  },
  
  clear : function () {
  	$('#membership-interface.membership input').val('');
  	$('#membership-interface.membership select').val('');
  	$('#payment-plan').html('');
  	$('#membership-interface').removeClass('membership');
  	this.validator.clear();
  	Interface.clear('#membership-interface');
  	if (!$('#use_card_on_file_checkbox').prop('checked')) {
  	  $('#use_card_on_file_checkbox').click();
  	};
  	$('#membership-interface .use_card_on_file').hide();
  	$('#membership-interface .credit-card-form').show();
  	
  },
  
  close : function () {
    Interface.closeslide('#membership-interface');
    this.clear();
    return false;
  }
};

