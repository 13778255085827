
var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Home = Pmp.Manager.Home || {};

Pmp.Manager.Home.Day = {
	initialize : function(managerBaseUrl, initDateText, dateUrlParam, todayDate, muni_today_date,
			bookedDates, blackedDates, customOpenedDates, daysOfOperation, media_url,
			is_live_day, starting_index, num_res_pages, num_guestlist_pages, can_view_guestlist,
			locale, res_display_grouping, is_nightlife_class, group_by_type, seating_area_id_to_code,
			using_total_dollars_cost_option, show_cost_options) {

		var self = this;
		var destroyerFn = function() { self.destroy(); };
		Pmp.Client.Static.Factory.Register(Nightloop.Templates.Manager.Home.Day,
                null, destroyerFn);

		this._managerBaseUrl = managerBaseUrl;
		this._initDateText = initDateText;
		this._dateUrlParam = dateUrlParam;
		this._doRefreshOnClose = false;
		this._bookedDates = bookedDates;
		this._blackedDates = blackedDates;
		this._customOpenedDates = customOpenedDates;
		this._daysOfOperation = daysOfOperation;
		this._media_url = media_url;
		this._is_live_day = is_live_day;
		this._ms_per_day = 24*60*60*1000;
		this._today_date = $.datepicker.parseDate('mm/dd/y', todayDate);
		this._muni_today_date = muni_today_date;
		this._num_res_pages = num_res_pages;
		this._num_guestlist_pages = num_guestlist_pages;
		this._can_view_guestlist = can_view_guestlist;
		this._locale = locale;
		this._res_display_grouping = res_display_grouping;
		this._is_nightlife_class = is_nightlife_class;
		this._seating_area_id_to_code = seating_area_id_to_code;
		this._using_total_dollars_cost_option = using_total_dollars_cost_option;
		this._group_by_type = group_by_type; // DEFAULT, SEATING_AREA, TABLE_BAR, PAYING_COMP
		this._actual_row_index = starting_index;
		this._show_cost_options = show_cost_options;

		this._initCalendar();
		this._bindClickHandlers();
		this._hideMessageOnDelay();
	},

	log : function(msg) {
		console.log("Pmp.Manager.Home.Day: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	destroy : function() {
		this.debug('destroying');
		$(document).unbind('mouseup');
	},

	_hideMessageOnDelay : function() {
		if ($('.gold-message')) {
			$('.gold-message').delay(5000).slideUp(1000);
		}
	},

	_initCalendar : function() {
		this.debug("initCalendar");
		var self = this;

		Pmp.Utils.LocalizeDatePicker(self._locale, '#monthly-calendar', '#monthly-calendar-submit');
		var sel = '#monthly-calendar';
		$(sel).datepicker('option', 'defaultDate', this._initDateText);
		$(sel).datepicker('setDate', this._initDateText);
		$(sel).datepicker('option', 'dayNamesMin', ['S','M','T','W','T','F','S']);
		//$(sel).datepicker('option', 'monthNames', ['JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER']);
		$(sel).datepicker('option', 'showOtherMonths', true);
		$(sel).datepicker('option', 'selectOtherMonths', true);
		$(sel).datepicker('option', 'onSelect',function(dateText, calendar) {
			self._onSelectDate(dateText, calendar);
      metric.track('Reservations.changeDate', {'page': 'reservations'});
		});
		$(sel).datepicker('option', 'beforeShowDay', function(date) {
			return self._beforeShowDay(date);
		});

	},

	_bindClickHandlers : function() {
		this.debug("bindClickHandlers");
		var self = this;
		var onClickBookedResRowFn = function(event) {self._onClickBookedResRow(event);};
		var onClickShowCanceledFn = function(event) {self._onClickShowCanceled(event);};
		var onClickHideCanceledFn = function(event) {self._onClickHideCanceled(event);};
		var onMouseEnterClientRequestFn = function(event) {self._onMouseEnterClientRequest(event);};
		var onMouseLeaveClientRequestFn = function(event) {self._onMouseLeaveClientRequest(event);};
		var onMouseEnterClientInfoReservedFn = function(event) {self._onMouseEnterClientInfoReserved(event);};
		var onMouseLeaveClientInfoReservedFn = function(event) {self._onMouseLeaveClientInfoReserved(event);};
		var onClickEditDailyNotesLinkFn = function(event) {self._onClickEditDailyNotesLink(event);};
		var onClickRefreshLinkFn = function(event) {self._onClickRefreshLink(event);};
		var onClickGroupLinkFn = function(event) {self._onClickGroupLink(event);};
		var onClickExpandGuestlistBlockFn = function(event) {self._onClickExpandGuestlistBlock(event);};
		var onClickCollapseGuestlistBlockFn = function(event) {self._onClickCollapseGuestlistBlock(event);};
		var onClickSortLink = function(event) {self._onClickSortLink(event, $(this));};

		var onClickRegroupDefaultFn = function(event) {self._onClickRegroupDefault(event, $(this)); };
		var onClickRegroupSeatingAreaFn = function(event) {self._onClickRegroupSeatingArea(event, $(this)); };
		var onClickRegroupTableBarFn = function(event) {self._onClickRegroupTableBar(event, $(this)); };
		var onClickRegroupPayingCompFn = function(event) {self._onClickRegroupPayingComp(event, $(this)); };

		var page = Pmp.Client.Static.Page;
		page.AddLiveHandler('.booked-row', 'click', onClickBookedResRowFn);
		page.AddLiveHandler('.client-request-hover', 'mouseenter', onMouseEnterClientRequestFn);
		page.AddLiveHandler('.client-request-hover', 'mouseleave', onMouseLeaveClientRequestFn);
		page.AddLiveHandler('.client-info-reserved-hover', 'mouseenter', onMouseEnterClientInfoReservedFn);
		page.AddLiveHandler('.client-info-reserved-hover', 'mouseleave', onMouseLeaveClientInfoReservedFn);

    // Kill this once client.js fix goes through (replacing die with off)
		$('body').off('click', '.sort-link');
		$('body').on('click', '.sort-link', onClickSortLink);
		//page.AddLiveHandler('.sort-link', 'click', onClickSortLink);

		if (!self._is_nightlife_class) {
			var onClickReceivedLink = function(event) {self._onClickReceivedLink(event, $(this));};
			page.AddLiveHandler('.received-button', 'click', onClickReceivedLink);

			var linkID = '#status-question-mark';
			var divID = '#status-help-tooltip';
			page.AddLiveHandler(linkID, 'mouseenter', function() {
				$(divID).show();
				return true;
			});
			page.AddLiveHandler(linkID, 'mouseleave', function() {
				$(divID).hide();
				return true;
			});
		}


		$('.show-canceled-link').click(onClickShowCanceledFn);
		$('.hide-canceled-link').click(onClickHideCanceledFn);
		$('.edit-daily-notes').click(onClickEditDailyNotesLinkFn);
		$('#refresh-container').click(onClickRefreshLinkFn);
		$('#regroup-container').click(onClickGroupLinkFn);
		$('#expand-guestlist-block').click(onClickExpandGuestlistBlockFn);
		$('#collapse-guestlist-block').click(onClickCollapseGuestlistBlockFn);

		// grouping links
		$('#regroup-link-default').click(onClickRegroupDefaultFn);
		$('#regroup-link-seatingarea').click(onClickRegroupSeatingAreaFn);
		$('#regroup-link-tablebar').click(onClickRegroupTableBarFn);
		$('#regroup-link-payingcomp').click(onClickRegroupPayingCompFn);

		// lazy load more reservations/guestlist
		this._lazyLoadReservations();
		this._lazyLoadGuestlist();

		var onClickDocumentHideRegroup = function(event) {self._hideRegroupOnClick(event); };
		$(document).mouseup(onClickDocumentHideRegroup);

	},

	initializeMobile : function () {
		var self = this;
		var onClickGuestlistRowFn = function(event) {self._onClickGuestlistRow(event);};
		var page = Pmp.Client.Static.Page;
		page.AddLiveHandler('.guestlist-row', 'click', onClickGuestlistRowFn);
	},

	_hideRegroupOnClick : function(e) {
		var container = $("#regroup-container");
	    if (container.has(e.target).length === 0)
	    {
	    	this._hideRegroupDropDown();
	    }
	},

	_lazyLoadReservations : function() {
		this._num_res_pages_received = 0;
		this._actuals_loaded = {}; // Only used if res display grouping is turned on
		for (var count=0; count < this._num_res_pages; count++) {
			var url = this._managerBaseUrl + '/home/day/actuals/' + this._dateUrlParam;
			url += '?page=' + count;
			var self = this;
			var callbackFn;

			if (this._group_by_type != 'DEFAULT' || this._res_display_grouping === 'EST_ARR_TIME') {
				callbackFn = function(data) { self._recieveMoreGroupableReservations(data.payload); };
			} else {
				callbackFn = function(data) { self._populateMoreReservations(data.payload); };
			}
			Pmp.Client.AsyncGet(url, callbackFn);
		}
	},

	_recieveMoreGroupableReservations : function(payload) {
		this._num_res_pages_received ++;
		var is_all_received = this._num_res_pages_received == this._num_res_pages;

		var content = payload.content;
		var page = content.page_num;
		this._actuals_loaded[page] = content.actuals;

		if (is_all_received) {
			this._groupActualsByTypeAndOrTime(this._group_by_type);
			$('#loading-row').hide();
		}
	},

	_getNumGuestsDisplay : function(actuals) {
		var count = 0;
		for (var i = 0; i < actuals.length; i++){
			var totalGuests = actuals[i].total_guests;
			if (totalGuests === '') {
				totalGuests = 0;
			}
			count += parseInt(totalGuests);
		}
		if (count == 1) {
			return count + ' guest';
		}
		return count + ' guests';
	},

	_groupActualsByTypeAndOrTime : function (group_by_type) {
		// group all actuals by seating area
		var section_groups = {};
		var section_list = [];
		var unassigned_text = 'Unassigned';

		// Init groups list with fixed, ordered groups
    	var groups_list = [];
		if (group_by_type == 'DEFAULT') {
			groups_list.push({'id' : '', 'label' : '', 'actuals' : []});
		} else if (group_by_type == 'SEATING_AREA') {
			groups_list.push({'id' : 'BAR', 'label' : 'Bar', 'actuals' : []});
		} else if (group_by_type == 'TABLE_BAR') {
			groups_list.push({'id' : 'TABLE', 'label' : 'Table', 'actuals' : []});
			groups_list.push({'id' : 'BAR', 'label' : 'Bar', 'actuals' : []});
		} else if (group_by_type == 'PAYING_COMP') {
			groups_list.push({'id' : 'PAYING', 'label' : 'Paying', 'actuals' : []});
			groups_list.push({'id' : 'COMP', 'label' : 'Comp', 'actuals' : []});
		}
    	var groups_map = {};
		for (var j=0; j < groups_list.length; j++) {
			var group = groups_list[j];
			groups_map[group['id']] = group;
		}

		for (var page = 0; page < this._num_res_pages; page++) {
			var actuals = this._actuals_loaded[page];
			for (var j=0; j < actuals.length; j++) {
				var actual = actuals[j];
				if (group_by_type == 'DEFAULT') {
					group_id = '';
				} else if (group_by_type == 'SEATING_AREA') {
					if (actual.is_bar_reservation) {
						group_id = 'BAR';
					} else {
						group_id = actual.venue_seating_area_id;
						var group_label;
						if (group_id && this._seating_area_id_to_code[group_id] !== undefined) {
							group_label = this._seating_area_id_to_code[group_id];
						} else {
							group_id = '';
							group_label = 'Unassigned';
						}
						if (groups_map[group_id] === undefined) {
							var new_group = {'id' : group_id, 'label' : group_label, 'actuals' : []};
							groups_map[group_id] = new_group;
							groups_list.push(new_group);
						}
					}
				} else if (group_by_type == 'TABLE_BAR') {
					group_id = actual.is_bar_reservation ? 'BAR' : 'TABLE';
				} else if (group_by_type == 'PAYING_COMP') {
					group_id = actual.is_comp ? 'COMP' : 'PAYING';
				}

				groups_map[group_id].actuals.push(actual);
			}
		}

		if (group_by_type == 'SEATING_AREA') {
			// Sort seating areas by name
			groups_list.sort(function(a,b) {
				if (a['id'] === 'BAR') {
					return 1;
				} else if (b['id'] === 'BAR') {
					return -1;
				}
				return a['label'] < b['label'] ? -1 : (a['label'] > b['label'] ? 1 : 0);
			});
		}
		// Filter empty out
		var non_empty_groups_list = [];
		for (var j=0; j < groups_list.length; j++) {
			var group = groups_list[j];
			if (group.actuals.length > 0) {
				non_empty_groups_list.push(group);
			}
		}


		for (var i = 0; i < non_empty_groups_list.length; i++) {
			var group = non_empty_groups_list[i];
			var actuals = group.actuals;
			var insert_before_div = '#insert-groups';

			var section_group_selector;
			if (group_by_type == 'DEFAULT') {
				var html = Nightloop.Templates.Manager.Home.ActualsGroup({
					'MEDIA_URL' : this._media_url,
					'is_nightlife_class' : this._is_nightlife_class,
					'using_total_dollars_cost_option' : this._using_total_dollars_cost_option,
					'show_cost_options': this._show_cost_options
				});
				section_group_selector = '#section-group--singlegroup';
			} else {

				var html = Nightloop.Templates.Manager.Home.ActualsGroup({
					'MEDIA_URL' : this._media_url,
					'is_nightlife_class' : this._is_nightlife_class,
					'using_total_dollars_cost_option' : this._using_total_dollars_cost_option,
					'show_cost_options': this._show_cost_options,
					'group_name' : group.label + ' (' + actuals.length + ')',
					'group_id' : group.id
				});
				if (group.id) {
					section_group_selector = '#section-group-' + group.id;
				} else {
					section_group_selector = '#section-group--singlegroup';
				}
			}
			$(html).insertBefore(insert_before_div);

			// insert ressies
			if (this._res_display_grouping === 'EST_ARR_TIME') {
				this._loadGroupedReservationsByEstArrTime(actuals, section_group_selector);
			} else {
				this._loadGroupedReservations(actuals, section_group_selector);
			}
		}
	},

	_loadGroupedReservations : function(actuals_master, insert_before_div) {
		var show_new_actual_row = $('.show-new-actual-row');
		var show_new_actual_id = undefined;
		if (show_new_actual_row.length !== 0) {
			show_new_actual_id = show_new_actual_row.attr('id');
		}
		var actuals_html = '';
		for (var j = 0; j < actuals_master.length; j++) {
			var actual = actuals_master[j];
			var html = Nightloop.Templates.Manager.Home.ActualRow({
				'MEDIA_URL': this._media_url,
				'actual': actual,
				'isEven': (j % 2) == 0,
				'isCanceled': false,
				'is_nightlife_class': this._is_nightlife_class,
				'show_cost_options': this._show_cost_options,
				'show_time_column': this._res_display_grouping
			});
			if (show_new_actual_row.length !== 0) {
				if (show_new_actual_id === $(html).attr('id')) {
					// Then remove the existing one
					show_new_actual_row.remove();
					show_new_actual_row = [];
				}
			}
			actuals_html += html;
		}
		// Enclose in div so tsort doesn't change timeslot groups
		actuals_html = "<div>" + actuals_html + "</div>";
		$(actuals_html).insertBefore(insert_before_div); // Always insert at end
	},


	_loadGroupedReservationsByEstArrTime : function(actuals_master, insert_before_div) {
		var show_new_actual_row = $('.show-new-actual-row');
		var show_new_actual_id = undefined;
		if (show_new_actual_row.length !== 0) {
			show_new_actual_id = show_new_actual_row.attr('id');
		}

		var timeslot_groups = {};
		var timeslots_ordered = [];
		for (var i = 0; i < actuals_master.length; i++) {
			var actual = actuals_master[i];
			var sort_order = actual.est_arrival_time_sort_order;
			if (timeslot_groups[sort_order] === undefined) {
				timeslot_groups[sort_order] = [actual];
				Pmp.Utils.Array.InsertSorted(sort_order, timeslots_ordered);
			} else {
				// Leverage the sorted-by-name pages, so just push onto the end
				timeslot_groups[sort_order].push(actual);
			}
		}
		for (var i = 0; i < timeslots_ordered.length; i++) {
			var sort_order = timeslots_ordered[i];
			var actuals = timeslot_groups[sort_order];
			var timeslot_actuals_html = '';
			var num_guests_display = this._getNumGuestsDisplay(actuals);
			for (var j = 0; j < actuals.length; j++) {
				var actual = actuals[j];
				if (j === 0) {
					// Insert group header
					var header_title = (sort_order == -1) ? 'No Time Specified' : actual.est_arrival_time_display;
					header_title += ' (' + actuals.length + ') - ' + num_guests_display;
					var html = Nightloop.Templates.Manager.Home.ActualGroupHeader({
						'header_title' : header_title
					});
					$(html).insertBefore(insert_before_div);
				}
				var html = Nightloop.Templates.Manager.Home.ActualRow({
					'MEDIA_URL' : this._media_url,
					'actual' : actual,
					'isEven' : (i % 2) == 0,
					'isCanceled' : false,
					'is_nightlife_class' : this._is_nightlife_class,
					'show_cost_options': this._show_cost_options,
					'show_time_column': this._res_display_grouping
				});
				if (show_new_actual_row.length !== 0) {
					if (show_new_actual_id === $(html).attr('id')) {
						// Then remove the existing one
						show_new_actual_row.remove();
						show_new_actual_row = [];
					}
				}
				timeslot_actuals_html += html;
			}
			// Enclose in div so tsort doesn't change timeslot groups
			timeslot_actuals_html = "<div>" + timeslot_actuals_html + "</div>";
			$(timeslot_actuals_html).insertBefore(insert_before_div); // Always insert at end
		}
	},

	_populateMoreReservations : function(payload) {
		this._num_res_pages_received ++;
		var is_all_loaded = this._num_res_pages_received == this._num_res_pages;
		var content = payload.content;
		var page = content.page_num;

		// also cache
		this._actuals_loaded[page] = content.actuals;

		var is_last_page = page === this._num_res_pages - 1;
		var show_new_actual_row = $('.show-new-actual-row');
		var show_new_actual_id = undefined;
		if (show_new_actual_row.length !== 0) {
			show_new_actual_id = show_new_actual_row.attr('id');
		}
		for (var i = 0; i < content.actuals.length; i++) {
			var html = Nightloop.Templates.Manager.Home.ActualRow({
					'MEDIA_URL' : this._media_url,
					'actual' : content.actuals[i],
					'isEven' : (i % 2) == 0,
					'isCanceled' : false,
					'is_nightlife_class' : this._is_nightlife_class,
					'show_cost_options': this._show_cost_options,
					'page' : page,
					'show_time_column': this._res_display_grouping
				});
			html = $(html);
			if (show_new_actual_row.length !== 0) {
				if (show_new_actual_id === html.attr('id')) {
					// Then remove the existing one
					show_new_actual_row.remove();
					show_new_actual_row = [];
				}
			}
			html.insertBefore('#insert-res-page-'+page);
		}

		$('.page-'+page).fadeIn('slow');

		// Show spinners for page gaps above
		$('#insert-res-page-spinner-'+page).remove();
		$('.insert-res-page-spinner').each(function(i,el) {
			if (parseInt($(el).attr('page_num'),10) < page) {
				$(el).show();
			}
		});

		this._moveShowNewActualToSortPosition();
		if (is_all_loaded) {
			$('#loading-row').hide();
		}
	},

	_moveShowNewActualToSortPosition : function() {
		if ($('.show-new-actual-row').length === 0) {
			return;
		}
		var show_new_actual_row_sort_name = $('.show-new-actual-row').attr('client_sort_name');
		var actual_rows = $('.actual-row');
		var last_smaller_row = undefined;
		for (var i = 0; i < actual_rows.length; i++) {
			var actual_row = $(actual_rows[i]);
			if (actual_row.attr('client_sort_name') < show_new_actual_row_sort_name) {
				last_smaller_row = actual_row;
			}
		}
		// Check one more time that it's not removed yet for race conditions
		if ($('.show-new-actual-row').length === 0) {
			return;
		}
		if (last_smaller_row !== undefined) {
			$('.show-new-actual-row').insertAfter(last_smaller_row);
		}
	},

	_lazyLoadGuestlist : function() {
		if (this._can_view_guestlist === false) {
			return;
		}

		// show spinning icon in place of link
		$('#loading-guestlist-row').fadeIn('fast');

		this._num_guestlist_pages_received = 0;
		for (var count=0; count < this._num_guestlist_pages; count++) {
			var url = this._managerBaseUrl + '/home/day/guestlist/' + this._dateUrlParam;
			url += '?type_key=view&fae=y&page=' + count;
			var self = this;
			Pmp.Client.AsyncGet(url,
	                function(data) { self._populateMoreGuestlist(data.payload); });
		}
	},

	_populateMoreGuestlist : function(payload) {
		this._num_guestlist_pages_received++;
		var is_all_loaded = this._num_guestlist_pages_received == this._num_guestlist_pages;
		var content = payload.content;
		var page = content.page_num;
		var is_last_page = page === this._num_guestlist_pages - 1;
		var combined_html = '';
		for (var i = 0; i < content.actuals.length; i++) {
			//var isLastActual = is_last_page && (i == (content.actuals.length-1));
			var html = Nightloop.Templates.Manager.Home.GuestListRow({
					'actual' : content.actuals[i],
					'page' : page
				});

			combined_html += html;
		}
		$(combined_html).insertBefore('#insert-guestlist-page-'+page);

		// Show spinners for page gaps above
		$('#insert-guestlist-page-spinner-'+page).remove();
		$('.insert-guestlist-page-spinner').each(function(i,el) {
			if (parseInt($(el).attr('page_num'),10) < page) {
				$(el).show();
			}
		});

		if (is_all_loaded) {
			$('#loading-guestlist-row').hide();
		}
	},

    _onClickEditDailyNotesLink : function(event) {
        var self = this;
		var onCloseOverlayFn = function() {self._onCloseOverlay();};
		var url = this._managerBaseUrl + '/home/dailynotes/' + this._dateUrlParam + '/edit';
		Pmp.Main.Popup.Base.loadUrl(url, true);
		Pmp.Main.Popup.Base.showPopup(400, undefined /*onCompleteFnHook*/, onCloseOverlayFn);
    },

	_onSelectDate : function(dateText, calendar) {
		this.debug("_onSelectDate");
		if (this._initDateText !== dateText) {

			var dateObj = $('#monthly-calendar').datepicker('getDate');
			var dateText = $.datepicker.formatDate('mm-dd-yy', dateObj);

			//var dateHyphenated = dateText.replace(/\//ig, '-');
			var relativeUrl = this._managerBaseUrl + "/home/day/"+ dateText;
			Pmp.Client.Static.Page.Navigate('#nightloop', relativeUrl);
		}
	},

	_onClickRefreshLink : function(event) {
		this.debug("_onClickRefreshLink");
		var relativeUrl = this._managerBaseUrl + "/home/day/"+ this._dateUrlParam + "?ic=1"; //invalidate cache
		Pmp.Client.Static.Page.Navigate('#nightloop', relativeUrl);
	},

	_onClickGroupLink : function(event) {
		this.debug("_onClickGroupLink");
		if (this._regroupDropDownIsShown()) {
			this._hideRegroupDropDown();
		} else {
			this._showRegroupDropDown();
		}
	},

	_regroupDropDownIsShown : function () {
		return !$('.regroup-dropdown-container').hasClass('no-display');
	},

	_showRegroupDropDown : function () {
		$('.regroup-dropdown-container').removeClass('no-display');
	},

	_hideRegroupDropDown : function () {
		$('.regroup-dropdown-container').addClass('no-display');
	},

	_saveVenueSetting: function(group_by_type) {
		var relativeUrl = this._managerBaseUrl + "/home/save/venue-user";
		var data = {
				'group-by-type' : group_by_type
		};
		Pmp.Client.LoadAjaxData(relativeUrl, data, true, function() {});
	},

	_onClickRegroupDefault : function(event, obj) {
		metric.track('Reservations.groupByDefault', {'page': 'reservations'});
		this._clearDashboardActuals();
		this._groupActualsByTypeAndOrTime('DEFAULT');
		$('.regroup-dropdown-link').removeClass('regroup-dropdown-selected');
		$(obj).addClass('regroup-dropdown-selected');
		this._saveVenueSetting('DEFAULT');
	},
	_onClickRegroupSeatingArea : function(event, obj) {
		metric.track('Reservations.groupBySeatingArea', {'page': 'reservations'});
		this._clearDashboardActuals();
		this._groupActualsByTypeAndOrTime('SEATING_AREA');
		$('.regroup-dropdown-link').removeClass('regroup-dropdown-selected');
		$(obj).addClass('regroup-dropdown-selected');
		this._saveVenueSetting('SEATING_AREA');
	},
	_onClickRegroupTableBar : function(event, obj) {
		metric.track('Reservations.groupByTableBar', {'page': 'reservations'});
		this._clearDashboardActuals();
		this._groupActualsByTypeAndOrTime('TABLE_BAR');
		$('.regroup-dropdown-link').removeClass('regroup-dropdown-selected');
		$(obj).addClass('regroup-dropdown-selected');
		this._saveVenueSetting('TABLE_BAR');
	},
	_onClickRegroupPayingComp : function(event, obj) {
		metric.track('Reservations.groupByPayingComp', {'page': 'reservations'});
		this._clearDashboardActuals();
		this._groupActualsByTypeAndOrTime('PAYING_COMP');
		$('.regroup-dropdown-link').removeClass('regroup-dropdown-selected');
		$(obj).addClass('regroup-dropdown-selected');
		this._saveVenueSetting('PAYING_COMP');
	},

	_clearDashboardActuals : function () {
		// remove headers
		$('.section-header').remove();
		$('.actual-group-header').remove();
		// remove actuals
		$('.booked-row').remove();
		$('.section-block').remove();
	},

	_beforeShowDay : function(date) {
		var date_formatted = $.datepicker.formatDate('mm/dd/y', date);
		var day_of_week_short = $.datepicker.formatDate('D', date);
		var dow_index = $.inArray(day_of_week_short, ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']);
		var td_class = '';

		/* Trumping order of background colors:
		 *  - Blacked-out
		 *  - Today
		 *  - Closed Date (except custom opened ones)
		 *  - Passed Date
		 */
		if ($.inArray(date_formatted, this._blackedDates)  >= 0) {
			td_class = 'blacked-date';
		} else if (date.compareTo(this._today_date) == 0) {
			td_class = 'today-date'
		} else if (!this._daysOfOperation[dow_index] && $.inArray(date_formatted, this._customOpenedDates) < 0) {
			td_class = 'closed-date';
		} else if (date.compareTo(this._today_date) < 0) {
			td_class = 'passed-date';
		}

		/* Added Background images:
		 *  - Booked date
		 */
		if ($.inArray(date_formatted, this._bookedDates)  >= 0) {
			if (td_class === 'closed-date' && date.compareTo(this._today_date) < 0) {
				// If it has past reservations, make it appear past and open, not closed
				td_class = 'passed-date';
			}
			td_class += ' booked-date';
		}

		return [true, td_class];
	},

	_onClickBookedResRow : function(event) {

		// avoid clicks to received
		if ($(event.target).hasClass('received-button')) {
			return;
		}

		var booked_row = Pmp.Utils.GetParentElemForEventHandle(event, '.booked-row');
		var res_id = $(booked_row).attr('res_id');
		var url = '';
		if (res_id !== undefined) {
			url = this._managerBaseUrl + '/reservation/' + res_id + '/view';
		} else {
			var actualid = $(booked_row).attr('actualid');
			url = this._managerBaseUrl + '/actual/' + actualid + '/view';
		}

		var self = this;
		var onCloseOverlayFn = function() {self._onCloseOverlay();};
		Pmp.Main.Popup.Base.loadUrl(url, true);
		Pmp.Main.Popup.Base.showPopup(650, undefined /*onCompleteFnHook*/, onCloseOverlayFn);
	},

	_onClickGuestlistRow : function (event) {
		var glist_row = Pmp.Utils.GetParentElemForEventHandle(event, '.guestlist-row');
		var actual_id = $(glist_row).attr('actualid');
		var url = this._managerBaseUrl + '/home/guestlist/' + this._dateUrlParam + '/' + actual_id + '/edit';
		Pmp.Client.Static.Page.Navigate('#nightloop', url);

	},

	_onCloseOverlay : function() {
		if (this._doRefreshOnClose) {
			// then need to refresh the page
			var url = Pmp.Client.getRelativeCurrentPageAjaxified();
			Pmp.Client.Static.Page.Load('#nightloop', url);
		}
	},

	_onClickShowCanceled : function(event) {
		$('.show-canceled-link').hide();
		$('.hide-canceled-link').show();
		$('#canceled-rows').show();
	},

	_onClickHideCanceled : function(event) {
		$('.show-canceled-link').show();
		$('.hide-canceled-link').hide();
		$('#canceled-rows').hide();
	},

	_onMouseEnterClientRequest : function(event) {
		var client_request_hover = $(event.target);
		var client_request = client_request_hover.siblings('.client-request');
		client_request.show();
	},

	_onMouseLeaveClientRequest : function(event) {
		var client_request_hover = $(event.target);
		var client_request = client_request_hover.siblings('.client-request');
		client_request.hide();
	},

	_onMouseEnterClientInfoReserved : function(event) {
		var client_request_hover = $(event.target);
		var client_request = client_request_hover.siblings('.client-info-reserved');
		client_request.show();
	},

	_onMouseLeaveClientInfoReserved : function(event) {
		var client_request_hover = $(event.target);
		var client_request = client_request_hover.siblings('.client-info-reserved');
		client_request.hide();
	},

	setDoRefreshOnClose : function() {
		this._doRefreshOnClose = true;
	},

	_onClickExpandGuestlistBlock : function(event) {
		$('#expand-guestlist-block').addClass('no-display');
		$('#collapse-guestlist-block').removeClass('no-display');
		$('#guestlist-block').slideDown();
	},

	_onClickCollapseGuestlistBlock : function(event) {
		$('#expand-guestlist-block').removeClass('no-display');
		$('#collapse-guestlist-block').addClass('no-display');
		$('#guestlist-block').slideUp();
	},


  // Sort defaults
  prev_sort_order: 'desc',
  prev_down_sort_key: 'name',

	_onClickSortLink : function(event, element) {

		if ($(element).closest('#guestlist-box').length > 0) {
			var container_id = '#guestlist-box';
			var row_class = '.guestlist-row';
		} else {
			var container_id = '#booked-box';
			var row_class = '.booked-row';
		}

    if( $(row_class, container_id).length == 0 ) return;

		var sort_key = $(element).attr('sort_key');
		var sort_attr = 'sort_' + sort_key;
		var sort_order = $(container_id).data('prev_sort_order') == 'desc' ? 'asc' : 'desc';

		if (this.prev_down_sort_key !== sort_key && container_id == this.prev_container) {
			sort_order = 'asc';
		}

    $(container_id).data('prev_sort_order', sort_order);

    this.prev_container = container_id;
    this.prev_down_sort_key = sort_key;

		$(container_id + ' .sort-link .up-arrow').addClass('no-display');
		$(container_id + ' .sort-link .down-arrow').addClass('no-display');
		if (sort_order === 'asc') {
			$(container_id + ' .sort-link[sort_key=' + sort_key + '] .down-arrow').removeClass('no-display');
		} else {
			$(container_id + ' .sort-link[sort_key=' + sort_key + '] .up-arrow').removeClass('no-display');
		}

		var iAsc = sort_order=='asc'?1:-1;
		var sort_fn = function(a, b) {
			var sA = $.trim(a.s), sB = $.trim(b.s);
			// Always bubble empty rows to the bottom
			if (sA === '') {
				return 1;
			} else if (sB === '') {
				return -1;
			}
			// Sort numbers
			fA = parseFloat(sA, 10);
			fB = parseFloat(sB, 10);
			if (!isNaN(fA) && !isNaN(fB)) {
				return iAsc*(fA<fB?-1:(fA>fB?1:0));
			}
			return iAsc*(sA<sB?-1:(sA>sB?1:0));
		};

		this.debug('_onClickSortLink: '+ sort_key + ', ' + sort_order);
		$(row_class).tsort({attr:sort_attr, order:sort_order, sortFunction:sort_fn});
	},

	_onClickReceivedLink : function(event, obj) {
		var self = this;

		var actual_id = $(obj).parents('.booked-row').attr('actualid');
		var received_col = $(obj).parents('.booked-row').find('.col-received');
		var spinner = received_col.find('.received-spinner');
		var relativeUrl = this._managerBaseUrl + "/actual/" + actual_id + "/save-edits";
		var data = {
				'is_received' : 'y'
		};

		$(obj).hide();
		spinner.removeClass('no-display');
		Pmp.Client.LoadAjaxData(relativeUrl, data, true, function(data) {
			spinner.addClass('no-display');
			if (data.payload.success) {
				var html = Nightloop.Templates.Manager.Home.ReceivedLink({
					'actual' : data.payload.actual,
					'MEDIA_URL' : self._media_url
				});
				received_col.html(html);
			} else {
				$(obj).show();
			}
		});
		return false; // stop further event propagation
	}

};
