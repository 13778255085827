// This file was automatically generated from manager.manage.eventmanager.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Marketing == 'undefined') { Nightloop.Templates.Manager.Marketing = {}; }


Nightloop.Templates.Manager.Marketing.EventManager = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Marketing.EventManagerBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Marketing.EventManagerBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Marketing.Sidebar(soy.$$augmentData(opt_data, {subcategory: 'events_manager'}), output);
  output.append('<!-- ', soy.$$escapeHtml(opt_data.venue), ' --><div id="page-event-manager" class="page-view-mode has-sidebar"><input type="hidden" id="_venue_id" value="', soy.$$escapeHtml(opt_data.venue.url_key_or_id), '" /><input type="hidden" id="can_edit_comp" value="', (opt_data.venue_settings.can_edit_comp) ? '1' : '0', '" /><input type="hidden" id="today_date_url" value="', soy.$$escapeHtml(opt_data.content.venue_today_urlparam), '" /><input type="hidden" id="tax_groups" value="', soy.$$escapeHtml(opt_data.content.tax_groups), '" /><input type="hidden" id="default_service_charge" value="', soy.$$escapeHtml(opt_data.content.default_service_charge), '" /><input type="hidden" id="default_gratuity" value="', soy.$$escapeHtml(opt_data.content.default_gratuity), '" /><input type="hidden" id="event_widget_processing_fee" value="', soy.$$escapeHtml(opt_data.content.event_widget_processing_fee), '" /><input type="hidden" id="currency_symbol" value="', soy.$$escapeHtml(opt_data.content.currency_symbol), '" /><input type="hidden" id="currency_code" value="', soy.$$escapeHtml(opt_data.content.currency_code), '" />', (! opt_data.is_payments_enabled) ? '<div class="instructions" style="font-size: 120%; line-height: 30px; height: 30px; background-color:red"><a href="/manager/' + soy.$$escapeHtml(opt_data.venue.url_key_or_id) + '/manage/bank" style="color: white; padding-left: 15px">Set up a Stripe Account here before you create any paid ' + soy.$$escapeHtml(opt_data.content.paid_setup_text) + '</a></div>' : '', '<div id="event-manager"></div><script>var MEDIA_URL = \'', soy.$$escapeHtml(opt_data.MEDIA_URL), '\';<\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/manager/events.js']), '" crossorigin="anonymous"><\/script></div>');
  return opt_sb ? '' : output.toString();
};
