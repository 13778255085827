var Payments = {

  base_url: $('#base_url').val(),
  type: 'payments',
  more: false,
  first: true,  // ugh
  cursor: null,
  params: {
  },

  // TODO: have base module and children to avoid this type switching
  // type = 'payments'
  init: function(STRIPE_KEY, type)
  {
    var that = this;

    this._base = Pmp.Manager.Reports.Base;
    this._base.initialize();

    if (type) {
      this.type = type;
    }
    this.params.type = type;

    BillingService.init(STRIPE_KEY);

    this.bind();

    $('#show-more').hide();


    ReservationSlideOut.onBookNew();
    ReservationSlideOut.onEdit();
    ReservationSlideOut.onCancel();
    ReservationSlideOut.onUncancel();

    Pmp.Manager.Global.subscribeOnUserDomainVenuesLoad(function (data) {
      $('.venue-type-filter').append(_.map(data.venues, function (x) {
        return Nightloop.Templates.Manager.Reports.VenueOption({
          label: x.internal_display_name,
          id: x.id_short,
          checked: Pmp.Manager.Global._url_key_or_id == x.url_key
        });
      }));

      if (type == 'payments') {
        $('#search-form-submit').click();
      }

    });
  },

  bind: function()
  {
    var that = this;
    $('#show-more').on('click', function(e) { that.fetch(); });

    $('#payment-date-filter').on('change', function(e) {
      if ($(this).val() == 'CUSTOM') {
        $('.custom-range').show();
      } else {
        $('.custom-range').hide();
      }
    });

    $('#search-form-submit').on('click', function() {
      that.params = $('#search-and-filter-form').serializeObject();
      that.first = true;
      that.cursor = null;
      that.more = true;
      $('#' + that.type + '-block').empty();
      that.fetch();
      return false;
    });

    $(document).on('click', '.col-description > .reference-code', function(event) {
      var entityId = $(event.currentTarget).attr('actualid');
      var venueId = $(event.currentTarget).attr('venue_id');
      var service_type = $(event.currentTarget).attr('service_type');
      var transaction_type = $(event.currentTarget).attr('transaction_type');
      if (globalInit.venueSettings.use_supafly) {
        if (transaction_type === 'transaction_type_web_order_charge' || service_type === 'Order') {
			    SvrManager.ActualSlideout.closeSlideout();
			    SvrManager.OrderSlideout.viewOrder(venueId, entityId);
        } else {
          SvrManager.OrderSlideout.closeSlideout();
          SvrManager.ActualSlideout.viewActual(entityId);
        }
      } else {
        ReservationSlideOut.showReservation(entityId);
      }
    });

    var $type_filter_wrapper = $('.venue-type-filter-wrapper');

    $(document).on('click',function(e) {
      $type_filter_wrapper.removeClass('show');
    });

    $type_filter_wrapper.find('.venue-type-filter').find('.all').click(function(e) {
      $(e.currentTarget).siblings('input').each(function(i, el) {
        $(el).attr('checked', $(e.target).is(':checked'));
      })
    });

    $type_filter_wrapper.click(function(e) {
      if (!$(e.target).hasClass('checkbox')) {
        $(e.currentTarget).toggleClass('show');
      }
      if (e.stopPropagation) {
        e.stopPropagation();
      } else if(window.event) {
        window.event.cancelBubble=true;
      }
    });

    $('#export-payments').on('click', function(event) { that._onClickExportLink(event);});
  },

  render: function(data)
  {
    $('#list-loader').hide();
    if (!data.length && this.first) {
      var empty = $('<p/>').addClass('no-content').sext('No activity to show');
      $('#' + this.type + '-block').append(empty);
      return;
    }

    for (iter=0; iter<data.length; iter++) {
      var item = data[iter];

      // Prevents pending/orphaned auths from
      // showing up as failed charges.
      if (item.status === 'status_authorized') {
        continue;
      }

      if (this.type == 'payments') {
        var row = this.renderPaymentRow(item);
      } else {
        var row = this.renderTransferRow(item);
      }

      $('#' + this.type + '-block').append($(row));
    }

    if (this.more) {
      $('#show-more').show();
    } else {
      $('#show-more').hide();
    }
  },

  getTransactionTypeToStr: function(transaction_type) {
    return transaction_type
      .replace('transaction_type_web_', '')
      .replace('transaction_type_3d_secure_', '')
      .replace('transaction_type_', '')
      .replace('transaction_payment_', '')
      .replace('status_', '')
      .replaceAll('_', ' ')
  },

  // attempting to keep this *somewhat* modular
  // in case we need to divide things up soon.
  renderPaymentRow: function(item)
  {
      var client = item.email;
      if (!client) {
        client = item.client_name;
      }

      var venue = _.findWhere(Pmp.Manager.Global.UserDomainVenues.venues, {id: item.venue_id});
      var row = Nightloop.Templates.Manager.Reports.PaymentRow({
        'base_url': this.base_url,
        'name': venue ? venue.internal_display_name : null,
        'date': item.charged_formatted,
        'res_date': item.res_date,
        'res_notes': item.res_notes,
        'venue_group_client_id': item.venue_group_client_id,
        'entity_id': item.entity_id,
        'plan': item.plan_name,
        'reference_code': item.reference_code,
        'client': client,
        'successful': item.successful,
        'card_display': item.card_display,
        'amount': item.amount_formatted,
        'base': item.original_base_formatted,
        'upsell': item.upsell_amount_formatted,
        'service_charge': item.service_charge_formatted,
        'gratuity': item.gratuity_formatted,
        'tax': item.tax_formatted,
        'custom': item.additional_fee_formatted,
        'custom_tax': item.additional_fee_tax_formatted,
        'processing': item.processing_fee_formatted,
        'promo_discount': item.promo_discount_amount_formatted,
        'is_refund': item.is_refund,
        'is_superuser': this.is_superuser,
        'report_refund_breakdown': item.report_refund_breakdown,
        'transaction_type': item.transaction_type,
        'transaction_type_str': this.getTransactionTypeToStr(item.transaction_type),
        'venue_id': item.venue_id,
        'service_type': item.service_type,
      });

      return row;
  },


  renderTransferRow: function(item)
  {
      var row = Nightloop.Templates.Manager.Reports.TransferRow({
        'date': item.date_created,
        'amount': item.amount_formatted
      });

      return row;
  },

  _onClickExportLink: function (event) {
      Pmp.Main.Popup.Base.loadHtml(Nightloop.Templates.Manager.Reports.ConfirmReportExportPopup({
        user_email: Pmp.Manager.Global._user_email
      }))
      metric.track('Payments.export');
      Pmp.Main.Popup.Base.showPopup();
      this.paymentsExport();
  },

  updatePrintHeader: function()
  {
    if ( !('date' in this.params) ) return;

    var display = [];

    if (this.params.q) {
      display.push('Searching for "' + this.params.q + '"')
    }

    if (this.params.date == 'CUSTOM') {
      display.push(this.params['from-date'] + ' - ' + this.params['to-date']);
    } else {
      display.push(this.params.date.split(',').join(' - '));
    }

    var addendum = '';
    switch (this.params.transaction_type) {
      case 'ALL':
        addendum = ' transactions';
        break;
      case 'INTERNAL':
      case 'WEB':
        addendum = ' reservations';
        break;
    }

    display.push(this.params.transaction_type.toLowerCase() + addendum);

    var _status = this.params.status.toLowerCase();
    if (_status == 'all') {
      _status = _status + ' statuses';
    }

    display.push(_status);

    $('#print-info-display').sext(display.join(' - '));
  },

  paymentsExport : function () {
    var that = this;
		$('#confirm-report-export-button').on('click', _.bind(function () {
		  var button = $(this).parents('.button');
			if (button.hasClass('disabled')) {
				return;
			}

			$.ajax({
	      url: '/manager/'+ Pmp.Manager.Global._url_key_or_id + '/payments/export',
				method: 'post',
	      data: that.params,
				error : function () {
					button.removeClass('disabled');
				},
	      success: function(resp) {
	        button.removeClass('disabled');
					$('#export-prompt-text').hide();
					$('#export-processing-text').show();
					$('#export-button-container').hide();
					$.colorbox.resize();
	      }
	    });
		}, this));

	},

  fetch: function()
  {
    $('#list-loader').show();

    this.params['cursor'] = this.cursor;
    this.params['type'] = this.type;

    var that = this;

    var billing = BillingService.listPayments(this.params);
    billing.done(function(resp) {
      that.cursor = resp.cursor;
      that.more = resp.more;
      that.is_superuser = resp.is_superuser;
      that.render(resp.charges);
      that.first = false;
      that.updatePrintHeader();
    });
  }

};
