
var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Access = Pmp.Manager.Access || {};
Pmp.Manager.Access.User = Pmp.Manager.Access.User || {};

Pmp.Manager.Access.User.AddFromAnotherVenue = {
    initialize : function() {
		this._bindEventHandlers();
    },

    log : function(msg) {
		console.log("Pmp.Manager.Access.User.AddFromAnotherVenue: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
    
    _bindEventHandlers : function() {
        this.debug("_bindEventHandlers");

        var self = this;
        var submitHandlerFn = function() {self._submitHandler($(this).closest('form')); };

        $('#add-from-another-venue-btn').click(submitHandlerFn)
    },

    _submitHandler : function(formHandle) {
		$(formHandle).submit();
	}
};