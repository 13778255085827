/**
 * Created by krunal on 9/1/15.
 * A common module to use for manipulating reservation tags and client tags on the different widgets
 */
var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};
Pmp.Manager.Generic = Pmp.Manager.Generic || {};
Pmp.Manager.Generic.Tags = function () {
    return {
        initialize: function (tag_groups,
                              container_div_lookup,
                              tags_input_identifier,
                              tags_results_identifier,
                              tags_input_parent_identifier,
                              tag_item_identifier,
                              tag_table_identifier,
                              tag_table_group_identifier) {
            this._tags_input_identifier = tags_input_identifier || '#find-tags-input';
            this._container_div = $(container_div_lookup);
            this._tags_input = this._container_div.find(tags_input_identifier || '#find-tags-input').find('span');
            this._tags_results = this._container_div.find(tags_results_identifier || '#find-tags-results');
            this._tags_input_parent = this._container_div.find(tags_input_parent_identifier || '.find-tags-container');
            this._tag_item_identifier = tag_item_identifier || '.tag-item-container';
            this._tag_table_identifier = tag_table_identifier || '.tag-table-container';
            this._tag_table_group_identifier = tag_table_group_identifier || '.tag-group-container';
            this._tags_group = '.tags-group';
            this._tag_group_id = '.tag-group-id';
            this._tag_item = '.tag-item';
            this._tag_item_color_identifier = '.tag-item-color';
            this._privacy = '.privacy';
            this._restricted = '.is_restricted';
            this._tag_name = '.tag-name-input';
            this._tag_name_display = '.tag-name';
            this._tag_groups = tag_groups;
            this._lookup_input = this._container_div.find('.lookup');

            return this;
        },

        bindEvents: function () {
            var that = this;
            this._tags_input.on('mouseup', function(e) {
                that._lookup_input.focus();
                e.preventDefault()
            });
            $(this._tags_input_identifier).on('click', function (e) {
                that._lookup_input.focus();
                that._tags_results.show();
            });
            this._lookup_input.on('keyup', {}, _.debounce(_.bind(function (ev) {
                if ((ev.which == 37) || (ev.which == 38)) { // left/up
                    this._onFindTagsPressUp();
                } else if ((ev.which == 39) || (ev.which == 40)) { // right/down
                    this._onFindTagsPressDown();
                } else if (ev.which == 13) { // enter
                    this._onFindTagsPressEnter();
                } else {
                    this._onFindTagsInputKeypress(ev.currentTarget);
                }
                ev.preventDefault();
            }, this), 400));

            this._lookup_input.on('click', {},
                _.bind(function (ev) {
                    this._onFindTagsInputKeypress(ev.currentTarget)
                }, this)
            );

            this._tags_results.find(this._tag_item_identifier).on('mousedown', {}, _.bind(function (ev) {
                this._onAddTagToSearch(ev.currentTarget);
                this._tags_results.hide();
                ev.stopPropagation();
            }, this));

            $(document).on('mouseup', {}, _.bind(function (ev) {
                this._hideTagsPicker(ev.target);
            }, this));
        },

        _onFindTagsPressUp: function () {
            var selected = this._container_div.find(this._tag_table_identifier).find(this._tag_item_identifier).find('.selected'),
                all_visible = this._container_div.find(this._tag_table_identifier).find(this._tag_item_identifier).filter(':visible'),
                idx = all_visible.index(selected);

            if ((idx - 1) >= 0) {
                var new_selected = all_visible.get(idx - 1);
                if ($(new_selected).length > 0) {
                    $(new_selected).addClass('selected');
                    $(selected).removeClass('selected');
                }
            }
        },

        _onFindTagsPressDown: function () {
            var selected = this._container_div.find(this._tag_table_identifier).find(this._tag_item_identifier).find('.selected'),
                all_visible = this._container_div.find(this._tag_table_identifier).find(this._tag_item_identifier).filter(':visible'),
                idx = all_visible.index(selected);

            if ((idx + 1) < all_visible.length) {
                var new_selected = all_visible.get(idx + 1);
                if ($(new_selected).length > 0) {
                    $(new_selected).addClass('selected');
                    $(selected).removeClass('selected');
                }
            }
        },

        _onFindTagsPressEnter: function () {
            this._container_div.find(this._tag_table_identifier).find(this._tag_item_identifier).find('.selected').mousedown();
        },

        search: function (search_str) {
            search_str = search_str.toLowerCase();
            var results = [];
            for (var i = 0; i < this._tag_groups.length; i++) {
                var tag_group = this._tag_groups[i];
                for (var j = 0; j < tag_group.tags.length; j++) {
                    var tag_name = tag_group.tags[j];
                    var tag_name_display = tag_group.tag_name_displays[tag_name] || tag_name;
                    if (this._startsWith(search_str, tag_name_display.toLowerCase())) {
                        results.push({
                            'tag_name': tag_name,
                            'can_manage_restricted_tags': Pmp.Manager.Global._can_manage_private_tags,
                            'tag_group_name': tag_group.name,
                            'tag_group_id': tag_group.id,
                            'privacy': tag_group.privacy,
                            'color_hex': tag_group.color_hex
                        });
                    }
                }
            }
            return results;
        },

        _onFindTagsInputKeypress: function (el) {
            var searchStr = $(el).val(),
                resultsDom = this._tags_results,
                results = this.search(searchStr);

            this._toggle_tags_display(!Boolean(searchStr.length));

            // Hide all the groups preemptively to avoid showing empty
            this._container_div.find('.tag-table-group').hide();

            for (var i = 0; i < results.length; i++) {
                var result = results[i],
                    tag_name = result.tag_name,
                    tag_group_id = result.tag_group_id,
                    privacy = result.privacy,
                    hash = privacy + '##' + tag_group_id + '##' + tag_name;

                this._container_div.find('.tag-group-id-name-hash' + ',' + this._tag_item_identifier).each(function (idx, el) {
                    if ($(el).text() == hash) {
                        $(el).parents(this._tag_table_group_identifier).show();
                        $(el).parents(this._tag_item_identifier).show();
                        return false;
                    }
                });
            }
            var tags = this._container_div.find(this._tag_item_identifier + ',' + this._tag_table_identifier);
            tags.removeClass('selected');
            if (!resultsDom.is(':visible')) {
                resultsDom.show();
            }
            tags.filter(':visible').first().addClass('selected');
        },

        _hideTagsPicker: function (target) {
            var container = this._tags_results;
            if (container.has(target).length === 0) {
                container.hide();
            }
        },

        groupNameFromId: function (id) {
            var group = this._findTagGroupById(id);
            if (group) {
                return group.name;
            }
            return '';
        },
        
        groupNameDisplayFromId: function (id) {
          var group = this._findTagGroupById(id);
          if (group) {
            return group.name_display || group.name;
          }
          return '';
        },

        _findTagGroupById: function (id) {
            for (var i = 0; i < this._tag_groups.length; i++) {
                var tag_group = this._tag_groups[i];
                if (tag_group.id == id) {
                    return tag_group;
                }
            }
            return null;
        },

        groupColorFromId: function (id) {
            var group = this._findTagGroupById(id);
            if (group) {
                return group.color_hex;
            }
            return '#ffffff';
        },

        renderTagTable: function () {
            var html = Nightloop.Templates.Widget.TagTable({
                'tag_groups': this._tag_groups
            });
            this._tags_results.html(html);
        },

        _onAddTagToSearch: function (el) {
            var tag_group_id = $(el).find(this._tag_group_id).text(),
                privacy = $(el).find(this._privacy).text(),
                is_restricted = $(el).find(this._restricted).length,
                tag_group_name = this.groupNameFromId(tag_group_id),
                tag_group_name_display = this.groupNameDisplayFromId(tag_group_id),
                tag_group_color = this.groupColorFromId(tag_group_id),
                tag_name = $(el).find(this._tag_name).text(),
                tag_name_display = $(el).find(this._tag_name_display).text();

            if (this._tagAlreadyExists(tag_group_id, tag_name, this._tags_input)) {
                this._lookup_input.val('');
                return;
            }

            var html = Nightloop.Templates.Widget.GenericTag({
                'newly_added': true,
                'tag_name': tag_name,
                'tag_name_display': tag_name_display,
                'tag_group_id': tag_group_id,
                'tag_group_name': tag_group_name,
                'tag_color': tag_group_color,
                'font_color': sr.formatter.getContrastYIQ(tag_group_color),
                'is_restricted': is_restricted,
                'is_private': privacy.toUpperCase() == 'PRIVATE',
                'close_func': 'Pmp.Manager.Generic.Tags.onCloseTag(this)'
            });

            // find category it belongs to and add after last one
            var added = false;

            this._container_div.find(this._tags_group).each(_.bind(function (idx, el) {
                if ($(el).attr('tag_group_id') === tag_group_id) {
                    // found the group
                    var tag_containers = $(el).find(this._tag_item_identifier);
                    if (tag_containers.length) {
                        $(html).insertAfter(tag_containers.last());
                    } else {
                        $(html).insertBefore(this._container_div.find(this._tags_input), $(el));
                    }

                    added = true;
                    return false;
                }
            }, this));

            // category doesn't exist
            if (!added) {
                var last_tag_group = this._container_div.find(this._tags_group).last();
                if (last_tag_group.length) {
                    var tag_group_html = Nightloop.Templates.Widget.GenericTagsInGroup({
                        'tag_group': {
                            'name': tag_group_name,
                            'name_display': tag_group_name_display,
                            'id': tag_group_id,
                            'is_private': privacy.toUpperCase() == 'PRIVATE',
                            'tags': [{
                                'tag_name': tag_name,
                                'tag_name_display': tag_name_display,
                                'newly_added': true,
                                'tag_color': tag_group_color,
                                'font_color': sr.formatter.getContrastYIQ(tag_group_color),
                                'tag_group_id': tag_group_id,
                                'tag_group_name': tag_group_name,
                                'is_private': privacy.toUpperCase() == 'PRIVATE',
                                'close_func': 'Pmp.Manager.Generic.Tags.onCloseTag(this)'
                            }]
                        },
                        'disabled': 0,
                        'color_hex': tag_group_color,
                        'font_color': sr.formatter.getContrastYIQ(tag_group_color),
                        'can_view_private': true, // doesn't matter
                        'close_func': 'Pmp.Manager.Generic.Tags.onCloseTag(this)'
                    });
                    $(tag_group_html).insertAfter(last_tag_group);
                } else {
                    var tag_group_container_html = Nightloop.Templates.Widget.GenericTagsByGroupDisplay({
                        'tag_groups': [{
                            'name': tag_group_name,
                            'name_display': tag_group_name_display,
                            'id': tag_group_id,
                            'is_private': privacy.toUpperCase() == 'PRIVATE',
                            'color_hex': tag_group_color,
                            'font_color': sr.formatter.getContrastYIQ(tag_group_color),
                            'close_func': 'Pmp.Manager.Generic.Tags.onCloseTag(this)',
                            'tags': [{
                                'tag_name': tag_name,
                                'tag_name_display': tag_name_display,
                                'tag_group_id': tag_group_id,
                                'tag_color': tag_group_color,
                                'font_color': sr.formatter.getContrastYIQ(tag_group_color),
                                'newly_added': true,
                                'tag_group_name': tag_group_name,
                                'is_private': privacy.toUpperCase() == 'PRIVATE'
                            }]
                        }],
                        'can_manage_restricted_tags': Pmp.Manager.Global._can_manage_restricted_tags,
                        'can_view_private': true, // doesn't matter
                        'close_func': 'Pmp.Manager.Generic.Tags.onCloseTag(this)'
                    });
                    this._tags_input.html(tag_group_container_html);
                }
                this.renderTags();
            }

            this._lookup_input.val('');
            this._toggle_tags_display(true);
        },

        _toggle_tags_display: function (should_show) {
            var selectedElements = this._container_div.find(this._tag_table_group_identifier + ',' + this._tag_table_identifier + ',' +
                this._tag_item_identifier + ',' + this._tag_table_identifier);
            if (should_show) {
                $(selectedElements).show();
            } else {
                $(selectedElements).hide();
            }
        },

        _tagAlreadyExists: function (tag_group_id, new_tag_name, tag_group_obj) {
            var tag_map = {};
            $(tag_group_obj).find(this._tag_name).each(_.bind(function (e, div) {
                var tag_name = $(div).text(),
                    existing_tag_group_id =
                        $(div).parents(this._tag_item_identifier).find(this._tag_group_id).filter('span').text();

                tag_map[tag_name.toLowerCase() + existing_tag_group_id] = true;
            }, this));
            return ((new_tag_name.toLowerCase() + tag_group_id) in tag_map);
        },

        _startsWith: function (search_str, full_str) {
            return (full_str.indexOf(search_str) == 0);
        },

        renderTags: function () {

            this._container_div.find(this._tag_item).each(_.bind(function (e, obj) {
                var tag_group_id = $(obj).find(this._tag_group_id).val();
                if (tag_group_id == '') {
                    tag_group_id = $(obj).find(this._tag_group_id).text();
                }
                var tag_group_obj = this._findTagGroupById(tag_group_id);
                // some tags can be removed from tag list but still be on other objects
                if (tag_group_obj) {
                    var bg_color_hex = tag_group_obj.color_hex;
                    $(obj).find(this._tag_item_color_identifier).css({'background-color': bg_color_hex});
                    $(obj).parents(this._tag_item_identifier).attr('sort_order_group', tag_group_obj.sort_order);
                    $(obj).parents(this._tags_group).attr('sort_order_group', tag_group_obj.sort_order);
                    $(obj).parents(this._tags_group).attr('tag_group_id', tag_group_id);
                    if (tag_group_obj.disabled) {
                        $(obj).parents(this._tags_group).remove();
                    }
                }
            }, this));
        },

        sortTags: function (container_id) {
            this._container_div.find(this._tag_item_identifier, container_id).tsort({
                order: 'asc',
                attr: 'sort_order_group'
            }, {data: 'int'});
        },

        sortTagsGroup: function () {
            this._container_div.find(this._tags_group).tsort({order: 'asc', attr: 'sort_order_group'}, {data: 'int'});
        }
    }
}

Pmp.Manager.Generic.Tags.onCloseTag = function (el) {
    var tag = $(el).parents('.tag-item-container'),
        tag_container = $(tag).parents('.tags-group'),
        tag_container_size = _.size($(tag_container).find('.tag-item-container'));
    if (tag_container_size == 1) {
        $(tag_container).remove();
    } else {
        $(tag).remove();
    }
};
